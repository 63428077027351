// Default Drawer data-test-ids
export const defaultDrawerIds: { [key: string]: string } = {
  drawerProjectsIcon: 'drawer-project-icon',
  drawerOrganizationIcon: 'drawer-organization-icon',
  drawerVendorsIcon: 'drawer-vendors-icon',
  drawerSupportIcon: 'drawer-support-icon',
  drawerOpenIcon: 'drawer-open-icon',
  drawerCloseIcon: 'drawer-close-icon',
};

// Header data-test-ids
export const headerIds: { [key: string]: string } = {
  notificationsIcon: 'notifications-icon',
  notificationsUnreadIcon: 'notifications-unread-icon',
};

// Profile Modal data-test-ids
export const profileModalIds: { [key: string]: string } = {
  profileModalLogoutIcon: 'profile-modal-logout-icon',
  profileModalSettingsIcon: 'profile-modal-settings-icon',
  profileModalTokenIcon: 'profile-modal-token-icon',
};

// Evidence Preview Modal data-test-ids
export const evidencePreviewIds: { [key: string]: string } = {
  evidencePreviewCreatedByValue: 'evidence-preview-created-by-value',
  evidencePreviewTypeValue: 'evidence-preview-type-value',
  evidencePreviewLastUpdateValue: 'evidence-preview-last-update-value',
  evidencePreviewFileSizeValue: 'evidence-preview-file-size-value',
};

// New Evidence Modal data-test-ids
export const newEvidenceIds: { [key: string]: string } = {
  newEvidenceEditNameIcon: 'new-evidence-edit-name',
  newEvidenceDeleteNameIcon: 'new-evidence-delete-name',
  newEvidenceRefreshNameIcon: 'new-evidence-delete-name',
  newEvidenceEditDescriptionIcon: 'new-evidence-edit-description',
  newEvidenceDeleteDescriptionIcon: 'new-evidence-delete-description',
  newEvidenceRefreshDescriptionIcon: 'new-evidence-delete-name',
};

// Edot Evidence Modal data-test-ids
export const editEvidenceIds: { [key: string]: string } = {
  editEvidenceEditNameIcon: 'edit-evidence-edit-name',
  editEvidenceDeleteNameIcon: 'edit-evidence-delete-name',
  editEvidenceRefreshNameIcon: 'edit-evidence-delete-name',
  editEvidenceEditDescriptionIcon: 'edit-evidence-edit-description',
  editEvidenceDeleteDescriptionIcon: 'edit-evidence-delete-description',
  editEvidenceRefreshDescriptionIcon: 'edit-evidence-delete-name',
};

// Token Modal data-test-ids
export const tokenModalIds: { [key: string]: string } = {
  tokenModalCopyIcon: 'token-modal-copy-icon',
};

// Invited User Modal data-test-ids
export const invitedUserModalIds: { [key: string]: string } = {
  resendInvitationButton: 'resend-invitation-button',
  cancelInvitationButton: 'cancel-invitation-button',
};

// Control Evidence Component data-test-ids
export const controlEvidenceComponentIds: { [key: string]: string } = {
  controlEvidenceComponentPreviewEvidenceIcon: 'control-evidence-component-preview-evidence-icon',
  controlEvidenceComponentDeleteEvidenceIcon: 'control-evidence-component-delete-evidence-icon',
};

// Project Creation data-test-ids
export const projectCreationIds: { [key: string]: string } = {
  projectCreationAddUserButton: 'project-creation-add-user-button',
  projectCreationResetFrameworksButton: 'project-creation-reset-Frameworks-button',
};

// Mixed data-test-ids
export const mixedIds: { [key: string]: string } = {
  arrowBackButton: 'arrow-back-button',
  editTitleButton: 'edit-title-button',
  editDescriptionButton: 'edit-description-button',
};
