/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 0.7.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street_name': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zip_code': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'number': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AiLifecycleDashboardContent = {
    Control: 'control',
    Risk: 'risk'
} as const;

export type AiLifecycleDashboardContent = typeof AiLifecycleDashboardContent[keyof typeof AiLifecycleDashboardContent];


/**
 * 
 * @export
 * @interface AssetCard
 */
export interface AssetCard {
    /**
     * 
     * @type {string}
     * @memberof AssetCard
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssetCard
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof AssetCard
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof AssetCard
     */
    'is_locked': boolean;
    /**
     * 
     * @type {string}
     * @memberof AssetCard
     */
    'modified_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof AssetCard
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AssetCard
     */
    'project_id': string;
    /**
     * 
     * @type {Status}
     * @memberof AssetCard
     */
    'status': Status;
    /**
     * 
     * @type {string}
     * @memberof AssetCard
     */
    'unique_code': string;
    /**
     * 
     * @type {UserDisplay}
     * @memberof AssetCard
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {UserDisplay}
     * @memberof AssetCard
     */
    'modified_by': UserDisplay | null;
    /**
     * 
     * @type {ProjectDisplay}
     * @memberof AssetCard
     */
    'project': ProjectDisplay;
    /**
     * 
     * @type {UserDisplay}
     * @memberof AssetCard
     */
    'assigned_to'?: UserDisplay | null;
    /**
     * 
     * @type {string}
     * @memberof AssetCard
     */
    'content': string;
    /**
     * 
     * @type {object}
     * @memberof AssetCard
     */
    'json_content': object | null;
    /**
     * 
     * @type {AssetCardTypes}
     * @memberof AssetCard
     */
    'asset_card_type': AssetCardTypes;
    /**
     * 
     * @type {number}
     * @memberof AssetCard
     */
    'n_comments'?: number;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof AssetCard
     */
    'comments'?: Array<Comment>;
    /**
     * 
     * @type {Array<UserDisplay>}
     * @memberof AssetCard
     */
    'reviewers'?: Array<UserDisplay> | null;
    /**
     * 
     * @type {string}
     * @memberof AssetCard
     */
    'review_message'?: string | null;
    /**
     * 
     * @type {AssetCardNature}
     * @memberof AssetCard
     */
    'nature'?: AssetCardNature | null;
    /**
     * 
     * @type {number}
     * @memberof AssetCard
     */
    'value'?: number | null;
    /**
     * 
     * @type {PendingReview}
     * @memberof AssetCard
     */
    'pending_review'?: PendingReview | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AssetCardNature = {
    Tangible: 'tangible',
    Intangible: 'intangible'
} as const;

export type AssetCardNature = typeof AssetCardNature[keyof typeof AssetCardNature];


/**
 * 
 * @export
 * @interface AssetCardPage
 */
export interface AssetCardPage {
    /**
     * 
     * @type {Array<AssetCard>}
     * @memberof AssetCardPage
     */
    'items': Array<AssetCard>;
    /**
     * 
     * @type {number}
     * @memberof AssetCardPage
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetCardPage
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetCardPage
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssetCardPage
     */
    'pages'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AssetCardPage
     */
    'sort_by': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssetCardPage
     */
    'descending': boolean | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AssetCardTypes = {
    ModelCard: 'model_card',
    DatasetCard: 'dataset_card',
    GeneralCard: 'general_card'
} as const;

export type AssetCardTypes = typeof AssetCardTypes[keyof typeof AssetCardTypes];


/**
 * 
 * @export
 * @interface AssignedControls
 */
export interface AssignedControls {
    /**
     * 
     * @type {Array<ControlWithoutComponents>}
     * @memberof AssignedControls
     */
    'items': Array<ControlWithoutComponents>;
    /**
     * 
     * @type {number}
     * @memberof AssignedControls
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssignedControls
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssignedControls
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssignedControls
     */
    'pages'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssignedControls
     */
    'nr_controls_assigned': number;
    /**
     * 
     * @type {number}
     * @memberof AssignedControls
     */
    'nr_controls_assigned_for_review': number;
    /**
     * 
     * @type {number}
     * @memberof AssignedControls
     */
    'nr_controls_rejected_by_reviewer': number;
    /**
     * 
     * @type {Array<ProjectWithName>}
     * @memberof AssignedControls
     */
    'projects_with_assigned_controls': Array<ProjectWithName>;
    /**
     * 
     * @type {ProjectWithNameAndReadAccess}
     * @memberof AssignedControls
     */
    'current_project': ProjectWithNameAndReadAccess | null;
    /**
     * 
     * @type {string}
     * @memberof AssignedControls
     */
    'sort_by': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssignedControls
     */
    'descending': boolean;
}
/**
 * 
 * @export
 * @interface AssignedRisks
 */
export interface AssignedRisks {
    /**
     * 
     * @type {Array<RiskWithoutComponentsAggregator>}
     * @memberof AssignedRisks
     */
    'items': Array<RiskWithoutComponentsAggregator>;
    /**
     * 
     * @type {number}
     * @memberof AssignedRisks
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssignedRisks
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssignedRisks
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof AssignedRisks
     */
    'pages'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AssignedRisks
     */
    'nr_risks_assigned': number;
    /**
     * 
     * @type {number}
     * @memberof AssignedRisks
     */
    'nr_risks_assigned_for_review': number;
    /**
     * 
     * @type {number}
     * @memberof AssignedRisks
     */
    'nr_risks_rejected_by_reviewer': number;
    /**
     * 
     * @type {Array<ProjectWithName>}
     * @memberof AssignedRisks
     */
    'projects_with_assigned_risks': Array<ProjectWithName>;
    /**
     * 
     * @type {ProjectWithNameAndReadAccess}
     * @memberof AssignedRisks
     */
    'current_project': ProjectWithNameAndReadAccess | null;
    /**
     * 
     * @type {string}
     * @memberof AssignedRisks
     */
    'sort_by': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssignedRisks
     */
    'descending': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Assignment = {
    Assigned: 'assigned',
    AssignedForReview: 'assigned_for_review',
    ReviewRejected: 'review_rejected'
} as const;

export type Assignment = typeof Assignment[keyof typeof Assignment];


/**
 * 
 * @export
 * @interface BodyApiTokenApiAuthTokenPost
 */
export interface BodyApiTokenApiAuthTokenPost {
    /**
     * 
     * @type {GrantTypes}
     * @memberof BodyApiTokenApiAuthTokenPost
     */
    'grant_type': GrantTypes;
    /**
     * 
     * @type {string}
     * @memberof BodyApiTokenApiAuthTokenPost
     */
    'code': string;
}


/**
 * 
 * @export
 * @interface BodyCreateEvidenceApiV1ProjectsProjectIdEvidencesPost
 */
export interface BodyCreateEvidenceApiV1ProjectsProjectIdEvidencesPost {
    /**
     * 
     * @type {File}
     * @memberof BodyCreateEvidenceApiV1ProjectsProjectIdEvidencesPost
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface BodyRefreshTokenApiAuthRefreshPost
 */
export interface BodyRefreshTokenApiAuthRefreshPost {
    /**
     * 
     * @type {GrantTypes}
     * @memberof BodyRefreshTokenApiAuthRefreshPost
     */
    'grant_type': GrantTypes;
    /**
     * 
     * @type {string}
     * @memberof BodyRefreshTokenApiAuthRefreshPost
     */
    'refresh_token': string;
}


/**
 * 
 * @export
 * @interface BodyRegisterApiAuthRegisterPost
 */
export interface BodyRegisterApiAuthRegisterPost {
    /**
     * 
     * @type {GrantTypes}
     * @memberof BodyRegisterApiAuthRegisterPost
     */
    'grant_type': GrantTypes;
    /**
     * 
     * @type {string}
     * @memberof BodyRegisterApiAuthRegisterPost
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof BodyRegisterApiAuthRegisterPost
     */
    'token': string;
}


/**
 * 
 * @export
 * @interface BodyUploadAndCreateEvidenceApiProjectsProjectIdEvidencePost
 */
export interface BodyUploadAndCreateEvidenceApiProjectsProjectIdEvidencePost {
    /**
     * 
     * @type {File}
     * @memberof BodyUploadAndCreateEvidenceApiProjectsProjectIdEvidencePost
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface BodyUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost
 */
export interface BodyUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost {
    /**
     * 
     * @type {File}
     * @memberof BodyUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface BodyUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost
 */
export interface BodyUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost {
    /**
     * 
     * @type {File}
     * @memberof BodyUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'object_id': string;
    /**
     * 
     * @type {Concept}
     * @memberof Comment
     */
    'object_type': Concept;
    /**
     * 
     * @type {UserDisplay}
     * @memberof Comment
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'created_at': string;
    /**
     * 
     * @type {UserDisplay}
     * @memberof Comment
     */
    'modified_by': UserDisplay | null;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'modified_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'content': string;
    /**
     * 
     * @type {object}
     * @memberof Comment
     */
    'json_content': object | null;
    /**
     * 
     * @type {CommentType}
     * @memberof Comment
     */
    'comment_type'?: CommentType | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CommentType = {
    Review: 'review',
    Comment: 'comment'
} as const;

export type CommentType = typeof CommentType[keyof typeof CommentType];


/**
 * 
 * @export
 * @interface Component
 */
export interface Component {
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    'control_id': string;
    /**
     * 
     * @type {number}
     * @memberof Component
     */
    'order_number': number;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    'component_type': string;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    'created_by_id': string;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    'modified_by_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    'modified_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    'created_at': string;
    /**
     * 
     * @type {Array<Evidence>}
     * @memberof Component
     */
    'evidences'?: Array<Evidence> | null;
    /**
     * 
     * @type {ComponentConfiguration}
     * @memberof Component
     */
    'configuration': ComponentConfiguration;
    /**
     * 
     * @type {string}
     * @memberof Component
     */
    'result'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof Component
     */
    'json_result'?: object | null;
}
/**
 * @type ComponentConfiguration
 * @export
 */
export type ComponentConfiguration = { component_type: 'evidence' } & EvidenceComponentTemplate | { component_type: 'guidance' } & GuidanceComponentTemplate | { component_type: 'notes' } & NoteComponentTemplate;

/**
 * @type ComponentSpecifics
 * @export
 */
export type ComponentSpecifics = { component_type: 'evidence' } & EvidenceComponentTemplate | { component_type: 'guidance' } & GuidanceComponentTemplate | { component_type: 'notes' } & NoteComponentTemplate;

/**
 * 
 * @export
 * @enum {string}
 */

export const Concept = {
    Framework: 'framework',
    Requirement: 'requirement',
    Control: 'control',
    Risk: 'risk',
    Project: 'project',
    Organization: 'organization',
    AssetCard: 'asset_card',
    TestingTest: 'testing_test'
} as const;

export type Concept = typeof Concept[keyof typeof Concept];


/**
 * 
 * @export
 * @interface Control
 */
export interface Control {
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'control_code': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'modified_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Control
     */
    'order_number': number;
    /**
     * 
     * @type {Status}
     * @memberof Control
     */
    'status': Status;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof Control
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof Control
     */
    'is_locked': boolean;
}


/**
 * 
 * @export
 * @interface ControlAggregator
 */
export interface ControlAggregator {
    /**
     * 
     * @type {string}
     * @memberof ControlAggregator
     */
    'control_code': string;
    /**
     * 
     * @type {string}
     * @memberof ControlAggregator
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ControlAggregator
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ControlAggregator
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof ControlAggregator
     */
    'modified_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlAggregator
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ControlAggregator
     */
    'order_number': number;
    /**
     * 
     * @type {Status}
     * @memberof ControlAggregator
     */
    'status': Status;
    /**
     * 
     * @type {string}
     * @memberof ControlAggregator
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof ControlAggregator
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlAggregator
     */
    'is_locked': boolean;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ControlAggregator
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ControlAggregator
     */
    'modified_by': UserDisplay | null;
    /**
     * 
     * @type {ProjectDisplay}
     * @memberof ControlAggregator
     */
    'project': ProjectDisplay;
    /**
     * 
     * @type {Array<FrameworkDisplay>}
     * @memberof ControlAggregator
     */
    'frameworks': Array<FrameworkDisplay>;
    /**
     * 
     * @type {Array<RequirementDisplay>}
     * @memberof ControlAggregator
     */
    'requirements': Array<RequirementDisplay>;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ControlAggregator
     */
    'assigned_to'?: UserDisplay | null;
    /**
     * 
     * @type {number}
     * @memberof ControlAggregator
     */
    'n_comments'?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlAggregator
     */
    'n_artifacts': number;
    /**
     * 
     * @type {Array<ControlTag>}
     * @memberof ControlAggregator
     */
    'tags': Array<ControlTag>;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof ControlAggregator
     */
    'comments': Array<Comment>;
    /**
     * 
     * @type {Array<Component>}
     * @memberof ControlAggregator
     */
    'components': Array<Component>;
    /**
     * 
     * @type {Array<UserDisplay>}
     * @memberof ControlAggregator
     */
    'reviewers'?: Array<UserDisplay> | null;
    /**
     * 
     * @type {string}
     * @memberof ControlAggregator
     */
    'review_message'?: string | null;
    /**
     * 
     * @type {PendingReview}
     * @memberof ControlAggregator
     */
    'pending_review'?: PendingReview | null;
}


/**
 * 
 * @export
 * @interface ControlComponent
 */
export interface ControlComponent {
    /**
     * 
     * @type {ComponentSpecifics}
     * @memberof ControlComponent
     */
    'component_specifics': ComponentSpecifics;
    /**
     * 
     * @type {string}
     * @memberof ControlComponent
     */
    'component_description': string;
    /**
     * 
     * @type {string}
     * @memberof ControlComponent
     */
    'component_name': string;
    /**
     * 
     * @type {string}
     * @memberof ControlComponent
     */
    'component_result'?: string;
}
/**
 * 
 * @export
 * @interface ControlDisplay
 */
export interface ControlDisplay {
    /**
     * 
     * @type {string}
     * @memberof ControlDisplay
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ControlDisplay
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ControlDisplay
     */
    'control_code': string;
    /**
     * 
     * @type {string}
     * @memberof ControlDisplay
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface ControlList
 */
export interface ControlList {
    /**
     * 
     * @type {string}
     * @memberof ControlList
     */
    'control_code': string;
    /**
     * 
     * @type {string}
     * @memberof ControlList
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ControlList
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ControlList
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof ControlList
     */
    'modified_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlList
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ControlList
     */
    'order_number': number;
    /**
     * 
     * @type {Status}
     * @memberof ControlList
     */
    'status': Status;
    /**
     * 
     * @type {string}
     * @memberof ControlList
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof ControlList
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlList
     */
    'is_locked': boolean;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ControlList
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ControlList
     */
    'modified_by': UserDisplay | null;
    /**
     * 
     * @type {Array<FrameworkListDisplay>}
     * @memberof ControlList
     */
    'frameworks': Array<FrameworkListDisplay>;
    /**
     * 
     * @type {Array<RequirementDisplay>}
     * @memberof ControlList
     */
    'requirements': Array<RequirementDisplay>;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ControlList
     */
    'assigned_to'?: UserDisplay | null;
    /**
     * 
     * @type {number}
     * @memberof ControlList
     */
    'n_comments'?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlList
     */
    'n_artifacts': number;
    /**
     * 
     * @type {Array<ControlTag>}
     * @memberof ControlList
     */
    'tags': Array<ControlTag>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ControlStatusFilter = {
    InProgress: 'in_progress',
    InReview: 'in_review',
    Completed: 'completed',
    All: 'all'
} as const;

export type ControlStatusFilter = typeof ControlStatusFilter[keyof typeof ControlStatusFilter];


/**
 * 
 * @export
 * @interface ControlTag
 */
export interface ControlTag {
    /**
     * 
     * @type {string}
     * @memberof ControlTag
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ControlTag
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface ControlWithoutComponents
 */
export interface ControlWithoutComponents {
    /**
     * 
     * @type {string}
     * @memberof ControlWithoutComponents
     */
    'control_code': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithoutComponents
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithoutComponents
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithoutComponents
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithoutComponents
     */
    'modified_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof ControlWithoutComponents
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ControlWithoutComponents
     */
    'order_number': number;
    /**
     * 
     * @type {Status}
     * @memberof ControlWithoutComponents
     */
    'status': Status;
    /**
     * 
     * @type {string}
     * @memberof ControlWithoutComponents
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof ControlWithoutComponents
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof ControlWithoutComponents
     */
    'is_locked': boolean;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ControlWithoutComponents
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ControlWithoutComponents
     */
    'modified_by': UserDisplay | null;
    /**
     * 
     * @type {ProjectDisplay}
     * @memberof ControlWithoutComponents
     */
    'project': ProjectDisplay;
    /**
     * 
     * @type {Array<FrameworkDisplay>}
     * @memberof ControlWithoutComponents
     */
    'frameworks': Array<FrameworkDisplay>;
    /**
     * 
     * @type {Array<RequirementDisplay>}
     * @memberof ControlWithoutComponents
     */
    'requirements': Array<RequirementDisplay>;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ControlWithoutComponents
     */
    'assigned_to'?: UserDisplay | null;
    /**
     * 
     * @type {number}
     * @memberof ControlWithoutComponents
     */
    'n_comments'?: number;
    /**
     * 
     * @type {number}
     * @memberof ControlWithoutComponents
     */
    'n_artifacts': number;
    /**
     * 
     * @type {Array<ControlTag>}
     * @memberof ControlWithoutComponents
     */
    'tags': Array<ControlTag>;
}


/**
 * 
 * @export
 * @interface CreateAssetCard
 */
export interface CreateAssetCard {
    /**
     * 
     * @type {string}
     * @memberof CreateAssetCard
     */
    'name': string;
    /**
     * 
     * @type {AssetCardTypes}
     * @memberof CreateAssetCard
     */
    'asset_card_type': AssetCardTypes;
    /**
     * 
     * @type {AssetCardNature}
     * @memberof CreateAssetCard
     */
    'nature'?: AssetCardNature | null;
    /**
     * 
     * @type {number}
     * @memberof CreateAssetCard
     */
    'value'?: number | null;
}


/**
 * 
 * @export
 * @interface CreateComment
 */
export interface CreateComment {
    /**
     * 
     * @type {string}
     * @memberof CreateComment
     */
    'content': string;
    /**
     * 
     * @type {object}
     * @memberof CreateComment
     */
    'json_content'?: object | null;
    /**
     * 
     * @type {CommentType}
     * @memberof CreateComment
     */
    'comment_type'?: CommentType | null;
}


/**
 * 
 * @export
 * @interface CreateProject
 */
export interface CreateProject {
    /**
     * 
     * @type {string}
     * @memberof CreateProject
     */
    'name': string;
    /**
     * 
     * @type {ProjectType}
     * @memberof CreateProject
     */
    'project_type'?: ProjectType;
    /**
     * 
     * @type {string}
     * @memberof CreateProject
     */
    'description': string;
    /**
     * 
     * @type {{ [key: string]: Array<Roles>; }}
     * @memberof CreateProject
     */
    'user_roles'?: { [key: string]: Array<Roles>; };
    /**
     * 
     * @type {number}
     * @memberof CreateProject
     */
    'risk_threshold'?: number | null;
}


/**
 * 
 * @export
 * @interface CreateRisk
 */
export interface CreateRisk {
    /**
     * 
     * @type {string}
     * @memberof CreateRisk
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateRisk
     */
    'risk_threshold'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateTestingTest
 */
export interface CreateTestingTest {
    /**
     * 
     * @type {string}
     * @memberof CreateTestingTest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTestingTest
     */
    'description'?: string | null;
    /**
     * 
     * @type {TestingTestCondition}
     * @memberof CreateTestingTest
     */
    'conditions': TestingTestCondition;
    /**
     * 
     * @type {string}
     * @memberof CreateTestingTest
     */
    'assigned_to_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTestingTest
     */
    'status': boolean | null;
}
/**
 * 
 * @export
 * @interface CreateToken
 */
export interface CreateToken {
    /**
     * 
     * @type {string}
     * @memberof CreateToken
     */
    'name': string;
    /**
     * Validity duration in hours
     * @type {number}
     * @memberof CreateToken
     */
    'duration_in_hours'?: number;
}
/**
 * 
 * @export
 * @interface CreateUser
 */
export interface CreateUser {
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'azure_oid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUser
     */
    'is_org_admin'?: boolean;
}
/**
 * 
 * @export
 * @interface CreationFrameworkTemplate
 */
export interface CreationFrameworkTemplate {
    /**
     * 
     * @type {ProjectType}
     * @memberof CreationFrameworkTemplate
     */
    'project_type'?: ProjectType;
    /**
     * 
     * @type {string}
     * @memberof CreationFrameworkTemplate
     */
    'framework_label': string;
    /**
     * 
     * @type {string}
     * @memberof CreationFrameworkTemplate
     */
    'framework_code': string;
    /**
     * 
     * @type {string}
     * @memberof CreationFrameworkTemplate
     */
    'framework_name': string;
    /**
     * 
     * @type {string}
     * @memberof CreationFrameworkTemplate
     */
    'framework_description': string;
    /**
     * 
     * @type {string}
     * @memberof CreationFrameworkTemplate
     */
    'framework_icon': string | null;
    /**
     * 
     * @type {FrameworkIconType}
     * @memberof CreationFrameworkTemplate
     */
    'framework_icon_type': FrameworkIconType;
    /**
     * 
     * @type {string}
     * @memberof CreationFrameworkTemplate
     */
    'framework_icon_base64'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreationFrameworkTemplate
     */
    'framework_mapped_requirement_codes': Array<string>;
    /**
     * 
     * @type {Array<{ [key: string]: CreationFrameworkTemplateProjectSettingsInnerValue; }>}
     * @memberof CreationFrameworkTemplate
     */
    'project_settings': Array<{ [key: string]: CreationFrameworkTemplateProjectSettingsInnerValue; }>;
    /**
     * 
     * @type {number}
     * @memberof CreationFrameworkTemplate
     */
    'number_of_requirements': number;
    /**
     * 
     * @type {number}
     * @memberof CreationFrameworkTemplate
     */
    'number_of_controls': number;
}


/**
 * 
 * @export
 * @interface CreationFrameworkTemplateProjectSettingsInnerValue
 */
export interface CreationFrameworkTemplateProjectSettingsInnerValue {
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Currency = {
    Eur: 'EUR',
    Usd: 'USD'
} as const;

export type Currency = typeof Currency[keyof typeof Currency];


/**
 * 
 * @export
 * @interface CustomNotificationPage
 */
export interface CustomNotificationPage {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof CustomNotificationPage
     */
    'items': Array<Notification>;
    /**
     * 
     * @type {number}
     * @memberof CustomNotificationPage
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomNotificationPage
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomNotificationPage
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomNotificationPage
     */
    'pages'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomNotificationPage
     */
    'nr_unread_notifications': number;
}
/**
 * 
 * @export
 * @interface CustomProjectPage
 */
export interface CustomProjectPage {
    /**
     * 
     * @type {Array<ProjectListings>}
     * @memberof CustomProjectPage
     */
    'items': Array<ProjectListings>;
    /**
     * 
     * @type {number}
     * @memberof CustomProjectPage
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomProjectPage
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomProjectPage
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomProjectPage
     */
    'pages'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomProjectPage
     */
    'sort_by': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomProjectPage
     */
    'descending': boolean | null;
}
/**
 * 
 * @export
 * @interface DocumentOverview
 */
export interface DocumentOverview {
    /**
     * 
     * @type {string}
     * @memberof DocumentOverview
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentOverview
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof DocumentOverview
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof DocumentOverview
     */
    'content_type': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentOverview
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentOverview
     */
    'uploaded_by': string;
}
/**
 * 
 * @export
 * @interface DocumentURL
 */
export interface DocumentURL {
    /**
     * 
     * @type {string}
     * @memberof DocumentURL
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'detail': string;
}
/**
 * 
 * @export
 * @interface Evidence
 */
export interface Evidence {
    /**
     * 
     * @type {string}
     * @memberof Evidence
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Evidence
     */
    'file_name': string;
    /**
     * 
     * @type {string}
     * @memberof Evidence
     */
    'file_type': string;
    /**
     * 
     * @type {string}
     * @memberof Evidence
     */
    'file_size': string;
    /**
     * 
     * @type {string}
     * @memberof Evidence
     */
    'description': string;
    /**
     * 
     * @type {object}
     * @memberof Evidence
     */
    'json_description': object | null;
    /**
     * 
     * @type {string}
     * @memberof Evidence
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof Evidence
     */
    'is_locked': boolean;
    /**
     * 
     * @type {string}
     * @memberof Evidence
     */
    'modified_at': string | null;
    /**
     * 
     * @type {ProjectDisplay}
     * @memberof Evidence
     */
    'project': ProjectDisplay;
    /**
     * 
     * @type {string}
     * @memberof Evidence
     */
    'unique_code': string;
    /**
     * 
     * @type {Status}
     * @memberof Evidence
     */
    'status': Status;
    /**
     * 
     * @type {string}
     * @memberof Evidence
     */
    'uri': string;
    /**
     * 
     * @type {UserDisplay}
     * @memberof Evidence
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {UserDisplay}
     * @memberof Evidence
     */
    'modified_by': UserDisplay | null;
    /**
     * 
     * @type {Array<RiskDisplay>}
     * @memberof Evidence
     */
    'risks': Array<RiskDisplay>;
    /**
     * 
     * @type {Array<ControlDisplay>}
     * @memberof Evidence
     */
    'controls': Array<ControlDisplay>;
    /**
     * 
     * @type {LockingControlsAndRisks}
     * @memberof Evidence
     */
    'locking_controls_and_risks': LockingControlsAndRisks;
    /**
     * 
     * @type {Array<EvidenceControlScore>}
     * @memberof Evidence
     */
    'suggested_controls'?: Array<EvidenceControlScore> | null;
    /**
     * 
     * @type {object}
     * @memberof Evidence
     */
    'suggestion_raw'?: object | null;
}


/**
 * 
 * @export
 * @interface EvidenceComponentTemplate
 */
export interface EvidenceComponentTemplate {
    /**
     * 
     * @type {string}
     * @memberof EvidenceComponentTemplate
     */
    'component_type': EvidenceComponentTemplateComponentTypeEnum;
}

export const EvidenceComponentTemplateComponentTypeEnum = {
    Evidence: 'evidence'
} as const;

export type EvidenceComponentTemplateComponentTypeEnum = typeof EvidenceComponentTemplateComponentTypeEnum[keyof typeof EvidenceComponentTemplateComponentTypeEnum];

/**
 * 
 * @export
 * @interface EvidenceControlScore
 */
export interface EvidenceControlScore {
    /**
     * 
     * @type {string}
     * @memberof EvidenceControlScore
     */
    'control_code': string;
    /**
     * 
     * @type {string}
     * @memberof EvidenceControlScore
     */
    'control_id': string;
    /**
     * 
     * @type {string}
     * @memberof EvidenceControlScore
     */
    'control_name': string;
    /**
     * 
     * @type {string}
     * @memberof EvidenceControlScore
     */
    'explanation': string;
    /**
     * 
     * @type {number}
     * @memberof EvidenceControlScore
     */
    'relevance_score': number;
}
/**
 * 
 * @export
 * @interface EvidencePage
 */
export interface EvidencePage {
    /**
     * 
     * @type {Array<Evidence>}
     * @memberof EvidencePage
     */
    'items': Array<Evidence>;
    /**
     * 
     * @type {number}
     * @memberof EvidencePage
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof EvidencePage
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof EvidencePage
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof EvidencePage
     */
    'pages'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EvidencePage
     */
    'sort_by': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EvidencePage
     */
    'descending': boolean | null;
}
/**
 * 
 * @export
 * @interface EvidencePreview
 */
export interface EvidencePreview {
    /**
     * 
     * @type {string}
     * @memberof EvidencePreview
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EvidencePreview
     */
    'file_name': string;
    /**
     * 
     * @type {string}
     * @memberof EvidencePreview
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface EvidenceSuggestions
 */
export interface EvidenceSuggestions {
    /**
     * 
     * @type {string}
     * @memberof EvidenceSuggestions
     */
    'file_name': string;
    /**
     * 
     * @type {string}
     * @memberof EvidenceSuggestions
     */
    'description': string;
    /**
     * 
     * @type {Array<EvidenceControlScore>}
     * @memberof EvidenceSuggestions
     */
    'suggested_controls': Array<EvidenceControlScore>;
    /**
     * 
     * @type {object}
     * @memberof EvidenceSuggestions
     */
    'suggestion_raw': object | null;
}
/**
 * 
 * @export
 * @interface EvidenceURL
 */
export interface EvidenceURL {
    /**
     * 
     * @type {string}
     * @memberof EvidenceURL
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface EvidenceUpdate
 */
export interface EvidenceUpdate {
    /**
     * 
     * @type {string}
     * @memberof EvidenceUpdate
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof EvidenceUpdate
     */
    'name': string;
    /**
     * 
     * @type {object}
     * @memberof EvidenceUpdate
     */
    'json_description'?: object | null;
}
/**
 * 
 * @export
 * @interface Flags
 */
export interface Flags {
    /**
     * 
     * @type {boolean}
     * @memberof Flags
     */
    'vendor_management_enabled_for_entire_organization'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Flags
     */
    'user_management_enabled_for_entire_organization'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Flags
     */
    'evidence_scoring_enabled_for_entire_organization'?: boolean | null;
}
/**
 * 
 * @export
 * @interface Framework
 */
export interface Framework {
    /**
     * 
     * @type {string}
     * @memberof Framework
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Framework
     */
    'framework_label': string;
    /**
     * 
     * @type {string}
     * @memberof Framework
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Framework
     */
    'icon': string;
    /**
     * 
     * @type {FrameworkIconType}
     * @memberof Framework
     */
    'icon_type': FrameworkIconType;
    /**
     * 
     * @type {string}
     * @memberof Framework
     */
    'modified_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof Framework
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Framework
     */
    'framework_code': string;
    /**
     * 
     * @type {string}
     * @memberof Framework
     */
    'id': string;
}


/**
 * 
 * @export
 * @interface FrameworkAggregator
 */
export interface FrameworkAggregator {
    /**
     * 
     * @type {string}
     * @memberof FrameworkAggregator
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkAggregator
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkAggregator
     */
    'framework_label'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkAggregator
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkAggregator
     */
    'icon': string;
    /**
     * 
     * @type {FrameworkIconType}
     * @memberof FrameworkAggregator
     */
    'icon_type': FrameworkIconType;
    /**
     * 
     * @type {string}
     * @memberof FrameworkAggregator
     */
    'modified_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof FrameworkAggregator
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkAggregator
     */
    'framework_code': string;
    /**
     * 
     * @type {boolean}
     * @memberof FrameworkAggregator
     */
    'is_locked': boolean;
    /**
     * 
     * @type {Status}
     * @memberof FrameworkAggregator
     */
    'status': Status;
    /**
     * 
     * @type {UserDisplay}
     * @memberof FrameworkAggregator
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {UserDisplay}
     * @memberof FrameworkAggregator
     */
    'modified_by': UserDisplay | null;
    /**
     * 
     * @type {ProjectDisplay}
     * @memberof FrameworkAggregator
     */
    'project': ProjectDisplay;
    /**
     * 
     * @type {Array<RequirementDisplay>}
     * @memberof FrameworkAggregator
     */
    'requirements': Array<RequirementDisplay>;
    /**
     * 
     * @type {Array<ControlDisplay>}
     * @memberof FrameworkAggregator
     */
    'controls': Array<ControlDisplay>;
    /**
     * 
     * @type {StatusOverview}
     * @memberof FrameworkAggregator
     */
    'control_status_overview': StatusOverview;
    /**
     * 
     * @type {StatusOverview}
     * @memberof FrameworkAggregator
     */
    'requirement_status_overview': StatusOverview;
}


/**
 * 
 * @export
 * @interface FrameworkControl
 */
export interface FrameworkControl {
    /**
     * 
     * @type {string}
     * @memberof FrameworkControl
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkControl
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkControl
     */
    'description': string;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof FrameworkControl
     */
    'tags': Array<{ [key: string]: string; }>;
    /**
     * 
     * @type {string}
     * @memberof FrameworkControl
     */
    'question'?: string | null;
    /**
     * 
     * @type {Array<ControlComponent>}
     * @memberof FrameworkControl
     */
    'components': Array<ControlComponent>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrameworkControl
     */
    'mapped_frameworks'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrameworkControl
     */
    'mapped_requirements'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FrameworkDetailed
 */
export interface FrameworkDetailed {
    /**
     * 
     * @type {string}
     * @memberof FrameworkDetailed
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkDetailed
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkDetailed
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof FrameworkDetailed
     */
    'number_of_requirements': number;
    /**
     * 
     * @type {number}
     * @memberof FrameworkDetailed
     */
    'number_of_controls': number;
    /**
     * 
     * @type {string}
     * @memberof FrameworkDetailed
     */
    'icon': string | null;
    /**
     * 
     * @type {string}
     * @memberof FrameworkDetailed
     */
    'icon_type': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkDetailed
     */
    'icon_base64': string | null;
    /**
     * 
     * @type {Array<FrameworkRequirement>}
     * @memberof FrameworkDetailed
     */
    'requirements': Array<FrameworkRequirement>;
    /**
     * 
     * @type {Array<FrameworkControl>}
     * @memberof FrameworkDetailed
     */
    'controls': Array<FrameworkControl>;
}
/**
 * 
 * @export
 * @interface FrameworkDisplay
 */
export interface FrameworkDisplay {
    /**
     * 
     * @type {string}
     * @memberof FrameworkDisplay
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkDisplay
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkDisplay
     */
    'framework_code': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkDisplay
     */
    'framework_label': string;
    /**
     * 
     * @type {FrameworkIconType}
     * @memberof FrameworkDisplay
     */
    'icon_type': FrameworkIconType;
    /**
     * 
     * @type {string}
     * @memberof FrameworkDisplay
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrameworkDisplay
     */
    'icon_base64'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrameworkDisplay
     */
    'description'?: string | null;
    /**
     * 
     * @type {UserDisplay}
     * @memberof FrameworkDisplay
     */
    'created_by'?: UserDisplay | null;
    /**
     * 
     * @type {UserDisplay}
     * @memberof FrameworkDisplay
     */
    'modified_by'?: UserDisplay | null;
    /**
     * 
     * @type {StatusOverview}
     * @memberof FrameworkDisplay
     */
    'control_status_overview': StatusOverview;
    /**
     * 
     * @type {StatusOverview}
     * @memberof FrameworkDisplay
     */
    'requirement_status_overview': StatusOverview;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FrameworkIconType = {
    Flag: 'flag',
    Image: 'image'
} as const;

export type FrameworkIconType = typeof FrameworkIconType[keyof typeof FrameworkIconType];


/**
 * 
 * @export
 * @interface FrameworkListDisplay
 */
export interface FrameworkListDisplay {
    /**
     * 
     * @type {string}
     * @memberof FrameworkListDisplay
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkListDisplay
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkListDisplay
     */
    'framework_code': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkListDisplay
     */
    'framework_label': string;
    /**
     * 
     * @type {FrameworkIconType}
     * @memberof FrameworkListDisplay
     */
    'icon_type': FrameworkIconType;
    /**
     * 
     * @type {string}
     * @memberof FrameworkListDisplay
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrameworkListDisplay
     */
    'icon_base64'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrameworkListDisplay
     */
    'description'?: string | null;
}


/**
 * 
 * @export
 * @interface FrameworkOverview
 */
export interface FrameworkOverview {
    /**
     * 
     * @type {string}
     * @memberof FrameworkOverview
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkOverview
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkOverview
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof FrameworkOverview
     */
    'number_of_requirements': number;
    /**
     * 
     * @type {number}
     * @memberof FrameworkOverview
     */
    'number_of_controls': number;
    /**
     * 
     * @type {string}
     * @memberof FrameworkOverview
     */
    'icon': string | null;
    /**
     * 
     * @type {string}
     * @memberof FrameworkOverview
     */
    'icon_type': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkOverview
     */
    'icon_base64': string | null;
}
/**
 * 
 * @export
 * @interface FrameworkRequirement
 */
export interface FrameworkRequirement {
    /**
     * 
     * @type {string}
     * @memberof FrameworkRequirement
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkRequirement
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkRequirement
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof FrameworkRequirement
     */
    'detail_content': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrameworkRequirement
     */
    'controls': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrameworkRequirement
     */
    'mapped_frameworks'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GeneralConfig
 */
export interface GeneralConfig {
    /**
     * 
     * @type {Currency}
     * @memberof GeneralConfig
     */
    'currency': Currency;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const GrantTypes = {
    AuthorizationCode: 'authorization_code',
    RefreshToken: 'refresh_token'
} as const;

export type GrantTypes = typeof GrantTypes[keyof typeof GrantTypes];


/**
 * 
 * @export
 * @interface GuidanceComponentTemplate
 */
export interface GuidanceComponentTemplate {
    /**
     * 
     * @type {string}
     * @memberof GuidanceComponentTemplate
     */
    'component_type': GuidanceComponentTemplateComponentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GuidanceComponentTemplate
     */
    'component_content': string;
}

export const GuidanceComponentTemplateComponentTypeEnum = {
    Guidance: 'guidance'
} as const;

export type GuidanceComponentTemplateComponentTypeEnum = typeof GuidanceComponentTemplateComponentTypeEnum[keyof typeof GuidanceComponentTemplateComponentTypeEnum];

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Icon = {
    Checkedbox: 'checkedbox',
    Uncheckedbox: 'uncheckedbox',
    Person: 'person',
    InReview: 'in_review',
    Approved: 'approved',
    Rejected: 'rejected',
    Cancelled: 'cancelled'
} as const;

export type Icon = typeof Icon[keyof typeof Icon];


/**
 * 
 * @export
 * @interface InviteUser
 */
export interface InviteUser {
    /**
     * 
     * @type {string}
     * @memberof InviteUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof InviteUser
     */
    'azure_oid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InviteUser
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InviteUser
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InviteUser
     */
    'job_title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InviteUser
     */
    'is_org_admin'?: boolean;
}
/**
 * 
 * @export
 * @interface LifecycleStageOverview
 */
export interface LifecycleStageOverview {
    /**
     * 
     * @type {string}
     * @memberof LifecycleStageOverview
     */
    'stage_name': string;
    /**
     * 
     * @type {string}
     * @memberof LifecycleStageOverview
     */
    'stage_description': string;
    /**
     * 
     * @type {StatusOverview}
     * @memberof LifecycleStageOverview
     */
    'status': StatusOverview;
}
/**
 * 
 * @export
 * @interface LockingControlsAndRisks
 */
export interface LockingControlsAndRisks {
    /**
     * 
     * @type {Array<ControlDisplay>}
     * @memberof LockingControlsAndRisks
     */
    'controls': Array<ControlDisplay>;
    /**
     * 
     * @type {Array<RiskDisplay>}
     * @memberof LockingControlsAndRisks
     */
    'risks': Array<RiskDisplay>;
}
/**
 * 
 * @export
 * @interface Logs
 */
export interface Logs {
    /**
     * 
     * @type {string}
     * @memberof Logs
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Logs
     */
    'created_at': string;
    /**
     * 
     * @type {UserDisplay}
     * @memberof Logs
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {string}
     * @memberof Logs
     */
    'object_id': string;
    /**
     * 
     * @type {string}
     * @memberof Logs
     */
    'object_name': string;
    /**
     * 
     * @type {string}
     * @memberof Logs
     */
    'action_name': string;
    /**
     * 
     * @type {Icon}
     * @memberof Logs
     */
    'icon'?: Icon | null;
}


/**
 * 
 * @export
 * @interface NoteComponentTemplate
 */
export interface NoteComponentTemplate {
    /**
     * 
     * @type {string}
     * @memberof NoteComponentTemplate
     */
    'component_type': NoteComponentTemplateComponentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NoteComponentTemplate
     */
    'component_content': string;
}

export const NoteComponentTemplateComponentTypeEnum = {
    Notes: 'notes'
} as const;

export type NoteComponentTemplateComponentTypeEnum = typeof NoteComponentTemplateComponentTypeEnum[keyof typeof NoteComponentTemplateComponentTypeEnum];

/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'created_at': string;
    /**
     * 
     * @type {NotificationStatus}
     * @memberof Notification
     */
    'status': NotificationStatus;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'content': string;
    /**
     * 
     * @type {NotificationTags}
     * @memberof Notification
     */
    'tag': NotificationTags;
    /**
     * 
     * @type {Concept}
     * @memberof Notification
     */
    'object_type': Concept;
    /**
     * 
     * @type {NotificationObject}
     * @memberof Notification
     */
    'object'?: NotificationObject | null;
}


/**
 * 
 * @export
 * @interface NotificationIdList
 */
export interface NotificationIdList {
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationIdList
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface NotificationObject
 */
export interface NotificationObject {
    /**
     * 
     * @type {string}
     * @memberof NotificationObject
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationObject
     */
    'name': string;
    /**
     * 
     * @type {Status}
     * @memberof NotificationObject
     */
    'status'?: Status | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationObject
     */
    'project_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationObject
     */
    'control_code'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationStatus = {
    Archived: 'archived',
    Read: 'read',
    Unread: 'unread'
} as const;

export type NotificationStatus = typeof NotificationStatus[keyof typeof NotificationStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationTags = {
    AccessRequested: 'access_requested',
    Assigned: 'assigned',
    Commented: 'commented',
    Created: 'created',
    ReviewApproved: 'review_approved',
    ReviewAssigned: 'review_assigned',
    ReviewCancelled: 'review_cancelled',
    ReviewRejected: 'review_rejected',
    StatusChanged: 'status_changed',
    TestException: 'test_exception',
    Updated: 'updated'
} as const;

export type NotificationTags = typeof NotificationTags[keyof typeof NotificationTags];


/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    'monitoring_enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'domain'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Organization
     */
    'allow_signup_from_domain'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    'risk_threshold'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface OrganizationConfig
 */
export interface OrganizationConfig {
    /**
     * 
     * @type {string}
     * @memberof OrganizationConfig
     */
    'organization_id': string;
    /**
     * 
     * @type {GeneralConfig}
     * @memberof OrganizationConfig
     */
    'general': GeneralConfig;
    /**
     * 
     * @type {PlanQuota}
     * @memberof OrganizationConfig
     */
    'plan_quota': PlanQuota;
    /**
     * 
     * @type {Flags}
     * @memberof OrganizationConfig
     */
    'flags'?: Flags | null;
}
/**
 * 
 * @export
 * @interface OrganizationDisplay
 */
export interface OrganizationDisplay {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDisplay
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDisplay
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationDisplay
     */
    'monitoring_enabled': boolean;
}
/**
 * 
 * @export
 * @interface OrganizationQuota
 */
export interface OrganizationQuota {
    /**
     * 
     * @type {string}
     * @memberof OrganizationQuota
     */
    'organization_id': string;
    /**
     * 
     * @type {PlanQuota}
     * @memberof OrganizationQuota
     */
    'plan_quota': PlanQuota;
    /**
     * 
     * @type {UsedQuota}
     * @memberof OrganizationQuota
     */
    'used_quota': UsedQuota;
}
/**
 * 
 * @export
 * @interface OrganizationUpdate
 */
export interface OrganizationUpdate {
    /**
     * 
     * @type {string}
     * @memberof OrganizationUpdate
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUpdate
     */
    'monitoring_enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUpdate
     */
    'domain'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUpdate
     */
    'allow_signup_from_domain'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrganizationUpdate
     */
    'risk_threshold'?: number | null;
}
/**
 * 
 * @export
 * @interface OrganizationUser
 */
export interface OrganizationUser {
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'azure_oid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUser
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {UserState}
     * @memberof OrganizationUser
     */
    'state': UserState;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUser
     */
    'is_super_admin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationUser
     */
    'is_org_admin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationUser
     */
    'terms_accepted'?: string | null;
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationUser
     */
    'organization': Organization;
    /**
     * 
     * @type {Array<ProjectRole>}
     * @memberof OrganizationUser
     */
    'roles'?: Array<ProjectRole>;
    /**
     * 
     * @type {{ [key: string]: Set<string>; }}
     * @memberof OrganizationUser
     */
    'rights': { [key: string]: Set<string>; };
}


/**
 * 
 * @export
 * @interface PageControl
 */
export interface PageControl {
    /**
     * 
     * @type {Array<Control>}
     * @memberof PageControl
     */
    'items': Array<Control>;
    /**
     * 
     * @type {number}
     * @memberof PageControl
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageControl
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageControl
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageControl
     */
    'pages'?: number | null;
}
/**
 * 
 * @export
 * @interface PageControlList
 */
export interface PageControlList {
    /**
     * 
     * @type {Array<ControlList>}
     * @memberof PageControlList
     */
    'items': Array<ControlList>;
    /**
     * 
     * @type {number}
     * @memberof PageControlList
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageControlList
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageControlList
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageControlList
     */
    'pages'?: number | null;
}
/**
 * 
 * @export
 * @interface PageDocumentOverview
 */
export interface PageDocumentOverview {
    /**
     * 
     * @type {Array<DocumentOverview>}
     * @memberof PageDocumentOverview
     */
    'items': Array<DocumentOverview>;
    /**
     * 
     * @type {number}
     * @memberof PageDocumentOverview
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageDocumentOverview
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageDocumentOverview
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageDocumentOverview
     */
    'pages'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PageDocumentOverview
     */
    'sort_by': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PageDocumentOverview
     */
    'descending': boolean | null;
}
/**
 * 
 * @export
 * @interface PageFrameworkAggregator
 */
export interface PageFrameworkAggregator {
    /**
     * 
     * @type {Array<FrameworkAggregator>}
     * @memberof PageFrameworkAggregator
     */
    'items': Array<FrameworkAggregator>;
    /**
     * 
     * @type {number}
     * @memberof PageFrameworkAggregator
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageFrameworkAggregator
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageFrameworkAggregator
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageFrameworkAggregator
     */
    'pages'?: number | null;
}
/**
 * 
 * @export
 * @interface PageOrganization
 */
export interface PageOrganization {
    /**
     * 
     * @type {Array<Organization>}
     * @memberof PageOrganization
     */
    'items': Array<Organization>;
    /**
     * 
     * @type {number}
     * @memberof PageOrganization
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageOrganization
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageOrganization
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageOrganization
     */
    'pages'?: number | null;
}
/**
 * 
 * @export
 * @interface PageRequirementAggregator
 */
export interface PageRequirementAggregator {
    /**
     * 
     * @type {Array<RequirementAggregator>}
     * @memberof PageRequirementAggregator
     */
    'items': Array<RequirementAggregator>;
    /**
     * 
     * @type {number}
     * @memberof PageRequirementAggregator
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageRequirementAggregator
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageRequirementAggregator
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageRequirementAggregator
     */
    'pages'?: number | null;
}
/**
 * 
 * @export
 * @interface PageRisk
 */
export interface PageRisk {
    /**
     * 
     * @type {Array<Risk>}
     * @memberof PageRisk
     */
    'items': Array<Risk>;
    /**
     * 
     * @type {number}
     * @memberof PageRisk
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageRisk
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageRisk
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageRisk
     */
    'pages'?: number | null;
}
/**
 * 
 * @export
 * @interface PageTestingMetric
 */
export interface PageTestingMetric {
    /**
     * 
     * @type {Array<TestingMetric>}
     * @memberof PageTestingMetric
     */
    'items': Array<TestingMetric>;
    /**
     * 
     * @type {number}
     * @memberof PageTestingMetric
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageTestingMetric
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageTestingMetric
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageTestingMetric
     */
    'pages'?: number | null;
}
/**
 * 
 * @export
 * @interface PageTestingSource
 */
export interface PageTestingSource {
    /**
     * 
     * @type {Array<TestingSource>}
     * @memberof PageTestingSource
     */
    'items': Array<TestingSource>;
    /**
     * 
     * @type {number}
     * @memberof PageTestingSource
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageTestingSource
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageTestingSource
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageTestingSource
     */
    'pages'?: number | null;
}
/**
 * 
 * @export
 * @interface PageTestingTest
 */
export interface PageTestingTest {
    /**
     * 
     * @type {Array<TestingTest>}
     * @memberof PageTestingTest
     */
    'items': Array<TestingTest>;
    /**
     * 
     * @type {number}
     * @memberof PageTestingTest
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageTestingTest
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageTestingTest
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageTestingTest
     */
    'pages'?: number | null;
}
/**
 * 
 * @export
 * @interface PageVendorDetails
 */
export interface PageVendorDetails {
    /**
     * 
     * @type {Array<VendorDetails>}
     * @memberof PageVendorDetails
     */
    'items': Array<VendorDetails>;
    /**
     * 
     * @type {number}
     * @memberof PageVendorDetails
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageVendorDetails
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageVendorDetails
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof PageVendorDetails
     */
    'pages'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PageVendorDetails
     */
    'sort_by': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PageVendorDetails
     */
    'descending': boolean | null;
}
/**
 * 
 * @export
 * @interface PatchFlags
 */
export interface PatchFlags {
    /**
     * 
     * @type {boolean}
     * @memberof PatchFlags
     */
    'vendor_management_enabled_for_entire_organization'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchFlags
     */
    'user_management_enabled_for_entire_organization'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchFlags
     */
    'evidence_scoring_enabled_for_entire_organization'?: boolean | null;
}
/**
 * 
 * @export
 * @interface PatchOrganizationConfig
 */
export interface PatchOrganizationConfig {
    /**
     * 
     * @type {GeneralConfig}
     * @memberof PatchOrganizationConfig
     */
    'general'?: GeneralConfig | null;
    /**
     * 
     * @type {PlanQuota}
     * @memberof PatchOrganizationConfig
     */
    'plan_quota'?: PlanQuota | null;
    /**
     * 
     * @type {PatchFlags}
     * @memberof PatchOrganizationConfig
     */
    'flags'?: PatchFlags | null;
}
/**
 * 
 * @export
 * @interface PendingReview
 */
export interface PendingReview {
    /**
     * 
     * @type {Status}
     * @memberof PendingReview
     */
    'old_status': Status;
    /**
     * 
     * @type {Status}
     * @memberof PendingReview
     */
    'requested_status': Status;
    /**
     * 
     * @type {UserDisplay}
     * @memberof PendingReview
     */
    'created_by': UserDisplay;
}


/**
 * 
 * @export
 * @interface PlanQuota
 */
export interface PlanQuota {
    /**
     * 
     * @type {number}
     * @memberof PlanQuota
     */
    'projects': number;
}
/**
 * 
 * @export
 * @interface ProjectAggregator
 */
export interface ProjectAggregator {
    /**
     * 
     * @type {ProjectType}
     * @memberof ProjectAggregator
     */
    'project_type': ProjectType;
    /**
     * 
     * @type {string}
     * @memberof ProjectAggregator
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAggregator
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAggregator
     */
    'ai_lifecycle_stage'?: string | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof ProjectAggregator
     */
    'project_settings'?: Array<object> | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAggregator
     */
    'modified_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAggregator
     */
    'created_at': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectAggregator
     */
    'risk_threshold'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAggregator
     */
    'id': string;
    /**
     * 
     * @type {Status}
     * @memberof ProjectAggregator
     */
    'status': Status;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectAggregator
     */
    'is_locked': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectAggregator
     */
    'n_evidences': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectAggregator
     */
    'n_controls': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectAggregator
     */
    'n_requirements': number;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ProjectAggregator
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ProjectAggregator
     */
    'modified_by': UserDisplay | null;
    /**
     * 
     * @type {OrganizationDisplay}
     * @memberof ProjectAggregator
     */
    'organization': OrganizationDisplay;
    /**
     * 
     * @type {StatusOverview}
     * @memberof ProjectAggregator
     */
    'control_status_overview': StatusOverview;
    /**
     * 
     * @type {StatusOverview}
     * @memberof ProjectAggregator
     */
    'requirement_status_overview': StatusOverview;
    /**
     * 
     * @type {Array<FrameworkDisplay>}
     * @memberof ProjectAggregator
     */
    'frameworks': Array<FrameworkDisplay>;
    /**
     * 
     * @type {number}
     * @memberof ProjectAggregator
     */
    'progress_percent': number;
}


/**
 * 
 * @export
 * @interface ProjectAiSystemLifecycleDashboard
 */
export interface ProjectAiSystemLifecycleDashboard {
    /**
     * 
     * @type {Array<LifecycleStageOverview>}
     * @memberof ProjectAiSystemLifecycleDashboard
     */
    'ai_lifecycle_stages': Array<LifecycleStageOverview>;
}
/**
 * 
 * @export
 * @interface ProjectCreator
 */
export interface ProjectCreator {
    /**
     * 
     * @type {ProjectType}
     * @memberof ProjectCreator
     */
    'project_type': ProjectType;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreator
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreator
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreator
     */
    'ai_lifecycle_stage'?: string | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof ProjectCreator
     */
    'project_settings'?: Array<object> | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreator
     */
    'modified_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreator
     */
    'created_at': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectCreator
     */
    'risk_threshold'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreator
     */
    'id': string;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ProjectCreator
     */
    'created_by': UserDisplay;
}


/**
 * 
 * @export
 * @interface ProjectDashboard
 */
export interface ProjectDashboard {
    /**
     * 
     * @type {ProjectDashboardGeneralInfo}
     * @memberof ProjectDashboard
     */
    'general_info': ProjectDashboardGeneralInfo;
    /**
     * 
     * @type {StatusOverview}
     * @memberof ProjectDashboard
     */
    'project_progress': StatusOverview;
    /**
     * 
     * @type {ProjectDashboardEvidence}
     * @memberof ProjectDashboard
     */
    'evidence': ProjectDashboardEvidence;
    /**
     * 
     * @type {ProjectDashboardRisks}
     * @memberof ProjectDashboard
     */
    'risks': ProjectDashboardRisks;
    /**
     * 
     * @type {Array<FrameworkDisplay>}
     * @memberof ProjectDashboard
     */
    'frameworks': Array<FrameworkDisplay>;
}
/**
 * 
 * @export
 * @interface ProjectDashboardEvidence
 */
export interface ProjectDashboardEvidence {
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboardEvidence
     */
    'total_evidence': number;
}
/**
 * 
 * @export
 * @interface ProjectDashboardGeneralInfo
 */
export interface ProjectDashboardGeneralInfo {
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardGeneralInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardGeneralInfo
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardGeneralInfo
     */
    'modified_at'?: string | null;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ProjectDashboardGeneralInfo
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboardGeneralInfo
     */
    'ai_lifecycle_stage'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectDashboardRisks
 */
export interface ProjectDashboardRisks {
    /**
     * 
     * @type {Array<number>}
     * @memberof ProjectDashboardRisks
     */
    'rpn_distribution': Array<number>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ProjectDashboardRisks
     */
    'inherent_risks': { [key: string]: number; };
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof ProjectDashboardRisks
     */
    'risk_matrix': Array<Array<number>>;
}
/**
 * 
 * @export
 * @interface ProjectDisplay
 */
export interface ProjectDisplay {
    /**
     * 
     * @type {string}
     * @memberof ProjectDisplay
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDisplay
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProjectListings
 */
export interface ProjectListings {
    /**
     * 
     * @type {string}
     * @memberof ProjectListings
     */
    'id': string;
    /**
     * 
     * @type {ProjectType}
     * @memberof ProjectListings
     */
    'project_type': ProjectType;
    /**
     * 
     * @type {string}
     * @memberof ProjectListings
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectListings
     */
    'description': string;
    /**
     * 
     * @type {Status}
     * @memberof ProjectListings
     */
    'status': Status;
    /**
     * 
     * @type {string}
     * @memberof ProjectListings
     */
    'created_at': string;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ProjectListings
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {string}
     * @memberof ProjectListings
     */
    'modified_at'?: string | null;
    /**
     * 
     * @type {UserDisplay}
     * @memberof ProjectListings
     */
    'modified_by'?: UserDisplay | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectListings
     */
    'read_access'?: boolean;
    /**
     * 
     * @type {Array<FrameworkDisplay>}
     * @memberof ProjectListings
     */
    'frameworks'?: Array<FrameworkDisplay>;
    /**
     * 
     * @type {string}
     * @memberof ProjectListings
     */
    'ai_lifecycle_stage'?: string | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof ProjectListings
     */
    'project_settings'?: Array<object> | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectListings
     */
    'n_evidences': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListings
     */
    'n_controls': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListings
     */
    'n_requirements': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListings
     */
    'progress_percent': number;
}


/**
 * 
 * @export
 * @interface ProjectRole
 */
export interface ProjectRole {
    /**
     * 
     * @type {string}
     * @memberof ProjectRole
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRole
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectRole
     */
    'user_id': string;
    /**
     * 
     * @type {Roles}
     * @memberof ProjectRole
     */
    'role': Roles;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectType = {
    AiApplication: 'ai_application',
    Organization: 'organization'
} as const;

export type ProjectType = typeof ProjectType[keyof typeof ProjectType];


/**
 * 
 * @export
 * @interface ProjectWithName
 */
export interface ProjectWithName {
    /**
     * 
     * @type {string}
     * @memberof ProjectWithName
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithName
     */
    'name': string | null;
}
/**
 * 
 * @export
 * @interface ProjectWithNameAndReadAccess
 */
export interface ProjectWithNameAndReadAccess {
    /**
     * 
     * @type {string}
     * @memberof ProjectWithNameAndReadAccess
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithNameAndReadAccess
     */
    'name': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithNameAndReadAccess
     */
    'read_access': boolean;
}
/**
 * 
 * @export
 * @interface ProjectsDashboard
 */
export interface ProjectsDashboard {
    /**
     * 
     * @type {Array<ProjectAggregator>}
     * @memberof ProjectsDashboard
     */
    'items': Array<ProjectAggregator>;
    /**
     * 
     * @type {number}
     * @memberof ProjectsDashboard
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectsDashboard
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectsDashboard
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectsDashboard
     */
    'pages'?: number | null;
}
/**
 * 
 * @export
 * @interface Requirement
 */
export interface Requirement {
    /**
     * 
     * @type {string}
     * @memberof Requirement
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Requirement
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Requirement
     */
    'requirement_detail_content': string;
    /**
     * 
     * @type {string}
     * @memberof Requirement
     */
    'requirement_code': string;
    /**
     * 
     * @type {number}
     * @memberof Requirement
     */
    'order_number': number;
    /**
     * 
     * @type {string}
     * @memberof Requirement
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Requirement
     */
    'modified_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof Requirement
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface RequirementAggregator
 */
export interface RequirementAggregator {
    /**
     * 
     * @type {string}
     * @memberof RequirementAggregator
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RequirementAggregator
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RequirementAggregator
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof RequirementAggregator
     */
    'requirement_detail_content': string;
    /**
     * 
     * @type {string}
     * @memberof RequirementAggregator
     */
    'modified_at': string | null;
    /**
     * 
     * @type {string}
     * @memberof RequirementAggregator
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof RequirementAggregator
     */
    'requirement_code': string;
    /**
     * 
     * @type {Status}
     * @memberof RequirementAggregator
     */
    'status': Status;
    /**
     * 
     * @type {boolean}
     * @memberof RequirementAggregator
     */
    'is_locked': boolean;
    /**
     * 
     * @type {UserDisplay}
     * @memberof RequirementAggregator
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {UserDisplay}
     * @memberof RequirementAggregator
     */
    'modified_by': UserDisplay | null;
    /**
     * 
     * @type {ProjectDisplay}
     * @memberof RequirementAggregator
     */
    'project': ProjectDisplay;
    /**
     * 
     * @type {Array<ControlDisplay>}
     * @memberof RequirementAggregator
     */
    'controls'?: Array<ControlDisplay> | null;
    /**
     * 
     * @type {Array<FrameworkDisplay>}
     * @memberof RequirementAggregator
     */
    'frameworks': Array<FrameworkDisplay>;
    /**
     * 
     * @type {string}
     * @memberof RequirementAggregator
     */
    'icon': string;
    /**
     * 
     * @type {string}
     * @memberof RequirementAggregator
     */
    'icon_type': string;
}


/**
 * 
 * @export
 * @interface RequirementDisplay
 */
export interface RequirementDisplay {
    /**
     * 
     * @type {string}
     * @memberof RequirementDisplay
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RequirementDisplay
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RequirementDisplay
     */
    'requirement_code': string;
    /**
     * 
     * @type {string}
     * @memberof RequirementDisplay
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface ResponseGetUserApiUsersUserIdGet
 */
export interface ResponseGetUserApiUsersUserIdGet {
    /**
     * 
     * @type {string}
     * @memberof ResponseGetUserApiUsersUserIdGet
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseGetUserApiUsersUserIdGet
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseGetUserApiUsersUserIdGet
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseGetUserApiUsersUserIdGet
     */
    'azure_oid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseGetUserApiUsersUserIdGet
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseGetUserApiUsersUserIdGet
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {UserState}
     * @memberof ResponseGetUserApiUsersUserIdGet
     */
    'state': UserState;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseGetUserApiUsersUserIdGet
     */
    'is_super_admin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseGetUserApiUsersUserIdGet
     */
    'is_org_admin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseGetUserApiUsersUserIdGet
     */
    'terms_accepted'?: string;
    /**
     * 
     * @type {Organization}
     * @memberof ResponseGetUserApiUsersUserIdGet
     */
    'organization': Organization;
    /**
     * 
     * @type {Array<ProjectRole>}
     * @memberof ResponseGetUserApiUsersUserIdGet
     */
    'roles'?: Array<ProjectRole>;
    /**
     * 
     * @type {{ [key: string]: Set<string>; }}
     * @memberof ResponseGetUserApiUsersUserIdGet
     */
    'rights': { [key: string]: Set<string>; };
}


/**
 * 
 * @export
 * @interface ResponseGetUsersMeApiUsersMeGet
 */
export interface ResponseGetUsersMeApiUsersMeGet {
    /**
     * 
     * @type {string}
     * @memberof ResponseGetUsersMeApiUsersMeGet
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseGetUsersMeApiUsersMeGet
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseGetUsersMeApiUsersMeGet
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseGetUsersMeApiUsersMeGet
     */
    'azure_oid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseGetUsersMeApiUsersMeGet
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseGetUsersMeApiUsersMeGet
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {UserState}
     * @memberof ResponseGetUsersMeApiUsersMeGet
     */
    'state': UserState;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseGetUsersMeApiUsersMeGet
     */
    'is_super_admin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseGetUsersMeApiUsersMeGet
     */
    'is_org_admin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseGetUsersMeApiUsersMeGet
     */
    'terms_accepted'?: string;
    /**
     * 
     * @type {Organization}
     * @memberof ResponseGetUsersMeApiUsersMeGet
     */
    'organization': Organization;
    /**
     * 
     * @type {Array<ProjectRole>}
     * @memberof ResponseGetUsersMeApiUsersMeGet
     */
    'roles'?: Array<ProjectRole>;
    /**
     * 
     * @type {{ [key: string]: Set<string>; }}
     * @memberof ResponseGetUsersMeApiUsersMeGet
     */
    'rights': { [key: string]: Set<string>; };
}


/**
 * 
 * @export
 * @interface Review
 */
export interface Review {
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'id': string;
    /**
     * 
     * @type {ReviewStatus}
     * @memberof Review
     */
    'decision': ReviewStatus;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'comment': string;
    /**
     * 
     * @type {object}
     * @memberof Review
     */
    'json_comment'?: object | null;
    /**
     * 
     * @type {UserDisplay}
     * @memberof Review
     */
    'assigned_to': UserDisplay;
    /**
     * 
     * @type {Status}
     * @memberof Review
     */
    'old_status': Status;
    /**
     * 
     * @type {Status}
     * @memberof Review
     */
    'requested_status': Status;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'created_at': string;
    /**
     * 
     * @type {UserDisplay}
     * @memberof Review
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'object_id': string;
}


/**
 * 
 * @export
 * @interface ReviewDecision
 */
export interface ReviewDecision {
    /**
     * 
     * @type {ReviewStatusDecision}
     * @memberof ReviewDecision
     */
    'decision': ReviewStatusDecision;
    /**
     * 
     * @type {string}
     * @memberof ReviewDecision
     */
    'decision_comment'?: string;
    /**
     * 
     * @type {object}
     * @memberof ReviewDecision
     */
    'json_decision_comment'?: object | null;
}


/**
 * 
 * @export
 * @interface ReviewRequest
 */
export interface ReviewRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReviewRequest
     */
    'reviewer_ids': Array<string>;
    /**
     * 
     * @type {Status}
     * @memberof ReviewRequest
     */
    'requested_status': Status;
    /**
     * 
     * @type {string}
     * @memberof ReviewRequest
     */
    'comment': string;
    /**
     * 
     * @type {object}
     * @memberof ReviewRequest
     */
    'json_comment'?: object | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ReviewStatus = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected',
    Stale: 'stale'
} as const;

export type ReviewStatus = typeof ReviewStatus[keyof typeof ReviewStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const ReviewStatusDecision = {
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type ReviewStatusDecision = typeof ReviewStatusDecision[keyof typeof ReviewStatusDecision];


/**
 * 
 * @export
 * @interface Risk
 */
export interface Risk {
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'description': string;
    /**
     * 
     * @type {Status}
     * @memberof Risk
     */
    'status': Status;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'unique_code': string;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'risk_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'risk_level'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'risk_stage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'ai_lifecycle_stage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    'risk_rpn_value'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    'risk_likelihood'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    'risk_impact'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    'risk_detection'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'rpn_risk_level'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Risk
     */
    'risk_threshold'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof Risk
     */
    'assigned_to_id': string | null;
}


/**
 * 
 * @export
 * @interface RiskAggregator
 */
export interface RiskAggregator {
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'description': string;
    /**
     * 
     * @type {Status}
     * @memberof RiskAggregator
     */
    'status': Status;
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'unique_code': string;
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'risk_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'risk_level'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'risk_stage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'ai_lifecycle_stage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskAggregator
     */
    'risk_rpn_value'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskAggregator
     */
    'risk_likelihood'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskAggregator
     */
    'risk_impact'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskAggregator
     */
    'risk_detection'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'rpn_risk_level'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskAggregator
     */
    'risk_threshold'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'assigned_to_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'created_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof RiskAggregator
     */
    'is_locked': boolean;
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'modified_at': string | null;
    /**
     * 
     * @type {UserDisplay}
     * @memberof RiskAggregator
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {UserDisplay}
     * @memberof RiskAggregator
     */
    'modified_by': UserDisplay | null;
    /**
     * 
     * @type {ProjectDisplay}
     * @memberof RiskAggregator
     */
    'project': ProjectDisplay;
    /**
     * 
     * @type {UserDisplay}
     * @memberof RiskAggregator
     */
    'assigned_to'?: UserDisplay | null;
    /**
     * 
     * @type {number}
     * @memberof RiskAggregator
     */
    'n_comments'?: number;
    /**
     * 
     * @type {Array<RiskTag>}
     * @memberof RiskAggregator
     */
    'tags': Array<RiskTag>;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof RiskAggregator
     */
    'comments'?: Array<Comment>;
    /**
     * 
     * @type {Array<RiskComponent>}
     * @memberof RiskAggregator
     */
    'components': Array<RiskComponent>;
    /**
     * 
     * @type {Array<UserDisplay>}
     * @memberof RiskAggregator
     */
    'reviewers'?: Array<UserDisplay> | null;
    /**
     * 
     * @type {string}
     * @memberof RiskAggregator
     */
    'review_message'?: string | null;
    /**
     * 
     * @type {PendingReview}
     * @memberof RiskAggregator
     */
    'pending_review'?: PendingReview | null;
}


/**
 * 
 * @export
 * @interface RiskComponent
 */
export interface RiskComponent {
    /**
     * 
     * @type {string}
     * @memberof RiskComponent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RiskComponent
     */
    'risk_id': string;
    /**
     * 
     * @type {number}
     * @memberof RiskComponent
     */
    'order_number': number;
    /**
     * 
     * @type {string}
     * @memberof RiskComponent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RiskComponent
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskComponent
     */
    'component_type': string;
    /**
     * 
     * @type {string}
     * @memberof RiskComponent
     */
    'created_by_id': string;
    /**
     * 
     * @type {string}
     * @memberof RiskComponent
     */
    'modified_by_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskComponent
     */
    'modified_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskComponent
     */
    'created_at': string;
    /**
     * 
     * @type {Array<Evidence>}
     * @memberof RiskComponent
     */
    'evidences'?: Array<Evidence> | null;
    /**
     * 
     * @type {object}
     * @memberof RiskComponent
     */
    'result'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof RiskComponent
     */
    'json_result'?: object | null;
}
/**
 * 
 * @export
 * @interface RiskDisplay
 */
export interface RiskDisplay {
    /**
     * 
     * @type {string}
     * @memberof RiskDisplay
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RiskDisplay
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RiskDisplay
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface RiskTag
 */
export interface RiskTag {
    /**
     * 
     * @type {string}
     * @memberof RiskTag
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RiskTag
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface RiskTreatment
 */
export interface RiskTreatment {
    /**
     * 
     * @type {string}
     * @memberof RiskTreatment
     */
    'conclusion'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof RiskTreatment
     */
    'json_conclusion'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof RiskTreatment
     */
    'selected_strategy_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskTreatment
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface RiskTreatmentProps
 */
export interface RiskTreatmentProps {
    /**
     * 
     * @type {string}
     * @memberof RiskTreatmentProps
     */
    'conclusion'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof RiskTreatmentProps
     */
    'json_conclusion'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof RiskTreatmentProps
     */
    'selected_strategy_id'?: string | null;
}
/**
 * 
 * @export
 * @interface RiskTreatmentStrategy
 */
export interface RiskTreatmentStrategy {
    /**
     * 
     * @type {string}
     * @memberof RiskTreatmentStrategy
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof RiskTreatmentStrategy
     */
    'risk_rpn_value'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskTreatmentStrategy
     */
    'risk_likelihood'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskTreatmentStrategy
     */
    'risk_impact'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskTreatmentStrategy
     */
    'risk_detection'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskTreatmentStrategy
     */
    'plan'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof RiskTreatmentStrategy
     */
    'json_plan'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof RiskTreatmentStrategy
     */
    'implementation'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof RiskTreatmentStrategy
     */
    'json_implementation'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof RiskTreatmentStrategy
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface RiskTreatmentStrategyProps
 */
export interface RiskTreatmentStrategyProps {
    /**
     * 
     * @type {string}
     * @memberof RiskTreatmentStrategyProps
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof RiskTreatmentStrategyProps
     */
    'risk_rpn_value'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskTreatmentStrategyProps
     */
    'risk_likelihood'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskTreatmentStrategyProps
     */
    'risk_impact'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskTreatmentStrategyProps
     */
    'risk_detection'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskTreatmentStrategyProps
     */
    'plan'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof RiskTreatmentStrategyProps
     */
    'json_plan'?: object | null;
    /**
     * 
     * @type {string}
     * @memberof RiskTreatmentStrategyProps
     */
    'implementation'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof RiskTreatmentStrategyProps
     */
    'json_implementation'?: object | null;
}
/**
 * 
 * @export
 * @interface RiskWithOverviewPage
 */
export interface RiskWithOverviewPage {
    /**
     * 
     * @type {Array<RiskWithResidualRisk>}
     * @memberof RiskWithOverviewPage
     */
    'items': Array<RiskWithResidualRisk>;
    /**
     * 
     * @type {number}
     * @memberof RiskWithOverviewPage
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithOverviewPage
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithOverviewPage
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithOverviewPage
     */
    'pages'?: number | null;
    /**
     * 
     * @type {object}
     * @memberof RiskWithOverviewPage
     */
    'risk_overview_information': object;
    /**
     * 
     * @type {string}
     * @memberof RiskWithOverviewPage
     */
    'sort_by': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RiskWithOverviewPage
     */
    'descending': boolean | null;
}
/**
 * 
 * @export
 * @interface RiskWithResidualRisk
 */
export interface RiskWithResidualRisk {
    /**
     * 
     * @type {string}
     * @memberof RiskWithResidualRisk
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RiskWithResidualRisk
     */
    'description': string;
    /**
     * 
     * @type {Status}
     * @memberof RiskWithResidualRisk
     */
    'status': Status;
    /**
     * 
     * @type {string}
     * @memberof RiskWithResidualRisk
     */
    'unique_code': string;
    /**
     * 
     * @type {string}
     * @memberof RiskWithResidualRisk
     */
    'risk_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskWithResidualRisk
     */
    'risk_level'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskWithResidualRisk
     */
    'risk_stage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskWithResidualRisk
     */
    'ai_lifecycle_stage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithResidualRisk
     */
    'risk_rpn_value'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithResidualRisk
     */
    'risk_likelihood'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithResidualRisk
     */
    'risk_impact'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithResidualRisk
     */
    'risk_detection'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskWithResidualRisk
     */
    'rpn_risk_level'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithResidualRisk
     */
    'risk_threshold'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskWithResidualRisk
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RiskWithResidualRisk
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof RiskWithResidualRisk
     */
    'assigned_to_id': string | null;
    /**
     * 
     * @type {UserDisplay}
     * @memberof RiskWithResidualRisk
     */
    'assigned_to': UserDisplay | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithResidualRisk
     */
    'residual_risk': number | null;
}


/**
 * 
 * @export
 * @interface RiskWithoutComponentsAggregator
 */
export interface RiskWithoutComponentsAggregator {
    /**
     * 
     * @type {string}
     * @memberof RiskWithoutComponentsAggregator
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RiskWithoutComponentsAggregator
     */
    'description': string;
    /**
     * 
     * @type {Status}
     * @memberof RiskWithoutComponentsAggregator
     */
    'status': Status;
    /**
     * 
     * @type {string}
     * @memberof RiskWithoutComponentsAggregator
     */
    'unique_code': string;
    /**
     * 
     * @type {string}
     * @memberof RiskWithoutComponentsAggregator
     */
    'risk_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskWithoutComponentsAggregator
     */
    'risk_level'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskWithoutComponentsAggregator
     */
    'risk_stage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskWithoutComponentsAggregator
     */
    'ai_lifecycle_stage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithoutComponentsAggregator
     */
    'risk_rpn_value'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithoutComponentsAggregator
     */
    'risk_likelihood'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithoutComponentsAggregator
     */
    'risk_impact'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithoutComponentsAggregator
     */
    'risk_detection'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskWithoutComponentsAggregator
     */
    'rpn_risk_level'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithoutComponentsAggregator
     */
    'risk_threshold'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RiskWithoutComponentsAggregator
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RiskWithoutComponentsAggregator
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof RiskWithoutComponentsAggregator
     */
    'assigned_to_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof RiskWithoutComponentsAggregator
     */
    'created_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof RiskWithoutComponentsAggregator
     */
    'is_locked': boolean;
    /**
     * 
     * @type {string}
     * @memberof RiskWithoutComponentsAggregator
     */
    'modified_at': string | null;
    /**
     * 
     * @type {UserDisplay}
     * @memberof RiskWithoutComponentsAggregator
     */
    'created_by': UserDisplay;
    /**
     * 
     * @type {UserDisplay}
     * @memberof RiskWithoutComponentsAggregator
     */
    'modified_by': UserDisplay | null;
    /**
     * 
     * @type {ProjectDisplay}
     * @memberof RiskWithoutComponentsAggregator
     */
    'project': ProjectDisplay;
    /**
     * 
     * @type {UserDisplay}
     * @memberof RiskWithoutComponentsAggregator
     */
    'assigned_to'?: UserDisplay | null;
    /**
     * 
     * @type {number}
     * @memberof RiskWithoutComponentsAggregator
     */
    'n_comments'?: number;
    /**
     * 
     * @type {Array<RiskTag>}
     * @memberof RiskWithoutComponentsAggregator
     */
    'tags': Array<RiskTag>;
}


/**
 * 
 * @export
 * @interface RoleDisplay
 */
export interface RoleDisplay {
    /**
     * 
     * @type {string}
     * @memberof RoleDisplay
     */
    'id': string;
    /**
     * 
     * @type {Roles}
     * @memberof RoleDisplay
     */
    'role': Roles;
    /**
     * 
     * @type {string}
     * @memberof RoleDisplay
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDisplay
     */
    'project_id': string;
    /**
     * 
     * @type {ProjectDisplay}
     * @memberof RoleDisplay
     */
    'project': ProjectDisplay;
    /**
     * 
     * @type {string}
     * @memberof RoleDisplay
     */
    'created_at': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const Roles = {
    Owner: 'owner',
    Reviewer: 'reviewer',
    Editor: 'editor',
    Auditor: 'auditor'
} as const;

export type Roles = typeof Roles[keyof typeof Roles];


/**
 * 
 * @export
 * @enum {string}
 */

export const Status = {
    InProgress: 'in_progress',
    InReview: 'in_review',
    Completed: 'completed',
    OutOfScope: 'out_of_scope'
} as const;

export type Status = typeof Status[keyof typeof Status];


/**
 * 
 * @export
 * @interface StatusOverview
 */
export interface StatusOverview {
    /**
     * 
     * @type {number}
     * @memberof StatusOverview
     */
    'in_progress': number;
    /**
     * 
     * @type {number}
     * @memberof StatusOverview
     */
    'in_review': number;
    /**
     * 
     * @type {number}
     * @memberof StatusOverview
     */
    'completed': number;
    /**
     * 
     * @type {number}
     * @memberof StatusOverview
     */
    'in_progress_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusOverview
     */
    'in_review_percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatusOverview
     */
    'completed_percentage'?: number;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'tag_name': string;
    /**
     * 
     * @type {Array<TagValues>}
     * @memberof Tag
     */
    'tag_values': Array<TagValues>;
}
/**
 * 
 * @export
 * @interface TagCreator
 */
export interface TagCreator {
    /**
     * 
     * @type {string}
     * @memberof TagCreator
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof TagCreator
     */
    'lastname': string;
}
/**
 * 
 * @export
 * @interface TagValues
 */
export interface TagValues {
    /**
     * 
     * @type {string}
     * @memberof TagValues
     */
    'tag_value': string;
    /**
     * 
     * @type {string}
     * @memberof TagValues
     */
    'tag_description': string;
}
/**
 * 
 * @export
 * @interface Tags
 */
export interface Tags {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Tags
     */
    'tags': Array<Tag>;
    /**
     * 
     * @type {string}
     * @memberof Tags
     */
    'created_at': string;
    /**
     * 
     * @type {TagCreator}
     * @memberof Tags
     */
    'user': TagCreator;
}
/**
 * 
 * @export
 * @interface TestingLog
 */
export interface TestingLog {
    /**
     * 
     * @type {string}
     * @memberof TestingLog
     */
    'testing_metric_id': string;
    /**
     * 
     * @type {Value}
     * @memberof TestingLog
     */
    'value': Value;
    /**
     * 
     * @type {string}
     * @memberof TestingLog
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TestingLog
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TestingLog
     */
    'project_id': string;
}
/**
 * 
 * @export
 * @interface TestingLogCreate
 */
export interface TestingLogCreate {
    /**
     * 
     * @type {string}
     * @memberof TestingLogCreate
     */
    'testing_metric_id': string;
    /**
     * 
     * @type {Value}
     * @memberof TestingLogCreate
     */
    'value': Value;
}
/**
 * 
 * @export
 * @interface TestingMetric
 */
export interface TestingMetric {
    /**
     * 
     * @type {string}
     * @memberof TestingMetric
     */
    'name': string;
    /**
     * 
     * @type {TestingMetricType}
     * @memberof TestingMetric
     */
    'type': TestingMetricType;
    /**
     * 
     * @type {string}
     * @memberof TestingMetric
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof TestingMetric
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TestingMetric
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof TestingMetric
     */
    'created_by_id': string;
}


/**
 * 
 * @export
 * @interface TestingMetricCreate
 */
export interface TestingMetricCreate {
    /**
     * 
     * @type {string}
     * @memberof TestingMetricCreate
     */
    'name': string;
    /**
     * 
     * @type {TestingMetricType}
     * @memberof TestingMetricCreate
     */
    'type': TestingMetricType;
    /**
     * 
     * @type {string}
     * @memberof TestingMetricCreate
     */
    'description': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TestingMetricType = {
    String: 'string',
    Integer: 'integer',
    Float: 'float'
} as const;

export type TestingMetricType = typeof TestingMetricType[keyof typeof TestingMetricType];


/**
 * 
 * @export
 * @interface TestingSource
 */
export interface TestingSource {
    /**
     * 
     * @type {string}
     * @memberof TestingSource
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TestingSource
     */
    'mode': string;
    /**
     * 
     * @type {string}
     * @memberof TestingSource
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface TestingTest
 */
export interface TestingTest {
    /**
     * 
     * @type {string}
     * @memberof TestingTest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TestingTest
     */
    'description'?: string | null;
    /**
     * 
     * @type {TestingTestCondition}
     * @memberof TestingTest
     */
    'conditions': TestingTestCondition;
    /**
     * 
     * @type {string}
     * @memberof TestingTest
     */
    'assigned_to_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof TestingTest
     */
    'status': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TestingTest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TestingTest
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof TestingTest
     */
    'unique_code': string;
}
/**
 * 
 * @export
 * @interface TestingTestAndResult
 */
export interface TestingTestAndResult {
    /**
     * 
     * @type {string}
     * @memberof TestingTestAndResult
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TestingTestAndResult
     */
    'description'?: string | null;
    /**
     * 
     * @type {TestingTestCondition}
     * @memberof TestingTestAndResult
     */
    'conditions': TestingTestCondition;
    /**
     * 
     * @type {string}
     * @memberof TestingTestAndResult
     */
    'assigned_to_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof TestingTestAndResult
     */
    'status': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TestingTestAndResult
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TestingTestAndResult
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof TestingTestAndResult
     */
    'unique_code': string;
    /**
     * 
     * @type {Array<TestingTestResult>}
     * @memberof TestingTestAndResult
     */
    'testing_test_results': Array<TestingTestResult> | null;
}
/**
 * 
 * @export
 * @interface TestingTestAndResultAndUser
 */
export interface TestingTestAndResultAndUser {
    /**
     * 
     * @type {string}
     * @memberof TestingTestAndResultAndUser
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TestingTestAndResultAndUser
     */
    'description'?: string | null;
    /**
     * 
     * @type {TestingTestCondition}
     * @memberof TestingTestAndResultAndUser
     */
    'conditions': TestingTestCondition;
    /**
     * 
     * @type {string}
     * @memberof TestingTestAndResultAndUser
     */
    'assigned_to_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof TestingTestAndResultAndUser
     */
    'status': boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TestingTestAndResultAndUser
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TestingTestAndResultAndUser
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof TestingTestAndResultAndUser
     */
    'unique_code': string;
    /**
     * 
     * @type {Array<TestingTestResult>}
     * @memberof TestingTestAndResultAndUser
     */
    'testing_test_results': Array<TestingTestResult> | null;
    /**
     * 
     * @type {User}
     * @memberof TestingTestAndResultAndUser
     */
    'assigned_to': User;
}
/**
 * 
 * @export
 * @interface TestingTestAndResultPage
 */
export interface TestingTestAndResultPage {
    /**
     * 
     * @type {Array<TestingTestAndResultAndUser>}
     * @memberof TestingTestAndResultPage
     */
    'items': Array<TestingTestAndResultAndUser>;
    /**
     * 
     * @type {number}
     * @memberof TestingTestAndResultPage
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof TestingTestAndResultPage
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof TestingTestAndResultPage
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof TestingTestAndResultPage
     */
    'pages'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestingTestAndResultPage
     */
    'sort_by': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TestingTestAndResultPage
     */
    'descending': boolean;
    /**
     * 
     * @type {number}
     * @memberof TestingTestAndResultPage
     */
    'number_of_errors': number;
    /**
     * 
     * @type {number}
     * @memberof TestingTestAndResultPage
     */
    'number_of_failed': number;
    /**
     * 
     * @type {number}
     * @memberof TestingTestAndResultPage
     */
    'number_of_passed': number;
}
/**
 * 
 * @export
 * @interface TestingTestCondition
 */
export interface TestingTestCondition {
    /**
     * 
     * @type {string}
     * @memberof TestingTestCondition
     */
    'source_id': string;
    /**
     * 
     * @type {string}
     * @memberof TestingTestCondition
     */
    'metric_id': string;
    /**
     * 
     * @type {string}
     * @memberof TestingTestCondition
     */
    'operator': string;
    /**
     * 
     * @type {Value}
     * @memberof TestingTestCondition
     */
    'value': Value;
}
/**
 * 
 * @export
 * @interface TestingTestResult
 */
export interface TestingTestResult {
    /**
     * 
     * @type {string}
     * @memberof TestingTestResult
     */
    'testing_test_id': string;
    /**
     * 
     * @type {TestingTestStatus}
     * @memberof TestingTestResult
     */
    'status': TestingTestStatus;
    /**
     * 
     * @type {Value1}
     * @memberof TestingTestResult
     */
    'value': Value1 | null;
    /**
     * 
     * @type {string}
     * @memberof TestingTestResult
     */
    'details': string | null;
    /**
     * 
     * @type {string}
     * @memberof TestingTestResult
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TestingTestResult
     */
    'created_at': string;
}


/**
 * 
 * @export
 * @interface TestingTestResultPage
 */
export interface TestingTestResultPage {
    /**
     * 
     * @type {Array<TestingTestResult>}
     * @memberof TestingTestResultPage
     */
    'items': Array<TestingTestResult>;
    /**
     * 
     * @type {number}
     * @memberof TestingTestResultPage
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof TestingTestResultPage
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof TestingTestResultPage
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof TestingTestResultPage
     */
    'pages'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TestingTestResultPage
     */
    'sort_by': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TestingTestResultPage
     */
    'descending': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TestingTestStatus = {
    Passed: 'passed',
    Failed: 'failed',
    Error: 'error'
} as const;

export type TestingTestStatus = typeof TestingTestStatus[keyof typeof TestingTestStatus];


/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'token_type': string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'expires_at': string;
}
/**
 * 
 * @export
 * @interface TokenStatus
 */
export interface TokenStatus {
    /**
     * 
     * @type {boolean}
     * @memberof TokenStatus
     */
    'is_valid': boolean;
}
/**
 * 
 * @export
 * @interface UpdateAssetCard
 */
export interface UpdateAssetCard {
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetCard
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAssetCard
     */
    'content'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof UpdateAssetCard
     */
    'json_content'?: object | null;
    /**
     * 
     * @type {AssetCardNature}
     * @memberof UpdateAssetCard
     */
    'nature'?: AssetCardNature | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAssetCard
     */
    'value'?: number | null;
}


/**
 * 
 * @export
 * @interface UpdateComponent
 */
export interface UpdateComponent {
    /**
     * 
     * @type {string}
     * @memberof UpdateComponent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateComponent
     */
    'result'?: string | null;
    /**
     * 
     * @type {object}
     * @memberof UpdateComponent
     */
    'json_result'?: object | null;
}
/**
 * 
 * @export
 * @interface UpdateControl
 */
export interface UpdateControl {
    /**
     * 
     * @type {Array<UpdateComponent>}
     * @memberof UpdateControl
     */
    'components'?: Array<UpdateComponent> | null;
    /**
     * 
     * @type {Array<ControlTag>}
     * @memberof UpdateControl
     */
    'tags'?: Array<ControlTag> | null;
}
/**
 * 
 * @export
 * @interface UpdateOrganizationConfig
 */
export interface UpdateOrganizationConfig {
    /**
     * 
     * @type {GeneralConfig}
     * @memberof UpdateOrganizationConfig
     */
    'general': GeneralConfig;
    /**
     * 
     * @type {PlanQuota}
     * @memberof UpdateOrganizationConfig
     */
    'plan_quota': PlanQuota;
    /**
     * 
     * @type {Flags}
     * @memberof UpdateOrganizationConfig
     */
    'flags': Flags;
}
/**
 * 
 * @export
 * @interface UpdateProject
 */
export interface UpdateProject {
    /**
     * 
     * @type {string}
     * @memberof UpdateProject
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProject
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProject
     */
    'ai_lifecycle_stage'?: string | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof UpdateProject
     */
    'project_settings'?: Array<object> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProject
     */
    'risk_threshold'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateReviewRequest
 */
export interface UpdateReviewRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateReviewRequest
     */
    'reviewer_ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateReviewRequest
     */
    'comment': string;
    /**
     * 
     * @type {object}
     * @memberof UpdateReviewRequest
     */
    'json_comment'?: object | null;
}
/**
 * 
 * @export
 * @interface UpdateRisk
 */
export interface UpdateRisk {
    /**
     * 
     * @type {Array<UpdateRiskComponent>}
     * @memberof UpdateRisk
     */
    'components'?: Array<UpdateRiskComponent> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRisk
     */
    'risk_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRisk
     */
    'risk_level'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRisk
     */
    'risk_stage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRisk
     */
    'ai_lifecycle_stage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRisk
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<RiskTag>}
     * @memberof UpdateRisk
     */
    'tags'?: Array<RiskTag> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateRisk
     */
    'risk_threshold'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateRiskComponent
 */
export interface UpdateRiskComponent {
    /**
     * 
     * @type {string}
     * @memberof UpdateRiskComponent
     */
    'id': string;
    /**
     * 
     * @type {object}
     * @memberof UpdateRiskComponent
     */
    'result'?: object | null;
    /**
     * 
     * @type {object}
     * @memberof UpdateRiskComponent
     */
    'json_result'?: object | null;
}
/**
 * 
 * @export
 * @interface UpdateTestingTest
 */
export interface UpdateTestingTest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTestingTest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTestingTest
     */
    'description'?: string | null;
    /**
     * 
     * @type {TestingTestCondition}
     * @memberof UpdateTestingTest
     */
    'conditions'?: TestingTestCondition | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateTestingTest
     */
    'assigned_to_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTestingTest
     */
    'status'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateUser
 */
export interface UpdateUser {
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUser
     */
    'terms_accepted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUser
     */
    'job_title'?: string | null;
}
/**
 * TODO: Remove me when we remove the azure_oid field from the Auth Flow.
 * @export
 * @interface UpdateUserOID
 */
export interface UpdateUserOID {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOID
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOID
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserOID
     */
    'terms_accepted'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOID
     */
    'job_title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOID
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserOID
     */
    'is_active'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserOID
     */
    'is_org_admin'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserOID
     */
    'azure_oid'?: string | null;
}
/**
 * 
 * @export
 * @interface UsedQuota
 */
export interface UsedQuota {
    /**
     * 
     * @type {number}
     * @memberof UsedQuota
     */
    'projects_ai_application': number;
    /**
     * 
     * @type {number}
     * @memberof UsedQuota
     */
    'projects_organization': number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'azure_oid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {UserState}
     * @memberof User
     */
    'state': UserState;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'is_super_admin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'is_org_admin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'terms_accepted'?: string | null;
}


/**
 * 
 * @export
 * @interface UserActivationStatus
 */
export interface UserActivationStatus {
    /**
     * 
     * @type {boolean}
     * @memberof UserActivationStatus
     */
    'is_active': boolean;
}
/**
 * 
 * @export
 * @interface UserDisplay
 */
export interface UserDisplay {
    /**
     * 
     * @type {string}
     * @memberof UserDisplay
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDisplay
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof UserDisplay
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof UserDisplay
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UserInOrgList
 */
export interface UserInOrgList {
    /**
     * 
     * @type {string}
     * @memberof UserInOrgList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserInOrgList
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof UserInOrgList
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof UserInOrgList
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserInOrgList
     */
    'job_title': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInOrgList
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserInOrgList
     */
    'activated_at'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserInOrgList
     */
    'is_active'?: boolean;
    /**
     * 
     * @type {UserState}
     * @memberof UserInOrgList
     */
    'state': UserState;
    /**
     * 
     * @type {boolean}
     * @memberof UserInOrgList
     */
    'is_org_admin'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInOrgList
     */
    'is_super_admin'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInOrgList
     */
    'last_access'?: string | null;
    /**
     * 
     * @type {Array<RoleDisplay>}
     * @memberof UserInOrgList
     */
    'roles'?: Array<RoleDisplay>;
    /**
     * 
     * @type {Array<ProjectDisplay>}
     * @memberof UserInOrgList
     */
    'projects'?: Array<ProjectDisplay>;
    /**
     * 
     * @type {Array<UserInviteResponse>}
     * @memberof UserInOrgList
     */
    'invites': Array<UserInviteResponse>;
}


/**
 * 
 * @export
 * @interface UserInProjectList
 */
export interface UserInProjectList {
    /**
     * 
     * @type {string}
     * @memberof UserInProjectList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserInProjectList
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof UserInProjectList
     */
    'lastname': string;
    /**
     * 
     * @type {string}
     * @memberof UserInProjectList
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserInProjectList
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserInProjectList
     */
    'last_access'?: string | null;
    /**
     * 
     * @type {Array<RoleDisplay>}
     * @memberof UserInProjectList
     */
    'roles'?: Array<RoleDisplay>;
}
/**
 * 
 * @export
 * @interface UserInviteResponse
 */
export interface UserInviteResponse {
    /**
     * 
     * @type {string}
     * @memberof UserInviteResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserInviteResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserInviteResponse
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserInviteResponse
     */
    'expires_at': string;
    /**
     * 
     * @type {UserInviteStatus}
     * @memberof UserInviteResponse
     */
    'status': UserInviteStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UserInviteStatus = {
    Pending: 'pending',
    Cancelled: 'cancelled',
    Completed: 'completed'
} as const;

export type UserInviteStatus = typeof UserInviteStatus[keyof typeof UserInviteStatus];


/**
 * 
 * @export
 * @interface UserOrgPage
 */
export interface UserOrgPage {
    /**
     * 
     * @type {Array<UserInOrgList>}
     * @memberof UserOrgPage
     */
    'items': Array<UserInOrgList>;
    /**
     * 
     * @type {number}
     * @memberof UserOrgPage
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof UserOrgPage
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof UserOrgPage
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof UserOrgPage
     */
    'pages'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserOrgPage
     */
    'sort_by': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserOrgPage
     */
    'descending': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOrgPage
     */
    'is_org_admin'?: boolean;
}
/**
 * 
 * @export
 * @interface UserProjectPage
 */
export interface UserProjectPage {
    /**
     * 
     * @type {Array<UserInProjectList>}
     * @memberof UserProjectPage
     */
    'items': Array<UserInProjectList>;
    /**
     * 
     * @type {number}
     * @memberof UserProjectPage
     */
    'total': number | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectPage
     */
    'page': number | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectPage
     */
    'size': number | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectPage
     */
    'pages'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectPage
     */
    'sort_by': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProjectPage
     */
    'descending': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProjectPage
     */
    'is_project_owner'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserState = {
    Invited: 'invited',
    Activated: 'activated',
    Deactivated: 'deactivated'
} as const;

export type UserState = typeof UserState[keyof typeof UserState];


/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}
/**
 * 
 * @export
 * @interface Value
 */
export interface Value {
}
/**
 * 
 * @export
 * @interface Value1
 */
export interface Value1 {
}
/**
 * 
 * @export
 * @interface VendorData
 */
export interface VendorData {
    /**
     * 
     * @type {string}
     * @memberof VendorData
     */
    'name': string;
    /**
     * 
     * @type {VendorType}
     * @memberof VendorData
     */
    'type'?: VendorType | null;
    /**
     * 
     * @type {VendorRiskLevel}
     * @memberof VendorData
     */
    'risk_level'?: VendorRiskLevel | null;
    /**
     * 
     * @type {VendorStatus}
     * @memberof VendorData
     */
    'status': VendorStatus;
    /**
     * 
     * @type {string}
     * @memberof VendorData
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VendorData
     */
    'website_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VendorData
     */
    'assigned_to': string;
    /**
     * 
     * @type {string}
     * @memberof VendorData
     */
    'review_date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VendorData
     */
    'annual_contract_value'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VendorData
     */
    'is_subprocessor'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VendorData
     */
    'privacy_policy_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VendorData
     */
    'terms_of_service_url'?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof VendorData
     */
    'address'?: Address | null;
}


/**
 * 
 * @export
 * @interface VendorDetails
 */
export interface VendorDetails {
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    'name': string;
    /**
     * 
     * @type {VendorStatus}
     * @memberof VendorDetails
     */
    'status': VendorStatus;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    'assigned_to': string;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    'organization_id': string;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    'created_at': string;
    /**
     * 
     * @type {VendorType}
     * @memberof VendorDetails
     */
    'type': VendorType | null;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    'modified_at': string | null;
    /**
     * 
     * @type {VendorRiskLevel}
     * @memberof VendorDetails
     */
    'risk_level': VendorRiskLevel | null;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    'website_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    'review_date': string | null;
    /**
     * 
     * @type {number}
     * @memberof VendorDetails
     */
    'annual_contract_value': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VendorDetails
     */
    'is_subprocessor': boolean;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    'privacy_policy_url': string | null;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    'terms_of_service_url': string | null;
    /**
     * 
     * @type {Address}
     * @memberof VendorDetails
     */
    'address': Address | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const VendorRiskLevel = {
    VeryHigh: 'very_high',
    High: 'high',
    Medium: 'medium',
    Low: 'low',
    VeryLow: 'very_low'
} as const;

export type VendorRiskLevel = typeof VendorRiskLevel[keyof typeof VendorRiskLevel];


/**
 * 
 * @export
 * @enum {string}
 */

export const VendorStatus = {
    Active: 'active',
    InReview: 'in_review',
    Rejected: 'rejected'
} as const;

export type VendorStatus = typeof VendorStatus[keyof typeof VendorStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const VendorType = {
    Supplier: 'supplier',
    Contractor: 'contractor',
    Partner: 'partner',
    DataSource: 'data_source',
    Other: 'other'
} as const;

export type VendorType = typeof VendorType[keyof typeof VendorType];



/**
 * AssetCardsApi - axios parameter creator
 * @export
 */
export const AssetCardsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get content of the asset_card with the given id.  Args:     asset_card_id (uuid.UUID): The id of the asset_card.  Returns:     Response: The content of the asset card.
         * @summary Download Asset Card
         * @param {string} assetCardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAssetCardApiAssetCardsAssetCardIdContentGet: async (assetCardId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetCardId' is not null or undefined
            assertParamExists('downloadAssetCardApiAssetCardsAssetCardIdContentGet', 'assetCardId', assetCardId)
            const localVarPath = `/api/asset_cards/{asset_card_id}/content`
                .replace(`{${"asset_card_id"}}`, encodeURIComponent(String(assetCardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return a single asset_card.  Args:     asset_card (schemas.AssetCard, optional): The asset_card to return.         Defaults to Depends(dependencies.get_asset_card_by_id).  Returns:     schemas.AssetCard: The asset_card to return.
         * @summary Get Asset Card
         * @param {string} assetCardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetCardApiAssetCardsAssetCardIdGet: async (assetCardId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetCardId' is not null or undefined
            assertParamExists('getAssetCardApiAssetCardsAssetCardIdGet', 'assetCardId', assetCardId)
            const localVarPath = `/api/asset_cards/{asset_card_id}`
                .replace(`{${"asset_card_id"}}`, encodeURIComponent(String(assetCardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the asset_card with the given id.  Args:     update_asset_card (schemas.UpdateAssetCard): The updated asset_card.     asset_card (schemas.AssetCard, optional): The asset_card to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The updated asset_card.
         * @summary Update Asset Card
         * @param {string} assetCardId 
         * @param {UpdateAssetCard} updateAssetCard 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssetCardApiAssetCardsAssetCardIdPatch: async (assetCardId: string, updateAssetCard: UpdateAssetCard, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetCardId' is not null or undefined
            assertParamExists('updateAssetCardApiAssetCardsAssetCardIdPatch', 'assetCardId', assetCardId)
            // verify required parameter 'updateAssetCard' is not null or undefined
            assertParamExists('updateAssetCardApiAssetCardsAssetCardIdPatch', 'updateAssetCard', updateAssetCard)
            const localVarPath = `/api/asset_cards/{asset_card_id}`
                .replace(`{${"asset_card_id"}}`, encodeURIComponent(String(assetCardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAssetCard, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the assignee of the asset_card to the user with the given user_id.  Args:     assignee (schemas.OrganizationUser): The user to assign the asset_card to.     db (Session, optional): The database session.     asset_card_id (uuid.UUID, optional): The id of the asset_card to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The updated asset_card.
         * @summary Update Asset Card Assignee
         * @param {string} assetCardId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch: async (assetCardId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetCardId' is not null or undefined
            assertParamExists('updateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch', 'assetCardId', assetCardId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch', 'userId', userId)
            const localVarPath = `/api/asset_cards/{asset_card_id}/assignee`
                .replace(`{${"asset_card_id"}}`, encodeURIComponent(String(assetCardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetCardsApi - functional programming interface
 * @export
 */
export const AssetCardsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetCardsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get content of the asset_card with the given id.  Args:     asset_card_id (uuid.UUID): The id of the asset_card.  Returns:     Response: The content of the asset card.
         * @summary Download Asset Card
         * @param {string} assetCardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAssetCardApiAssetCardsAssetCardIdContentGet(assetCardId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAssetCardApiAssetCardsAssetCardIdContentGet(assetCardId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetCardsApi.downloadAssetCardApiAssetCardsAssetCardIdContentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return a single asset_card.  Args:     asset_card (schemas.AssetCard, optional): The asset_card to return.         Defaults to Depends(dependencies.get_asset_card_by_id).  Returns:     schemas.AssetCard: The asset_card to return.
         * @summary Get Asset Card
         * @param {string} assetCardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetCardApiAssetCardsAssetCardIdGet(assetCardId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetCardApiAssetCardsAssetCardIdGet(assetCardId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetCardsApi.getAssetCardApiAssetCardsAssetCardIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the asset_card with the given id.  Args:     update_asset_card (schemas.UpdateAssetCard): The updated asset_card.     asset_card (schemas.AssetCard, optional): The asset_card to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The updated asset_card.
         * @summary Update Asset Card
         * @param {string} assetCardId 
         * @param {UpdateAssetCard} updateAssetCard 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssetCardApiAssetCardsAssetCardIdPatch(assetCardId: string, updateAssetCard: UpdateAssetCard, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssetCardApiAssetCardsAssetCardIdPatch(assetCardId, updateAssetCard, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetCardsApi.updateAssetCardApiAssetCardsAssetCardIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the assignee of the asset_card to the user with the given user_id.  Args:     assignee (schemas.OrganizationUser): The user to assign the asset_card to.     db (Session, optional): The database session.     asset_card_id (uuid.UUID, optional): The id of the asset_card to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The updated asset_card.
         * @summary Update Asset Card Assignee
         * @param {string} assetCardId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch(assetCardId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch(assetCardId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetCardsApi.updateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetCardsApi - factory interface
 * @export
 */
export const AssetCardsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetCardsApiFp(configuration)
    return {
        /**
         * Get content of the asset_card with the given id.  Args:     asset_card_id (uuid.UUID): The id of the asset_card.  Returns:     Response: The content of the asset card.
         * @summary Download Asset Card
         * @param {AssetCardsApiDownloadAssetCardApiAssetCardsAssetCardIdContentGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAssetCardApiAssetCardsAssetCardIdContentGet(requestParameters: AssetCardsApiDownloadAssetCardApiAssetCardsAssetCardIdContentGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.downloadAssetCardApiAssetCardsAssetCardIdContentGet(requestParameters.assetCardId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return a single asset_card.  Args:     asset_card (schemas.AssetCard, optional): The asset_card to return.         Defaults to Depends(dependencies.get_asset_card_by_id).  Returns:     schemas.AssetCard: The asset_card to return.
         * @summary Get Asset Card
         * @param {AssetCardsApiGetAssetCardApiAssetCardsAssetCardIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetCardApiAssetCardsAssetCardIdGet(requestParameters: AssetCardsApiGetAssetCardApiAssetCardsAssetCardIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetCard> {
            return localVarFp.getAssetCardApiAssetCardsAssetCardIdGet(requestParameters.assetCardId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the asset_card with the given id.  Args:     update_asset_card (schemas.UpdateAssetCard): The updated asset_card.     asset_card (schemas.AssetCard, optional): The asset_card to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The updated asset_card.
         * @summary Update Asset Card
         * @param {AssetCardsApiUpdateAssetCardApiAssetCardsAssetCardIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssetCardApiAssetCardsAssetCardIdPatch(requestParameters: AssetCardsApiUpdateAssetCardApiAssetCardsAssetCardIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetCard> {
            return localVarFp.updateAssetCardApiAssetCardsAssetCardIdPatch(requestParameters.assetCardId, requestParameters.updateAssetCard, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the assignee of the asset_card to the user with the given user_id.  Args:     assignee (schemas.OrganizationUser): The user to assign the asset_card to.     db (Session, optional): The database session.     asset_card_id (uuid.UUID, optional): The id of the asset_card to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The updated asset_card.
         * @summary Update Asset Card Assignee
         * @param {AssetCardsApiUpdateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch(requestParameters: AssetCardsApiUpdateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetCard> {
            return localVarFp.updateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch(requestParameters.assetCardId, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for downloadAssetCardApiAssetCardsAssetCardIdContentGet operation in AssetCardsApi.
 * @export
 * @interface AssetCardsApiDownloadAssetCardApiAssetCardsAssetCardIdContentGetRequest
 */
export interface AssetCardsApiDownloadAssetCardApiAssetCardsAssetCardIdContentGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetCardsApiDownloadAssetCardApiAssetCardsAssetCardIdContentGet
     */
    readonly assetCardId: string
}

/**
 * Request parameters for getAssetCardApiAssetCardsAssetCardIdGet operation in AssetCardsApi.
 * @export
 * @interface AssetCardsApiGetAssetCardApiAssetCardsAssetCardIdGetRequest
 */
export interface AssetCardsApiGetAssetCardApiAssetCardsAssetCardIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetCardsApiGetAssetCardApiAssetCardsAssetCardIdGet
     */
    readonly assetCardId: string
}

/**
 * Request parameters for updateAssetCardApiAssetCardsAssetCardIdPatch operation in AssetCardsApi.
 * @export
 * @interface AssetCardsApiUpdateAssetCardApiAssetCardsAssetCardIdPatchRequest
 */
export interface AssetCardsApiUpdateAssetCardApiAssetCardsAssetCardIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetCardsApiUpdateAssetCardApiAssetCardsAssetCardIdPatch
     */
    readonly assetCardId: string

    /**
     * 
     * @type {UpdateAssetCard}
     * @memberof AssetCardsApiUpdateAssetCardApiAssetCardsAssetCardIdPatch
     */
    readonly updateAssetCard: UpdateAssetCard
}

/**
 * Request parameters for updateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch operation in AssetCardsApi.
 * @export
 * @interface AssetCardsApiUpdateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatchRequest
 */
export interface AssetCardsApiUpdateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetCardsApiUpdateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch
     */
    readonly assetCardId: string

    /**
     * 
     * @type {string}
     * @memberof AssetCardsApiUpdateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch
     */
    readonly userId: string
}

/**
 * AssetCardsApi - object-oriented interface
 * @export
 * @class AssetCardsApi
 * @extends {BaseAPI}
 */
export class AssetCardsApi extends BaseAPI {
    /**
     * Get content of the asset_card with the given id.  Args:     asset_card_id (uuid.UUID): The id of the asset_card.  Returns:     Response: The content of the asset card.
     * @summary Download Asset Card
     * @param {AssetCardsApiDownloadAssetCardApiAssetCardsAssetCardIdContentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetCardsApi
     */
    public downloadAssetCardApiAssetCardsAssetCardIdContentGet(requestParameters: AssetCardsApiDownloadAssetCardApiAssetCardsAssetCardIdContentGetRequest, options?: RawAxiosRequestConfig) {
        return AssetCardsApiFp(this.configuration).downloadAssetCardApiAssetCardsAssetCardIdContentGet(requestParameters.assetCardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return a single asset_card.  Args:     asset_card (schemas.AssetCard, optional): The asset_card to return.         Defaults to Depends(dependencies.get_asset_card_by_id).  Returns:     schemas.AssetCard: The asset_card to return.
     * @summary Get Asset Card
     * @param {AssetCardsApiGetAssetCardApiAssetCardsAssetCardIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetCardsApi
     */
    public getAssetCardApiAssetCardsAssetCardIdGet(requestParameters: AssetCardsApiGetAssetCardApiAssetCardsAssetCardIdGetRequest, options?: RawAxiosRequestConfig) {
        return AssetCardsApiFp(this.configuration).getAssetCardApiAssetCardsAssetCardIdGet(requestParameters.assetCardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the asset_card with the given id.  Args:     update_asset_card (schemas.UpdateAssetCard): The updated asset_card.     asset_card (schemas.AssetCard, optional): The asset_card to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The updated asset_card.
     * @summary Update Asset Card
     * @param {AssetCardsApiUpdateAssetCardApiAssetCardsAssetCardIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetCardsApi
     */
    public updateAssetCardApiAssetCardsAssetCardIdPatch(requestParameters: AssetCardsApiUpdateAssetCardApiAssetCardsAssetCardIdPatchRequest, options?: RawAxiosRequestConfig) {
        return AssetCardsApiFp(this.configuration).updateAssetCardApiAssetCardsAssetCardIdPatch(requestParameters.assetCardId, requestParameters.updateAssetCard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the assignee of the asset_card to the user with the given user_id.  Args:     assignee (schemas.OrganizationUser): The user to assign the asset_card to.     db (Session, optional): The database session.     asset_card_id (uuid.UUID, optional): The id of the asset_card to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The updated asset_card.
     * @summary Update Asset Card Assignee
     * @param {AssetCardsApiUpdateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetCardsApi
     */
    public updateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch(requestParameters: AssetCardsApiUpdateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatchRequest, options?: RawAxiosRequestConfig) {
        return AssetCardsApiFp(this.configuration).updateAssetCardAssigneeApiAssetCardsAssetCardIdAssigneePatch(requestParameters.assetCardId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Completes Login flow.
         * @summary Api Token
         * @param {GrantTypes} grantType 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenApiAuthTokenPost: async (grantType: GrantTypes, code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grantType' is not null or undefined
            assertParamExists('apiTokenApiAuthTokenPost', 'grantType', grantType)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiTokenApiAuthTokenPost', 'code', code)
            const localVarPath = `/api/auth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (code !== undefined) { 
                localVarFormParams.set('code', code as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initiates Login. Redirects to oauth2_login_scheme.b2c.authorize_url
         * @summary Authorize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeApiAuthAuthorizeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initiates Signup. Redirects to oauth2_signup_scheme.b2c.authorize_url
         * @summary Authorize Token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeTokenApiAuthAuthorizeTokenGet: async (token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('authorizeTokenApiAuthAuthorizeTokenGet', 'token', token)
            const localVarPath = `/api/auth/authorize/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an API token for the current user.  Args:     user (User): The current user     duration (int): The validity duration in days  Returns:     Token: The API token
         * @summary Create Api Token
         * @param {CreateToken} createToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiTokenApiAuthApiPost: async (createToken: CreateToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createToken' is not null or undefined
            assertParamExists('createApiTokenApiAuthApiPost', 'createToken', createToken)
            const localVarPath = `/api/auth/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an API token for the current user.  Args:     user (User): The current user     token_id (str): The token id  Returns:     ErrorResponse:
         * @summary Delete Api Token
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTokenApiAuthApiDelete: async (tokenId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('deleteApiTokenApiAuthApiDelete', 'tokenId', tokenId)
            const localVarPath = `/api/auth/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (tokenId !== undefined) {
                localVarQueryParameter['token_id'] = tokenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dummy
         * @param {GrantTypes} x 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyApiAuthDummyGet: async (x: GrantTypes, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'x' is not null or undefined
            assertParamExists('dummyApiAuthDummyGet', 'x', x)
            const localVarPath = `/api/auth/dummy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x !== undefined) {
                localVarQueryParameter['x'] = x;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all API tokens for the current user.  Args:     user (User): The current user  Returns:     List[Token]: The list of API tokens
         * @summary Get Api Tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTokensApiAuthApiGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the status of the current user\'s token.  Args:     user (User): The current user  Returns:     TokenStatus: The token status
         * @summary Get Token Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenStatusApiAuthTokenStatusGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/token/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutApiAuthLogoutGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh Token
         * @param {GrantTypes} grantType 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokenApiAuthRefreshPost: async (grantType: GrantTypes, refreshToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grantType' is not null or undefined
            assertParamExists('refreshTokenApiAuthRefreshPost', 'grantType', grantType)
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('refreshTokenApiAuthRefreshPost', 'refreshToken', refreshToken)
            const localVarPath = `/api/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (refreshToken !== undefined) { 
                localVarFormParams.set('refresh_token', refreshToken as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Completes Registration flow.
         * @summary Register
         * @param {GrantTypes} grantType 
         * @param {string} code 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerApiAuthRegisterPost: async (grantType: GrantTypes, code: string, token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grantType' is not null or undefined
            assertParamExists('registerApiAuthRegisterPost', 'grantType', grantType)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('registerApiAuthRegisterPost', 'code', code)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('registerApiAuthRegisterPost', 'token', token)
            const localVarPath = `/api/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (code !== undefined) { 
                localVarFormParams.set('code', code as any);
            }
    
            if (token !== undefined) { 
                localVarFormParams.set('token', token as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * Completes Login flow.
         * @summary Api Token
         * @param {GrantTypes} grantType 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTokenApiAuthTokenPost(grantType: GrantTypes, code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTokenApiAuthTokenPost(grantType, code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.apiTokenApiAuthTokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Initiates Login. Redirects to oauth2_login_scheme.b2c.authorize_url
         * @summary Authorize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeApiAuthAuthorizeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeApiAuthAuthorizeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authorizeApiAuthAuthorizeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Initiates Signup. Redirects to oauth2_signup_scheme.b2c.authorize_url
         * @summary Authorize Token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeTokenApiAuthAuthorizeTokenGet(token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeTokenApiAuthAuthorizeTokenGet(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authorizeTokenApiAuthAuthorizeTokenGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create an API token for the current user.  Args:     user (User): The current user     duration (int): The validity duration in days  Returns:     Token: The API token
         * @summary Create Api Token
         * @param {CreateToken} createToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApiTokenApiAuthApiPost(createToken: CreateToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApiTokenApiAuthApiPost(createToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.createApiTokenApiAuthApiPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an API token for the current user.  Args:     user (User): The current user     token_id (str): The token id  Returns:     ErrorResponse:
         * @summary Delete Api Token
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiTokenApiAuthApiDelete(tokenId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiTokenApiAuthApiDelete(tokenId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.deleteApiTokenApiAuthApiDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Dummy
         * @param {GrantTypes} x 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dummyApiAuthDummyGet(x: GrantTypes, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dummyApiAuthDummyGet(x, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.dummyApiAuthDummyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all API tokens for the current user.  Args:     user (User): The current user  Returns:     List[Token]: The list of API tokens
         * @summary Get Api Tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiTokensApiAuthApiGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Token>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiTokensApiAuthApiGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.getApiTokensApiAuthApiGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the status of the current user\'s token.  Args:     user (User): The current user  Returns:     TokenStatus: The token status
         * @summary Get Token Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokenStatusApiAuthTokenStatusGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenStatusApiAuthTokenStatusGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.getTokenStatusApiAuthTokenStatusGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutApiAuthLogoutGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutApiAuthLogoutGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.logoutApiAuthLogoutGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh Token
         * @param {GrantTypes} grantType 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshTokenApiAuthRefreshPost(grantType: GrantTypes, refreshToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshTokenApiAuthRefreshPost(grantType, refreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.refreshTokenApiAuthRefreshPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Completes Registration flow.
         * @summary Register
         * @param {GrantTypes} grantType 
         * @param {string} code 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerApiAuthRegisterPost(grantType: GrantTypes, code: string, token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerApiAuthRegisterPost(grantType, code, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.registerApiAuthRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * Completes Login flow.
         * @summary Api Token
         * @param {AuthenticationApiApiTokenApiAuthTokenPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenApiAuthTokenPost(requestParameters: AuthenticationApiApiTokenApiAuthTokenPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.apiTokenApiAuthTokenPost(requestParameters.grantType, requestParameters.code, options).then((request) => request(axios, basePath));
        },
        /**
         * Initiates Login. Redirects to oauth2_login_scheme.b2c.authorize_url
         * @summary Authorize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeApiAuthAuthorizeGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.authorizeApiAuthAuthorizeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Initiates Signup. Redirects to oauth2_signup_scheme.b2c.authorize_url
         * @summary Authorize Token
         * @param {AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeTokenApiAuthAuthorizeTokenGet(requestParameters: AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.authorizeTokenApiAuthAuthorizeTokenGet(requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an API token for the current user.  Args:     user (User): The current user     duration (int): The validity duration in days  Returns:     Token: The API token
         * @summary Create Api Token
         * @param {AuthenticationApiCreateApiTokenApiAuthApiPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiTokenApiAuthApiPost(requestParameters: AuthenticationApiCreateApiTokenApiAuthApiPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.createApiTokenApiAuthApiPost(requestParameters.createToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an API token for the current user.  Args:     user (User): The current user     token_id (str): The token id  Returns:     ErrorResponse:
         * @summary Delete Api Token
         * @param {AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTokenApiAuthApiDelete(requestParameters: AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiTokenApiAuthApiDelete(requestParameters.tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dummy
         * @param {AuthenticationApiDummyApiAuthDummyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyApiAuthDummyGet(requestParameters: AuthenticationApiDummyApiAuthDummyGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.dummyApiAuthDummyGet(requestParameters.x, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all API tokens for the current user.  Args:     user (User): The current user  Returns:     List[Token]: The list of API tokens
         * @summary Get Api Tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTokensApiAuthApiGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<Token>> {
            return localVarFp.getApiTokensApiAuthApiGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the status of the current user\'s token.  Args:     user (User): The current user  Returns:     TokenStatus: The token status
         * @summary Get Token Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenStatusApiAuthTokenStatusGet(options?: RawAxiosRequestConfig): AxiosPromise<TokenStatus> {
            return localVarFp.getTokenStatusApiAuthTokenStatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutApiAuthLogoutGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.logoutApiAuthLogoutGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh Token
         * @param {AuthenticationApiRefreshTokenApiAuthRefreshPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokenApiAuthRefreshPost(requestParameters: AuthenticationApiRefreshTokenApiAuthRefreshPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.refreshTokenApiAuthRefreshPost(requestParameters.grantType, requestParameters.refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Completes Registration flow.
         * @summary Register
         * @param {AuthenticationApiRegisterApiAuthRegisterPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerApiAuthRegisterPost(requestParameters: AuthenticationApiRegisterApiAuthRegisterPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.registerApiAuthRegisterPost(requestParameters.grantType, requestParameters.code, requestParameters.token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiTokenApiAuthTokenPost operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiApiTokenApiAuthTokenPostRequest
 */
export interface AuthenticationApiApiTokenApiAuthTokenPostRequest {
    /**
     * 
     * @type {GrantTypes}
     * @memberof AuthenticationApiApiTokenApiAuthTokenPost
     */
    readonly grantType: GrantTypes

    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiApiTokenApiAuthTokenPost
     */
    readonly code: string
}

/**
 * Request parameters for authorizeTokenApiAuthAuthorizeTokenGet operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGetRequest
 */
export interface AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGet
     */
    readonly token: string
}

/**
 * Request parameters for createApiTokenApiAuthApiPost operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiCreateApiTokenApiAuthApiPostRequest
 */
export interface AuthenticationApiCreateApiTokenApiAuthApiPostRequest {
    /**
     * 
     * @type {CreateToken}
     * @memberof AuthenticationApiCreateApiTokenApiAuthApiPost
     */
    readonly createToken: CreateToken
}

/**
 * Request parameters for deleteApiTokenApiAuthApiDelete operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest
 */
export interface AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiDeleteApiTokenApiAuthApiDelete
     */
    readonly tokenId: string
}

/**
 * Request parameters for dummyApiAuthDummyGet operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiDummyApiAuthDummyGetRequest
 */
export interface AuthenticationApiDummyApiAuthDummyGetRequest {
    /**
     * 
     * @type {GrantTypes}
     * @memberof AuthenticationApiDummyApiAuthDummyGet
     */
    readonly x: GrantTypes
}

/**
 * Request parameters for refreshTokenApiAuthRefreshPost operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiRefreshTokenApiAuthRefreshPostRequest
 */
export interface AuthenticationApiRefreshTokenApiAuthRefreshPostRequest {
    /**
     * 
     * @type {GrantTypes}
     * @memberof AuthenticationApiRefreshTokenApiAuthRefreshPost
     */
    readonly grantType: GrantTypes

    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiRefreshTokenApiAuthRefreshPost
     */
    readonly refreshToken: string
}

/**
 * Request parameters for registerApiAuthRegisterPost operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiRegisterApiAuthRegisterPostRequest
 */
export interface AuthenticationApiRegisterApiAuthRegisterPostRequest {
    /**
     * 
     * @type {GrantTypes}
     * @memberof AuthenticationApiRegisterApiAuthRegisterPost
     */
    readonly grantType: GrantTypes

    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiRegisterApiAuthRegisterPost
     */
    readonly code: string

    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiRegisterApiAuthRegisterPost
     */
    readonly token: string
}

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * Completes Login flow.
     * @summary Api Token
     * @param {AuthenticationApiApiTokenApiAuthTokenPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiTokenApiAuthTokenPost(requestParameters: AuthenticationApiApiTokenApiAuthTokenPostRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiTokenApiAuthTokenPost(requestParameters.grantType, requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initiates Login. Redirects to oauth2_login_scheme.b2c.authorize_url
     * @summary Authorize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authorizeApiAuthAuthorizeGet(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authorizeApiAuthAuthorizeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initiates Signup. Redirects to oauth2_signup_scheme.b2c.authorize_url
     * @summary Authorize Token
     * @param {AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authorizeTokenApiAuthAuthorizeTokenGet(requestParameters: AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGetRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authorizeTokenApiAuthAuthorizeTokenGet(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an API token for the current user.  Args:     user (User): The current user     duration (int): The validity duration in days  Returns:     Token: The API token
     * @summary Create Api Token
     * @param {AuthenticationApiCreateApiTokenApiAuthApiPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public createApiTokenApiAuthApiPost(requestParameters: AuthenticationApiCreateApiTokenApiAuthApiPostRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).createApiTokenApiAuthApiPost(requestParameters.createToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an API token for the current user.  Args:     user (User): The current user     token_id (str): The token id  Returns:     ErrorResponse:
     * @summary Delete Api Token
     * @param {AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public deleteApiTokenApiAuthApiDelete(requestParameters: AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).deleteApiTokenApiAuthApiDelete(requestParameters.tokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dummy
     * @param {AuthenticationApiDummyApiAuthDummyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public dummyApiAuthDummyGet(requestParameters: AuthenticationApiDummyApiAuthDummyGetRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).dummyApiAuthDummyGet(requestParameters.x, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all API tokens for the current user.  Args:     user (User): The current user  Returns:     List[Token]: The list of API tokens
     * @summary Get Api Tokens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getApiTokensApiAuthApiGet(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getApiTokensApiAuthApiGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the status of the current user\'s token.  Args:     user (User): The current user  Returns:     TokenStatus: The token status
     * @summary Get Token Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getTokenStatusApiAuthTokenStatusGet(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getTokenStatusApiAuthTokenStatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logoutApiAuthLogoutGet(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).logoutApiAuthLogoutGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh Token
     * @param {AuthenticationApiRefreshTokenApiAuthRefreshPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public refreshTokenApiAuthRefreshPost(requestParameters: AuthenticationApiRefreshTokenApiAuthRefreshPostRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).refreshTokenApiAuthRefreshPost(requestParameters.grantType, requestParameters.refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Completes Registration flow.
     * @summary Register
     * @param {AuthenticationApiRegisterApiAuthRegisterPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public registerApiAuthRegisterPost(requestParameters: AuthenticationApiRegisterApiAuthRegisterPostRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).registerApiAuthRegisterPost(requestParameters.grantType, requestParameters.code, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Organization Config
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/config/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Organization Config
         * @param {string} organizationId 
         * @param {PatchOrganizationConfig} patchOrganizationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch: async (organizationId: string, patchOrganizationConfig: PatchOrganizationConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch', 'organizationId', organizationId)
            // verify required parameter 'patchOrganizationConfig' is not null or undefined
            assertParamExists('patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch', 'patchOrganizationConfig', patchOrganizationConfig)
            const localVarPath = `/api/v1/config/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchOrganizationConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Organization Config
         * @param {string} organizationId 
         * @param {UpdateOrganizationConfig} updateOrganizationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut: async (organizationId: string, updateOrganizationConfig: UpdateOrganizationConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut', 'organizationId', organizationId)
            // verify required parameter 'updateOrganizationConfig' is not null or undefined
            assertParamExists('updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut', 'updateOrganizationConfig', updateOrganizationConfig)
            const localVarPath = `/api/v1/config/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Organization Config
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigApi.getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Patch Organization Config
         * @param {string} organizationId 
         * @param {PatchOrganizationConfig} patchOrganizationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(organizationId: string, patchOrganizationConfig: PatchOrganizationConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(organizationId, patchOrganizationConfig, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigApi.patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Organization Config
         * @param {string} organizationId 
         * @param {UpdateOrganizationConfig} updateOrganizationConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut(organizationId: string, updateOrganizationConfig: UpdateOrganizationConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut(organizationId, updateOrganizationConfig, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConfigApi.updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Organization Config
         * @param {ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(requestParameters: ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationConfig> {
            return localVarFp.getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Organization Config
         * @param {ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(requestParameters: ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationConfig> {
            return localVarFp.patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(requestParameters.organizationId, requestParameters.patchOrganizationConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Organization Config
         * @param {ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut(requestParameters: ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationConfig> {
            return localVarFp.updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut(requestParameters.organizationId, requestParameters.updateOrganizationConfig, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet operation in ConfigApi.
 * @export
 * @interface ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGetRequest
 */
export interface ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet
     */
    readonly organizationId: string
}

/**
 * Request parameters for patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch operation in ConfigApi.
 * @export
 * @interface ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatchRequest
 */
export interface ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch
     */
    readonly organizationId: string

    /**
     * 
     * @type {PatchOrganizationConfig}
     * @memberof ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch
     */
    readonly patchOrganizationConfig: PatchOrganizationConfig
}

/**
 * Request parameters for updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut operation in ConfigApi.
 * @export
 * @interface ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPutRequest
 */
export interface ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut
     */
    readonly organizationId: string

    /**
     * 
     * @type {UpdateOrganizationConfig}
     * @memberof ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut
     */
    readonly updateOrganizationConfig: UpdateOrganizationConfig
}

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
    /**
     * 
     * @summary Get Organization Config
     * @param {ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(requestParameters: ConfigApiGetOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGetRequest, options?: RawAxiosRequestConfig) {
        return ConfigApiFp(this.configuration).getOrganizationConfigApiV1ConfigOrganizationsOrganizationIdGet(requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Organization Config
     * @param {ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(requestParameters: ConfigApiPatchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatchRequest, options?: RawAxiosRequestConfig) {
        return ConfigApiFp(this.configuration).patchOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPatch(requestParameters.organizationId, requestParameters.patchOrganizationConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Organization Config
     * @param {ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut(requestParameters: ConfigApiUpdateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPutRequest, options?: RawAxiosRequestConfig) {
        return ConfigApiFp(this.configuration).updateOrganizationConfigApiV1ConfigOrganizationsOrganizationIdPut(requestParameters.organizationId, requestParameters.updateOrganizationConfig, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ControlsApi - axios parameter creator
 * @export
 */
export const ControlsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a risk to a control.  Args:     control (schemas.Control): The control to add the risk to.     risk (schemas.Risk): The risk to add to the control.  Returns:     schemas.Risk: The risks for the control.
         * @summary Add Risk To Control
         * @param {string} controlId 
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRiskToControlApiControlsControlIdRisksRiskIdPut: async (controlId: string, riskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('addRiskToControlApiControlsControlIdRisksRiskIdPut', 'controlId', controlId)
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('addRiskToControlApiControlsControlIdRisksRiskIdPut', 'riskId', riskId)
            const localVarPath = `/api/controls/{control_id}/risks/{risk_id}`
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)))
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return a single control.  Args:     control (schemas.Control, optional): The control to return.         Defaults to Depends(dependencies.get_control_by_id).  Returns:     schemas.Control: The control to return.
         * @summary Get Control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlApiControlsControlIdGet: async (controlId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('getControlApiControlsControlIdGet', 'controlId', controlId)
            const localVarPath = `/api/controls/{control_id}`
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlApiV1ControlsControlIdGet: async (controlId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('getControlApiV1ControlsControlIdGet', 'controlId', controlId)
            const localVarPath = `/api/v1/controls/{control_id}`
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the risks for a single control.  Args:     control (schemas.Control): The control to return the risks for.  Returns:     list[schemas.Risk]: The risks for the control.
         * @summary Get Control Risks
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlRisksApiControlsControlIdRisksGet: async (controlId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('getControlRisksApiControlsControlIdRisksGet', 'controlId', controlId)
            const localVarPath = `/api/controls/{control_id}/risks`
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Tests By Control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestsByControlApiControlsControlIdTestsGet: async (controlId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('getTestingTestsByControlApiControlsControlIdTestsGet', 'controlId', controlId)
            const localVarPath = `/api/controls/{control_id}/tests`
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove a risk from a control.  Args:     control (schemas.Control): The control to remove the risk from.     risk (schemas.Risk): The risk to remove from the control.  Returns:     schemas.Risk: The risks for the control.
         * @summary Remove Risk From Control
         * @param {string} controlId 
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRiskFromControlApiControlsControlIdRisksRiskIdDelete: async (controlId: string, riskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('removeRiskFromControlApiControlsControlIdRisksRiskIdDelete', 'controlId', controlId)
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('removeRiskFromControlApiControlsControlIdRisksRiskIdDelete', 'riskId', riskId)
            const localVarPath = `/api/controls/{control_id}/risks/{risk_id}`
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)))
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the control with the given id.  Args:     update_control (schemas.UpdateControl): The updated control.     control (schemas.Control, optional): The control to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
         * @summary Update Control
         * @param {string} controlId 
         * @param {UpdateControl} updateControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateControlApiControlsControlIdPatch: async (controlId: string, updateControl: UpdateControl, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('updateControlApiControlsControlIdPatch', 'controlId', controlId)
            // verify required parameter 'updateControl' is not null or undefined
            assertParamExists('updateControlApiControlsControlIdPatch', 'updateControl', updateControl)
            const localVarPath = `/api/controls/{control_id}`
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateControl, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the assignee of the control to the user with the given user_id.  Args:     assignee (schemas.OrganizationUser): The user to assign the control to.     db (Session, optional): The database session.     control_id (uuid.UUID, optional): The id of the control to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
         * @summary Update Control Assignee
         * @param {string} controlId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateControlAssigneeApiControlsControlIdAssigneePatch: async (controlId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('updateControlAssigneeApiControlsControlIdAssigneePatch', 'controlId', controlId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateControlAssigneeApiControlsControlIdAssigneePatch', 'userId', userId)
            const localVarPath = `/api/controls/{control_id}/assignee`
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ControlsApi - functional programming interface
 * @export
 */
export const ControlsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ControlsApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a risk to a control.  Args:     control (schemas.Control): The control to add the risk to.     risk (schemas.Risk): The risk to add to the control.  Returns:     schemas.Risk: The risks for the control.
         * @summary Add Risk To Control
         * @param {string} controlId 
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRiskToControlApiControlsControlIdRisksRiskIdPut(controlId: string, riskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Risk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRiskToControlApiControlsControlIdRisksRiskIdPut(controlId, riskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ControlsApi.addRiskToControlApiControlsControlIdRisksRiskIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return a single control.  Args:     control (schemas.Control, optional): The control to return.         Defaults to Depends(dependencies.get_control_by_id).  Returns:     schemas.Control: The control to return.
         * @summary Get Control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlApiControlsControlIdGet(controlId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlApiControlsControlIdGet(controlId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ControlsApi.getControlApiControlsControlIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlApiV1ControlsControlIdGet(controlId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Control>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlApiV1ControlsControlIdGet(controlId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ControlsApi.getControlApiV1ControlsControlIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return the risks for a single control.  Args:     control (schemas.Control): The control to return the risks for.  Returns:     list[schemas.Risk]: The risks for the control.
         * @summary Get Control Risks
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlRisksApiControlsControlIdRisksGet(controlId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Risk>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlRisksApiControlsControlIdRisksGet(controlId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ControlsApi.getControlRisksApiControlsControlIdRisksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Tests By Control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestsByControlApiControlsControlIdTestsGet(controlId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TestingTestAndResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestsByControlApiControlsControlIdTestsGet(controlId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ControlsApi.getTestingTestsByControlApiControlsControlIdTestsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Remove a risk from a control.  Args:     control (schemas.Control): The control to remove the risk from.     risk (schemas.Risk): The risk to remove from the control.  Returns:     schemas.Risk: The risks for the control.
         * @summary Remove Risk From Control
         * @param {string} controlId 
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeRiskFromControlApiControlsControlIdRisksRiskIdDelete(controlId: string, riskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeRiskFromControlApiControlsControlIdRisksRiskIdDelete(controlId, riskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ControlsApi.removeRiskFromControlApiControlsControlIdRisksRiskIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the control with the given id.  Args:     update_control (schemas.UpdateControl): The updated control.     control (schemas.Control, optional): The control to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
         * @summary Update Control
         * @param {string} controlId 
         * @param {UpdateControl} updateControl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateControlApiControlsControlIdPatch(controlId: string, updateControl: UpdateControl, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateControlApiControlsControlIdPatch(controlId, updateControl, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ControlsApi.updateControlApiControlsControlIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the assignee of the control to the user with the given user_id.  Args:     assignee (schemas.OrganizationUser): The user to assign the control to.     db (Session, optional): The database session.     control_id (uuid.UUID, optional): The id of the control to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
         * @summary Update Control Assignee
         * @param {string} controlId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateControlAssigneeApiControlsControlIdAssigneePatch(controlId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateControlAssigneeApiControlsControlIdAssigneePatch(controlId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ControlsApi.updateControlAssigneeApiControlsControlIdAssigneePatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ControlsApi - factory interface
 * @export
 */
export const ControlsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ControlsApiFp(configuration)
    return {
        /**
         * Add a risk to a control.  Args:     control (schemas.Control): The control to add the risk to.     risk (schemas.Risk): The risk to add to the control.  Returns:     schemas.Risk: The risks for the control.
         * @summary Add Risk To Control
         * @param {ControlsApiAddRiskToControlApiControlsControlIdRisksRiskIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRiskToControlApiControlsControlIdRisksRiskIdPut(requestParameters: ControlsApiAddRiskToControlApiControlsControlIdRisksRiskIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<Risk> {
            return localVarFp.addRiskToControlApiControlsControlIdRisksRiskIdPut(requestParameters.controlId, requestParameters.riskId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return a single control.  Args:     control (schemas.Control, optional): The control to return.         Defaults to Depends(dependencies.get_control_by_id).  Returns:     schemas.Control: The control to return.
         * @summary Get Control
         * @param {ControlsApiGetControlApiControlsControlIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlApiControlsControlIdGet(requestParameters: ControlsApiGetControlApiControlsControlIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControlAggregator> {
            return localVarFp.getControlApiControlsControlIdGet(requestParameters.controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Control
         * @param {ControlsApiGetControlApiV1ControlsControlIdGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlApiV1ControlsControlIdGet(requestParameters: ControlsApiGetControlApiV1ControlsControlIdGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<Control> {
            return localVarFp.getControlApiV1ControlsControlIdGet(requestParameters.controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the risks for a single control.  Args:     control (schemas.Control): The control to return the risks for.  Returns:     list[schemas.Risk]: The risks for the control.
         * @summary Get Control Risks
         * @param {ControlsApiGetControlRisksApiControlsControlIdRisksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlRisksApiControlsControlIdRisksGet(requestParameters: ControlsApiGetControlRisksApiControlsControlIdRisksGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Risk>> {
            return localVarFp.getControlRisksApiControlsControlIdRisksGet(requestParameters.controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Tests By Control
         * @param {ControlsApiGetTestingTestsByControlApiControlsControlIdTestsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestsByControlApiControlsControlIdTestsGet(requestParameters: ControlsApiGetTestingTestsByControlApiControlsControlIdTestsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<TestingTestAndResult>> {
            return localVarFp.getTestingTestsByControlApiControlsControlIdTestsGet(requestParameters.controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove a risk from a control.  Args:     control (schemas.Control): The control to remove the risk from.     risk (schemas.Risk): The risk to remove from the control.  Returns:     schemas.Risk: The risks for the control.
         * @summary Remove Risk From Control
         * @param {ControlsApiRemoveRiskFromControlApiControlsControlIdRisksRiskIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRiskFromControlApiControlsControlIdRisksRiskIdDelete(requestParameters: ControlsApiRemoveRiskFromControlApiControlsControlIdRisksRiskIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeRiskFromControlApiControlsControlIdRisksRiskIdDelete(requestParameters.controlId, requestParameters.riskId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the control with the given id.  Args:     update_control (schemas.UpdateControl): The updated control.     control (schemas.Control, optional): The control to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
         * @summary Update Control
         * @param {ControlsApiUpdateControlApiControlsControlIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateControlApiControlsControlIdPatch(requestParameters: ControlsApiUpdateControlApiControlsControlIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControlAggregator> {
            return localVarFp.updateControlApiControlsControlIdPatch(requestParameters.controlId, requestParameters.updateControl, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the assignee of the control to the user with the given user_id.  Args:     assignee (schemas.OrganizationUser): The user to assign the control to.     db (Session, optional): The database session.     control_id (uuid.UUID, optional): The id of the control to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
         * @summary Update Control Assignee
         * @param {ControlsApiUpdateControlAssigneeApiControlsControlIdAssigneePatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateControlAssigneeApiControlsControlIdAssigneePatch(requestParameters: ControlsApiUpdateControlAssigneeApiControlsControlIdAssigneePatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControlAggregator> {
            return localVarFp.updateControlAssigneeApiControlsControlIdAssigneePatch(requestParameters.controlId, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addRiskToControlApiControlsControlIdRisksRiskIdPut operation in ControlsApi.
 * @export
 * @interface ControlsApiAddRiskToControlApiControlsControlIdRisksRiskIdPutRequest
 */
export interface ControlsApiAddRiskToControlApiControlsControlIdRisksRiskIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlsApiAddRiskToControlApiControlsControlIdRisksRiskIdPut
     */
    readonly controlId: string

    /**
     * 
     * @type {string}
     * @memberof ControlsApiAddRiskToControlApiControlsControlIdRisksRiskIdPut
     */
    readonly riskId: string
}

/**
 * Request parameters for getControlApiControlsControlIdGet operation in ControlsApi.
 * @export
 * @interface ControlsApiGetControlApiControlsControlIdGetRequest
 */
export interface ControlsApiGetControlApiControlsControlIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlsApiGetControlApiControlsControlIdGet
     */
    readonly controlId: string
}

/**
 * Request parameters for getControlApiV1ControlsControlIdGet operation in ControlsApi.
 * @export
 * @interface ControlsApiGetControlApiV1ControlsControlIdGet0Request
 */
export interface ControlsApiGetControlApiV1ControlsControlIdGet0Request {
    /**
     * 
     * @type {string}
     * @memberof ControlsApiGetControlApiV1ControlsControlIdGet0
     */
    readonly controlId: string
}

/**
 * Request parameters for getControlRisksApiControlsControlIdRisksGet operation in ControlsApi.
 * @export
 * @interface ControlsApiGetControlRisksApiControlsControlIdRisksGetRequest
 */
export interface ControlsApiGetControlRisksApiControlsControlIdRisksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlsApiGetControlRisksApiControlsControlIdRisksGet
     */
    readonly controlId: string
}

/**
 * Request parameters for getTestingTestsByControlApiControlsControlIdTestsGet operation in ControlsApi.
 * @export
 * @interface ControlsApiGetTestingTestsByControlApiControlsControlIdTestsGetRequest
 */
export interface ControlsApiGetTestingTestsByControlApiControlsControlIdTestsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlsApiGetTestingTestsByControlApiControlsControlIdTestsGet
     */
    readonly controlId: string
}

/**
 * Request parameters for removeRiskFromControlApiControlsControlIdRisksRiskIdDelete operation in ControlsApi.
 * @export
 * @interface ControlsApiRemoveRiskFromControlApiControlsControlIdRisksRiskIdDeleteRequest
 */
export interface ControlsApiRemoveRiskFromControlApiControlsControlIdRisksRiskIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlsApiRemoveRiskFromControlApiControlsControlIdRisksRiskIdDelete
     */
    readonly controlId: string

    /**
     * 
     * @type {string}
     * @memberof ControlsApiRemoveRiskFromControlApiControlsControlIdRisksRiskIdDelete
     */
    readonly riskId: string
}

/**
 * Request parameters for updateControlApiControlsControlIdPatch operation in ControlsApi.
 * @export
 * @interface ControlsApiUpdateControlApiControlsControlIdPatchRequest
 */
export interface ControlsApiUpdateControlApiControlsControlIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlsApiUpdateControlApiControlsControlIdPatch
     */
    readonly controlId: string

    /**
     * 
     * @type {UpdateControl}
     * @memberof ControlsApiUpdateControlApiControlsControlIdPatch
     */
    readonly updateControl: UpdateControl
}

/**
 * Request parameters for updateControlAssigneeApiControlsControlIdAssigneePatch operation in ControlsApi.
 * @export
 * @interface ControlsApiUpdateControlAssigneeApiControlsControlIdAssigneePatchRequest
 */
export interface ControlsApiUpdateControlAssigneeApiControlsControlIdAssigneePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ControlsApiUpdateControlAssigneeApiControlsControlIdAssigneePatch
     */
    readonly controlId: string

    /**
     * 
     * @type {string}
     * @memberof ControlsApiUpdateControlAssigneeApiControlsControlIdAssigneePatch
     */
    readonly userId: string
}

/**
 * ControlsApi - object-oriented interface
 * @export
 * @class ControlsApi
 * @extends {BaseAPI}
 */
export class ControlsApi extends BaseAPI {
    /**
     * Add a risk to a control.  Args:     control (schemas.Control): The control to add the risk to.     risk (schemas.Risk): The risk to add to the control.  Returns:     schemas.Risk: The risks for the control.
     * @summary Add Risk To Control
     * @param {ControlsApiAddRiskToControlApiControlsControlIdRisksRiskIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlsApi
     */
    public addRiskToControlApiControlsControlIdRisksRiskIdPut(requestParameters: ControlsApiAddRiskToControlApiControlsControlIdRisksRiskIdPutRequest, options?: RawAxiosRequestConfig) {
        return ControlsApiFp(this.configuration).addRiskToControlApiControlsControlIdRisksRiskIdPut(requestParameters.controlId, requestParameters.riskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return a single control.  Args:     control (schemas.Control, optional): The control to return.         Defaults to Depends(dependencies.get_control_by_id).  Returns:     schemas.Control: The control to return.
     * @summary Get Control
     * @param {ControlsApiGetControlApiControlsControlIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlsApi
     */
    public getControlApiControlsControlIdGet(requestParameters: ControlsApiGetControlApiControlsControlIdGetRequest, options?: RawAxiosRequestConfig) {
        return ControlsApiFp(this.configuration).getControlApiControlsControlIdGet(requestParameters.controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Control
     * @param {ControlsApiGetControlApiV1ControlsControlIdGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlsApi
     */
    public getControlApiV1ControlsControlIdGet(requestParameters: ControlsApiGetControlApiV1ControlsControlIdGet0Request, options?: RawAxiosRequestConfig) {
        return ControlsApiFp(this.configuration).getControlApiV1ControlsControlIdGet(requestParameters.controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the risks for a single control.  Args:     control (schemas.Control): The control to return the risks for.  Returns:     list[schemas.Risk]: The risks for the control.
     * @summary Get Control Risks
     * @param {ControlsApiGetControlRisksApiControlsControlIdRisksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlsApi
     */
    public getControlRisksApiControlsControlIdRisksGet(requestParameters: ControlsApiGetControlRisksApiControlsControlIdRisksGetRequest, options?: RawAxiosRequestConfig) {
        return ControlsApiFp(this.configuration).getControlRisksApiControlsControlIdRisksGet(requestParameters.controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Tests By Control
     * @param {ControlsApiGetTestingTestsByControlApiControlsControlIdTestsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlsApi
     */
    public getTestingTestsByControlApiControlsControlIdTestsGet(requestParameters: ControlsApiGetTestingTestsByControlApiControlsControlIdTestsGetRequest, options?: RawAxiosRequestConfig) {
        return ControlsApiFp(this.configuration).getTestingTestsByControlApiControlsControlIdTestsGet(requestParameters.controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove a risk from a control.  Args:     control (schemas.Control): The control to remove the risk from.     risk (schemas.Risk): The risk to remove from the control.  Returns:     schemas.Risk: The risks for the control.
     * @summary Remove Risk From Control
     * @param {ControlsApiRemoveRiskFromControlApiControlsControlIdRisksRiskIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlsApi
     */
    public removeRiskFromControlApiControlsControlIdRisksRiskIdDelete(requestParameters: ControlsApiRemoveRiskFromControlApiControlsControlIdRisksRiskIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ControlsApiFp(this.configuration).removeRiskFromControlApiControlsControlIdRisksRiskIdDelete(requestParameters.controlId, requestParameters.riskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the control with the given id.  Args:     update_control (schemas.UpdateControl): The updated control.     control (schemas.Control, optional): The control to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
     * @summary Update Control
     * @param {ControlsApiUpdateControlApiControlsControlIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlsApi
     */
    public updateControlApiControlsControlIdPatch(requestParameters: ControlsApiUpdateControlApiControlsControlIdPatchRequest, options?: RawAxiosRequestConfig) {
        return ControlsApiFp(this.configuration).updateControlApiControlsControlIdPatch(requestParameters.controlId, requestParameters.updateControl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the assignee of the control to the user with the given user_id.  Args:     assignee (schemas.OrganizationUser): The user to assign the control to.     db (Session, optional): The database session.     control_id (uuid.UUID, optional): The id of the control to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
     * @summary Update Control Assignee
     * @param {ControlsApiUpdateControlAssigneeApiControlsControlIdAssigneePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ControlsApi
     */
    public updateControlAssigneeApiControlsControlIdAssigneePatch(requestParameters: ControlsApiUpdateControlAssigneeApiControlsControlIdAssigneePatchRequest, options?: RawAxiosRequestConfig) {
        return ControlsApiFp(this.configuration).updateControlAssigneeApiControlsControlIdAssigneePatch(requestParameters.controlId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Controls
         * @param {string | null} [controlSearch] Search for a control.
         * @param {string | null} [projectIdOfControls] Filter controls by project id.
         * @param {ControlStatusFilter} [controlStatus] Filter controls by status.
         * @param {Assignment} [assignedAs] Either the assignee or the assignee to review controls.
         * @param {string | null} [sortBy] Sort controls by a field.
         * @param {boolean} [descending] Sort either descending (True) or ascending (False).
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlsApiDashboardAssignedControlsGet: async (controlSearch?: string | null, projectIdOfControls?: string | null, controlStatus?: ControlStatusFilter, assignedAs?: Assignment, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/assigned_controls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (controlSearch !== undefined) {
                localVarQueryParameter['control_search'] = controlSearch;
            }

            if (projectIdOfControls !== undefined) {
                localVarQueryParameter['project_id_of_controls'] = projectIdOfControls;
            }

            if (controlStatus !== undefined) {
                localVarQueryParameter['control_status'] = controlStatus;
            }

            if (assignedAs !== undefined) {
                localVarQueryParameter['assigned_as'] = assignedAs;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Projects
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsApiDashboardProjectsGet: async (page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Risks
         * @param {string | null} [riskSearch] Search for a risk.
         * @param {string | null} [projectIdOfRisks] Filter controls by project id.
         * @param {ControlStatusFilter} [riskStatus] Filter risk by status.
         * @param {Assignment} [assignedAs] Either the assignee or the assignee to review risks.
         * @param {string | null} [sortBy] Sort risks by a field.
         * @param {boolean} [descending] Sort either descending (True) or ascending (False).
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRisksApiDashboardAssignedRisksGet: async (riskSearch?: string | null, projectIdOfRisks?: string | null, riskStatus?: ControlStatusFilter, assignedAs?: Assignment, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/assigned_risks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (riskSearch !== undefined) {
                localVarQueryParameter['risk_search'] = riskSearch;
            }

            if (projectIdOfRisks !== undefined) {
                localVarQueryParameter['project_id_of_risks'] = projectIdOfRisks;
            }

            if (riskStatus !== undefined) {
                localVarQueryParameter['risk_status'] = riskStatus;
            }

            if (assignedAs !== undefined) {
                localVarQueryParameter['assigned_as'] = assignedAs;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Controls
         * @param {string | null} [controlSearch] Search for a control.
         * @param {string | null} [projectIdOfControls] Filter controls by project id.
         * @param {ControlStatusFilter} [controlStatus] Filter controls by status.
         * @param {Assignment} [assignedAs] Either the assignee or the assignee to review controls.
         * @param {string | null} [sortBy] Sort controls by a field.
         * @param {boolean} [descending] Sort either descending (True) or ascending (False).
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlsApiDashboardAssignedControlsGet(controlSearch?: string | null, projectIdOfControls?: string | null, controlStatus?: ControlStatusFilter, assignedAs?: Assignment, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignedControls>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlsApiDashboardAssignedControlsGet(controlSearch, projectIdOfControls, controlStatus, assignedAs, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.getControlsApiDashboardAssignedControlsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Projects
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsApiDashboardProjectsGet(page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsApiDashboardProjectsGet(page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.getProjectsApiDashboardProjectsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Risks
         * @param {string | null} [riskSearch] Search for a risk.
         * @param {string | null} [projectIdOfRisks] Filter controls by project id.
         * @param {ControlStatusFilter} [riskStatus] Filter risk by status.
         * @param {Assignment} [assignedAs] Either the assignee or the assignee to review risks.
         * @param {string | null} [sortBy] Sort risks by a field.
         * @param {boolean} [descending] Sort either descending (True) or ascending (False).
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRisksApiDashboardAssignedRisksGet(riskSearch?: string | null, projectIdOfRisks?: string | null, riskStatus?: ControlStatusFilter, assignedAs?: Assignment, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignedRisks>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRisksApiDashboardAssignedRisksGet(riskSearch, projectIdOfRisks, riskStatus, assignedAs, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardApi.getRisksApiDashboardAssignedRisksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Controls
         * @param {DashboardApiGetControlsApiDashboardAssignedControlsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlsApiDashboardAssignedControlsGet(requestParameters: DashboardApiGetControlsApiDashboardAssignedControlsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AssignedControls> {
            return localVarFp.getControlsApiDashboardAssignedControlsGet(requestParameters.controlSearch, requestParameters.projectIdOfControls, requestParameters.controlStatus, requestParameters.assignedAs, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Projects
         * @param {DashboardApiGetProjectsApiDashboardProjectsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsApiDashboardProjectsGet(requestParameters: DashboardApiGetProjectsApiDashboardProjectsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ProjectsDashboard> {
            return localVarFp.getProjectsApiDashboardProjectsGet(requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Risks
         * @param {DashboardApiGetRisksApiDashboardAssignedRisksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRisksApiDashboardAssignedRisksGet(requestParameters: DashboardApiGetRisksApiDashboardAssignedRisksGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AssignedRisks> {
            return localVarFp.getRisksApiDashboardAssignedRisksGet(requestParameters.riskSearch, requestParameters.projectIdOfRisks, requestParameters.riskStatus, requestParameters.assignedAs, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getControlsApiDashboardAssignedControlsGet operation in DashboardApi.
 * @export
 * @interface DashboardApiGetControlsApiDashboardAssignedControlsGetRequest
 */
export interface DashboardApiGetControlsApiDashboardAssignedControlsGetRequest {
    /**
     * Search for a control.
     * @type {string}
     * @memberof DashboardApiGetControlsApiDashboardAssignedControlsGet
     */
    readonly controlSearch?: string | null

    /**
     * Filter controls by project id.
     * @type {string}
     * @memberof DashboardApiGetControlsApiDashboardAssignedControlsGet
     */
    readonly projectIdOfControls?: string | null

    /**
     * Filter controls by status.
     * @type {ControlStatusFilter}
     * @memberof DashboardApiGetControlsApiDashboardAssignedControlsGet
     */
    readonly controlStatus?: ControlStatusFilter

    /**
     * Either the assignee or the assignee to review controls.
     * @type {Assignment}
     * @memberof DashboardApiGetControlsApiDashboardAssignedControlsGet
     */
    readonly assignedAs?: Assignment

    /**
     * Sort controls by a field.
     * @type {string}
     * @memberof DashboardApiGetControlsApiDashboardAssignedControlsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort either descending (True) or ascending (False).
     * @type {boolean}
     * @memberof DashboardApiGetControlsApiDashboardAssignedControlsGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof DashboardApiGetControlsApiDashboardAssignedControlsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof DashboardApiGetControlsApiDashboardAssignedControlsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectsApiDashboardProjectsGet operation in DashboardApi.
 * @export
 * @interface DashboardApiGetProjectsApiDashboardProjectsGetRequest
 */
export interface DashboardApiGetProjectsApiDashboardProjectsGetRequest {
    /**
     * Page number
     * @type {number}
     * @memberof DashboardApiGetProjectsApiDashboardProjectsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof DashboardApiGetProjectsApiDashboardProjectsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getRisksApiDashboardAssignedRisksGet operation in DashboardApi.
 * @export
 * @interface DashboardApiGetRisksApiDashboardAssignedRisksGetRequest
 */
export interface DashboardApiGetRisksApiDashboardAssignedRisksGetRequest {
    /**
     * Search for a risk.
     * @type {string}
     * @memberof DashboardApiGetRisksApiDashboardAssignedRisksGet
     */
    readonly riskSearch?: string | null

    /**
     * Filter controls by project id.
     * @type {string}
     * @memberof DashboardApiGetRisksApiDashboardAssignedRisksGet
     */
    readonly projectIdOfRisks?: string | null

    /**
     * Filter risk by status.
     * @type {ControlStatusFilter}
     * @memberof DashboardApiGetRisksApiDashboardAssignedRisksGet
     */
    readonly riskStatus?: ControlStatusFilter

    /**
     * Either the assignee or the assignee to review risks.
     * @type {Assignment}
     * @memberof DashboardApiGetRisksApiDashboardAssignedRisksGet
     */
    readonly assignedAs?: Assignment

    /**
     * Sort risks by a field.
     * @type {string}
     * @memberof DashboardApiGetRisksApiDashboardAssignedRisksGet
     */
    readonly sortBy?: string | null

    /**
     * Sort either descending (True) or ascending (False).
     * @type {boolean}
     * @memberof DashboardApiGetRisksApiDashboardAssignedRisksGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof DashboardApiGetRisksApiDashboardAssignedRisksGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof DashboardApiGetRisksApiDashboardAssignedRisksGet
     */
    readonly size?: number
}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary Get Controls
     * @param {DashboardApiGetControlsApiDashboardAssignedControlsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getControlsApiDashboardAssignedControlsGet(requestParameters: DashboardApiGetControlsApiDashboardAssignedControlsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getControlsApiDashboardAssignedControlsGet(requestParameters.controlSearch, requestParameters.projectIdOfControls, requestParameters.controlStatus, requestParameters.assignedAs, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Projects
     * @param {DashboardApiGetProjectsApiDashboardProjectsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getProjectsApiDashboardProjectsGet(requestParameters: DashboardApiGetProjectsApiDashboardProjectsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getProjectsApiDashboardProjectsGet(requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Risks
     * @param {DashboardApiGetRisksApiDashboardAssignedRisksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getRisksApiDashboardAssignedRisksGet(requestParameters: DashboardApiGetRisksApiDashboardAssignedRisksGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getRisksApiDashboardAssignedRisksGet(requestParameters.riskSearch, requestParameters.projectIdOfRisks, requestParameters.riskStatus, requestParameters.assignedAs, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Endpoint that serves Prometheus metrics.
         * @summary Metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsMetricsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * Endpoint that serves Prometheus metrics.
         * @summary Metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsMetricsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metricsMetricsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.metricsMetricsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * Endpoint that serves Prometheus metrics.
         * @summary Metrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsMetricsGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.metricsMetricsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Endpoint that serves Prometheus metrics.
     * @summary Metrics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public metricsMetricsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).metricsMetricsGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EvidencesApi - axios parameter creator
 * @export
 */
export const EvidencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Control To Evidence
         * @param {string} evidenceId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost: async (evidenceId: string, controlId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost', 'evidenceId', evidenceId)
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost', 'controlId', controlId)
            const localVarPath = `/api/evidences/{evidence_id}/controls/{control_id}`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)))
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Control To Evidence
         * @param {string} evidenceId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost_1: async (evidenceId: string, controlId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost_1', 'evidenceId', evidenceId)
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost_1', 'controlId', controlId)
            const localVarPath = `/api/evidences/{evidence_id}/controls/{control_id}`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)))
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds the given evidence to the given component.  Args:     evidence: The evidence to add.     component: The component or risk_component to add the evidence to.
         * @summary Add Evidence To Component
         * @param {string | null} componentId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost: async (componentId: string | null, evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost', 'componentId', componentId)
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost', 'evidenceId', evidenceId)
            const localVarPath = `/api/evidences/{evidence_id}/components/{component_id}`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)))
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds the given evidence to the given component.  Args:     evidence: The evidence to add.     component: The component or risk_component to add the evidence to.
         * @summary Add Evidence To Component
         * @param {string | null} componentId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost_2: async (componentId: string | null, evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost_2', 'componentId', componentId)
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost_2', 'evidenceId', evidenceId)
            const localVarPath = `/api/evidences/{evidence_id}/components/{component_id}`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)))
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Evidence
         * @param {string} projectId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvidenceApiV1ProjectsProjectIdEvidencesPost: async (projectId: string, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createEvidenceApiV1ProjectsProjectIdEvidencesPost', 'projectId', projectId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createEvidenceApiV1ProjectsProjectIdEvidencesPost', 'file', file)
            const localVarPath = `/api/v1/projects/{project_id}/evidences`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Evidence
         * @param {string} projectId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvidenceApiV1ProjectsProjectIdEvidencesPost_3: async (projectId: string, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createEvidenceApiV1ProjectsProjectIdEvidencesPost_3', 'projectId', projectId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createEvidenceApiV1ProjectsProjectIdEvidencesPost_3', 'file', file)
            const localVarPath = `/api/v1/projects/{project_id}/evidences`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Evidence Suggestions
         * @param {string} projectId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost: async (projectId: string, evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost', 'projectId', projectId)
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost', 'evidenceId', evidenceId)
            const localVarPath = `/api/v1/projects/{project_id}/evidences{evidence_id}/suggestions`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Evidence Suggestions
         * @param {string} projectId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost_4: async (projectId: string, evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost_4', 'projectId', projectId)
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost_4', 'evidenceId', evidenceId)
            const localVarPath = `/api/v1/projects/{project_id}/evidences{evidence_id}/suggestions`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the evidence with its file.  Args:     evidence (schemas.Evidence, optional): The evidence to delete with its file.         Defaults to Depends(dependencies.get_evidence).
         * @summary Delete Evidence
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvidenceApiEvidencesEvidenceIdDelete: async (evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('deleteEvidenceApiEvidencesEvidenceIdDelete', 'evidenceId', evidenceId)
            const localVarPath = `/api/evidences/{evidence_id}`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the evidence with its file.  Args:     evidence (schemas.Evidence, optional): The evidence to delete with its file.         Defaults to Depends(dependencies.get_evidence).
         * @summary Delete Evidence
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvidenceApiEvidencesEvidenceIdDelete_5: async (evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('deleteEvidenceApiEvidencesEvidenceIdDelete_5', 'evidenceId', evidenceId)
            const localVarPath = `/api/evidences/{evidence_id}`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the evidence file as a streaming response.  Args:     evidence (schemas.Evidence, optional): The evidence to download.         Defaults to Depends(dependencies.get_evidence). Returns:     StreamingResponse: The evidence file to download.
         * @summary Download Evidence
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEvidenceApiEvidencesEvidenceIdDownloadGet: async (evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('downloadEvidenceApiEvidencesEvidenceIdDownloadGet', 'evidenceId', evidenceId)
            const localVarPath = `/api/evidences/{evidence_id}/download`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return the evidence file as a streaming response.  Args:     evidence (schemas.Evidence, optional): The evidence to download.         Defaults to Depends(dependencies.get_evidence). Returns:     StreamingResponse: The evidence file to download.
         * @summary Download Evidence
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEvidenceApiEvidencesEvidenceIdDownloadGet_6: async (evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('downloadEvidenceApiEvidencesEvidenceIdDownloadGet_6', 'evidenceId', evidenceId)
            const localVarPath = `/api/evidences/{evidence_id}/download`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the evidence with the given id.  Returns:     schemas.Evidence: The evidence with the given id.
         * @summary Get Evidence
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceApiEvidencesEvidenceIdGet: async (evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('getEvidenceApiEvidencesEvidenceIdGet', 'evidenceId', evidenceId)
            const localVarPath = `/api/evidences/{evidence_id}`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the evidence with the given id.  Returns:     schemas.Evidence: The evidence with the given id.
         * @summary Get Evidence
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceApiEvidencesEvidenceIdGet_7: async (evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('getEvidenceApiEvidencesEvidenceIdGet_7', 'evidenceId', evidenceId)
            const localVarPath = `/api/evidences/{evidence_id}`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Evidence Url
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceUrlApiEvidencesEvidenceIdUrlGet: async (evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('getEvidenceUrlApiEvidencesEvidenceIdUrlGet', 'evidenceId', evidenceId)
            const localVarPath = `/api/evidences/{evidence_id}/url`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Evidence Url
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceUrlApiEvidencesEvidenceIdUrlGet_8: async (evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('getEvidenceUrlApiEvidencesEvidenceIdUrlGet_8', 'evidenceId', evidenceId)
            const localVarPath = `/api/evidences/{evidence_id}/url`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Control From Evidence
         * @param {string} evidenceId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete: async (evidenceId: string, controlId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete', 'evidenceId', evidenceId)
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete', 'controlId', controlId)
            const localVarPath = `/api/evidences/{evidence_id}/controls/{control_id}`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)))
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Control From Evidence
         * @param {string} evidenceId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete_9: async (evidenceId: string, controlId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete_9', 'evidenceId', evidenceId)
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete_9', 'controlId', controlId)
            const localVarPath = `/api/evidences/{evidence_id}/controls/{control_id}`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)))
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the given evidence from the given component.  Args:     evidence: The evidence to remove.     component: The component or risk_component to remove the evidence from.
         * @summary Remove Evidence From Component
         * @param {string | null} componentId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete: async (componentId: string | null, evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete', 'componentId', componentId)
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete', 'evidenceId', evidenceId)
            const localVarPath = `/api/evidences/{evidence_id}/components/{component_id}`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)))
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes the given evidence from the given component.  Args:     evidence: The evidence to remove.     component: The component or risk_component to remove the evidence from.
         * @summary Remove Evidence From Component
         * @param {string | null} componentId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete_10: async (componentId: string | null, evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete_10', 'componentId', componentId)
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete_10', 'evidenceId', evidenceId)
            const localVarPath = `/api/evidences/{evidence_id}/components/{component_id}`
                .replace(`{${"component_id"}}`, encodeURIComponent(String(componentId)))
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Evidence
         * @param {string} evidenceId 
         * @param {EvidenceUpdate} evidenceUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvidenceApiEvidencesEvidenceIdPatch: async (evidenceId: string, evidenceUpdate: EvidenceUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('updateEvidenceApiEvidencesEvidenceIdPatch', 'evidenceId', evidenceId)
            // verify required parameter 'evidenceUpdate' is not null or undefined
            assertParamExists('updateEvidenceApiEvidencesEvidenceIdPatch', 'evidenceUpdate', evidenceUpdate)
            const localVarPath = `/api/evidences/{evidence_id}`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(evidenceUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Evidence
         * @param {string} evidenceId 
         * @param {EvidenceUpdate} evidenceUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvidenceApiEvidencesEvidenceIdPatch_11: async (evidenceId: string, evidenceUpdate: EvidenceUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('updateEvidenceApiEvidencesEvidenceIdPatch_11', 'evidenceId', evidenceId)
            // verify required parameter 'evidenceUpdate' is not null or undefined
            assertParamExists('updateEvidenceApiEvidencesEvidenceIdPatch_11', 'evidenceUpdate', evidenceUpdate)
            const localVarPath = `/api/evidences/{evidence_id}`
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(evidenceUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EvidencesApi - functional programming interface
 * @export
 */
export const EvidencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EvidencesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Control To Evidence
         * @param {string} evidenceId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost(evidenceId: string, controlId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost(evidenceId, controlId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add Control To Evidence
         * @param {string} evidenceId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost_1(evidenceId: string, controlId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost_1(evidenceId, controlId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Adds the given evidence to the given component.  Args:     evidence: The evidence to add.     component: The component or risk_component to add the evidence to.
         * @summary Add Evidence To Component
         * @param {string | null} componentId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost(componentId: string | null, evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost(componentId, evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Adds the given evidence to the given component.  Args:     evidence: The evidence to add.     component: The component or risk_component to add the evidence to.
         * @summary Add Evidence To Component
         * @param {string | null} componentId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost_2(componentId: string | null, evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost_2(componentId, evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost_2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Evidence
         * @param {string} projectId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEvidenceApiV1ProjectsProjectIdEvidencesPost(projectId: string, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEvidenceApiV1ProjectsProjectIdEvidencesPost(projectId, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.createEvidenceApiV1ProjectsProjectIdEvidencesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Evidence
         * @param {string} projectId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEvidenceApiV1ProjectsProjectIdEvidencesPost_3(projectId: string, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEvidenceApiV1ProjectsProjectIdEvidencesPost_3(projectId, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.createEvidenceApiV1ProjectsProjectIdEvidencesPost_3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Evidence Suggestions
         * @param {string} projectId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost(projectId: string, evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvidenceSuggestions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost(projectId, evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Evidence Suggestions
         * @param {string} projectId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost_4(projectId: string, evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvidenceSuggestions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost_4(projectId, evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost_4']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the evidence with its file.  Args:     evidence (schemas.Evidence, optional): The evidence to delete with its file.         Defaults to Depends(dependencies.get_evidence).
         * @summary Delete Evidence
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEvidenceApiEvidencesEvidenceIdDelete(evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEvidenceApiEvidencesEvidenceIdDelete(evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.deleteEvidenceApiEvidencesEvidenceIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the evidence with its file.  Args:     evidence (schemas.Evidence, optional): The evidence to delete with its file.         Defaults to Depends(dependencies.get_evidence).
         * @summary Delete Evidence
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEvidenceApiEvidencesEvidenceIdDelete_5(evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEvidenceApiEvidencesEvidenceIdDelete_5(evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.deleteEvidenceApiEvidencesEvidenceIdDelete_5']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return the evidence file as a streaming response.  Args:     evidence (schemas.Evidence, optional): The evidence to download.         Defaults to Depends(dependencies.get_evidence). Returns:     StreamingResponse: The evidence file to download.
         * @summary Download Evidence
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadEvidenceApiEvidencesEvidenceIdDownloadGet(evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadEvidenceApiEvidencesEvidenceIdDownloadGet(evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.downloadEvidenceApiEvidencesEvidenceIdDownloadGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return the evidence file as a streaming response.  Args:     evidence (schemas.Evidence, optional): The evidence to download.         Defaults to Depends(dependencies.get_evidence). Returns:     StreamingResponse: The evidence file to download.
         * @summary Download Evidence
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadEvidenceApiEvidencesEvidenceIdDownloadGet_6(evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadEvidenceApiEvidencesEvidenceIdDownloadGet_6(evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.downloadEvidenceApiEvidencesEvidenceIdDownloadGet_6']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the evidence with the given id.  Returns:     schemas.Evidence: The evidence with the given id.
         * @summary Get Evidence
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvidenceApiEvidencesEvidenceIdGet(evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvidenceApiEvidencesEvidenceIdGet(evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.getEvidenceApiEvidencesEvidenceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the evidence with the given id.  Returns:     schemas.Evidence: The evidence with the given id.
         * @summary Get Evidence
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvidenceApiEvidencesEvidenceIdGet_7(evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvidenceApiEvidencesEvidenceIdGet_7(evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.getEvidenceApiEvidencesEvidenceIdGet_7']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Evidence Url
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvidenceUrlApiEvidencesEvidenceIdUrlGet(evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvidenceURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvidenceUrlApiEvidencesEvidenceIdUrlGet(evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.getEvidenceUrlApiEvidencesEvidenceIdUrlGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Evidence Url
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvidenceUrlApiEvidencesEvidenceIdUrlGet_8(evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvidenceURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvidenceUrlApiEvidencesEvidenceIdUrlGet_8(evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.getEvidenceUrlApiEvidencesEvidenceIdUrlGet_8']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove Control From Evidence
         * @param {string} evidenceId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete(evidenceId: string, controlId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete(evidenceId, controlId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove Control From Evidence
         * @param {string} evidenceId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete_9(evidenceId: string, controlId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete_9(evidenceId, controlId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete_9']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the given evidence from the given component.  Args:     evidence: The evidence to remove.     component: The component or risk_component to remove the evidence from.
         * @summary Remove Evidence From Component
         * @param {string | null} componentId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete(componentId: string | null, evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete(componentId, evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Removes the given evidence from the given component.  Args:     evidence: The evidence to remove.     component: The component or risk_component to remove the evidence from.
         * @summary Remove Evidence From Component
         * @param {string | null} componentId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete_10(componentId: string | null, evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete_10(componentId, evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete_10']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Evidence
         * @param {string} evidenceId 
         * @param {EvidenceUpdate} evidenceUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEvidenceApiEvidencesEvidenceIdPatch(evidenceId: string, evidenceUpdate: EvidenceUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvidenceApiEvidencesEvidenceIdPatch(evidenceId, evidenceUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.updateEvidenceApiEvidencesEvidenceIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Evidence
         * @param {string} evidenceId 
         * @param {EvidenceUpdate} evidenceUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEvidenceApiEvidencesEvidenceIdPatch_11(evidenceId: string, evidenceUpdate: EvidenceUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvidenceApiEvidencesEvidenceIdPatch_11(evidenceId, evidenceUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvidencesApi.updateEvidenceApiEvidencesEvidenceIdPatch_11']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EvidencesApi - factory interface
 * @export
 */
export const EvidencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EvidencesApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Control To Evidence
         * @param {EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost(requestParameters: EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost(requestParameters.evidenceId, requestParameters.controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Control To Evidence
         * @param {EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost_1(requestParameters: EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost0Request, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost_1(requestParameters.evidenceId, requestParameters.controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds the given evidence to the given component.  Args:     evidence: The evidence to add.     component: The component or risk_component to add the evidence to.
         * @summary Add Evidence To Component
         * @param {EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost(requestParameters: EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Evidence> {
            return localVarFp.addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost(requestParameters.componentId, requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds the given evidence to the given component.  Args:     evidence: The evidence to add.     component: The component or risk_component to add the evidence to.
         * @summary Add Evidence To Component
         * @param {EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost_2(requestParameters: EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost0Request, options?: RawAxiosRequestConfig): AxiosPromise<Evidence> {
            return localVarFp.addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost_2(requestParameters.componentId, requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Evidence
         * @param {EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvidenceApiV1ProjectsProjectIdEvidencesPost(requestParameters: EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost0Request, options?: RawAxiosRequestConfig): AxiosPromise<Evidence> {
            return localVarFp.createEvidenceApiV1ProjectsProjectIdEvidencesPost(requestParameters.projectId, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Evidence
         * @param {EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvidenceApiV1ProjectsProjectIdEvidencesPost_3(requestParameters: EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost1Request, options?: RawAxiosRequestConfig): AxiosPromise<Evidence> {
            return localVarFp.createEvidenceApiV1ProjectsProjectIdEvidencesPost_3(requestParameters.projectId, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Evidence Suggestions
         * @param {EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost(requestParameters: EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost0Request, options?: RawAxiosRequestConfig): AxiosPromise<EvidenceSuggestions> {
            return localVarFp.createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost(requestParameters.projectId, requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Evidence Suggestions
         * @param {EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost_4(requestParameters: EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost1Request, options?: RawAxiosRequestConfig): AxiosPromise<EvidenceSuggestions> {
            return localVarFp.createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost_4(requestParameters.projectId, requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the evidence with its file.  Args:     evidence (schemas.Evidence, optional): The evidence to delete with its file.         Defaults to Depends(dependencies.get_evidence).
         * @summary Delete Evidence
         * @param {EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvidenceApiEvidencesEvidenceIdDelete(requestParameters: EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteEvidenceApiEvidencesEvidenceIdDelete(requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the evidence with its file.  Args:     evidence (schemas.Evidence, optional): The evidence to delete with its file.         Defaults to Depends(dependencies.get_evidence).
         * @summary Delete Evidence
         * @param {EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDelete0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvidenceApiEvidencesEvidenceIdDelete_5(requestParameters: EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDelete0Request, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteEvidenceApiEvidencesEvidenceIdDelete_5(requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the evidence file as a streaming response.  Args:     evidence (schemas.Evidence, optional): The evidence to download.         Defaults to Depends(dependencies.get_evidence). Returns:     StreamingResponse: The evidence file to download.
         * @summary Download Evidence
         * @param {EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEvidenceApiEvidencesEvidenceIdDownloadGet(requestParameters: EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.downloadEvidenceApiEvidencesEvidenceIdDownloadGet(requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return the evidence file as a streaming response.  Args:     evidence (schemas.Evidence, optional): The evidence to download.         Defaults to Depends(dependencies.get_evidence). Returns:     StreamingResponse: The evidence file to download.
         * @summary Download Evidence
         * @param {EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEvidenceApiEvidencesEvidenceIdDownloadGet_6(requestParameters: EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.downloadEvidenceApiEvidencesEvidenceIdDownloadGet_6(requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the evidence with the given id.  Returns:     schemas.Evidence: The evidence with the given id.
         * @summary Get Evidence
         * @param {EvidencesApiGetEvidenceApiEvidencesEvidenceIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceApiEvidencesEvidenceIdGet(requestParameters: EvidencesApiGetEvidenceApiEvidencesEvidenceIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Evidence> {
            return localVarFp.getEvidenceApiEvidencesEvidenceIdGet(requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the evidence with the given id.  Returns:     schemas.Evidence: The evidence with the given id.
         * @summary Get Evidence
         * @param {EvidencesApiGetEvidenceApiEvidencesEvidenceIdGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceApiEvidencesEvidenceIdGet_7(requestParameters: EvidencesApiGetEvidenceApiEvidencesEvidenceIdGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<Evidence> {
            return localVarFp.getEvidenceApiEvidencesEvidenceIdGet_7(requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Evidence Url
         * @param {EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceUrlApiEvidencesEvidenceIdUrlGet(requestParameters: EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<EvidenceURL> {
            return localVarFp.getEvidenceUrlApiEvidencesEvidenceIdUrlGet(requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Evidence Url
         * @param {EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceUrlApiEvidencesEvidenceIdUrlGet_8(requestParameters: EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<EvidenceURL> {
            return localVarFp.getEvidenceUrlApiEvidencesEvidenceIdUrlGet_8(requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Control From Evidence
         * @param {EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete(requestParameters: EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete(requestParameters.evidenceId, requestParameters.controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Control From Evidence
         * @param {EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete_9(requestParameters: EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete0Request, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete_9(requestParameters.evidenceId, requestParameters.controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the given evidence from the given component.  Args:     evidence: The evidence to remove.     component: The component or risk_component to remove the evidence from.
         * @summary Remove Evidence From Component
         * @param {EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete(requestParameters: EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete(requestParameters.componentId, requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes the given evidence from the given component.  Args:     evidence: The evidence to remove.     component: The component or risk_component to remove the evidence from.
         * @summary Remove Evidence From Component
         * @param {EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete_10(requestParameters: EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete0Request, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete_10(requestParameters.componentId, requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Evidence
         * @param {EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvidenceApiEvidencesEvidenceIdPatch(requestParameters: EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<Evidence> {
            return localVarFp.updateEvidenceApiEvidencesEvidenceIdPatch(requestParameters.evidenceId, requestParameters.evidenceUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Evidence
         * @param {EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatch0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvidenceApiEvidencesEvidenceIdPatch_11(requestParameters: EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatch0Request, options?: RawAxiosRequestConfig): AxiosPromise<Evidence> {
            return localVarFp.updateEvidenceApiEvidencesEvidenceIdPatch_11(requestParameters.evidenceId, requestParameters.evidenceUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost operation in EvidencesApi.
 * @export
 * @interface EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPostRequest
 */
export interface EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost
     */
    readonly evidenceId: string

    /**
     * 
     * @type {string}
     * @memberof EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost
     */
    readonly controlId: string
}

/**
 * Request parameters for addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost_1 operation in EvidencesApi.
 * @export
 * @interface EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost0Request
 */
export interface EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost0Request {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost0
     */
    readonly evidenceId: string

    /**
     * 
     * @type {string}
     * @memberof EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost0
     */
    readonly controlId: string
}

/**
 * Request parameters for addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost operation in EvidencesApi.
 * @export
 * @interface EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPostRequest
 */
export interface EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost
     */
    readonly componentId: string | null

    /**
     * 
     * @type {string}
     * @memberof EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost
     */
    readonly evidenceId: string
}

/**
 * Request parameters for addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost_2 operation in EvidencesApi.
 * @export
 * @interface EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost0Request
 */
export interface EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost0Request {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost0
     */
    readonly componentId: string | null

    /**
     * 
     * @type {string}
     * @memberof EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost0
     */
    readonly evidenceId: string
}

/**
 * Request parameters for createEvidenceApiV1ProjectsProjectIdEvidencesPost operation in EvidencesApi.
 * @export
 * @interface EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost0Request
 */
export interface EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost0Request {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost0
     */
    readonly projectId: string

    /**
     * 
     * @type {File}
     * @memberof EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost0
     */
    readonly file: File
}

/**
 * Request parameters for createEvidenceApiV1ProjectsProjectIdEvidencesPost_3 operation in EvidencesApi.
 * @export
 * @interface EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost1Request
 */
export interface EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost1Request {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost1
     */
    readonly projectId: string

    /**
     * 
     * @type {File}
     * @memberof EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost1
     */
    readonly file: File
}

/**
 * Request parameters for createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost operation in EvidencesApi.
 * @export
 * @interface EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost0Request
 */
export interface EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost0Request {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost0
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost0
     */
    readonly evidenceId: string
}

/**
 * Request parameters for createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost_4 operation in EvidencesApi.
 * @export
 * @interface EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost1Request
 */
export interface EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost1Request {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost1
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost1
     */
    readonly evidenceId: string
}

/**
 * Request parameters for deleteEvidenceApiEvidencesEvidenceIdDelete operation in EvidencesApi.
 * @export
 * @interface EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDeleteRequest
 */
export interface EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDelete
     */
    readonly evidenceId: string
}

/**
 * Request parameters for deleteEvidenceApiEvidencesEvidenceIdDelete_5 operation in EvidencesApi.
 * @export
 * @interface EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDelete0Request
 */
export interface EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDelete0Request {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDelete0
     */
    readonly evidenceId: string
}

/**
 * Request parameters for downloadEvidenceApiEvidencesEvidenceIdDownloadGet operation in EvidencesApi.
 * @export
 * @interface EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGetRequest
 */
export interface EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGet
     */
    readonly evidenceId: string
}

/**
 * Request parameters for downloadEvidenceApiEvidencesEvidenceIdDownloadGet_6 operation in EvidencesApi.
 * @export
 * @interface EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGet0Request
 */
export interface EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGet0Request {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGet0
     */
    readonly evidenceId: string
}

/**
 * Request parameters for getEvidenceApiEvidencesEvidenceIdGet operation in EvidencesApi.
 * @export
 * @interface EvidencesApiGetEvidenceApiEvidencesEvidenceIdGetRequest
 */
export interface EvidencesApiGetEvidenceApiEvidencesEvidenceIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiGetEvidenceApiEvidencesEvidenceIdGet
     */
    readonly evidenceId: string
}

/**
 * Request parameters for getEvidenceApiEvidencesEvidenceIdGet_7 operation in EvidencesApi.
 * @export
 * @interface EvidencesApiGetEvidenceApiEvidencesEvidenceIdGet0Request
 */
export interface EvidencesApiGetEvidenceApiEvidencesEvidenceIdGet0Request {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiGetEvidenceApiEvidencesEvidenceIdGet0
     */
    readonly evidenceId: string
}

/**
 * Request parameters for getEvidenceUrlApiEvidencesEvidenceIdUrlGet operation in EvidencesApi.
 * @export
 * @interface EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGetRequest
 */
export interface EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGet
     */
    readonly evidenceId: string
}

/**
 * Request parameters for getEvidenceUrlApiEvidencesEvidenceIdUrlGet_8 operation in EvidencesApi.
 * @export
 * @interface EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGet0Request
 */
export interface EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGet0Request {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGet0
     */
    readonly evidenceId: string
}

/**
 * Request parameters for removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete operation in EvidencesApi.
 * @export
 * @interface EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDeleteRequest
 */
export interface EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete
     */
    readonly evidenceId: string

    /**
     * 
     * @type {string}
     * @memberof EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete
     */
    readonly controlId: string
}

/**
 * Request parameters for removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete_9 operation in EvidencesApi.
 * @export
 * @interface EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete0Request
 */
export interface EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete0Request {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete0
     */
    readonly evidenceId: string

    /**
     * 
     * @type {string}
     * @memberof EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete0
     */
    readonly controlId: string
}

/**
 * Request parameters for removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete operation in EvidencesApi.
 * @export
 * @interface EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDeleteRequest
 */
export interface EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete
     */
    readonly componentId: string | null

    /**
     * 
     * @type {string}
     * @memberof EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete
     */
    readonly evidenceId: string
}

/**
 * Request parameters for removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete_10 operation in EvidencesApi.
 * @export
 * @interface EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete0Request
 */
export interface EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete0Request {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete0
     */
    readonly componentId: string | null

    /**
     * 
     * @type {string}
     * @memberof EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete0
     */
    readonly evidenceId: string
}

/**
 * Request parameters for updateEvidenceApiEvidencesEvidenceIdPatch operation in EvidencesApi.
 * @export
 * @interface EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatchRequest
 */
export interface EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatch
     */
    readonly evidenceId: string

    /**
     * 
     * @type {EvidenceUpdate}
     * @memberof EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatch
     */
    readonly evidenceUpdate: EvidenceUpdate
}

/**
 * Request parameters for updateEvidenceApiEvidencesEvidenceIdPatch_11 operation in EvidencesApi.
 * @export
 * @interface EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatch0Request
 */
export interface EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatch0Request {
    /**
     * 
     * @type {string}
     * @memberof EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatch0
     */
    readonly evidenceId: string

    /**
     * 
     * @type {EvidenceUpdate}
     * @memberof EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatch0
     */
    readonly evidenceUpdate: EvidenceUpdate
}

/**
 * EvidencesApi - object-oriented interface
 * @export
 * @class EvidencesApi
 * @extends {BaseAPI}
 */
export class EvidencesApi extends BaseAPI {
    /**
     * 
     * @summary Add Control To Evidence
     * @param {EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost(requestParameters: EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPostRequest, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost(requestParameters.evidenceId, requestParameters.controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add Control To Evidence
     * @param {EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost_1(requestParameters: EvidencesApiAddControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost0Request, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).addControlToEvidenceApiEvidencesEvidenceIdControlsControlIdPost_1(requestParameters.evidenceId, requestParameters.controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds the given evidence to the given component.  Args:     evidence: The evidence to add.     component: The component or risk_component to add the evidence to.
     * @summary Add Evidence To Component
     * @param {EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost(requestParameters: EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPostRequest, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost(requestParameters.componentId, requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds the given evidence to the given component.  Args:     evidence: The evidence to add.     component: The component or risk_component to add the evidence to.
     * @summary Add Evidence To Component
     * @param {EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost_2(requestParameters: EvidencesApiAddEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost0Request, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).addEvidenceToComponentApiEvidencesEvidenceIdComponentsComponentIdPost_2(requestParameters.componentId, requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Evidence
     * @param {EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public createEvidenceApiV1ProjectsProjectIdEvidencesPost(requestParameters: EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost0Request, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).createEvidenceApiV1ProjectsProjectIdEvidencesPost(requestParameters.projectId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Evidence
     * @param {EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public createEvidenceApiV1ProjectsProjectIdEvidencesPost_3(requestParameters: EvidencesApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost1Request, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).createEvidenceApiV1ProjectsProjectIdEvidencesPost_3(requestParameters.projectId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Evidence Suggestions
     * @param {EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost(requestParameters: EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost0Request, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost(requestParameters.projectId, requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Evidence Suggestions
     * @param {EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost_4(requestParameters: EvidencesApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost1Request, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost_4(requestParameters.projectId, requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the evidence with its file.  Args:     evidence (schemas.Evidence, optional): The evidence to delete with its file.         Defaults to Depends(dependencies.get_evidence).
     * @summary Delete Evidence
     * @param {EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public deleteEvidenceApiEvidencesEvidenceIdDelete(requestParameters: EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).deleteEvidenceApiEvidencesEvidenceIdDelete(requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the evidence with its file.  Args:     evidence (schemas.Evidence, optional): The evidence to delete with its file.         Defaults to Depends(dependencies.get_evidence).
     * @summary Delete Evidence
     * @param {EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDelete0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public deleteEvidenceApiEvidencesEvidenceIdDelete_5(requestParameters: EvidencesApiDeleteEvidenceApiEvidencesEvidenceIdDelete0Request, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).deleteEvidenceApiEvidencesEvidenceIdDelete_5(requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the evidence file as a streaming response.  Args:     evidence (schemas.Evidence, optional): The evidence to download.         Defaults to Depends(dependencies.get_evidence). Returns:     StreamingResponse: The evidence file to download.
     * @summary Download Evidence
     * @param {EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public downloadEvidenceApiEvidencesEvidenceIdDownloadGet(requestParameters: EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGetRequest, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).downloadEvidenceApiEvidencesEvidenceIdDownloadGet(requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return the evidence file as a streaming response.  Args:     evidence (schemas.Evidence, optional): The evidence to download.         Defaults to Depends(dependencies.get_evidence). Returns:     StreamingResponse: The evidence file to download.
     * @summary Download Evidence
     * @param {EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public downloadEvidenceApiEvidencesEvidenceIdDownloadGet_6(requestParameters: EvidencesApiDownloadEvidenceApiEvidencesEvidenceIdDownloadGet0Request, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).downloadEvidenceApiEvidencesEvidenceIdDownloadGet_6(requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the evidence with the given id.  Returns:     schemas.Evidence: The evidence with the given id.
     * @summary Get Evidence
     * @param {EvidencesApiGetEvidenceApiEvidencesEvidenceIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public getEvidenceApiEvidencesEvidenceIdGet(requestParameters: EvidencesApiGetEvidenceApiEvidencesEvidenceIdGetRequest, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).getEvidenceApiEvidencesEvidenceIdGet(requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the evidence with the given id.  Returns:     schemas.Evidence: The evidence with the given id.
     * @summary Get Evidence
     * @param {EvidencesApiGetEvidenceApiEvidencesEvidenceIdGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public getEvidenceApiEvidencesEvidenceIdGet_7(requestParameters: EvidencesApiGetEvidenceApiEvidencesEvidenceIdGet0Request, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).getEvidenceApiEvidencesEvidenceIdGet_7(requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Evidence Url
     * @param {EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public getEvidenceUrlApiEvidencesEvidenceIdUrlGet(requestParameters: EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGetRequest, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).getEvidenceUrlApiEvidencesEvidenceIdUrlGet(requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Evidence Url
     * @param {EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public getEvidenceUrlApiEvidencesEvidenceIdUrlGet_8(requestParameters: EvidencesApiGetEvidenceUrlApiEvidencesEvidenceIdUrlGet0Request, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).getEvidenceUrlApiEvidencesEvidenceIdUrlGet_8(requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Control From Evidence
     * @param {EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete(requestParameters: EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete(requestParameters.evidenceId, requestParameters.controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Control From Evidence
     * @param {EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete_9(requestParameters: EvidencesApiRemoveControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete0Request, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).removeControlFromEvidenceApiEvidencesEvidenceIdControlsControlIdDelete_9(requestParameters.evidenceId, requestParameters.controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the given evidence from the given component.  Args:     evidence: The evidence to remove.     component: The component or risk_component to remove the evidence from.
     * @summary Remove Evidence From Component
     * @param {EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete(requestParameters: EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete(requestParameters.componentId, requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes the given evidence from the given component.  Args:     evidence: The evidence to remove.     component: The component or risk_component to remove the evidence from.
     * @summary Remove Evidence From Component
     * @param {EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete_10(requestParameters: EvidencesApiRemoveEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete0Request, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).removeEvidenceFromComponentApiEvidencesEvidenceIdComponentsComponentIdDelete_10(requestParameters.componentId, requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Evidence
     * @param {EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public updateEvidenceApiEvidencesEvidenceIdPatch(requestParameters: EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatchRequest, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).updateEvidenceApiEvidencesEvidenceIdPatch(requestParameters.evidenceId, requestParameters.evidenceUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Evidence
     * @param {EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatch0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvidencesApi
     */
    public updateEvidenceApiEvidencesEvidenceIdPatch_11(requestParameters: EvidencesApiUpdateEvidenceApiEvidencesEvidenceIdPatch0Request, options?: RawAxiosRequestConfig) {
        return EvidencesApiFp(this.configuration).updateEvidenceApiEvidencesEvidenceIdPatch_11(requestParameters.evidenceId, requestParameters.evidenceUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FrameworksApi - axios parameter creator
 * @export
 */
export const FrameworksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the framework with the given id. Args:     framework_id (UUID): The framework\'s id.  Returns:     framework: The framework with the given id.
         * @summary Get Framework
         * @param {string} frameworkId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFrameworkApiFrameworksFrameworkIdGet: async (frameworkId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frameworkId' is not null or undefined
            assertParamExists('getFrameworkApiFrameworksFrameworkIdGet', 'frameworkId', frameworkId)
            const localVarPath = `/api/frameworks/{framework_id}`
                .replace(`{${"framework_id"}}`, encodeURIComponent(String(frameworkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Framework In Organization
         * @param {string} frameworkCode 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet: async (frameworkCode: string, organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frameworkCode' is not null or undefined
            assertParamExists('getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet', 'frameworkCode', frameworkCode)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}/frameworks/{framework_code}`
                .replace(`{${"framework_code"}}`, encodeURIComponent(String(frameworkCode)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Frameworks In Organization
         * @param {string} organizationId 
         * @param {ProjectType} [projectType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet: async (organizationId: string, projectType?: ProjectType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}/frameworks`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (projectType !== undefined) {
                localVarQueryParameter['project_type'] = projectType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrameworksApi - functional programming interface
 * @export
 */
export const FrameworksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrameworksApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the framework with the given id. Args:     framework_id (UUID): The framework\'s id.  Returns:     framework: The framework with the given id.
         * @summary Get Framework
         * @param {string} frameworkId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getFrameworkApiFrameworksFrameworkIdGet(frameworkId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrameworkAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrameworkApiFrameworksFrameworkIdGet(frameworkId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrameworksApi.getFrameworkApiFrameworksFrameworkIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Framework In Organization
         * @param {string} frameworkCode 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(frameworkCode: string, organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrameworkDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(frameworkCode, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrameworksApi.getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Frameworks In Organization
         * @param {string} organizationId 
         * @param {ProjectType} [projectType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(organizationId: string, projectType?: ProjectType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FrameworkOverview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(organizationId, projectType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FrameworksApi.getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FrameworksApi - factory interface
 * @export
 */
export const FrameworksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrameworksApiFp(configuration)
    return {
        /**
         * Returns the framework with the given id. Args:     framework_id (UUID): The framework\'s id.  Returns:     framework: The framework with the given id.
         * @summary Get Framework
         * @param {FrameworksApiGetFrameworkApiFrameworksFrameworkIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFrameworkApiFrameworksFrameworkIdGet(requestParameters: FrameworksApiGetFrameworkApiFrameworksFrameworkIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FrameworkAggregator> {
            return localVarFp.getFrameworkApiFrameworksFrameworkIdGet(requestParameters.frameworkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Framework In Organization
         * @param {FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(requestParameters: FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FrameworkDetailed> {
            return localVarFp.getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(requestParameters.frameworkCode, requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Frameworks In Organization
         * @param {FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(requestParameters: FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<FrameworkOverview>> {
            return localVarFp.getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(requestParameters.organizationId, requestParameters.projectType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getFrameworkApiFrameworksFrameworkIdGet operation in FrameworksApi.
 * @export
 * @interface FrameworksApiGetFrameworkApiFrameworksFrameworkIdGetRequest
 */
export interface FrameworksApiGetFrameworkApiFrameworksFrameworkIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FrameworksApiGetFrameworkApiFrameworksFrameworkIdGet
     */
    readonly frameworkId: string
}

/**
 * Request parameters for getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet operation in FrameworksApi.
 * @export
 * @interface FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest
 */
export interface FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet
     */
    readonly frameworkCode: string

    /**
     * 
     * @type {string}
     * @memberof FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet
     */
    readonly organizationId: string
}

/**
 * Request parameters for getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet operation in FrameworksApi.
 * @export
 * @interface FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGetRequest
 */
export interface FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet
     */
    readonly organizationId: string

    /**
     * 
     * @type {ProjectType}
     * @memberof FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet
     */
    readonly projectType?: ProjectType
}

/**
 * FrameworksApi - object-oriented interface
 * @export
 * @class FrameworksApi
 * @extends {BaseAPI}
 */
export class FrameworksApi extends BaseAPI {
    /**
     * Returns the framework with the given id. Args:     framework_id (UUID): The framework\'s id.  Returns:     framework: The framework with the given id.
     * @summary Get Framework
     * @param {FrameworksApiGetFrameworkApiFrameworksFrameworkIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FrameworksApi
     */
    public getFrameworkApiFrameworksFrameworkIdGet(requestParameters: FrameworksApiGetFrameworkApiFrameworksFrameworkIdGetRequest, options?: RawAxiosRequestConfig) {
        return FrameworksApiFp(this.configuration).getFrameworkApiFrameworksFrameworkIdGet(requestParameters.frameworkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Framework In Organization
     * @param {FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrameworksApi
     */
    public getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(requestParameters: FrameworksApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGetRequest, options?: RawAxiosRequestConfig) {
        return FrameworksApiFp(this.configuration).getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(requestParameters.frameworkCode, requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Frameworks In Organization
     * @param {FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrameworksApi
     */
    public getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(requestParameters: FrameworksApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGetRequest, options?: RawAxiosRequestConfig) {
        return FrameworksApiFp(this.configuration).getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(requestParameters.organizationId, requestParameters.projectType, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endpointApiHealthGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endpointApiHealthGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endpointApiHealthGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HealthApi.endpointApiHealthGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary Endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endpointApiHealthGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.endpointApiHealthGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary Endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public endpointApiHealthGet(options?: RawAxiosRequestConfig) {
        return HealthApiFp(this.configuration).endpointApiHealthGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MaintenanceApi - axios parameter creator
 * @export
 */
export const MaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Clear cache
         * @summary Clear Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCacheMaintenanceClearCacheGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/maintenance/clear-cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Configure templates and create tags for the default organization
         * @summary Configure Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configureTemplatesMaintenanceTemplatesPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/maintenance/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initialize the vector database
         * @summary Initialize Vector Db
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeVectorDbMaintenanceInitializeVectorDbPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/maintenance/initialize-vector-db`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceApi - functional programming interface
 * @export
 */
export const MaintenanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenanceApiAxiosParamCreator(configuration)
    return {
        /**
         * Clear cache
         * @summary Clear Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearCacheMaintenanceClearCacheGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearCacheMaintenanceClearCacheGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaintenanceApi.clearCacheMaintenanceClearCacheGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Configure templates and create tags for the default organization
         * @summary Configure Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configureTemplatesMaintenanceTemplatesPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configureTemplatesMaintenanceTemplatesPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaintenanceApi.configureTemplatesMaintenanceTemplatesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Initialize the vector database
         * @summary Initialize Vector Db
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initializeVectorDbMaintenanceInitializeVectorDbPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initializeVectorDbMaintenanceInitializeVectorDbPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MaintenanceApi.initializeVectorDbMaintenanceInitializeVectorDbPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MaintenanceApi - factory interface
 * @export
 */
export const MaintenanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenanceApiFp(configuration)
    return {
        /**
         * Clear cache
         * @summary Clear Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCacheMaintenanceClearCacheGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.clearCacheMaintenanceClearCacheGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Configure templates and create tags for the default organization
         * @summary Configure Templates
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configureTemplatesMaintenanceTemplatesPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.configureTemplatesMaintenanceTemplatesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * Initialize the vector database
         * @summary Initialize Vector Db
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initializeVectorDbMaintenanceInitializeVectorDbPost(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.initializeVectorDbMaintenanceInitializeVectorDbPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenanceApi - object-oriented interface
 * @export
 * @class MaintenanceApi
 * @extends {BaseAPI}
 */
export class MaintenanceApi extends BaseAPI {
    /**
     * Clear cache
     * @summary Clear Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public clearCacheMaintenanceClearCacheGet(options?: RawAxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).clearCacheMaintenanceClearCacheGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Configure templates and create tags for the default organization
     * @summary Configure Templates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public configureTemplatesMaintenanceTemplatesPost(options?: RawAxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).configureTemplatesMaintenanceTemplatesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initialize the vector database
     * @summary Initialize Vector Db
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public initializeVectorDbMaintenanceInitializeVectorDbPost(options?: RawAxiosRequestConfig) {
        return MaintenanceApiFp(this.configuration).initializeVectorDbMaintenanceInitializeVectorDbPost(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Notifications
         * @param {NotificationStatus | null} [notificationStatus] Filter notifications by status.
         * @param {string | null} [name] Filter by notification content
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsApiNotificationsGet: async (notificationStatus?: NotificationStatus | null, name?: string | null, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (notificationStatus !== undefined) {
                localVarQueryParameter['notification_status'] = notificationStatus;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SSE endpoint that sends JSON objects with a \'type\' and \'count\' field. Example: {\'type\': \'unread_notifications\', \'count\': 5}
         * @summary Get Unread Notifications Stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotificationsStreamApiNotificationsUnreadStreamGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/unread/stream/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Notification
         * @param {string} notificationId 
         * @param {NotificationStatus} notificationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationApiNotificationsNotificationIdPatch: async (notificationId: string, notificationStatus: NotificationStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('updateNotificationApiNotificationsNotificationIdPatch', 'notificationId', notificationId)
            // verify required parameter 'notificationStatus' is not null or undefined
            assertParamExists('updateNotificationApiNotificationsNotificationIdPatch', 'notificationStatus', notificationStatus)
            const localVarPath = `/api/notifications/{notification_id}`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (notificationStatus !== undefined) {
                localVarQueryParameter['notification_status'] = notificationStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Notifications
         * @param {NotificationStatus} notificationStatus 
         * @param {NotificationIdList} notificationIdList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationsApiNotificationsBulkPatch: async (notificationStatus: NotificationStatus, notificationIdList: NotificationIdList, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationStatus' is not null or undefined
            assertParamExists('updateNotificationsApiNotificationsBulkPatch', 'notificationStatus', notificationStatus)
            // verify required parameter 'notificationIdList' is not null or undefined
            assertParamExists('updateNotificationsApiNotificationsBulkPatch', 'notificationIdList', notificationIdList)
            const localVarPath = `/api/notifications/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (notificationStatus !== undefined) {
                localVarQueryParameter['notification_status'] = notificationStatus;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationIdList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Notifications
         * @param {NotificationStatus | null} [notificationStatus] Filter notifications by status.
         * @param {string | null} [name] Filter by notification content
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationsApiNotificationsGet(notificationStatus?: NotificationStatus | null, name?: string | null, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomNotificationPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationsApiNotificationsGet(notificationStatus, name, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.getNotificationsApiNotificationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * SSE endpoint that sends JSON objects with a \'type\' and \'count\' field. Example: {\'type\': \'unread_notifications\', \'count\': 5}
         * @summary Get Unread Notifications Stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadNotificationsStreamApiNotificationsUnreadStreamGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadNotificationsStreamApiNotificationsUnreadStreamGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.getUnreadNotificationsStreamApiNotificationsUnreadStreamGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Notification
         * @param {string} notificationId 
         * @param {NotificationStatus} notificationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationApiNotificationsNotificationIdPatch(notificationId: string, notificationStatus: NotificationStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationApiNotificationsNotificationIdPatch(notificationId, notificationStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.updateNotificationApiNotificationsNotificationIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Notifications
         * @param {NotificationStatus} notificationStatus 
         * @param {NotificationIdList} notificationIdList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationsApiNotificationsBulkPatch(notificationStatus: NotificationStatus, notificationIdList: NotificationIdList, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationsApiNotificationsBulkPatch(notificationStatus, notificationIdList, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationsApi.updateNotificationsApiNotificationsBulkPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Notifications
         * @param {NotificationsApiGetNotificationsApiNotificationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsApiNotificationsGet(requestParameters: NotificationsApiGetNotificationsApiNotificationsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CustomNotificationPage> {
            return localVarFp.getNotificationsApiNotificationsGet(requestParameters.notificationStatus, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * SSE endpoint that sends JSON objects with a \'type\' and \'count\' field. Example: {\'type\': \'unread_notifications\', \'count\': 5}
         * @summary Get Unread Notifications Stream
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotificationsStreamApiNotificationsUnreadStreamGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getUnreadNotificationsStreamApiNotificationsUnreadStreamGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Notification
         * @param {NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationApiNotificationsNotificationIdPatch(requestParameters: NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<Notification> {
            return localVarFp.updateNotificationApiNotificationsNotificationIdPatch(requestParameters.notificationId, requestParameters.notificationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Notifications
         * @param {NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationsApiNotificationsBulkPatch(requestParameters: NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateNotificationsApiNotificationsBulkPatch(requestParameters.notificationStatus, requestParameters.notificationIdList, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getNotificationsApiNotificationsGet operation in NotificationsApi.
 * @export
 * @interface NotificationsApiGetNotificationsApiNotificationsGetRequest
 */
export interface NotificationsApiGetNotificationsApiNotificationsGetRequest {
    /**
     * Filter notifications by status.
     * @type {NotificationStatus}
     * @memberof NotificationsApiGetNotificationsApiNotificationsGet
     */
    readonly notificationStatus?: NotificationStatus | null

    /**
     * Filter by notification content
     * @type {string}
     * @memberof NotificationsApiGetNotificationsApiNotificationsGet
     */
    readonly name?: string | null

    /**
     * Page number
     * @type {number}
     * @memberof NotificationsApiGetNotificationsApiNotificationsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof NotificationsApiGetNotificationsApiNotificationsGet
     */
    readonly size?: number
}

/**
 * Request parameters for updateNotificationApiNotificationsNotificationIdPatch operation in NotificationsApi.
 * @export
 * @interface NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest
 */
export interface NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatch
     */
    readonly notificationId: string

    /**
     * 
     * @type {NotificationStatus}
     * @memberof NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatch
     */
    readonly notificationStatus: NotificationStatus
}

/**
 * Request parameters for updateNotificationsApiNotificationsBulkPatch operation in NotificationsApi.
 * @export
 * @interface NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest
 */
export interface NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest {
    /**
     * 
     * @type {NotificationStatus}
     * @memberof NotificationsApiUpdateNotificationsApiNotificationsBulkPatch
     */
    readonly notificationStatus: NotificationStatus

    /**
     * 
     * @type {NotificationIdList}
     * @memberof NotificationsApiUpdateNotificationsApiNotificationsBulkPatch
     */
    readonly notificationIdList: NotificationIdList
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Get Notifications
     * @param {NotificationsApiGetNotificationsApiNotificationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotificationsApiNotificationsGet(requestParameters: NotificationsApiGetNotificationsApiNotificationsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotificationsApiNotificationsGet(requestParameters.notificationStatus, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SSE endpoint that sends JSON objects with a \'type\' and \'count\' field. Example: {\'type\': \'unread_notifications\', \'count\': 5}
     * @summary Get Unread Notifications Stream
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getUnreadNotificationsStreamApiNotificationsUnreadStreamGet(options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getUnreadNotificationsStreamApiNotificationsUnreadStreamGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Notification
     * @param {NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotificationApiNotificationsNotificationIdPatch(requestParameters: NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).updateNotificationApiNotificationsNotificationIdPatch(requestParameters.notificationId, requestParameters.notificationStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Notifications
     * @param {NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotificationsApiNotificationsBulkPatch(requestParameters: NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).updateNotificationsApiNotificationsBulkPatch(requestParameters.notificationStatus, requestParameters.notificationIdList, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationsApi - axios parameter creator
 * @export
 */
export const OrganizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates an organization.  Args:     organization (schemas.OrganizationCreate): The organization to create.     db (Session): The database session.  Returns:     schemas.Organization: The created organization.
         * @summary Create Organization
         * @param {OrganizationUpdate} organizationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationApiOrganizationsPost: async (organizationUpdate: OrganizationUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationUpdate' is not null or undefined
            assertParamExists('createOrganizationApiOrganizationsPost', 'organizationUpdate', organizationUpdate)
            const localVarPath = `/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Templates Of Organization
         * @param {string} frameworkCode 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete: async (frameworkCode: string, organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frameworkCode' is not null or undefined
            assertParamExists('deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete', 'frameworkCode', frameworkCode)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete', 'organizationId', organizationId)
            const localVarPath = `/api/organizations/{organization_id}/templates/{framework_code}`
                .replace(`{${"framework_code"}}`, encodeURIComponent(String(frameworkCode)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint is used to get files from the storage from local deployment. On production, the files are served by a sas link to the blob.
         * @summary Get File
         * @param {string} token 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileApiOrganizationsOrganizationIdFilesTokenGet: async (token: string, organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getFileApiOrganizationsOrganizationIdFilesTokenGet', 'token', token)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getFileApiOrganizationsOrganizationIdFilesTokenGet', 'organizationId', organizationId)
            const localVarPath = `/api/organizations/{organization_id}/files/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Frameworks Of Organization
         * @param {string} organizationId 
         * @param {ProjectType} [projectType] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet: async (organizationId: string, projectType?: ProjectType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet', 'organizationId', organizationId)
            const localVarPath = `/api/organizations/{organization_id}/templates`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (projectType !== undefined) {
                localVarQueryParameter['project_type'] = projectType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the organization quota. Args:     organization_id (schemas.UUID): The organization ID.     db (Session): The database session. Raises:     HTTPException: If the organization is not found. Returns (schemas.OrganizationQuota): The organization quota.
         * @summary Get Organization Quota
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}/quota`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all organizations.  Args:     db (Session): The database session.  Returns:     Page[schemas.Organization]: The organizations.
         * @summary Get Organizations
         * @param {string | null} [name] The name filter.
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsApiOrganizationsGet: async (name?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the projects of the organization.  Args:     organization (Orgnaization): The organization.     db (Session): Database session.     name (str, optional): Filter by project name. Defaults to None.     project_type (enums.ProjectType, optional): Filter by project type.     params (Params, optional): Pagination params. Defaults to Depends().     sort_by (str, optional): Sort by a field. Defaults to None.     descending (bool, optional): Sort order. If true, descending, otherwise         ascending. Defaults to True.  Returns:     Page[schemas.Project]: List of projects.
         * @summary Get Projects
         * @param {string} organizationId 
         * @param {string | null} [name] Filter by project name
         * @param {ProjectType} [projectType] 
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsApiOrganizationsOrganizationIdProjectsGet: async (organizationId: string, name?: string | null, projectType?: ProjectType, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getProjectsApiOrganizationsOrganizationIdProjectsGet', 'organizationId', organizationId)
            const localVarPath = `/api/organizations/{organization_id}/projects`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (projectType !== undefined) {
                localVarQueryParameter['project_type'] = projectType;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tags
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsApiOrganizationsOrganizationIdTagsGet: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getTagsApiOrganizationsOrganizationIdTagsGet', 'organizationId', organizationId)
            const localVarPath = `/api/organizations/{organization_id}/tags`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users Of Organization
         * @param {string} organizationId 
         * @param {string | null} [name] The user name filter.
         * @param {string | null} [notInProject] Filter by users not in a project.
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {boolean} [includeInactive] Filter by status. By default, inactive users will not be returned.
         * @param {string | null} [state] Filter by user current state.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet: async (organizationId: string, name?: string | null, notInProject?: string | null, sortBy?: string | null, descending?: boolean, includeInactive?: boolean, state?: string | null, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet', 'organizationId', organizationId)
            const localVarPath = `/api/organizations/{organization_id}/users`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (notInProject !== undefined) {
                localVarQueryParameter['not_in_project'] = notInProject;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['include_inactive'] = includeInactive;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post User Force
         * @param {string} organizationId 
         * @param {CreateUser} createUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserForceApiOrganizationsOrganizationIdUsersForcePost: async (organizationId: string, createUser: CreateUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('postUserForceApiOrganizationsOrganizationIdUsersForcePost', 'organizationId', organizationId)
            // verify required parameter 'createUser' is not null or undefined
            assertParamExists('postUserForceApiOrganizationsOrganizationIdUsersForcePost', 'createUser', createUser)
            const localVarPath = `/api/organizations/{organization_id}/users_force`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post Users
         * @param {string} organizationId 
         * @param {InviteUser} inviteUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsersApiOrganizationsOrganizationIdUsersPost: async (organizationId: string, inviteUser: InviteUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('postUsersApiOrganizationsOrganizationIdUsersPost', 'organizationId', organizationId)
            // verify required parameter 'inviteUser' is not null or undefined
            assertParamExists('postUsersApiOrganizationsOrganizationIdUsersPost', 'inviteUser', inviteUser)
            const localVarPath = `/api/organizations/{organization_id}/users`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an organization.  Args:     organization_update (schemas.OrganizationUpdate): The update to do for the         organization.     organization (int): The organization to update.     db (Session): The database session.  Returns:     schemas.Organization: The updated organization.
         * @summary Update Organization
         * @param {string} organizationId 
         * @param {OrganizationUpdate} organizationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationApiOrganizationsOrganizationIdPatch: async (organizationId: string, organizationUpdate: OrganizationUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateOrganizationApiOrganizationsOrganizationIdPatch', 'organizationId', organizationId)
            // verify required parameter 'organizationUpdate' is not null or undefined
            assertParamExists('updateOrganizationApiOrganizationsOrganizationIdPatch', 'organizationUpdate', organizationUpdate)
            const localVarPath = `/api/organizations/{organization_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organizationUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an user.  Args:     user_update (schemas.UserUpdate): The update to do for the user.     user (schemas.User): The user to update.     db (Session): The database session.  Returns:     schemas.User: The updated user.
         * @summary Update User Admin
         * @param {string} organizationId 
         * @param {string} userId 
         * @param {UpdateUserOID} updateUserOID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch: async (organizationId: string, userId: string, updateUserOID: UpdateUserOID, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch', 'userId', userId)
            // verify required parameter 'updateUserOID' is not null or undefined
            assertParamExists('updateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch', 'updateUserOID', updateUserOID)
            const localVarPath = `/api/organizations/{organization_id}/users/{user_id}`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserOID, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Status
         * @param {string} organizationId 
         * @param {string} userId 
         * @param {UserActivationStatus} userActivationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch: async (organizationId: string, userId: string, userActivationStatus: UserActivationStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch', 'organizationId', organizationId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch', 'userId', userId)
            // verify required parameter 'userActivationStatus' is not null or undefined
            assertParamExists('updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch', 'userActivationStatus', userActivationStatus)
            const localVarPath = `/api/organizations/{organization_id}/users/{user_id}/status`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userActivationStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Templates To Organization
         * @param {string} organizationId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost: async (organizationId: string, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost', 'organizationId', organizationId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost', 'file', file)
            const localVarPath = `/api/organizations/{organization_id}/templates`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationsApi - functional programming interface
 * @export
 */
export const OrganizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates an organization.  Args:     organization (schemas.OrganizationCreate): The organization to create.     db (Session): The database session.  Returns:     schemas.Organization: The created organization.
         * @summary Create Organization
         * @param {OrganizationUpdate} organizationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrganizationApiOrganizationsPost(organizationUpdate: OrganizationUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrganizationApiOrganizationsPost(organizationUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.createOrganizationApiOrganizationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Templates Of Organization
         * @param {string} frameworkCode 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete(frameworkCode: string, organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete(frameworkCode, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint is used to get files from the storage from local deployment. On production, the files are served by a sas link to the blob.
         * @summary Get File
         * @param {string} token 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileApiOrganizationsOrganizationIdFilesTokenGet(token: string, organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileApiOrganizationsOrganizationIdFilesTokenGet(token, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getFileApiOrganizationsOrganizationIdFilesTokenGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Frameworks Of Organization
         * @param {string} organizationId 
         * @param {ProjectType} [projectType] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet(organizationId: string, projectType?: ProjectType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreationFrameworkTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet(organizationId, projectType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the organization quota. Args:     organization_id (schemas.UUID): The organization ID.     db (Session): The database session. Raises:     HTTPException: If the organization is not found. Returns (schemas.OrganizationQuota): The organization quota.
         * @summary Get Organization Quota
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationQuota>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all organizations.  Args:     db (Session): The database session.  Returns:     Page[schemas.Organization]: The organizations.
         * @summary Get Organizations
         * @param {string | null} [name] The name filter.
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationsApiOrganizationsGet(name?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOrganization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationsApiOrganizationsGet(name, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getOrganizationsApiOrganizationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the projects of the organization.  Args:     organization (Orgnaization): The organization.     db (Session): Database session.     name (str, optional): Filter by project name. Defaults to None.     project_type (enums.ProjectType, optional): Filter by project type.     params (Params, optional): Pagination params. Defaults to Depends().     sort_by (str, optional): Sort by a field. Defaults to None.     descending (bool, optional): Sort order. If true, descending, otherwise         ascending. Defaults to True.  Returns:     Page[schemas.Project]: List of projects.
         * @summary Get Projects
         * @param {string} organizationId 
         * @param {string | null} [name] Filter by project name
         * @param {ProjectType} [projectType] 
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsApiOrganizationsOrganizationIdProjectsGet(organizationId: string, name?: string | null, projectType?: ProjectType, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomProjectPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsApiOrganizationsOrganizationIdProjectsGet(organizationId, name, projectType, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getProjectsApiOrganizationsOrganizationIdProjectsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Tags
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagsApiOrganizationsOrganizationIdTagsGet(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tags>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagsApiOrganizationsOrganizationIdTagsGet(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getTagsApiOrganizationsOrganizationIdTagsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Users Of Organization
         * @param {string} organizationId 
         * @param {string | null} [name] The user name filter.
         * @param {string | null} [notInProject] Filter by users not in a project.
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {boolean} [includeInactive] Filter by status. By default, inactive users will not be returned.
         * @param {string | null} [state] Filter by user current state.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet(organizationId: string, name?: string | null, notInProject?: string | null, sortBy?: string | null, descending?: boolean, includeInactive?: boolean, state?: string | null, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOrgPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet(organizationId, name, notInProject, sortBy, descending, includeInactive, state, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post User Force
         * @param {string} organizationId 
         * @param {CreateUser} createUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserForceApiOrganizationsOrganizationIdUsersForcePost(organizationId: string, createUser: CreateUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserForceApiOrganizationsOrganizationIdUsersForcePost(organizationId, createUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.postUserForceApiOrganizationsOrganizationIdUsersForcePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post Users
         * @param {string} organizationId 
         * @param {InviteUser} inviteUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUsersApiOrganizationsOrganizationIdUsersPost(organizationId: string, inviteUser: InviteUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUsersApiOrganizationsOrganizationIdUsersPost(organizationId, inviteUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.postUsersApiOrganizationsOrganizationIdUsersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an organization.  Args:     organization_update (schemas.OrganizationUpdate): The update to do for the         organization.     organization (int): The organization to update.     db (Session): The database session.  Returns:     schemas.Organization: The updated organization.
         * @summary Update Organization
         * @param {string} organizationId 
         * @param {OrganizationUpdate} organizationUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrganizationApiOrganizationsOrganizationIdPatch(organizationId: string, organizationUpdate: OrganizationUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrganizationApiOrganizationsOrganizationIdPatch(organizationId, organizationUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.updateOrganizationApiOrganizationsOrganizationIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an user.  Args:     user_update (schemas.UserUpdate): The update to do for the user.     user (schemas.User): The user to update.     db (Session): The database session.  Returns:     schemas.User: The updated user.
         * @summary Update User Admin
         * @param {string} organizationId 
         * @param {string} userId 
         * @param {UpdateUserOID} updateUserOID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch(organizationId: string, userId: string, updateUserOID: UpdateUserOID, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch(organizationId, userId, updateUserOID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.updateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update User Status
         * @param {string} organizationId 
         * @param {string} userId 
         * @param {UserActivationStatus} userActivationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch(organizationId: string, userId: string, userActivationStatus: UserActivationStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch(organizationId, userId, userActivationStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload Templates To Organization
         * @param {string} organizationId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost(organizationId: string, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreationFrameworkTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost(organizationId, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['OrganizationsApi.uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * OrganizationsApi - factory interface
 * @export
 */
export const OrganizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationsApiFp(configuration)
    return {
        /**
         * Creates an organization.  Args:     organization (schemas.OrganizationCreate): The organization to create.     db (Session): The database session.  Returns:     schemas.Organization: The created organization.
         * @summary Create Organization
         * @param {OrganizationsApiCreateOrganizationApiOrganizationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationApiOrganizationsPost(requestParameters: OrganizationsApiCreateOrganizationApiOrganizationsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Organization> {
            return localVarFp.createOrganizationApiOrganizationsPost(requestParameters.organizationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Templates Of Organization
         * @param {OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete(requestParameters: OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete(requestParameters.frameworkCode, requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint is used to get files from the storage from local deployment. On production, the files are served by a sas link to the blob.
         * @summary Get File
         * @param {OrganizationsApiGetFileApiOrganizationsOrganizationIdFilesTokenGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileApiOrganizationsOrganizationIdFilesTokenGet(requestParameters: OrganizationsApiGetFileApiOrganizationsOrganizationIdFilesTokenGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getFileApiOrganizationsOrganizationIdFilesTokenGet(requestParameters.token, requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Frameworks Of Organization
         * @param {OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet(requestParameters: OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<CreationFrameworkTemplate>> {
            return localVarFp.getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet(requestParameters.organizationId, requestParameters.projectType, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the organization quota. Args:     organization_id (schemas.UUID): The organization ID.     db (Session): The database session. Raises:     HTTPException: If the organization is not found. Returns (schemas.OrganizationQuota): The organization quota.
         * @summary Get Organization Quota
         * @param {OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet(requestParameters: OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationQuota> {
            return localVarFp.getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet(requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all organizations.  Args:     db (Session): The database session.  Returns:     Page[schemas.Organization]: The organizations.
         * @summary Get Organizations
         * @param {OrganizationsApiGetOrganizationsApiOrganizationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationsApiOrganizationsGet(requestParameters: OrganizationsApiGetOrganizationsApiOrganizationsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageOrganization> {
            return localVarFp.getOrganizationsApiOrganizationsGet(requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the projects of the organization.  Args:     organization (Orgnaization): The organization.     db (Session): Database session.     name (str, optional): Filter by project name. Defaults to None.     project_type (enums.ProjectType, optional): Filter by project type.     params (Params, optional): Pagination params. Defaults to Depends().     sort_by (str, optional): Sort by a field. Defaults to None.     descending (bool, optional): Sort order. If true, descending, otherwise         ascending. Defaults to True.  Returns:     Page[schemas.Project]: List of projects.
         * @summary Get Projects
         * @param {OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsApiOrganizationsOrganizationIdProjectsGet(requestParameters: OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<CustomProjectPage> {
            return localVarFp.getProjectsApiOrganizationsOrganizationIdProjectsGet(requestParameters.organizationId, requestParameters.name, requestParameters.projectType, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tags
         * @param {OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsApiOrganizationsOrganizationIdTagsGet(requestParameters: OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Tags> {
            return localVarFp.getTagsApiOrganizationsOrganizationIdTagsGet(requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Users Of Organization
         * @param {OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet(requestParameters: OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserOrgPage> {
            return localVarFp.getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet(requestParameters.organizationId, requestParameters.name, requestParameters.notInProject, requestParameters.sortBy, requestParameters.descending, requestParameters.includeInactive, requestParameters.state, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post User Force
         * @param {OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserForceApiOrganizationsOrganizationIdUsersForcePost(requestParameters: OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationUser> {
            return localVarFp.postUserForceApiOrganizationsOrganizationIdUsersForcePost(requestParameters.organizationId, requestParameters.createUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post Users
         * @param {OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUsersApiOrganizationsOrganizationIdUsersPost(requestParameters: OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationUser> {
            return localVarFp.postUsersApiOrganizationsOrganizationIdUsersPost(requestParameters.organizationId, requestParameters.inviteUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an organization.  Args:     organization_update (schemas.OrganizationUpdate): The update to do for the         organization.     organization (int): The organization to update.     db (Session): The database session.  Returns:     schemas.Organization: The updated organization.
         * @summary Update Organization
         * @param {OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationApiOrganizationsOrganizationIdPatch(requestParameters: OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<Organization> {
            return localVarFp.updateOrganizationApiOrganizationsOrganizationIdPatch(requestParameters.organizationId, requestParameters.organizationUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an user.  Args:     user_update (schemas.UserUpdate): The update to do for the user.     user (schemas.User): The user to update.     db (Session): The database session.  Returns:     schemas.User: The updated user.
         * @summary Update User Admin
         * @param {OrganizationsApiUpdateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch(requestParameters: OrganizationsApiUpdateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationUser> {
            return localVarFp.updateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch(requestParameters.organizationId, requestParameters.userId, requestParameters.updateUserOID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User Status
         * @param {OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch(requestParameters: OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<OrganizationUser> {
            return localVarFp.updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch(requestParameters.organizationId, requestParameters.userId, requestParameters.userActivationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Templates To Organization
         * @param {OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost(requestParameters: OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<CreationFrameworkTemplate>> {
            return localVarFp.uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost(requestParameters.organizationId, requestParameters.file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createOrganizationApiOrganizationsPost operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiCreateOrganizationApiOrganizationsPostRequest
 */
export interface OrganizationsApiCreateOrganizationApiOrganizationsPostRequest {
    /**
     * 
     * @type {OrganizationUpdate}
     * @memberof OrganizationsApiCreateOrganizationApiOrganizationsPost
     */
    readonly organizationUpdate: OrganizationUpdate
}

/**
 * Request parameters for deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDeleteRequest
 */
export interface OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete
     */
    readonly frameworkCode: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete
     */
    readonly organizationId: string
}

/**
 * Request parameters for getFileApiOrganizationsOrganizationIdFilesTokenGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetFileApiOrganizationsOrganizationIdFilesTokenGetRequest
 */
export interface OrganizationsApiGetFileApiOrganizationsOrganizationIdFilesTokenGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiGetFileApiOrganizationsOrganizationIdFilesTokenGet
     */
    readonly token: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiGetFileApiOrganizationsOrganizationIdFilesTokenGet
     */
    readonly organizationId: string
}

/**
 * Request parameters for getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGetRequest
 */
export interface OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet
     */
    readonly organizationId: string

    /**
     * 
     * @type {ProjectType}
     * @memberof OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet
     */
    readonly projectType?: ProjectType
}

/**
 * Request parameters for getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest
 */
export interface OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet
     */
    readonly organizationId: string
}

/**
 * Request parameters for getOrganizationsApiOrganizationsGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetOrganizationsApiOrganizationsGetRequest
 */
export interface OrganizationsApiGetOrganizationsApiOrganizationsGetRequest {
    /**
     * The name filter.
     * @type {string}
     * @memberof OrganizationsApiGetOrganizationsApiOrganizationsGet
     */
    readonly name?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof OrganizationsApiGetOrganizationsApiOrganizationsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof OrganizationsApiGetOrganizationsApiOrganizationsGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof OrganizationsApiGetOrganizationsApiOrganizationsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof OrganizationsApiGetOrganizationsApiOrganizationsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectsApiOrganizationsOrganizationIdProjectsGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest
 */
export interface OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly organizationId: string

    /**
     * Filter by project name
     * @type {string}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly name?: string | null

    /**
     * 
     * @type {ProjectType}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly projectType?: ProjectType

    /**
     * Sort by a field.
     * @type {string}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getTagsApiOrganizationsOrganizationIdTagsGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGetRequest
 */
export interface OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGet
     */
    readonly organizationId: string
}

/**
 * Request parameters for getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest
 */
export interface OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly organizationId: string

    /**
     * The user name filter.
     * @type {string}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly name?: string | null

    /**
     * Filter by users not in a project.
     * @type {string}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly notInProject?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly descending?: boolean

    /**
     * Filter by status. By default, inactive users will not be returned.
     * @type {boolean}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly includeInactive?: boolean

    /**
     * Filter by user current state.
     * @type {string}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly state?: string | null

    /**
     * Page number
     * @type {number}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet
     */
    readonly size?: number
}

/**
 * Request parameters for postUserForceApiOrganizationsOrganizationIdUsersForcePost operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePostRequest
 */
export interface OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePostRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePost
     */
    readonly organizationId: string

    /**
     * 
     * @type {CreateUser}
     * @memberof OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePost
     */
    readonly createUser: CreateUser
}

/**
 * Request parameters for postUsersApiOrganizationsOrganizationIdUsersPost operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPostRequest
 */
export interface OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPostRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPost
     */
    readonly organizationId: string

    /**
     * 
     * @type {InviteUser}
     * @memberof OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPost
     */
    readonly inviteUser: InviteUser
}

/**
 * Request parameters for updateOrganizationApiOrganizationsOrganizationIdPatch operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatchRequest
 */
export interface OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatch
     */
    readonly organizationId: string

    /**
     * 
     * @type {OrganizationUpdate}
     * @memberof OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatch
     */
    readonly organizationUpdate: OrganizationUpdate
}

/**
 * Request parameters for updateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiUpdateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatchRequest
 */
export interface OrganizationsApiUpdateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiUpdateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch
     */
    readonly organizationId: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiUpdateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch
     */
    readonly userId: string

    /**
     * 
     * @type {UpdateUserOID}
     * @memberof OrganizationsApiUpdateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch
     */
    readonly updateUserOID: UpdateUserOID
}

/**
 * Request parameters for updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatchRequest
 */
export interface OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch
     */
    readonly organizationId: string

    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch
     */
    readonly userId: string

    /**
     * 
     * @type {UserActivationStatus}
     * @memberof OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch
     */
    readonly userActivationStatus: UserActivationStatus
}

/**
 * Request parameters for uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost operation in OrganizationsApi.
 * @export
 * @interface OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPostRequest
 */
export interface OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost
     */
    readonly organizationId: string

    /**
     * 
     * @type {File}
     * @memberof OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost
     */
    readonly file: File
}

/**
 * OrganizationsApi - object-oriented interface
 * @export
 * @class OrganizationsApi
 * @extends {BaseAPI}
 */
export class OrganizationsApi extends BaseAPI {
    /**
     * Creates an organization.  Args:     organization (schemas.OrganizationCreate): The organization to create.     db (Session): The database session.  Returns:     schemas.Organization: The created organization.
     * @summary Create Organization
     * @param {OrganizationsApiCreateOrganizationApiOrganizationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public createOrganizationApiOrganizationsPost(requestParameters: OrganizationsApiCreateOrganizationApiOrganizationsPostRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).createOrganizationApiOrganizationsPost(requestParameters.organizationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Templates Of Organization
     * @param {OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete(requestParameters: OrganizationsApiDeleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDeleteRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).deleteTemplatesOfOrganizationApiOrganizationsOrganizationIdTemplatesFrameworkCodeDelete(requestParameters.frameworkCode, requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint is used to get files from the storage from local deployment. On production, the files are served by a sas link to the blob.
     * @summary Get File
     * @param {OrganizationsApiGetFileApiOrganizationsOrganizationIdFilesTokenGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getFileApiOrganizationsOrganizationIdFilesTokenGet(requestParameters: OrganizationsApiGetFileApiOrganizationsOrganizationIdFilesTokenGetRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getFileApiOrganizationsOrganizationIdFilesTokenGet(requestParameters.token, requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Frameworks Of Organization
     * @param {OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet(requestParameters: OrganizationsApiGetFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGetRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getFrameworksOfOrganizationApiOrganizationsOrganizationIdTemplatesGet(requestParameters.organizationId, requestParameters.projectType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the organization quota. Args:     organization_id (schemas.UUID): The organization ID.     db (Session): The database session. Raises:     HTTPException: If the organization is not found. Returns (schemas.OrganizationQuota): The organization quota.
     * @summary Get Organization Quota
     * @param {OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet(requestParameters: OrganizationsApiGetOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGetRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationQuotaApiV1OrganizationsOrganizationIdQuotaGet(requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all organizations.  Args:     db (Session): The database session.  Returns:     Page[schemas.Organization]: The organizations.
     * @summary Get Organizations
     * @param {OrganizationsApiGetOrganizationsApiOrganizationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getOrganizationsApiOrganizationsGet(requestParameters: OrganizationsApiGetOrganizationsApiOrganizationsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getOrganizationsApiOrganizationsGet(requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the projects of the organization.  Args:     organization (Orgnaization): The organization.     db (Session): Database session.     name (str, optional): Filter by project name. Defaults to None.     project_type (enums.ProjectType, optional): Filter by project type.     params (Params, optional): Pagination params. Defaults to Depends().     sort_by (str, optional): Sort by a field. Defaults to None.     descending (bool, optional): Sort order. If true, descending, otherwise         ascending. Defaults to True.  Returns:     Page[schemas.Project]: List of projects.
     * @summary Get Projects
     * @param {OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getProjectsApiOrganizationsOrganizationIdProjectsGet(requestParameters: OrganizationsApiGetProjectsApiOrganizationsOrganizationIdProjectsGetRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getProjectsApiOrganizationsOrganizationIdProjectsGet(requestParameters.organizationId, requestParameters.name, requestParameters.projectType, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tags
     * @param {OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getTagsApiOrganizationsOrganizationIdTagsGet(requestParameters: OrganizationsApiGetTagsApiOrganizationsOrganizationIdTagsGetRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getTagsApiOrganizationsOrganizationIdTagsGet(requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Users Of Organization
     * @param {OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet(requestParameters: OrganizationsApiGetUsersOfOrganizationApiOrganizationsOrganizationIdUsersGetRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).getUsersOfOrganizationApiOrganizationsOrganizationIdUsersGet(requestParameters.organizationId, requestParameters.name, requestParameters.notInProject, requestParameters.sortBy, requestParameters.descending, requestParameters.includeInactive, requestParameters.state, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post User Force
     * @param {OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public postUserForceApiOrganizationsOrganizationIdUsersForcePost(requestParameters: OrganizationsApiPostUserForceApiOrganizationsOrganizationIdUsersForcePostRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).postUserForceApiOrganizationsOrganizationIdUsersForcePost(requestParameters.organizationId, requestParameters.createUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post Users
     * @param {OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public postUsersApiOrganizationsOrganizationIdUsersPost(requestParameters: OrganizationsApiPostUsersApiOrganizationsOrganizationIdUsersPostRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).postUsersApiOrganizationsOrganizationIdUsersPost(requestParameters.organizationId, requestParameters.inviteUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an organization.  Args:     organization_update (schemas.OrganizationUpdate): The update to do for the         organization.     organization (int): The organization to update.     db (Session): The database session.  Returns:     schemas.Organization: The updated organization.
     * @summary Update Organization
     * @param {OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateOrganizationApiOrganizationsOrganizationIdPatch(requestParameters: OrganizationsApiUpdateOrganizationApiOrganizationsOrganizationIdPatchRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).updateOrganizationApiOrganizationsOrganizationIdPatch(requestParameters.organizationId, requestParameters.organizationUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an user.  Args:     user_update (schemas.UserUpdate): The update to do for the user.     user (schemas.User): The user to update.     db (Session): The database session.  Returns:     schemas.User: The updated user.
     * @summary Update User Admin
     * @param {OrganizationsApiUpdateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch(requestParameters: OrganizationsApiUpdateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatchRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).updateUserAdminApiOrganizationsOrganizationIdUsersUserIdPatch(requestParameters.organizationId, requestParameters.userId, requestParameters.updateUserOID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User Status
     * @param {OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch(requestParameters: OrganizationsApiUpdateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatchRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).updateUserStatusApiOrganizationsOrganizationIdUsersUserIdStatusPatch(requestParameters.organizationId, requestParameters.userId, requestParameters.userActivationStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Templates To Organization
     * @param {OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationsApi
     */
    public uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost(requestParameters: OrganizationsApiUploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPostRequest, options?: RawAxiosRequestConfig) {
        return OrganizationsApiFp(this.configuration).uploadTemplatesToOrganizationApiOrganizationsOrganizationIdTemplatesPost(requestParameters.organizationId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Testing Testing To Control
         * @param {string} projectId 
         * @param {string} controlId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut: async (projectId: string, controlId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut', 'projectId', projectId)
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut', 'controlId', controlId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/controls/{control_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancel all pending reviews for a concept object.  Args:     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.
         * @summary Cancel Pending Reviews
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete: async (concept: Concept, objectId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'concept' is not null or undefined
            assertParamExists('cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete', 'concept', concept)
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete', 'objectId', objectId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/reviews/{concept}/{object_id}`
                .replace(`{${"concept"}}`, encodeURIComponent(String(concept)))
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new asset_card.  Args:     asset_card (schemas.CreateAssetCard): The asset_card to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the asset_card for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The created AssetCard.
         * @summary Create Asset Card
         * @param {string} projectId 
         * @param {CreateAssetCard} createAssetCard 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssetCardApiProjectsProjectIdAssetCardsPost: async (projectId: string, createAssetCard: CreateAssetCard, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createAssetCardApiProjectsProjectIdAssetCardsPost', 'projectId', projectId)
            // verify required parameter 'createAssetCard' is not null or undefined
            assertParamExists('createAssetCardApiProjectsProjectIdAssetCardsPost', 'createAssetCard', createAssetCard)
            const localVarPath = `/api/projects/{project_id}/asset_cards`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAssetCard, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Args:     object_type (str): The object type. (enums.Concept)     object_id (UUID): The object ID.     comment (schemas.Comment): The comment to create.     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
         * @summary Create Comment
         * @param {string} objectId 
         * @param {Concept} objectType 
         * @param {string} projectId 
         * @param {CreateComment} createComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost: async (objectId: string, objectType: Concept, projectId: string, createComment: CreateComment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost', 'objectId', objectId)
            // verify required parameter 'objectType' is not null or undefined
            assertParamExists('createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost', 'objectType', objectType)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost', 'projectId', projectId)
            // verify required parameter 'createComment' is not null or undefined
            assertParamExists('createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost', 'createComment', createComment)
            const localVarPath = `/api/projects/{project_id}/comments/{object_type}/{object_id}`
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"object_type"}}`, encodeURIComponent(String(objectType)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new project.  Args:     new_project (CreateProject): The new project.     db (Session): Database session.     user (User): The user creating the project.  Returns:     Project: The created project.
         * @summary Create Project
         * @param {CreateProject} createProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectApiProjectsPost: async (createProject: CreateProject, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProject' is not null or undefined
            assertParamExists('createProjectApiProjectsPost', 'createProject', createProject)
            const localVarPath = `/api/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new framework for the project with the given id.  Args:     framework_codes (List[str]): The framework codes to create.     response (Response): The response object.     user (User): The user creating the framework.     project (Project): The project to create the framework for.     db (Session): Database session.     cache_service (Cache): The cache service.  Returns:     Framework: The created framework.
         * @summary Create Project Framework
         * @param {string} projectId 
         * @param {Array<string | null>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectFrameworkApiProjectsProjectIdFrameworksPost: async (projectId: string, requestBody: Array<string | null>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createProjectFrameworkApiProjectsProjectIdFrameworksPost', 'projectId', projectId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('createProjectFrameworkApiProjectsProjectIdFrameworksPost', 'requestBody', requestBody)
            const localVarPath = `/api/projects/{project_id}/frameworks`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new risk.  Args:     background_tasks:  The background tasks.     risk (schemas.CreateRisk): The risk to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the risk for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The created risk.
         * @summary Create Risk
         * @param {string} projectId 
         * @param {CreateRisk} createRisk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRiskApiProjectsProjectIdRisksPost: async (projectId: string, createRisk: CreateRisk, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createRiskApiProjectsProjectIdRisksPost', 'projectId', projectId)
            // verify required parameter 'createRisk' is not null or undefined
            assertParamExists('createRiskApiProjectsProjectIdRisksPost', 'createRisk', createRisk)
            const localVarPath = `/api/projects/{project_id}/risks`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRisk, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a monitoring datasource.  Args:     testing_log (TestingLogCreate): Monitoring datasource data.     project (Project): Project.     db (Session): Database session.  Returns:     TestingLog: Monitoring datasource.  Raises:     HTTPException: Project not found.
         * @summary Create Testing Log
         * @param {string} projectId 
         * @param {TestingLogCreate} testingLogCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingLogApiV1ProjectsProjectIdTestingLogsPost: async (projectId: string, testingLogCreate: TestingLogCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createTestingLogApiV1ProjectsProjectIdTestingLogsPost', 'projectId', projectId)
            // verify required parameter 'testingLogCreate' is not null or undefined
            assertParamExists('createTestingLogApiV1ProjectsProjectIdTestingLogsPost', 'testingLogCreate', testingLogCreate)
            const localVarPath = `/api/v1/projects/{project_id}/testing/logs`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testingLogCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Testing Metric
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {TestingMetricCreate} testingMetricCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost: async (testingSourceId: string, projectId: string, testingMetricCreate: TestingMetricCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingSourceId' is not null or undefined
            assertParamExists('createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost', 'testingSourceId', testingSourceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost', 'projectId', projectId)
            // verify required parameter 'testingMetricCreate' is not null or undefined
            assertParamExists('createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost', 'testingMetricCreate', testingMetricCreate)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources/{testing_source_id}/metrics`
                .replace(`{${"testing_source_id"}}`, encodeURIComponent(String(testingSourceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testingMetricCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Testing Test
         * @param {string} projectId 
         * @param {CreateTestingTest} createTestingTest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingTestApiV1ProjectsProjectIdTestingTestsPost: async (projectId: string, createTestingTest: CreateTestingTest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createTestingTestApiV1ProjectsProjectIdTestingTestsPost', 'projectId', projectId)
            // verify required parameter 'createTestingTest' is not null or undefined
            assertParamExists('createTestingTestApiV1ProjectsProjectIdTestingTestsPost', 'createTestingTest', createTestingTest)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTestingTest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.
         * @summary Delete Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectApiProjectsProjectIdDelete: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProjectApiProjectsProjectIdDelete', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     ProjectAiSystemLifecycleDashboard: The Lifecycle Dashboard.
         * @summary Get Ai System Life Cycle Dashboard
         * @param {string} projectId 
         * @param {AiLifecycleDashboardContent} [dashboardContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet: async (projectId: string, dashboardContent?: AiLifecycleDashboardContent, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/ai_system_life_cycle_dashboard`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (dashboardContent !== undefined) {
                localVarQueryParameter['dashboard_content'] = dashboardContent;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Args:     object_id (UUID): The object ID.     object_type (enums.Concept): The object type. (enums.Concept)     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.  Returns:     schemas.Comment: The updated control.
         * @summary Get Comments
         * @param {string} objectId 
         * @param {Concept} objectType 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet: async (objectId: string, objectType: Concept, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet', 'objectId', objectId)
            // verify required parameter 'objectType' is not null or undefined
            assertParamExists('getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet', 'objectType', objectType)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/comments/{object_type}/{object_id}`
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"object_type"}}`, encodeURIComponent(String(objectType)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Control By Code
         * @param {string} controlCode 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlByCodeApiProjectsProjectIdControlsControlCodeGet: async (controlCode: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlCode' is not null or undefined
            assertParamExists('getControlByCodeApiProjectsProjectIdControlsControlCodeGet', 'controlCode', controlCode)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getControlByCodeApiProjectsProjectIdControlsControlCodeGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/controls/{control_code}`
                .replace(`{${"control_code"}}`, encodeURIComponent(String(controlCode)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Control Evidences By Code
         * @param {string} controlCode 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGet: async (controlCode: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlCode' is not null or undefined
            assertParamExists('getControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGet', 'controlCode', controlCode)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/controls/{control_code}/evidences`
                .replace(`{${"control_code"}}`, encodeURIComponent(String(controlCode)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Controls By Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet: async (projectId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/controls`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the file types of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session. Returns:     List[schemas.FileType]: List of file types.
         * @summary Get Evidence File Types
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/evidence/files/types`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the first 10 evidences (name, description and id) of the project with a name filter. This is used for a dropdown selection.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     List[schemas.EvidencePreview]: List of evidences.
         * @summary Get Evidence List
         * @param {string} projectId 
         * @param {string | null} [name] Filter by evidence name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceListApiProjectsProjectIdEvidenceListGet: async (projectId: string, name?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEvidenceListApiProjectsProjectIdEvidenceListGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/evidence_list`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the evidences of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     Page[schemas.Evidence]: Page of evidences.
         * @summary Get Evidences By Project Id
         * @param {string} projectId 
         * @param {string | null} [name] Filter by evidence name
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {Array<string>} [associatedControls] Filter by associated controls
         * @param {Array<string>} [associatedRisks] Filter by associated risks
         * @param {string | null} [startDate] Filter by creation date of the evidence
         * @param {string | null} [endDate] Filter by creation date of the evidence
         * @param {string | null} [fileType] Filter by file type (e.g. \&#39;Image\&#39;)
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet: async (projectId: string, name?: string | null, sortBy?: string | null, descending?: boolean, associatedControls?: Array<string>, associatedRisks?: Array<string>, startDate?: string | null, endDate?: string | null, fileType?: string | null, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/evidence`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (associatedControls) {
                localVarQueryParameter['associated_controls'] = associatedControls;
            }

            if (associatedRisks) {
                localVarQueryParameter['associated_risks'] = associatedRisks;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }

            if (fileType !== undefined) {
                localVarQueryParameter['file_type'] = fileType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the logs for the given project and object id.  Args:     project_id (UUID): The project ID.     object_id (UUID): The object ID.  Returns:     List[Logs]: The logs.
         * @summary Get Logs
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsApiProjectsProjectIdLogsObjectIdGet: async (objectId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('getLogsApiProjectsProjectIdLogsObjectIdGet', 'objectId', objectId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getLogsApiProjectsProjectIdLogsObjectIdGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/logs/{object_id}`
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the project with the given id. Args:     project_id (UUID): The project ID.  Returns:     Project: The project.
         * @summary Get Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectApiProjectsProjectIdGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectApiProjectsProjectIdGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the asset_cards of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.AssetCardPage]: List of asset_cards.
         * @summary Get Project Asset Cards
         * @param {string} projectId 
         * @param {string | null} [name] Filter by asset_card name
         * @param {string | null} [status] Filter by asset_card status
         * @param {boolean} [isAssignedToMe] Filter by asset_cards assigned to me
         * @param {AssetCardTypes | null} [assetCardType] Filter by asset_card_type
         * @param {AssetCardNature | null} [assetCardNature] Filter by asset card nature
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectAssetCardsApiProjectsProjectIdAssetCardsGet: async (projectId: string, name?: string | null, status?: string | null, isAssignedToMe?: boolean, assetCardType?: AssetCardTypes | null, assetCardNature?: AssetCardNature | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectAssetCardsApiProjectsProjectIdAssetCardsGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/asset_cards`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isAssignedToMe !== undefined) {
                localVarQueryParameter['is_assigned_to_me'] = isAssignedToMe;
            }

            if (assetCardType !== undefined) {
                localVarQueryParameter['asset_card_type'] = assetCardType;
            }

            if (assetCardNature !== undefined) {
                localVarQueryParameter['asset_card_nature'] = assetCardNature;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the controls of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.ControlWithoutComponents]: List of controls.
         * @summary Get Project Controls
         * @param {string} projectId 
         * @param {Array<string>} [tags] 
         * @param {string | null} [name] Filter by control name
         * @param {string | null} [framework] Filter by framework code
         * @param {string | null} [requirement] Filter by requirement id
         * @param {string | null} [status] Filter by control status
         * @param {boolean} [isAssignedToMe] Filter by controls assigned to me
         * @param {boolean} [isAssignedToMeForReview] Filter by controls assigned to me for review
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsApiProjectsProjectIdControlsGet: async (projectId: string, tags?: Array<string>, name?: string | null, framework?: string | null, requirement?: string | null, status?: string | null, isAssignedToMe?: boolean, isAssignedToMeForReview?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectControlsApiProjectsProjectIdControlsGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/controls`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (tags) {
                localVarQueryParameter['tags'] = tags;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (framework !== undefined) {
                localVarQueryParameter['framework'] = framework;
            }

            if (requirement !== undefined) {
                localVarQueryParameter['requirement'] = requirement;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isAssignedToMe !== undefined) {
                localVarQueryParameter['is_assigned_to_me'] = isAssignedToMe;
            }

            if (isAssignedToMeForReview !== undefined) {
                localVarQueryParameter['is_assigned_to_me_for_review'] = isAssignedToMeForReview;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Project Controls
         * @param {string} projectId 
         * @param {Status | null} [status] Filter by control status
         * @param {string | null} [name] 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsApiV1ProjectsProjectIdControlsGet: async (projectId: string, status?: Status | null, name?: string | null, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectControlsApiV1ProjectsProjectIdControlsGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/controls`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the controls of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
         * @summary Get Project Controls With Evidences
         * @param {string} projectId 
         * @param {string | null} [name] Filter by control name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet: async (projectId: string, name?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/controls_with_evidences`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     Project: The project dashboard.
         * @summary Get Project Dashboard
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDashboardApiProjectsProjectIdDashboardGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectDashboardApiProjectsProjectIdDashboardGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/dashboard`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the frameworks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by framework name.     params (Params): Pagination params.  Returns:     Page[schemas.FrameworkAggregator]: List of frameworks.
         * @summary Get Project Frameworks
         * @param {string} projectId 
         * @param {string | null} [name] Filter by framework name
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectFrameworksApiProjectsProjectIdFrameworksGet: async (projectId: string, name?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectFrameworksApiProjectsProjectIdFrameworksGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/frameworks`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the project pdf for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     FileResponse: The project pdf.
         * @summary Get Project Pdf
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectPdfApiProjectsProjectIdExportGet: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectPdfApiProjectsProjectIdExportGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/export`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the requirements of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by requirement name.     framework (str): Filter by framework code.     status (str): Filter by requirement status.     params (Params): Pagination params.  Returns:     Page[schemas.Requirement]: Paginated List of requirements.
         * @summary Get Project Requirements
         * @param {string} projectId 
         * @param {string | null} [name] Filter by requirement name
         * @param {string | null} [framework] Filter by framework code
         * @param {string | null} [status] Filter by requirement status
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRequirementsApiProjectsProjectIdRequirementsGet: async (projectId: string, name?: string | null, framework?: string | null, status?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectRequirementsApiProjectsProjectIdRequirementsGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/requirements`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (framework !== undefined) {
                localVarQueryParameter['framework'] = framework;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
         * @summary Get Project Risks
         * @param {string} projectId 
         * @param {string | null} [name] Filter by risk name
         * @param {string | null} [status] Filter by risk status
         * @param {boolean} [isAssignedToMe] Filter by risks assigned to me
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRisksApiProjectsProjectIdRisksGet: async (projectId: string, name?: string | null, status?: string | null, isAssignedToMe?: boolean, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectRisksApiProjectsProjectIdRisksGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/risks`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isAssignedToMe !== undefined) {
                localVarQueryParameter['is_assigned_to_me'] = isAssignedToMe;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
         * @summary Get Project Risks List
         * @param {string} projectId 
         * @param {string | null} [name] Filter by risk name
         * @param {string | null} [status] Filter by risk status
         * @param {boolean} [isAssignedToMe] Filter by risks assigned to me
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRisksListApiProjectsProjectIdRisksListGet: async (projectId: string, name?: string | null, status?: string | null, isAssignedToMe?: boolean, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectRisksListApiProjectsProjectIdRisksListGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/risks/list`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isAssignedToMe !== undefined) {
                localVarQueryParameter['is_assigned_to_me'] = isAssignedToMe;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the risks of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
         * @summary Get Project Risks With Evidences
         * @param {string} projectId 
         * @param {string | null} [name] Filter by risk name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet: async (projectId: string, name?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/risks_with_evidences`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Reviews For Concept Type
         * @param {Concept} concept 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet: async (concept: Concept, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'concept' is not null or undefined
            assertParamExists('getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet', 'concept', concept)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/reviews/{concept}`
                .replace(`{${"concept"}}`, encodeURIComponent(String(concept)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Reviews For Object Id
         * @param {string} objectId 
         * @param {Concept} concept 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet: async (objectId: string, concept: Concept, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet', 'objectId', objectId)
            // verify required parameter 'concept' is not null or undefined
            assertParamExists('getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet', 'concept', concept)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/reviews/{concept}/{object_id}`
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"concept"}}`, encodeURIComponent(String(concept)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Metric
         * @param {string} testingSourceId 
         * @param {string} testingMetricId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet: async (testingSourceId: string, testingMetricId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingSourceId' is not null or undefined
            assertParamExists('getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet', 'testingSourceId', testingSourceId)
            // verify required parameter 'testingMetricId' is not null or undefined
            assertParamExists('getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet', 'testingMetricId', testingMetricId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources/{testing_source_id}/metrics/{testing_metric_id}`
                .replace(`{${"testing_source_id"}}`, encodeURIComponent(String(testingSourceId)))
                .replace(`{${"testing_metric_id"}}`, encodeURIComponent(String(testingMetricId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Source
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet: async (testingSourceId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingSourceId' is not null or undefined
            assertParamExists('getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet', 'testingSourceId', testingSourceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources/{testing_source_id}`
                .replace(`{${"testing_source_id"}}`, encodeURIComponent(String(testingSourceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Source Metrics
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet: async (testingSourceId: string, projectId: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingSourceId' is not null or undefined
            assertParamExists('getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet', 'testingSourceId', testingSourceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources/{testing_source_id}/metrics`
                .replace(`{${"testing_source_id"}}`, encodeURIComponent(String(testingSourceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Sources
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet: async (projectId: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Test And Latest Result
         * @param {string} projectId 
         * @param {string | null} [sortBy] Column name to sort by
         * @param {boolean} [descending] Sort in descending order
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string | null} [name] Filter by name
         * @param {TestingTestStatus | null} [resultStatus] Filter by result status
         * @param {boolean | null} [status] Filter by test status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet: async (projectId: string, sortBy?: string | null, descending?: boolean, page?: number, size?: number, name?: string | null, resultStatus?: TestingTestStatus | null, status?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests-results`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (resultStatus !== undefined) {
                localVarQueryParameter['result_status'] = resultStatus;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet: async (projectId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Test Result
         * @param {string} testingTestResultId 
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet: async (testingTestResultId: string, projectId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingTestResultId' is not null or undefined
            assertParamExists('getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet', 'testingTestResultId', testingTestResultId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/results/{testing_test_result_id}`
                .replace(`{${"testing_test_result_id"}}`, encodeURIComponent(String(testingTestResultId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Test Results
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {TestingTestStatus | null} [resultStatus] Filter by result status
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string | null} [sortBy] Column name to sort by
         * @param {boolean} [descending] Sort in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet: async (projectId: string, testingTestId: string, resultStatus?: TestingTestStatus | null, page?: number, size?: number, sortBy?: string | null, descending?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/results`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (resultStatus !== undefined) {
                localVarQueryParameter['result_status'] = resultStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Tests
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestsApiV1ProjectsProjectIdTestingTestsGet: async (projectId: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestsApiV1ProjectsProjectIdTestingTestsGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the users of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     params (Params): Pagination params.     roles (List[enums.Roles]): Filter by role.     exclude_me (bool): Include current user.     sort_by (str): Sort by a field.     descending (bool): Sort order. If true, descending, otherwise ascending.  Returns:     Page[schemas.OrganizationUser]: List of users.
         * @summary Get User Of Project
         * @param {string} projectId 
         * @param {Array<Roles>} [roles] Filter by role.
         * @param {boolean} [excludeMe] Exclude current user.
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {string | null} [name] The user name filter.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOfProjectApiProjectsProjectIdUsersGet: async (projectId: string, roles?: Array<Roles>, excludeMe?: boolean, sortBy?: string | null, descending?: boolean, name?: string | null, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getUserOfProjectApiProjectsProjectIdUsersGet', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/users`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }

            if (excludeMe !== undefined) {
                localVarQueryParameter['exclude_me'] = excludeMe;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Control From Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete: async (projectId: string, testingTestId: string, controlId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete', 'testingTestId', testingTestId)
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete', 'controlId', controlId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/controls/{control_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)))
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request access to the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     user (User): The user requesting access to the project.
         * @summary Request Access
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccessApiProjectsProjectIdRequestAccessPost: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('requestAccessApiProjectsProjectIdRequestAccessPost', 'projectId', projectId)
            const localVarPath = `/api/projects/{project_id}/request_access`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request reviews for a concept object.  Args:     review_request (schemas.ReviewRequest): The review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object to request reviews for. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.     force_update_status (bool, optional): Project owners can force update the         status of the object.  Returns:     List[schemas.Review]: The created reviews.
         * @summary Request Reviews
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {ReviewRequest} reviewRequest 
         * @param {boolean} [forceUpdateStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost: async (concept: Concept, objectId: string, projectId: string, reviewRequest: ReviewRequest, forceUpdateStatus?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'concept' is not null or undefined
            assertParamExists('requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost', 'concept', concept)
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost', 'objectId', objectId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost', 'projectId', projectId)
            // verify required parameter 'reviewRequest' is not null or undefined
            assertParamExists('requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost', 'reviewRequest', reviewRequest)
            const localVarPath = `/api/projects/{project_id}/reviews/{concept}/{object_id}`
                .replace(`{${"concept"}}`, encodeURIComponent(String(concept)))
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (forceUpdateStatus !== undefined) {
                localVarQueryParameter['force_update_status'] = forceUpdateStatus;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost: async (projectId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/results`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Take a decision on a review for a concept object.  Args:     project_id (uuid.UUID): The project ID.     concept (enums.Concept): The concept of the object.     review_decision (schemas.ReviewDecision): The review decision.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Review: The updated review.
         * @summary Take Review Decision
         * @param {string} projectId 
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {ReviewDecision} reviewDecision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch: async (projectId: string, concept: Concept, objectId: string, reviewDecision: ReviewDecision, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch', 'projectId', projectId)
            // verify required parameter 'concept' is not null or undefined
            assertParamExists('takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch', 'concept', concept)
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch', 'objectId', objectId)
            // verify required parameter 'reviewDecision' is not null or undefined
            assertParamExists('takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch', 'reviewDecision', reviewDecision)
            const localVarPath = `/api/projects/{project_id}/reviews/{concept}/{object_id}/decision`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"concept"}}`, encodeURIComponent(String(concept)))
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewDecision, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the project with the given id.  Args:     project_id (UUID): The project\'s id.     project_update (UpdateProject): The update to the project.     db (Session): Database session.  Returns:     schemas.Project: The updated project.
         * @summary Update Project
         * @param {string} projectId 
         * @param {UpdateProject} updateProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectApiProjectsProjectIdPatch: async (projectId: string, updateProject: UpdateProject, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProjectApiProjectsProjectIdPatch', 'projectId', projectId)
            // verify required parameter 'updateProject' is not null or undefined
            assertParamExists('updateProjectApiProjectsProjectIdPatch', 'updateProject', updateProject)
            const localVarPath = `/api/projects/{project_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the reviewers for a concept object.  Args:     review_request (schemas.UpdateReviewRequest): The update review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     List[schemas.Review]: The updated reviews.
         * @summary Update Reviewers
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {UpdateReviewRequest} updateReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch: async (concept: Concept, objectId: string, projectId: string, updateReviewRequest: UpdateReviewRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'concept' is not null or undefined
            assertParamExists('updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch', 'concept', concept)
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch', 'objectId', objectId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch', 'projectId', projectId)
            // verify required parameter 'updateReviewRequest' is not null or undefined
            assertParamExists('updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch', 'updateReviewRequest', updateReviewRequest)
            const localVarPath = `/api/projects/{project_id}/reviews/{concept}/{object_id}`
                .replace(`{${"concept"}}`, encodeURIComponent(String(concept)))
                .replace(`{${"object_id"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateReviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {UpdateTestingTest} updateTestingTest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch: async (projectId: string, testingTestId: string, updateTestingTest: UpdateTestingTest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch', 'testingTestId', testingTestId)
            // verify required parameter 'updateTestingTest' is not null or undefined
            assertParamExists('updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch', 'updateTestingTest', updateTestingTest)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTestingTest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uploads the evidence file and creates an entry in the evidence database.  Args:     db (Session): Database session.     project_id (UUID): The project\'s id.     user (User): The user creating the evidence.     file (UploadFile): The file to upload.     component (Component): The component the evidence belongs to.  Returns:     schemas.Evidence: The created evidence.
         * @summary Upload And Create Evidence
         * @param {string} projectId 
         * @param {File} file 
         * @param {string} [fileName] The file name.
         * @param {string} [description] The description.
         * @param {string | null} [componentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost: async (projectId: string, file: File, fileName?: string, description?: string, componentId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost', 'projectId', projectId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost', 'file', file)
            const localVarPath = `/api/projects/{project_id}/evidence`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['file_name'] = fileName;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (componentId !== undefined) {
                localVarQueryParameter['component_id'] = componentId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Testing Testing To Control
         * @param {string} projectId 
         * @param {string} controlId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(projectId: string, controlId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(projectId, controlId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Cancel all pending reviews for a concept object.  Args:     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.
         * @summary Cancel Pending Reviews
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete(concept: Concept, objectId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete(concept, objectId, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new asset_card.  Args:     asset_card (schemas.CreateAssetCard): The asset_card to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the asset_card for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The created AssetCard.
         * @summary Create Asset Card
         * @param {string} projectId 
         * @param {CreateAssetCard} createAssetCard 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssetCardApiProjectsProjectIdAssetCardsPost(projectId: string, createAssetCard: CreateAssetCard, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssetCardApiProjectsProjectIdAssetCardsPost(projectId, createAssetCard, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createAssetCardApiProjectsProjectIdAssetCardsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Args:     object_type (str): The object type. (enums.Concept)     object_id (UUID): The object ID.     comment (schemas.Comment): The comment to create.     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
         * @summary Create Comment
         * @param {string} objectId 
         * @param {Concept} objectType 
         * @param {string} projectId 
         * @param {CreateComment} createComment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost(objectId: string, objectType: Concept, projectId: string, createComment: CreateComment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost(objectId, objectType, projectId, createComment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new project.  Args:     new_project (CreateProject): The new project.     db (Session): Database session.     user (User): The user creating the project.  Returns:     Project: The created project.
         * @summary Create Project
         * @param {CreateProject} createProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectApiProjectsPost(createProject: CreateProject, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectCreator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectApiProjectsPost(createProject, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createProjectApiProjectsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new framework for the project with the given id.  Args:     framework_codes (List[str]): The framework codes to create.     response (Response): The response object.     user (User): The user creating the framework.     project (Project): The project to create the framework for.     db (Session): Database session.     cache_service (Cache): The cache service.  Returns:     Framework: The created framework.
         * @summary Create Project Framework
         * @param {string} projectId 
         * @param {Array<string | null>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectFrameworkApiProjectsProjectIdFrameworksPost(projectId: string, requestBody: Array<string | null>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Framework>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectFrameworkApiProjectsProjectIdFrameworksPost(projectId, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createProjectFrameworkApiProjectsProjectIdFrameworksPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new risk.  Args:     background_tasks:  The background tasks.     risk (schemas.CreateRisk): The risk to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the risk for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The created risk.
         * @summary Create Risk
         * @param {string} projectId 
         * @param {CreateRisk} createRisk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRiskApiProjectsProjectIdRisksPost(projectId: string, createRisk: CreateRisk, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRiskApiProjectsProjectIdRisksPost(projectId, createRisk, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createRiskApiProjectsProjectIdRisksPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a monitoring datasource.  Args:     testing_log (TestingLogCreate): Monitoring datasource data.     project (Project): Project.     db (Session): Database session.  Returns:     TestingLog: Monitoring datasource.  Raises:     HTTPException: Project not found.
         * @summary Create Testing Log
         * @param {string} projectId 
         * @param {TestingLogCreate} testingLogCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestingLogApiV1ProjectsProjectIdTestingLogsPost(projectId: string, testingLogCreate: TestingLogCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestingLogApiV1ProjectsProjectIdTestingLogsPost(projectId, testingLogCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createTestingLogApiV1ProjectsProjectIdTestingLogsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Testing Metric
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {TestingMetricCreate} testingMetricCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(testingSourceId: string, projectId: string, testingMetricCreate: TestingMetricCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingMetric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(testingSourceId, projectId, testingMetricCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Testing Test
         * @param {string} projectId 
         * @param {CreateTestingTest} createTestingTest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestingTestApiV1ProjectsProjectIdTestingTestsPost(projectId: string, createTestingTest: CreateTestingTest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestingTestApiV1ProjectsProjectIdTestingTestsPost(projectId, createTestingTest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.createTestingTestApiV1ProjectsProjectIdTestingTestsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.
         * @summary Delete Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectApiProjectsProjectIdDelete(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectApiProjectsProjectIdDelete(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.deleteProjectApiProjectsProjectIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     ProjectAiSystemLifecycleDashboard: The Lifecycle Dashboard.
         * @summary Get Ai System Life Cycle Dashboard
         * @param {string} projectId 
         * @param {AiLifecycleDashboardContent} [dashboardContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet(projectId: string, dashboardContent?: AiLifecycleDashboardContent, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectAiSystemLifecycleDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet(projectId, dashboardContent, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Args:     object_id (UUID): The object ID.     object_type (enums.Concept): The object type. (enums.Concept)     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.  Returns:     schemas.Comment: The updated control.
         * @summary Get Comments
         * @param {string} objectId 
         * @param {Concept} objectType 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet(objectId: string, objectType: Concept, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Comment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet(objectId, objectType, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Control By Code
         * @param {string} controlCode 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlByCodeApiProjectsProjectIdControlsControlCodeGet(controlCode: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlByCodeApiProjectsProjectIdControlsControlCodeGet(controlCode, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getControlByCodeApiProjectsProjectIdControlsControlCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Control Evidences By Code
         * @param {string} controlCode 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGet(controlCode: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Evidence>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGet(controlCode, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Controls By Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(projectId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Control>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(projectId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the file types of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session. Returns:     List[schemas.FileType]: List of file types.
         * @summary Get Evidence File Types
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string | null>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the first 10 evidences (name, description and id) of the project with a name filter. This is used for a dropdown selection.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     List[schemas.EvidencePreview]: List of evidences.
         * @summary Get Evidence List
         * @param {string} projectId 
         * @param {string | null} [name] Filter by evidence name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvidenceListApiProjectsProjectIdEvidenceListGet(projectId: string, name?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvidencePreview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvidenceListApiProjectsProjectIdEvidenceListGet(projectId, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getEvidenceListApiProjectsProjectIdEvidenceListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the evidences of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     Page[schemas.Evidence]: Page of evidences.
         * @summary Get Evidences By Project Id
         * @param {string} projectId 
         * @param {string | null} [name] Filter by evidence name
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {Array<string>} [associatedControls] Filter by associated controls
         * @param {Array<string>} [associatedRisks] Filter by associated risks
         * @param {string | null} [startDate] Filter by creation date of the evidence
         * @param {string | null} [endDate] Filter by creation date of the evidence
         * @param {string | null} [fileType] Filter by file type (e.g. \&#39;Image\&#39;)
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet(projectId: string, name?: string | null, sortBy?: string | null, descending?: boolean, associatedControls?: Array<string>, associatedRisks?: Array<string>, startDate?: string | null, endDate?: string | null, fileType?: string | null, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvidencePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet(projectId, name, sortBy, descending, associatedControls, associatedRisks, startDate, endDate, fileType, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the logs for the given project and object id.  Args:     project_id (UUID): The project ID.     object_id (UUID): The object ID.  Returns:     List[Logs]: The logs.
         * @summary Get Logs
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogsApiProjectsProjectIdLogsObjectIdGet(objectId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Logs>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogsApiProjectsProjectIdLogsObjectIdGet(objectId, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getLogsApiProjectsProjectIdLogsObjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the project with the given id. Args:     project_id (UUID): The project ID.  Returns:     Project: The project.
         * @summary Get Project
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectApiProjectsProjectIdGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectApiProjectsProjectIdGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectApiProjectsProjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the asset_cards of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.AssetCardPage]: List of asset_cards.
         * @summary Get Project Asset Cards
         * @param {string} projectId 
         * @param {string | null} [name] Filter by asset_card name
         * @param {string | null} [status] Filter by asset_card status
         * @param {boolean} [isAssignedToMe] Filter by asset_cards assigned to me
         * @param {AssetCardTypes | null} [assetCardType] Filter by asset_card_type
         * @param {AssetCardNature | null} [assetCardNature] Filter by asset card nature
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectAssetCardsApiProjectsProjectIdAssetCardsGet(projectId: string, name?: string | null, status?: string | null, isAssignedToMe?: boolean, assetCardType?: AssetCardTypes | null, assetCardNature?: AssetCardNature | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetCardPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectAssetCardsApiProjectsProjectIdAssetCardsGet(projectId, name, status, isAssignedToMe, assetCardType, assetCardNature, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectAssetCardsApiProjectsProjectIdAssetCardsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the controls of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.ControlWithoutComponents]: List of controls.
         * @summary Get Project Controls
         * @param {string} projectId 
         * @param {Array<string>} [tags] 
         * @param {string | null} [name] Filter by control name
         * @param {string | null} [framework] Filter by framework code
         * @param {string | null} [requirement] Filter by requirement id
         * @param {string | null} [status] Filter by control status
         * @param {boolean} [isAssignedToMe] Filter by controls assigned to me
         * @param {boolean} [isAssignedToMeForReview] Filter by controls assigned to me for review
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectControlsApiProjectsProjectIdControlsGet(projectId: string, tags?: Array<string>, name?: string | null, framework?: string | null, requirement?: string | null, status?: string | null, isAssignedToMe?: boolean, isAssignedToMeForReview?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageControlList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectControlsApiProjectsProjectIdControlsGet(projectId, tags, name, framework, requirement, status, isAssignedToMe, isAssignedToMeForReview, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectControlsApiProjectsProjectIdControlsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Project Controls
         * @param {string} projectId 
         * @param {Status | null} [status] Filter by control status
         * @param {string | null} [name] 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectControlsApiV1ProjectsProjectIdControlsGet(projectId: string, status?: Status | null, name?: string | null, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageControl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectControlsApiV1ProjectsProjectIdControlsGet(projectId, status, name, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectControlsApiV1ProjectsProjectIdControlsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the controls of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
         * @summary Get Project Controls With Evidences
         * @param {string} projectId 
         * @param {string | null} [name] Filter by control name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet(projectId: string, name?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ControlDisplay>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet(projectId, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     Project: The project dashboard.
         * @summary Get Project Dashboard
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectDashboardApiProjectsProjectIdDashboardGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDashboard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectDashboardApiProjectsProjectIdDashboardGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectDashboardApiProjectsProjectIdDashboardGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the frameworks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by framework name.     params (Params): Pagination params.  Returns:     Page[schemas.FrameworkAggregator]: List of frameworks.
         * @summary Get Project Frameworks
         * @param {string} projectId 
         * @param {string | null} [name] Filter by framework name
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectFrameworksApiProjectsProjectIdFrameworksGet(projectId: string, name?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageFrameworkAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectFrameworksApiProjectsProjectIdFrameworksGet(projectId, name, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectFrameworksApiProjectsProjectIdFrameworksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the project pdf for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     FileResponse: The project pdf.
         * @summary Get Project Pdf
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectPdfApiProjectsProjectIdExportGet(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectPdfApiProjectsProjectIdExportGet(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectPdfApiProjectsProjectIdExportGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the requirements of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by requirement name.     framework (str): Filter by framework code.     status (str): Filter by requirement status.     params (Params): Pagination params.  Returns:     Page[schemas.Requirement]: Paginated List of requirements.
         * @summary Get Project Requirements
         * @param {string} projectId 
         * @param {string | null} [name] Filter by requirement name
         * @param {string | null} [framework] Filter by framework code
         * @param {string | null} [status] Filter by requirement status
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectRequirementsApiProjectsProjectIdRequirementsGet(projectId: string, name?: string | null, framework?: string | null, status?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRequirementAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectRequirementsApiProjectsProjectIdRequirementsGet(projectId, name, framework, status, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectRequirementsApiProjectsProjectIdRequirementsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
         * @summary Get Project Risks
         * @param {string} projectId 
         * @param {string | null} [name] Filter by risk name
         * @param {string | null} [status] Filter by risk status
         * @param {boolean} [isAssignedToMe] Filter by risks assigned to me
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectRisksApiProjectsProjectIdRisksGet(projectId: string, name?: string | null, status?: string | null, isAssignedToMe?: boolean, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRisk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectRisksApiProjectsProjectIdRisksGet(projectId, name, status, isAssignedToMe, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectRisksApiProjectsProjectIdRisksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
         * @summary Get Project Risks List
         * @param {string} projectId 
         * @param {string | null} [name] Filter by risk name
         * @param {string | null} [status] Filter by risk status
         * @param {boolean} [isAssignedToMe] Filter by risks assigned to me
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectRisksListApiProjectsProjectIdRisksListGet(projectId: string, name?: string | null, status?: string | null, isAssignedToMe?: boolean, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskWithOverviewPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectRisksListApiProjectsProjectIdRisksListGet(projectId, name, status, isAssignedToMe, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectRisksListApiProjectsProjectIdRisksListGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the risks of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
         * @summary Get Project Risks With Evidences
         * @param {string} projectId 
         * @param {string | null} [name] Filter by risk name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet(projectId: string, name?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RiskDisplay>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet(projectId, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Reviews For Concept Type
         * @param {Concept} concept 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet(concept: Concept, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet(concept, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Reviews For Object Id
         * @param {string} objectId 
         * @param {Concept} concept 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet(objectId: string, concept: Concept, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet(objectId, concept, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Metric
         * @param {string} testingSourceId 
         * @param {string} testingMetricId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(testingSourceId: string, testingMetricId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingMetric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(testingSourceId, testingMetricId, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Source
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(testingSourceId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(testingSourceId, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Source Metrics
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(testingSourceId: string, projectId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTestingMetric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(testingSourceId, projectId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Sources
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(projectId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTestingSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(projectId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Test And Latest Result
         * @param {string} projectId 
         * @param {string | null} [sortBy] Column name to sort by
         * @param {boolean} [descending] Sort in descending order
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string | null} [name] Filter by name
         * @param {TestingTestStatus | null} [resultStatus] Filter by result status
         * @param {boolean | null} [status] Filter by test status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(projectId: string, sortBy?: string | null, descending?: boolean, page?: number, size?: number, name?: string | null, resultStatus?: TestingTestStatus | null, status?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTestAndResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(projectId, sortBy, descending, page, size, name, resultStatus, status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(projectId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(projectId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Test Result
         * @param {string} testingTestResultId 
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(testingTestResultId: string, projectId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(testingTestResultId, projectId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Test Results
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {TestingTestStatus | null} [resultStatus] Filter by result status
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string | null} [sortBy] Column name to sort by
         * @param {boolean} [descending] Sort in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(projectId: string, testingTestId: string, resultStatus?: TestingTestStatus | null, page?: number, size?: number, sortBy?: string | null, descending?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTestResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(projectId, testingTestId, resultStatus, page, size, sortBy, descending, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Tests
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(projectId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(projectId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getTestingTestsApiV1ProjectsProjectIdTestingTestsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns all the users of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     params (Params): Pagination params.     roles (List[enums.Roles]): Filter by role.     exclude_me (bool): Include current user.     sort_by (str): Sort by a field.     descending (bool): Sort order. If true, descending, otherwise ascending.  Returns:     Page[schemas.OrganizationUser]: List of users.
         * @summary Get User Of Project
         * @param {string} projectId 
         * @param {Array<Roles>} [roles] Filter by role.
         * @param {boolean} [excludeMe] Exclude current user.
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {string | null} [name] The user name filter.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserOfProjectApiProjectsProjectIdUsersGet(projectId: string, roles?: Array<Roles>, excludeMe?: boolean, sortBy?: string | null, descending?: boolean, name?: string | null, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProjectPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserOfProjectApiProjectsProjectIdUsersGet(projectId, roles, excludeMe, sortBy, descending, name, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.getUserOfProjectApiProjectsProjectIdUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove Control From Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(projectId: string, testingTestId: string, controlId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(projectId, testingTestId, controlId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Request access to the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     user (User): The user requesting access to the project.
         * @summary Request Access
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestAccessApiProjectsProjectIdRequestAccessPost(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestAccessApiProjectsProjectIdRequestAccessPost(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.requestAccessApiProjectsProjectIdRequestAccessPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Request reviews for a concept object.  Args:     review_request (schemas.ReviewRequest): The review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object to request reviews for. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.     force_update_status (bool, optional): Project owners can force update the         status of the object.  Returns:     List[schemas.Review]: The created reviews.
         * @summary Request Reviews
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {ReviewRequest} reviewRequest 
         * @param {boolean} [forceUpdateStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost(concept: Concept, objectId: string, projectId: string, reviewRequest: ReviewRequest, forceUpdateStatus?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost(concept, objectId, projectId, reviewRequest, forceUpdateStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Run Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(projectId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(projectId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Take a decision on a review for a concept object.  Args:     project_id (uuid.UUID): The project ID.     concept (enums.Concept): The concept of the object.     review_decision (schemas.ReviewDecision): The review decision.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Review: The updated review.
         * @summary Take Review Decision
         * @param {string} projectId 
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {ReviewDecision} reviewDecision 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch(projectId: string, concept: Concept, objectId: string, reviewDecision: ReviewDecision, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch(projectId, concept, objectId, reviewDecision, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the project with the given id.  Args:     project_id (UUID): The project\'s id.     project_update (UpdateProject): The update to the project.     db (Session): Database session.  Returns:     schemas.Project: The updated project.
         * @summary Update Project
         * @param {string} projectId 
         * @param {UpdateProject} updateProject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectApiProjectsProjectIdPatch(projectId: string, updateProject: UpdateProject, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectApiProjectsProjectIdPatch(projectId, updateProject, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.updateProjectApiProjectsProjectIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update the reviewers for a concept object.  Args:     review_request (schemas.UpdateReviewRequest): The update review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     List[schemas.Review]: The updated reviews.
         * @summary Update Reviewers
         * @param {Concept} concept 
         * @param {string} objectId 
         * @param {string} projectId 
         * @param {UpdateReviewRequest} updateReviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch(concept: Concept, objectId: string, projectId: string, updateReviewRequest: UpdateReviewRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch(concept, objectId, projectId, updateReviewRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {UpdateTestingTest} updateTestingTest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(projectId: string, testingTestId: string, updateTestingTest: UpdateTestingTest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(projectId, testingTestId, updateTestingTest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Uploads the evidence file and creates an entry in the evidence database.  Args:     db (Session): Database session.     project_id (UUID): The project\'s id.     user (User): The user creating the evidence.     file (UploadFile): The file to upload.     component (Component): The component the evidence belongs to.  Returns:     schemas.Evidence: The created evidence.
         * @summary Upload And Create Evidence
         * @param {string} projectId 
         * @param {File} file 
         * @param {string} [fileName] The file name.
         * @param {string} [description] The description.
         * @param {string | null} [componentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost(projectId: string, file: File, fileName?: string, description?: string, componentId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost(projectId, file, fileName, description, componentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectsApi.uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Testing Testing To Control
         * @param {ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(requestParameters: ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut0Request, options?: RawAxiosRequestConfig): AxiosPromise<TestingTest> {
            return localVarFp.addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(requestParameters.projectId, requestParameters.controlId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancel all pending reviews for a concept object.  Args:     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.
         * @summary Cancel Pending Reviews
         * @param {ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete(requestParameters: ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete(requestParameters.concept, requestParameters.objectId, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new asset_card.  Args:     asset_card (schemas.CreateAssetCard): The asset_card to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the asset_card for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The created AssetCard.
         * @summary Create Asset Card
         * @param {ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssetCardApiProjectsProjectIdAssetCardsPost(requestParameters: ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetCard> {
            return localVarFp.createAssetCardApiProjectsProjectIdAssetCardsPost(requestParameters.projectId, requestParameters.createAssetCard, options).then((request) => request(axios, basePath));
        },
        /**
         * Args:     object_type (str): The object type. (enums.Concept)     object_id (UUID): The object ID.     comment (schemas.Comment): The comment to create.     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
         * @summary Create Comment
         * @param {ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost(requestParameters: ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Comment> {
            return localVarFp.createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost(requestParameters.objectId, requestParameters.objectType, requestParameters.projectId, requestParameters.createComment, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new project.  Args:     new_project (CreateProject): The new project.     db (Session): Database session.     user (User): The user creating the project.  Returns:     Project: The created project.
         * @summary Create Project
         * @param {ProjectsApiCreateProjectApiProjectsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectApiProjectsPost(requestParameters: ProjectsApiCreateProjectApiProjectsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectCreator> {
            return localVarFp.createProjectApiProjectsPost(requestParameters.createProject, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new framework for the project with the given id.  Args:     framework_codes (List[str]): The framework codes to create.     response (Response): The response object.     user (User): The user creating the framework.     project (Project): The project to create the framework for.     db (Session): Database session.     cache_service (Cache): The cache service.  Returns:     Framework: The created framework.
         * @summary Create Project Framework
         * @param {ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectFrameworkApiProjectsProjectIdFrameworksPost(requestParameters: ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Framework>> {
            return localVarFp.createProjectFrameworkApiProjectsProjectIdFrameworksPost(requestParameters.projectId, requestParameters.requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new risk.  Args:     background_tasks:  The background tasks.     risk (schemas.CreateRisk): The risk to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the risk for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The created risk.
         * @summary Create Risk
         * @param {ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRiskApiProjectsProjectIdRisksPost(requestParameters: ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<RiskAggregator> {
            return localVarFp.createRiskApiProjectsProjectIdRisksPost(requestParameters.projectId, requestParameters.createRisk, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a monitoring datasource.  Args:     testing_log (TestingLogCreate): Monitoring datasource data.     project (Project): Project.     db (Session): Database session.  Returns:     TestingLog: Monitoring datasource.  Raises:     HTTPException: Project not found.
         * @summary Create Testing Log
         * @param {ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPost0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingLogApiV1ProjectsProjectIdTestingLogsPost(requestParameters: ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPost0Request, options?: RawAxiosRequestConfig): AxiosPromise<TestingLog> {
            return localVarFp.createTestingLogApiV1ProjectsProjectIdTestingLogsPost(requestParameters.projectId, requestParameters.testingLogCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Testing Metric
         * @param {ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(requestParameters: ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost0Request, options?: RawAxiosRequestConfig): AxiosPromise<TestingMetric> {
            return localVarFp.createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(requestParameters.testingSourceId, requestParameters.projectId, requestParameters.testingMetricCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Testing Test
         * @param {ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPost0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingTestApiV1ProjectsProjectIdTestingTestsPost(requestParameters: ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPost0Request, options?: RawAxiosRequestConfig): AxiosPromise<TestingTest> {
            return localVarFp.createTestingTestApiV1ProjectsProjectIdTestingTestsPost(requestParameters.projectId, requestParameters.createTestingTest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.
         * @summary Delete Project
         * @param {ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectApiProjectsProjectIdDelete(requestParameters: ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProjectApiProjectsProjectIdDelete(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     ProjectAiSystemLifecycleDashboard: The Lifecycle Dashboard.
         * @summary Get Ai System Life Cycle Dashboard
         * @param {ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet(requestParameters: ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectAiSystemLifecycleDashboard> {
            return localVarFp.getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet(requestParameters.projectId, requestParameters.dashboardContent, options).then((request) => request(axios, basePath));
        },
        /**
         * Args:     object_id (UUID): The object ID.     object_type (enums.Concept): The object type. (enums.Concept)     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.  Returns:     schemas.Comment: The updated control.
         * @summary Get Comments
         * @param {ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet(requestParameters: ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Comment>> {
            return localVarFp.getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet(requestParameters.objectId, requestParameters.objectType, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Control By Code
         * @param {ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlByCodeApiProjectsProjectIdControlsControlCodeGet(requestParameters: ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControlAggregator> {
            return localVarFp.getControlByCodeApiProjectsProjectIdControlsControlCodeGet(requestParameters.controlCode, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Control Evidences By Code
         * @param {ProjectsApiGetControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGet(requestParameters: ProjectsApiGetControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Evidence>> {
            return localVarFp.getControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGet(requestParameters.controlCode, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Controls By Testing Test
         * @param {ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(requestParameters: ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<Array<Control>> {
            return localVarFp.getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the file types of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session. Returns:     List[schemas.FileType]: List of file types.
         * @summary Get Evidence File Types
         * @param {ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet(requestParameters: ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<string | null>> {
            return localVarFp.getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the first 10 evidences (name, description and id) of the project with a name filter. This is used for a dropdown selection.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     List[schemas.EvidencePreview]: List of evidences.
         * @summary Get Evidence List
         * @param {ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidenceListApiProjectsProjectIdEvidenceListGet(requestParameters: ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<EvidencePreview>> {
            return localVarFp.getEvidenceListApiProjectsProjectIdEvidenceListGet(requestParameters.projectId, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the evidences of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     Page[schemas.Evidence]: Page of evidences.
         * @summary Get Evidences By Project Id
         * @param {ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet(requestParameters: ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<EvidencePage> {
            return localVarFp.getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet(requestParameters.projectId, requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.associatedControls, requestParameters.associatedRisks, requestParameters.startDate, requestParameters.endDate, requestParameters.fileType, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the logs for the given project and object id.  Args:     project_id (UUID): The project ID.     object_id (UUID): The object ID.  Returns:     List[Logs]: The logs.
         * @summary Get Logs
         * @param {ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsApiProjectsProjectIdLogsObjectIdGet(requestParameters: ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Logs>> {
            return localVarFp.getLogsApiProjectsProjectIdLogsObjectIdGet(requestParameters.objectId, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the project with the given id. Args:     project_id (UUID): The project ID.  Returns:     Project: The project.
         * @summary Get Project
         * @param {ProjectsApiGetProjectApiProjectsProjectIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectApiProjectsProjectIdGet(requestParameters: ProjectsApiGetProjectApiProjectsProjectIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectAggregator> {
            return localVarFp.getProjectApiProjectsProjectIdGet(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the asset_cards of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.AssetCardPage]: List of asset_cards.
         * @summary Get Project Asset Cards
         * @param {ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectAssetCardsApiProjectsProjectIdAssetCardsGet(requestParameters: ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetCardPage> {
            return localVarFp.getProjectAssetCardsApiProjectsProjectIdAssetCardsGet(requestParameters.projectId, requestParameters.name, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.assetCardType, requestParameters.assetCardNature, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the controls of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.ControlWithoutComponents]: List of controls.
         * @summary Get Project Controls
         * @param {ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsApiProjectsProjectIdControlsGet(requestParameters: ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageControlList> {
            return localVarFp.getProjectControlsApiProjectsProjectIdControlsGet(requestParameters.projectId, requestParameters.tags, requestParameters.name, requestParameters.framework, requestParameters.requirement, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.isAssignedToMeForReview, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Project Controls
         * @param {ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsApiV1ProjectsProjectIdControlsGet(requestParameters: ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<PageControl> {
            return localVarFp.getProjectControlsApiV1ProjectsProjectIdControlsGet(requestParameters.projectId, requestParameters.status, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the controls of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
         * @summary Get Project Controls With Evidences
         * @param {ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet(requestParameters: ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ControlDisplay>> {
            return localVarFp.getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet(requestParameters.projectId, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     Project: The project dashboard.
         * @summary Get Project Dashboard
         * @param {ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDashboardApiProjectsProjectIdDashboardGet(requestParameters: ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectDashboard> {
            return localVarFp.getProjectDashboardApiProjectsProjectIdDashboardGet(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the frameworks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by framework name.     params (Params): Pagination params.  Returns:     Page[schemas.FrameworkAggregator]: List of frameworks.
         * @summary Get Project Frameworks
         * @param {ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectFrameworksApiProjectsProjectIdFrameworksGet(requestParameters: ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageFrameworkAggregator> {
            return localVarFp.getProjectFrameworksApiProjectsProjectIdFrameworksGet(requestParameters.projectId, requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the project pdf for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     FileResponse: The project pdf.
         * @summary Get Project Pdf
         * @param {ProjectsApiGetProjectPdfApiProjectsProjectIdExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectPdfApiProjectsProjectIdExportGet(requestParameters: ProjectsApiGetProjectPdfApiProjectsProjectIdExportGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.getProjectPdfApiProjectsProjectIdExportGet(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the requirements of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by requirement name.     framework (str): Filter by framework code.     status (str): Filter by requirement status.     params (Params): Pagination params.  Returns:     Page[schemas.Requirement]: Paginated List of requirements.
         * @summary Get Project Requirements
         * @param {ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRequirementsApiProjectsProjectIdRequirementsGet(requestParameters: ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageRequirementAggregator> {
            return localVarFp.getProjectRequirementsApiProjectsProjectIdRequirementsGet(requestParameters.projectId, requestParameters.name, requestParameters.framework, requestParameters.status, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
         * @summary Get Project Risks
         * @param {ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRisksApiProjectsProjectIdRisksGet(requestParameters: ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageRisk> {
            return localVarFp.getProjectRisksApiProjectsProjectIdRisksGet(requestParameters.projectId, requestParameters.name, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
         * @summary Get Project Risks List
         * @param {ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRisksListApiProjectsProjectIdRisksListGet(requestParameters: ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RiskWithOverviewPage> {
            return localVarFp.getProjectRisksListApiProjectsProjectIdRisksListGet(requestParameters.projectId, requestParameters.name, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the risks of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
         * @summary Get Project Risks With Evidences
         * @param {ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet(requestParameters: ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<RiskDisplay>> {
            return localVarFp.getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet(requestParameters.projectId, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Reviews For Concept Type
         * @param {ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet(requestParameters: ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Review>> {
            return localVarFp.getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet(requestParameters.concept, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Reviews For Object Id
         * @param {ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet(requestParameters: ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Review>> {
            return localVarFp.getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet(requestParameters.objectId, requestParameters.concept, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Metric
         * @param {ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(requestParameters: ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<TestingMetric> {
            return localVarFp.getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(requestParameters.testingSourceId, requestParameters.testingMetricId, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Source
         * @param {ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(requestParameters: ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<TestingSource> {
            return localVarFp.getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(requestParameters.testingSourceId, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Source Metrics
         * @param {ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(requestParameters: ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<PageTestingMetric> {
            return localVarFp.getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(requestParameters.testingSourceId, requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Sources
         * @param {ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(requestParameters: ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<PageTestingSource> {
            return localVarFp.getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Test And Latest Result
         * @param {ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(requestParameters: ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<TestingTestAndResultPage> {
            return localVarFp.getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(requestParameters.projectId, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, requestParameters.name, requestParameters.resultStatus, requestParameters.status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Test
         * @param {ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(requestParameters: ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<TestingTest> {
            return localVarFp.getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Test Result
         * @param {ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(requestParameters: ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<TestingTestResult> {
            return localVarFp.getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(requestParameters.testingTestResultId, requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Test Results
         * @param {ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(requestParameters: ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<TestingTestResultPage> {
            return localVarFp.getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(requestParameters.projectId, requestParameters.testingTestId, requestParameters.resultStatus, requestParameters.page, requestParameters.size, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Tests
         * @param {ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(requestParameters: ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<PageTestingTest> {
            return localVarFp.getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the users of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     params (Params): Pagination params.     roles (List[enums.Roles]): Filter by role.     exclude_me (bool): Include current user.     sort_by (str): Sort by a field.     descending (bool): Sort order. If true, descending, otherwise ascending.  Returns:     Page[schemas.OrganizationUser]: List of users.
         * @summary Get User Of Project
         * @param {ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserOfProjectApiProjectsProjectIdUsersGet(requestParameters: ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserProjectPage> {
            return localVarFp.getUserOfProjectApiProjectsProjectIdUsersGet(requestParameters.projectId, requestParameters.roles, requestParameters.excludeMe, requestParameters.sortBy, requestParameters.descending, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Control From Testing Test
         * @param {ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(requestParameters: ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete0Request, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(requestParameters.projectId, requestParameters.testingTestId, requestParameters.controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * Request access to the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     user (User): The user requesting access to the project.
         * @summary Request Access
         * @param {ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestAccessApiProjectsProjectIdRequestAccessPost(requestParameters: ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.requestAccessApiProjectsProjectIdRequestAccessPost(requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Request reviews for a concept object.  Args:     review_request (schemas.ReviewRequest): The review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object to request reviews for. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.     force_update_status (bool, optional): Project owners can force update the         status of the object.  Returns:     List[schemas.Review]: The created reviews.
         * @summary Request Reviews
         * @param {ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost(requestParameters: ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Review>> {
            return localVarFp.requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost(requestParameters.concept, requestParameters.objectId, requestParameters.projectId, requestParameters.reviewRequest, requestParameters.forceUpdateStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run Testing Test
         * @param {ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(requestParameters: ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost0Request, options?: RawAxiosRequestConfig): AxiosPromise<TestingTestResult> {
            return localVarFp.runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * Take a decision on a review for a concept object.  Args:     project_id (uuid.UUID): The project ID.     concept (enums.Concept): The concept of the object.     review_decision (schemas.ReviewDecision): The review decision.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Review: The updated review.
         * @summary Take Review Decision
         * @param {ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch(requestParameters: ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<Review> {
            return localVarFp.takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch(requestParameters.projectId, requestParameters.concept, requestParameters.objectId, requestParameters.reviewDecision, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the project with the given id.  Args:     project_id (UUID): The project\'s id.     project_update (UpdateProject): The update to the project.     db (Session): Database session.  Returns:     schemas.Project: The updated project.
         * @summary Update Project
         * @param {ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectApiProjectsProjectIdPatch(requestParameters: ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProjectAggregator> {
            return localVarFp.updateProjectApiProjectsProjectIdPatch(requestParameters.projectId, requestParameters.updateProject, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the reviewers for a concept object.  Args:     review_request (schemas.UpdateReviewRequest): The update review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     List[schemas.Review]: The updated reviews.
         * @summary Update Reviewers
         * @param {ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch(requestParameters: ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Review>> {
            return localVarFp.updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch(requestParameters.concept, requestParameters.objectId, requestParameters.projectId, requestParameters.updateReviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Testing Test
         * @param {ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(requestParameters: ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch0Request, options?: RawAxiosRequestConfig): AxiosPromise<TestingTest> {
            return localVarFp.updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(requestParameters.projectId, requestParameters.testingTestId, requestParameters.updateTestingTest, options).then((request) => request(axios, basePath));
        },
        /**
         * Uploads the evidence file and creates an entry in the evidence database.  Args:     db (Session): Database session.     project_id (UUID): The project\'s id.     user (User): The user creating the evidence.     file (UploadFile): The file to upload.     component (Component): The component the evidence belongs to.  Returns:     schemas.Evidence: The created evidence.
         * @summary Upload And Create Evidence
         * @param {ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost(requestParameters: ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Evidence> {
            return localVarFp.uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost(requestParameters.projectId, requestParameters.file, requestParameters.fileName, requestParameters.description, requestParameters.componentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut operation in ProjectsApi.
 * @export
 * @interface ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut0Request
 */
export interface ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut0
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut0
     */
    readonly controlId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut0
     */
    readonly testingTestId: string
}

/**
 * Request parameters for cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest
 */
export interface ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest {
    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete
     */
    readonly concept: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete
     */
    readonly objectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete
     */
    readonly projectId: string
}

/**
 * Request parameters for createAssetCardApiProjectsProjectIdAssetCardsPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest
 */
export interface ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPost
     */
    readonly projectId: string

    /**
     * 
     * @type {CreateAssetCard}
     * @memberof ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPost
     */
    readonly createAssetCard: CreateAssetCard
}

/**
 * Request parameters for createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest
 */
export interface ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost
     */
    readonly objectId: string

    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost
     */
    readonly objectType: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost
     */
    readonly projectId: string

    /**
     * 
     * @type {CreateComment}
     * @memberof ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost
     */
    readonly createComment: CreateComment
}

/**
 * Request parameters for createProjectApiProjectsPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateProjectApiProjectsPostRequest
 */
export interface ProjectsApiCreateProjectApiProjectsPostRequest {
    /**
     * 
     * @type {CreateProject}
     * @memberof ProjectsApiCreateProjectApiProjectsPost
     */
    readonly createProject: CreateProject
}

/**
 * Request parameters for createProjectFrameworkApiProjectsProjectIdFrameworksPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest
 */
export interface ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPost
     */
    readonly projectId: string

    /**
     * 
     * @type {Array<string | null>}
     * @memberof ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPost
     */
    readonly requestBody: Array<string | null>
}

/**
 * Request parameters for createRiskApiProjectsProjectIdRisksPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest
 */
export interface ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateRiskApiProjectsProjectIdRisksPost
     */
    readonly projectId: string

    /**
     * 
     * @type {CreateRisk}
     * @memberof ProjectsApiCreateRiskApiProjectsProjectIdRisksPost
     */
    readonly createRisk: CreateRisk
}

/**
 * Request parameters for createTestingLogApiV1ProjectsProjectIdTestingLogsPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPost0Request
 */
export interface ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPost0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPost0
     */
    readonly projectId: string

    /**
     * 
     * @type {TestingLogCreate}
     * @memberof ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPost0
     */
    readonly testingLogCreate: TestingLogCreate
}

/**
 * Request parameters for createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost0Request
 */
export interface ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost0
     */
    readonly testingSourceId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost0
     */
    readonly projectId: string

    /**
     * 
     * @type {TestingMetricCreate}
     * @memberof ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost0
     */
    readonly testingMetricCreate: TestingMetricCreate
}

/**
 * Request parameters for createTestingTestApiV1ProjectsProjectIdTestingTestsPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPost0Request
 */
export interface ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPost0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPost0
     */
    readonly projectId: string

    /**
     * 
     * @type {CreateTestingTest}
     * @memberof ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPost0
     */
    readonly createTestingTest: CreateTestingTest
}

/**
 * Request parameters for deleteProjectApiProjectsProjectIdDelete operation in ProjectsApi.
 * @export
 * @interface ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest
 */
export interface ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiDeleteProjectApiProjectsProjectIdDelete
     */
    readonly projectId: string
}

/**
 * Request parameters for getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest
 */
export interface ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet
     */
    readonly projectId: string

    /**
     * 
     * @type {AiLifecycleDashboardContent}
     * @memberof ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet
     */
    readonly dashboardContent?: AiLifecycleDashboardContent
}

/**
 * Request parameters for getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest
 */
export interface ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet
     */
    readonly objectId: string

    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet
     */
    readonly objectType: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getControlByCodeApiProjectsProjectIdControlsControlCodeGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest
 */
export interface ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGet
     */
    readonly controlCode: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGetRequest
 */
export interface ProjectsApiGetControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGet
     */
    readonly controlCode: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet0Request
 */
export interface ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet0
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet0
     */
    readonly testingTestId: string
}

/**
 * Request parameters for getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest
 */
export interface ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getEvidenceListApiProjectsProjectIdEvidenceListGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest
 */
export interface ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGet
     */
    readonly projectId: string

    /**
     * Filter by evidence name
     * @type {string}
     * @memberof ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGet
     */
    readonly name?: string | null
}

/**
 * Request parameters for getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest
 */
export interface ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly projectId: string

    /**
     * Filter by evidence name
     * @type {string}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly name?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly descending?: boolean

    /**
     * Filter by associated controls
     * @type {Array<string>}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly associatedControls?: Array<string>

    /**
     * Filter by associated risks
     * @type {Array<string>}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly associatedRisks?: Array<string>

    /**
     * Filter by creation date of the evidence
     * @type {string}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly startDate?: string | null

    /**
     * Filter by creation date of the evidence
     * @type {string}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly endDate?: string | null

    /**
     * Filter by file type (e.g. \&#39;Image\&#39;)
     * @type {string}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly fileType?: string | null

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGet
     */
    readonly size?: number
}

/**
 * Request parameters for getLogsApiProjectsProjectIdLogsObjectIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest
 */
export interface ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGet
     */
    readonly objectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getProjectApiProjectsProjectIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectApiProjectsProjectIdGetRequest
 */
export interface ProjectsApiGetProjectApiProjectsProjectIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectApiProjectsProjectIdGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getProjectAssetCardsApiProjectsProjectIdAssetCardsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest
 */
export interface ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly projectId: string

    /**
     * Filter by asset_card name
     * @type {string}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly name?: string | null

    /**
     * Filter by asset_card status
     * @type {string}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly status?: string | null

    /**
     * Filter by asset_cards assigned to me
     * @type {boolean}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly isAssignedToMe?: boolean

    /**
     * Filter by asset_card_type
     * @type {AssetCardTypes}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly assetCardType?: AssetCardTypes | null

    /**
     * Filter by asset card nature
     * @type {AssetCardNature}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly assetCardNature?: AssetCardNature | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectControlsApiProjectsProjectIdControlsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest
 */
export interface ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly projectId: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly tags?: Array<string>

    /**
     * Filter by control name
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly name?: string | null

    /**
     * Filter by framework code
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly framework?: string | null

    /**
     * Filter by requirement id
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly requirement?: string | null

    /**
     * Filter by control status
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly status?: string | null

    /**
     * Filter by controls assigned to me
     * @type {boolean}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly isAssignedToMe?: boolean

    /**
     * Filter by controls assigned to me for review
     * @type {boolean}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly isAssignedToMeForReview?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectControlsApiV1ProjectsProjectIdControlsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet0Request
 */
export interface ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet0
     */
    readonly projectId: string

    /**
     * Filter by control status
     * @type {Status}
     * @memberof ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet0
     */
    readonly status?: Status | null

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet0
     */
    readonly name?: string | null

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet0
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet0
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest
 */
export interface ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet
     */
    readonly projectId: string

    /**
     * Filter by control name
     * @type {string}
     * @memberof ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet
     */
    readonly name?: string | null
}

/**
 * Request parameters for getProjectDashboardApiProjectsProjectIdDashboardGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest
 */
export interface ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getProjectFrameworksApiProjectsProjectIdFrameworksGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest
 */
export interface ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGet
     */
    readonly projectId: string

    /**
     * Filter by framework name
     * @type {string}
     * @memberof ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGet
     */
    readonly name?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectPdfApiProjectsProjectIdExportGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectPdfApiProjectsProjectIdExportGetRequest
 */
export interface ProjectsApiGetProjectPdfApiProjectsProjectIdExportGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectPdfApiProjectsProjectIdExportGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getProjectRequirementsApiProjectsProjectIdRequirementsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest
 */
export interface ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly projectId: string

    /**
     * Filter by requirement name
     * @type {string}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly name?: string | null

    /**
     * Filter by framework code
     * @type {string}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly framework?: string | null

    /**
     * Filter by requirement status
     * @type {string}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly status?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectRisksApiProjectsProjectIdRisksGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGetRequest
 */
export interface ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly projectId: string

    /**
     * Filter by risk name
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly name?: string | null

    /**
     * Filter by risk status
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly status?: string | null

    /**
     * Filter by risks assigned to me
     * @type {boolean}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly isAssignedToMe?: boolean

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectRisksListApiProjectsProjectIdRisksListGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest
 */
export interface ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly projectId: string

    /**
     * Filter by risk name
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly name?: string | null

    /**
     * Filter by risk status
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly status?: string | null

    /**
     * Filter by risks assigned to me
     * @type {boolean}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly isAssignedToMe?: boolean

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGet
     */
    readonly size?: number
}

/**
 * Request parameters for getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest
 */
export interface ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet
     */
    readonly projectId: string

    /**
     * Filter by risk name
     * @type {string}
     * @memberof ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet
     */
    readonly name?: string | null
}

/**
 * Request parameters for getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGetRequest
 */
export interface ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGetRequest {
    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet
     */
    readonly concept: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGetRequest
 */
export interface ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet
     */
    readonly objectId: string

    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet
     */
    readonly concept: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet0Request
 */
export interface ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet0
     */
    readonly testingSourceId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet0
     */
    readonly testingMetricId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet0
     */
    readonly projectId: string
}

/**
 * Request parameters for getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet0Request
 */
export interface ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet0
     */
    readonly testingSourceId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet0
     */
    readonly projectId: string
}

/**
 * Request parameters for getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet0Request
 */
export interface ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet0
     */
    readonly testingSourceId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet0
     */
    readonly projectId: string

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet0
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet0
     */
    readonly size?: number
}

/**
 * Request parameters for getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet0Request
 */
export interface ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet0
     */
    readonly projectId: string

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet0
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet0
     */
    readonly size?: number
}

/**
 * Request parameters for getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0Request
 */
export interface ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0
     */
    readonly projectId: string

    /**
     * Column name to sort by
     * @type {string}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0
     */
    readonly sortBy?: string | null

    /**
     * Sort in descending order
     * @type {boolean}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0
     */
    readonly size?: number

    /**
     * Filter by name
     * @type {string}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0
     */
    readonly name?: string | null

    /**
     * Filter by result status
     * @type {TestingTestStatus}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0
     */
    readonly resultStatus?: TestingTestStatus | null

    /**
     * Filter by test status
     * @type {boolean}
     * @memberof ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0
     */
    readonly status?: boolean | null
}

/**
 * Request parameters for getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet0Request
 */
export interface ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet0
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet0
     */
    readonly testingTestId: string
}

/**
 * Request parameters for getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet0Request
 */
export interface ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet0
     */
    readonly testingTestResultId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet0
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet0
     */
    readonly testingTestId: string
}

/**
 * Request parameters for getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet0Request
 */
export interface ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet0
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet0
     */
    readonly testingTestId: string

    /**
     * Filter by result status
     * @type {TestingTestStatus}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet0
     */
    readonly resultStatus?: TestingTestStatus | null

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet0
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet0
     */
    readonly size?: number

    /**
     * Column name to sort by
     * @type {string}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet0
     */
    readonly sortBy?: string | null

    /**
     * Sort in descending order
     * @type {boolean}
     * @memberof ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet0
     */
    readonly descending?: boolean
}

/**
 * Request parameters for getTestingTestsApiV1ProjectsProjectIdTestingTestsGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet0Request
 */
export interface ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet0
     */
    readonly projectId: string

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet0
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet0
     */
    readonly size?: number
}

/**
 * Request parameters for getUserOfProjectApiProjectsProjectIdUsersGet operation in ProjectsApi.
 * @export
 * @interface ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest
 */
export interface ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly projectId: string

    /**
     * Filter by role.
     * @type {Array<Roles>}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly roles?: Array<Roles>

    /**
     * Exclude current user.
     * @type {boolean}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly excludeMe?: boolean

    /**
     * Sort by a field.
     * @type {string}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly descending?: boolean

    /**
     * The user name filter.
     * @type {string}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly name?: string | null

    /**
     * Page number
     * @type {number}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGet
     */
    readonly size?: number
}

/**
 * Request parameters for removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete operation in ProjectsApi.
 * @export
 * @interface ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete0Request
 */
export interface ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete0
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete0
     */
    readonly testingTestId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete0
     */
    readonly controlId: string
}

/**
 * Request parameters for requestAccessApiProjectsProjectIdRequestAccessPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest
 */
export interface ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPost
     */
    readonly projectId: string
}

/**
 * Request parameters for requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest
 */
export interface ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest {
    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost
     */
    readonly concept: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost
     */
    readonly objectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost
     */
    readonly projectId: string

    /**
     * 
     * @type {ReviewRequest}
     * @memberof ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost
     */
    readonly reviewRequest: ReviewRequest

    /**
     * 
     * @type {boolean}
     * @memberof ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost
     */
    readonly forceUpdateStatus?: boolean
}

/**
 * Request parameters for runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost0Request
 */
export interface ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost0
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost0
     */
    readonly testingTestId: string
}

/**
 * Request parameters for takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch operation in ProjectsApi.
 * @export
 * @interface ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest
 */
export interface ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch
     */
    readonly projectId: string

    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch
     */
    readonly concept: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch
     */
    readonly objectId: string

    /**
     * 
     * @type {ReviewDecision}
     * @memberof ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch
     */
    readonly reviewDecision: ReviewDecision
}

/**
 * Request parameters for updateProjectApiProjectsProjectIdPatch operation in ProjectsApi.
 * @export
 * @interface ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest
 */
export interface ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUpdateProjectApiProjectsProjectIdPatch
     */
    readonly projectId: string

    /**
     * 
     * @type {UpdateProject}
     * @memberof ProjectsApiUpdateProjectApiProjectsProjectIdPatch
     */
    readonly updateProject: UpdateProject
}

/**
 * Request parameters for updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch operation in ProjectsApi.
 * @export
 * @interface ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest
 */
export interface ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest {
    /**
     * 
     * @type {Concept}
     * @memberof ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch
     */
    readonly concept: Concept

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch
     */
    readonly objectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch
     */
    readonly projectId: string

    /**
     * 
     * @type {UpdateReviewRequest}
     * @memberof ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch
     */
    readonly updateReviewRequest: UpdateReviewRequest
}

/**
 * Request parameters for updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch operation in ProjectsApi.
 * @export
 * @interface ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch0Request
 */
export interface ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch0Request {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch0
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch0
     */
    readonly testingTestId: string

    /**
     * 
     * @type {UpdateTestingTest}
     * @memberof ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch0
     */
    readonly updateTestingTest: UpdateTestingTest
}

/**
 * Request parameters for uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost operation in ProjectsApi.
 * @export
 * @interface ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePostRequest
 */
export interface ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePostRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePost
     */
    readonly projectId: string

    /**
     * 
     * @type {File}
     * @memberof ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePost
     */
    readonly file: File

    /**
     * The file name.
     * @type {string}
     * @memberof ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePost
     */
    readonly fileName?: string

    /**
     * The description.
     * @type {string}
     * @memberof ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePost
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePost
     */
    readonly componentId?: string | null
}

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * 
     * @summary Add Testing Testing To Control
     * @param {ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(requestParameters: ProjectsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(requestParameters.projectId, requestParameters.controlId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancel all pending reviews for a concept object.  Args:     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.
     * @summary Cancel Pending Reviews
     * @param {ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete(requestParameters: ProjectsApiCancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).cancelPendingReviewsApiProjectsProjectIdReviewsConceptObjectIdDelete(requestParameters.concept, requestParameters.objectId, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new asset_card.  Args:     asset_card (schemas.CreateAssetCard): The asset_card to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the asset_card for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.AssetCard: The created AssetCard.
     * @summary Create Asset Card
     * @param {ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createAssetCardApiProjectsProjectIdAssetCardsPost(requestParameters: ProjectsApiCreateAssetCardApiProjectsProjectIdAssetCardsPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createAssetCardApiProjectsProjectIdAssetCardsPost(requestParameters.projectId, requestParameters.createAssetCard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Args:     object_type (str): The object type. (enums.Concept)     object_id (UUID): The object ID.     comment (schemas.Comment): The comment to create.     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Control: The updated control.
     * @summary Create Comment
     * @param {ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost(requestParameters: ProjectsApiCreateCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createCommentApiProjectsProjectIdCommentsObjectTypeObjectIdPost(requestParameters.objectId, requestParameters.objectType, requestParameters.projectId, requestParameters.createComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new project.  Args:     new_project (CreateProject): The new project.     db (Session): Database session.     user (User): The user creating the project.  Returns:     Project: The created project.
     * @summary Create Project
     * @param {ProjectsApiCreateProjectApiProjectsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProjectApiProjectsPost(requestParameters: ProjectsApiCreateProjectApiProjectsPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProjectApiProjectsPost(requestParameters.createProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new framework for the project with the given id.  Args:     framework_codes (List[str]): The framework codes to create.     response (Response): The response object.     user (User): The user creating the framework.     project (Project): The project to create the framework for.     db (Session): Database session.     cache_service (Cache): The cache service.  Returns:     Framework: The created framework.
     * @summary Create Project Framework
     * @param {ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProjectFrameworkApiProjectsProjectIdFrameworksPost(requestParameters: ProjectsApiCreateProjectFrameworkApiProjectsProjectIdFrameworksPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProjectFrameworkApiProjectsProjectIdFrameworksPost(requestParameters.projectId, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new risk.  Args:     background_tasks:  The background tasks.     risk (schemas.CreateRisk): The risk to create.     response (Response): The response object.     db (Session, optional): The database session.     project (schemas.Project, optional): The project to create the risk for.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The created risk.
     * @summary Create Risk
     * @param {ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createRiskApiProjectsProjectIdRisksPost(requestParameters: ProjectsApiCreateRiskApiProjectsProjectIdRisksPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createRiskApiProjectsProjectIdRisksPost(requestParameters.projectId, requestParameters.createRisk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a monitoring datasource.  Args:     testing_log (TestingLogCreate): Monitoring datasource data.     project (Project): Project.     db (Session): Database session.  Returns:     TestingLog: Monitoring datasource.  Raises:     HTTPException: Project not found.
     * @summary Create Testing Log
     * @param {ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPost0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createTestingLogApiV1ProjectsProjectIdTestingLogsPost(requestParameters: ProjectsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPost0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createTestingLogApiV1ProjectsProjectIdTestingLogsPost(requestParameters.projectId, requestParameters.testingLogCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Testing Metric
     * @param {ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(requestParameters: ProjectsApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(requestParameters.testingSourceId, requestParameters.projectId, requestParameters.testingMetricCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Testing Test
     * @param {ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPost0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createTestingTestApiV1ProjectsProjectIdTestingTestsPost(requestParameters: ProjectsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPost0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createTestingTestApiV1ProjectsProjectIdTestingTestsPost(requestParameters.projectId, requestParameters.createTestingTest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.
     * @summary Delete Project
     * @param {ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProjectApiProjectsProjectIdDelete(requestParameters: ProjectsApiDeleteProjectApiProjectsProjectIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProjectApiProjectsProjectIdDelete(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     ProjectAiSystemLifecycleDashboard: The Lifecycle Dashboard.
     * @summary Get Ai System Life Cycle Dashboard
     * @param {ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet(requestParameters: ProjectsApiGetAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getAiSystemLifeCycleDashboardApiProjectsProjectIdAiSystemLifeCycleDashboardGet(requestParameters.projectId, requestParameters.dashboardContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Args:     object_id (UUID): The object ID.     object_type (enums.Concept): The object type. (enums.Concept)     response (Response): The response object.     project (schemas.Project): The project.     db (Session, optional): The database session.  Returns:     schemas.Comment: The updated control.
     * @summary Get Comments
     * @param {ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet(requestParameters: ProjectsApiGetCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getCommentsApiProjectsProjectIdCommentsObjectTypeObjectIdGet(requestParameters.objectId, requestParameters.objectType, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Control By Code
     * @param {ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getControlByCodeApiProjectsProjectIdControlsControlCodeGet(requestParameters: ProjectsApiGetControlByCodeApiProjectsProjectIdControlsControlCodeGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getControlByCodeApiProjectsProjectIdControlsControlCodeGet(requestParameters.controlCode, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Control Evidences By Code
     * @param {ProjectsApiGetControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGet(requestParameters: ProjectsApiGetControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getControlEvidencesByCodeApiProjectsProjectIdControlsControlCodeEvidencesGet(requestParameters.controlCode, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Controls By Testing Test
     * @param {ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(requestParameters: ProjectsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the file types of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session. Returns:     List[schemas.FileType]: List of file types.
     * @summary Get Evidence File Types
     * @param {ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet(requestParameters: ProjectsApiGetEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getEvidenceFileTypesApiProjectsProjectIdEvidenceFilesTypesGet(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the first 10 evidences (name, description and id) of the project with a name filter. This is used for a dropdown selection.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     List[schemas.EvidencePreview]: List of evidences.
     * @summary Get Evidence List
     * @param {ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getEvidenceListApiProjectsProjectIdEvidenceListGet(requestParameters: ProjectsApiGetEvidenceListApiProjectsProjectIdEvidenceListGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getEvidenceListApiProjectsProjectIdEvidenceListGet(requestParameters.projectId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the evidences of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by evidence name.     params (Params): Pagination params.  Returns:     Page[schemas.Evidence]: Page of evidences.
     * @summary Get Evidences By Project Id
     * @param {ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet(requestParameters: ProjectsApiGetEvidencesByProjectIdApiProjectsProjectIdEvidenceGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getEvidencesByProjectIdApiProjectsProjectIdEvidenceGet(requestParameters.projectId, requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.associatedControls, requestParameters.associatedRisks, requestParameters.startDate, requestParameters.endDate, requestParameters.fileType, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the logs for the given project and object id.  Args:     project_id (UUID): The project ID.     object_id (UUID): The object ID.  Returns:     List[Logs]: The logs.
     * @summary Get Logs
     * @param {ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getLogsApiProjectsProjectIdLogsObjectIdGet(requestParameters: ProjectsApiGetLogsApiProjectsProjectIdLogsObjectIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getLogsApiProjectsProjectIdLogsObjectIdGet(requestParameters.objectId, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the project with the given id. Args:     project_id (UUID): The project ID.  Returns:     Project: The project.
     * @summary Get Project
     * @param {ProjectsApiGetProjectApiProjectsProjectIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectApiProjectsProjectIdGet(requestParameters: ProjectsApiGetProjectApiProjectsProjectIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectApiProjectsProjectIdGet(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the asset_cards of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.AssetCardPage]: List of asset_cards.
     * @summary Get Project Asset Cards
     * @param {ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectAssetCardsApiProjectsProjectIdAssetCardsGet(requestParameters: ProjectsApiGetProjectAssetCardsApiProjectsProjectIdAssetCardsGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectAssetCardsApiProjectsProjectIdAssetCardsGet(requestParameters.projectId, requestParameters.name, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.assetCardType, requestParameters.assetCardNature, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the controls of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.ControlWithoutComponents]: List of controls.
     * @summary Get Project Controls
     * @param {ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectControlsApiProjectsProjectIdControlsGet(requestParameters: ProjectsApiGetProjectControlsApiProjectsProjectIdControlsGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectControlsApiProjectsProjectIdControlsGet(requestParameters.projectId, requestParameters.tags, requestParameters.name, requestParameters.framework, requestParameters.requirement, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.isAssignedToMeForReview, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Project Controls
     * @param {ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectControlsApiV1ProjectsProjectIdControlsGet(requestParameters: ProjectsApiGetProjectControlsApiV1ProjectsProjectIdControlsGet0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectControlsApiV1ProjectsProjectIdControlsGet(requestParameters.projectId, requestParameters.status, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the controls of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
     * @summary Get Project Controls With Evidences
     * @param {ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet(requestParameters: ProjectsApiGetProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectControlsWithEvidencesApiProjectsProjectIdControlsWithEvidencesGet(requestParameters.projectId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the project dashboard for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     Project: The project dashboard.
     * @summary Get Project Dashboard
     * @param {ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectDashboardApiProjectsProjectIdDashboardGet(requestParameters: ProjectsApiGetProjectDashboardApiProjectsProjectIdDashboardGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectDashboardApiProjectsProjectIdDashboardGet(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the frameworks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by framework name.     params (Params): Pagination params.  Returns:     Page[schemas.FrameworkAggregator]: List of frameworks.
     * @summary Get Project Frameworks
     * @param {ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectFrameworksApiProjectsProjectIdFrameworksGet(requestParameters: ProjectsApiGetProjectFrameworksApiProjectsProjectIdFrameworksGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectFrameworksApiProjectsProjectIdFrameworksGet(requestParameters.projectId, requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the project pdf for the given project id.  Args:     project_id (UUID): The project ID.  Returns:     FileResponse: The project pdf.
     * @summary Get Project Pdf
     * @param {ProjectsApiGetProjectPdfApiProjectsProjectIdExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectPdfApiProjectsProjectIdExportGet(requestParameters: ProjectsApiGetProjectPdfApiProjectsProjectIdExportGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectPdfApiProjectsProjectIdExportGet(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the requirements of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     name (str): Filter by requirement name.     framework (str): Filter by framework code.     status (str): Filter by requirement status.     params (Params): Pagination params.  Returns:     Page[schemas.Requirement]: Paginated List of requirements.
     * @summary Get Project Requirements
     * @param {ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectRequirementsApiProjectsProjectIdRequirementsGet(requestParameters: ProjectsApiGetProjectRequirementsApiProjectsProjectIdRequirementsGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectRequirementsApiProjectsProjectIdRequirementsGet(requestParameters.projectId, requestParameters.name, requestParameters.framework, requestParameters.status, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
     * @summary Get Project Risks
     * @param {ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectRisksApiProjectsProjectIdRisksGet(requestParameters: ProjectsApiGetProjectRisksApiProjectsProjectIdRisksGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectRisksApiProjectsProjectIdRisksGet(requestParameters.projectId, requestParameters.name, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the risks of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     Page[schemas.RiskWithoutComponentsAggregator]: List of risks.
     * @summary Get Project Risks List
     * @param {ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectRisksListApiProjectsProjectIdRisksListGet(requestParameters: ProjectsApiGetProjectRisksListApiProjectsProjectIdRisksListGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectRisksListApiProjectsProjectIdRisksListGet(requestParameters.projectId, requestParameters.name, requestParameters.status, requestParameters.isAssignedToMe, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the risks of the project with the given id which have at least one evidence.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.  Returns:     List[schemas.Risk]: List of risks.
     * @summary Get Project Risks With Evidences
     * @param {ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet(requestParameters: ProjectsApiGetProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectRisksWithEvidencesApiProjectsProjectIdRisksWithEvidencesGet(requestParameters.projectId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Reviews For Concept Type
     * @param {ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet(requestParameters: ProjectsApiGetReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getReviewsForConceptTypeApiProjectsProjectIdReviewsConceptGet(requestParameters.concept, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Reviews For Object Id
     * @param {ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet(requestParameters: ProjectsApiGetReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getReviewsForObjectIdApiProjectsProjectIdReviewsConceptObjectIdGet(requestParameters.objectId, requestParameters.concept, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Metric
     * @param {ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(requestParameters: ProjectsApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(requestParameters.testingSourceId, requestParameters.testingMetricId, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Source
     * @param {ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(requestParameters: ProjectsApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(requestParameters.testingSourceId, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Source Metrics
     * @param {ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(requestParameters: ProjectsApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(requestParameters.testingSourceId, requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Sources
     * @param {ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(requestParameters: ProjectsApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Test And Latest Result
     * @param {ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(requestParameters: ProjectsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(requestParameters.projectId, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, requestParameters.name, requestParameters.resultStatus, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Test
     * @param {ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(requestParameters: ProjectsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Test Result
     * @param {ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(requestParameters: ProjectsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(requestParameters.testingTestResultId, requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Test Results
     * @param {ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(requestParameters: ProjectsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(requestParameters.projectId, requestParameters.testingTestId, requestParameters.resultStatus, requestParameters.page, requestParameters.size, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Tests
     * @param {ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(requestParameters: ProjectsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the users of the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     params (Params): Pagination params.     roles (List[enums.Roles]): Filter by role.     exclude_me (bool): Include current user.     sort_by (str): Sort by a field.     descending (bool): Sort order. If true, descending, otherwise ascending.  Returns:     Page[schemas.OrganizationUser]: List of users.
     * @summary Get User Of Project
     * @param {ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getUserOfProjectApiProjectsProjectIdUsersGet(requestParameters: ProjectsApiGetUserOfProjectApiProjectsProjectIdUsersGetRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getUserOfProjectApiProjectsProjectIdUsersGet(requestParameters.projectId, requestParameters.roles, requestParameters.excludeMe, requestParameters.sortBy, requestParameters.descending, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Control From Testing Test
     * @param {ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(requestParameters: ProjectsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(requestParameters.projectId, requestParameters.testingTestId, requestParameters.controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request access to the project with the given id.  Args:     project_id (UUID): The project\'s id.     db (Session): Database session.     user (User): The user requesting access to the project.
     * @summary Request Access
     * @param {ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public requestAccessApiProjectsProjectIdRequestAccessPost(requestParameters: ProjectsApiRequestAccessApiProjectsProjectIdRequestAccessPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).requestAccessApiProjectsProjectIdRequestAccessPost(requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request reviews for a concept object.  Args:     review_request (schemas.ReviewRequest): The review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object to request reviews for. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.     force_update_status (bool, optional): Project owners can force update the         status of the object.  Returns:     List[schemas.Review]: The created reviews.
     * @summary Request Reviews
     * @param {ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost(requestParameters: ProjectsApiRequestReviewsApiProjectsProjectIdReviewsConceptObjectIdPostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).requestReviewsApiProjectsProjectIdReviewsConceptObjectIdPost(requestParameters.concept, requestParameters.objectId, requestParameters.projectId, requestParameters.reviewRequest, requestParameters.forceUpdateStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run Testing Test
     * @param {ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(requestParameters: ProjectsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Take a decision on a review for a concept object.  Args:     project_id (uuid.UUID): The project ID.     concept (enums.Concept): The concept of the object.     review_decision (schemas.ReviewDecision): The review decision.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Review: The updated review.
     * @summary Take Review Decision
     * @param {ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch(requestParameters: ProjectsApiTakeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatchRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).takeReviewDecisionApiProjectsProjectIdReviewsConceptObjectIdDecisionPatch(requestParameters.projectId, requestParameters.concept, requestParameters.objectId, requestParameters.reviewDecision, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the project with the given id.  Args:     project_id (UUID): The project\'s id.     project_update (UpdateProject): The update to the project.     db (Session): Database session.  Returns:     schemas.Project: The updated project.
     * @summary Update Project
     * @param {ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProjectApiProjectsProjectIdPatch(requestParameters: ProjectsApiUpdateProjectApiProjectsProjectIdPatchRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProjectApiProjectsProjectIdPatch(requestParameters.projectId, requestParameters.updateProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the reviewers for a concept object.  Args:     review_request (schemas.UpdateReviewRequest): The update review request.     concept (enums.Concept): The concept of the object.     concept_object (schemas.ControlAggregator | schemas.Risk | schemas.AssetCard, optional): The object. # noqa     db (Session, optional): The database session.     project (schemas.Project, optional): The project.     current_user (schemas.OrganizationUser, optional): The current user.  Returns:     List[schemas.Review]: The updated reviews.
     * @summary Update Reviewers
     * @param {ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch(requestParameters: ProjectsApiUpdateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatchRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateReviewersApiProjectsProjectIdReviewsConceptObjectIdPatch(requestParameters.concept, requestParameters.objectId, requestParameters.projectId, requestParameters.updateReviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Testing Test
     * @param {ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(requestParameters: ProjectsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch0Request, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(requestParameters.projectId, requestParameters.testingTestId, requestParameters.updateTestingTest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uploads the evidence file and creates an entry in the evidence database.  Args:     db (Session): Database session.     project_id (UUID): The project\'s id.     user (User): The user creating the evidence.     file (UploadFile): The file to upload.     component (Component): The component the evidence belongs to.  Returns:     schemas.Evidence: The created evidence.
     * @summary Upload And Create Evidence
     * @param {ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost(requestParameters: ProjectsApiUploadAndCreateEvidenceApiProjectsProjectIdEvidencePostRequest, options?: RawAxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).uploadAndCreateEvidenceApiProjectsProjectIdEvidencePost(requestParameters.projectId, requestParameters.file, requestParameters.fileName, requestParameters.description, requestParameters.componentId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RequirementsApi - axios parameter creator
 * @export
 */
export const RequirementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the requirement with the given id. Args:     requirement_id (UUID): The requirement\'s id.  Returns:     requirement: The requirement with the given id.
         * @summary Get Requirement
         * @param {string} requirementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequirementApiRequirementsRequirementIdGet: async (requirementId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requirementId' is not null or undefined
            assertParamExists('getRequirementApiRequirementsRequirementIdGet', 'requirementId', requirementId)
            const localVarPath = `/api/requirements/{requirement_id}`
                .replace(`{${"requirement_id"}}`, encodeURIComponent(String(requirementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Requirement
         * @param {string} requirementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequirementApiV1RequirementsRequirementIdGet: async (requirementId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requirementId' is not null or undefined
            assertParamExists('getRequirementApiV1RequirementsRequirementIdGet', 'requirementId', requirementId)
            const localVarPath = `/api/v1/requirements/{requirement_id}`
                .replace(`{${"requirement_id"}}`, encodeURIComponent(String(requirementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return a single risk.  Args:     risk (schemas.Risk, optional): The risk to return.         Defaults to Depends(dependencies.get_risk_by_id).  Returns:     schemas.Risk: The risk to return.
         * @summary Get Risk
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskApiV1RisksRiskIdGet: async (riskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('getRiskApiV1RisksRiskIdGet', 'riskId', riskId)
            const localVarPath = `/api/v1/risks/{risk_id}`
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequirementsApi - functional programming interface
 * @export
 */
export const RequirementsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RequirementsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the requirement with the given id. Args:     requirement_id (UUID): The requirement\'s id.  Returns:     requirement: The requirement with the given id.
         * @summary Get Requirement
         * @param {string} requirementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequirementApiRequirementsRequirementIdGet(requirementId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequirementAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRequirementApiRequirementsRequirementIdGet(requirementId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RequirementsApi.getRequirementApiRequirementsRequirementIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Requirement
         * @param {string} requirementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequirementApiV1RequirementsRequirementIdGet(requirementId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Requirement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRequirementApiV1RequirementsRequirementIdGet(requirementId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RequirementsApi.getRequirementApiV1RequirementsRequirementIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return a single risk.  Args:     risk (schemas.Risk, optional): The risk to return.         Defaults to Depends(dependencies.get_risk_by_id).  Returns:     schemas.Risk: The risk to return.
         * @summary Get Risk
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRiskApiV1RisksRiskIdGet(riskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Risk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskApiV1RisksRiskIdGet(riskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RequirementsApi.getRiskApiV1RisksRiskIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RequirementsApi - factory interface
 * @export
 */
export const RequirementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequirementsApiFp(configuration)
    return {
        /**
         * Returns the requirement with the given id. Args:     requirement_id (UUID): The requirement\'s id.  Returns:     requirement: The requirement with the given id.
         * @summary Get Requirement
         * @param {RequirementsApiGetRequirementApiRequirementsRequirementIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequirementApiRequirementsRequirementIdGet(requestParameters: RequirementsApiGetRequirementApiRequirementsRequirementIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RequirementAggregator> {
            return localVarFp.getRequirementApiRequirementsRequirementIdGet(requestParameters.requirementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Requirement
         * @param {RequirementsApiGetRequirementApiV1RequirementsRequirementIdGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequirementApiV1RequirementsRequirementIdGet(requestParameters: RequirementsApiGetRequirementApiV1RequirementsRequirementIdGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<Requirement> {
            return localVarFp.getRequirementApiV1RequirementsRequirementIdGet(requestParameters.requirementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return a single risk.  Args:     risk (schemas.Risk, optional): The risk to return.         Defaults to Depends(dependencies.get_risk_by_id).  Returns:     schemas.Risk: The risk to return.
         * @summary Get Risk
         * @param {RequirementsApiGetRiskApiV1RisksRiskIdGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskApiV1RisksRiskIdGet(requestParameters: RequirementsApiGetRiskApiV1RisksRiskIdGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<Risk> {
            return localVarFp.getRiskApiV1RisksRiskIdGet(requestParameters.riskId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRequirementApiRequirementsRequirementIdGet operation in RequirementsApi.
 * @export
 * @interface RequirementsApiGetRequirementApiRequirementsRequirementIdGetRequest
 */
export interface RequirementsApiGetRequirementApiRequirementsRequirementIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof RequirementsApiGetRequirementApiRequirementsRequirementIdGet
     */
    readonly requirementId: string
}

/**
 * Request parameters for getRequirementApiV1RequirementsRequirementIdGet operation in RequirementsApi.
 * @export
 * @interface RequirementsApiGetRequirementApiV1RequirementsRequirementIdGet0Request
 */
export interface RequirementsApiGetRequirementApiV1RequirementsRequirementIdGet0Request {
    /**
     * 
     * @type {string}
     * @memberof RequirementsApiGetRequirementApiV1RequirementsRequirementIdGet0
     */
    readonly requirementId: string
}

/**
 * Request parameters for getRiskApiV1RisksRiskIdGet operation in RequirementsApi.
 * @export
 * @interface RequirementsApiGetRiskApiV1RisksRiskIdGet0Request
 */
export interface RequirementsApiGetRiskApiV1RisksRiskIdGet0Request {
    /**
     * 
     * @type {string}
     * @memberof RequirementsApiGetRiskApiV1RisksRiskIdGet0
     */
    readonly riskId: string
}

/**
 * RequirementsApi - object-oriented interface
 * @export
 * @class RequirementsApi
 * @extends {BaseAPI}
 */
export class RequirementsApi extends BaseAPI {
    /**
     * Returns the requirement with the given id. Args:     requirement_id (UUID): The requirement\'s id.  Returns:     requirement: The requirement with the given id.
     * @summary Get Requirement
     * @param {RequirementsApiGetRequirementApiRequirementsRequirementIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequirementsApi
     */
    public getRequirementApiRequirementsRequirementIdGet(requestParameters: RequirementsApiGetRequirementApiRequirementsRequirementIdGetRequest, options?: RawAxiosRequestConfig) {
        return RequirementsApiFp(this.configuration).getRequirementApiRequirementsRequirementIdGet(requestParameters.requirementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Requirement
     * @param {RequirementsApiGetRequirementApiV1RequirementsRequirementIdGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequirementsApi
     */
    public getRequirementApiV1RequirementsRequirementIdGet(requestParameters: RequirementsApiGetRequirementApiV1RequirementsRequirementIdGet0Request, options?: RawAxiosRequestConfig) {
        return RequirementsApiFp(this.configuration).getRequirementApiV1RequirementsRequirementIdGet(requestParameters.requirementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return a single risk.  Args:     risk (schemas.Risk, optional): The risk to return.         Defaults to Depends(dependencies.get_risk_by_id).  Returns:     schemas.Risk: The risk to return.
     * @summary Get Risk
     * @param {RequirementsApiGetRiskApiV1RisksRiskIdGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequirementsApi
     */
    public getRiskApiV1RisksRiskIdGet(requestParameters: RequirementsApiGetRiskApiV1RisksRiskIdGet0Request, options?: RawAxiosRequestConfig) {
        return RequirementsApiFp(this.configuration).getRiskApiV1RisksRiskIdGet(requestParameters.riskId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RisksApi - axios parameter creator
 * @export
 */
export const RisksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create the treatment of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to create the treatment for.     new_risk_treatment (schemas.RiskTreatment): The new risk treatment.  Returns:     schemas.RiskTreatmentProps: The risk treatment.
         * @summary Create Risk Treatment
         * @param {string} riskId 
         * @param {RiskTreatmentProps} riskTreatmentProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRiskTreatmentApiRisksRiskIdTreatmentPost: async (riskId: string, riskTreatmentProps: RiskTreatmentProps, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('createRiskTreatmentApiRisksRiskIdTreatmentPost', 'riskId', riskId)
            // verify required parameter 'riskTreatmentProps' is not null or undefined
            assertParamExists('createRiskTreatmentApiRisksRiskIdTreatmentPost', 'riskTreatmentProps', riskTreatmentProps)
            const localVarPath = `/api/risks/{risk_id}/treatment`
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(riskTreatmentProps, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create the treatment strategy of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to create the treatment strategy for.     new_strategy (schemas.RiskTreatmentStrategyProps): The new risk treatment strategy. # noqa: E501  Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategy.
         * @summary Create Risk Treatment Strategy
         * @param {string} riskId 
         * @param {RiskTreatmentStrategyProps} riskTreatmentStrategyProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost: async (riskId: string, riskTreatmentStrategyProps: RiskTreatmentStrategyProps, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('createRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost', 'riskId', riskId)
            // verify required parameter 'riskTreatmentStrategyProps' is not null or undefined
            assertParamExists('createRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost', 'riskTreatmentStrategyProps', riskTreatmentStrategyProps)
            const localVarPath = `/api/risks/{risk_id}/treatment/strategies`
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(riskTreatmentStrategyProps, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the treatment strategy of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to delete the treatment strategy for.     strategy_id (uuid.UUID Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategy.
         * @summary Delete Risk Treatment Strategy
         * @param {string} strategyId 
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete: async (strategyId: string, riskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'strategyId' is not null or undefined
            assertParamExists('deleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete', 'strategyId', strategyId)
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('deleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete', 'riskId', riskId)
            const localVarPath = `/api/risks/{risk_id}/treatment/strategies/{strategy_id}`
                .replace(`{${"strategy_id"}}`, encodeURIComponent(String(strategyId)))
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the controls of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to get the controls for.  Returns:     list[schemas.Control]: The controls.
         * @summary Get Controls By Risk
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlsByRiskApiRisksRiskIdControlsGet: async (riskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('getControlsByRiskApiRisksRiskIdControlsGet', 'riskId', riskId)
            const localVarPath = `/api/risks/{risk_id}/controls`
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return a single risk.  Args:     risk (schemas.Risk, optional): The risk to return.         Defaults to Depends(dependencies.get_risk_by_id).  Returns:     schemas.Risk: The risk to return.
         * @summary Get Risk
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskApiRisksRiskIdGet: async (riskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('getRiskApiRisksRiskIdGet', 'riskId', riskId)
            const localVarPath = `/api/risks/{risk_id}`
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the treatment of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to get the treatment for.  Returns:     schemas.RiskTreatment: The risk treatment.
         * @summary Get Risk Treatment
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskTreatmentApiRisksRiskIdTreatmentGet: async (riskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('getRiskTreatmentApiRisksRiskIdTreatmentGet', 'riskId', riskId)
            const localVarPath = `/api/risks/{risk_id}/treatment`
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the treatment strategies of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to get the treatment strategies for.  Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategies.
         * @summary Get Risk Treatment Strategy
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGet: async (riskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('getRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGet', 'riskId', riskId)
            const localVarPath = `/api/risks/{risk_id}/treatment/strategies`
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove a risk from a control.  Args:     control (schemas.Control): The control to remove the risk from.     risk (schemas.Risk): The risk to remove from the control.  Returns:     schemas.Risk: The risks for the control.
         * @summary Remove Control From Risk
         * @param {string} controlId 
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeControlFromRiskApiRisksRiskIdControlsControlIdDelete: async (controlId: string, riskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('removeControlFromRiskApiRisksRiskIdControlsControlIdDelete', 'controlId', controlId)
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('removeControlFromRiskApiRisksRiskIdControlsControlIdDelete', 'riskId', riskId)
            const localVarPath = `/api/risks/{risk_id}/controls/{control_id}`
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)))
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the risk with the given id.  Args:     update_risk (schemas.UpdateRisk): The updated risk.     risk (schemas.Risk, optional): The risk to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The updated risk.
         * @summary Update Risk
         * @param {string} riskId 
         * @param {UpdateRisk} updateRisk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRiskApiRisksRiskIdPatch: async (riskId: string, updateRisk: UpdateRisk, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('updateRiskApiRisksRiskIdPatch', 'riskId', riskId)
            // verify required parameter 'updateRisk' is not null or undefined
            assertParamExists('updateRiskApiRisksRiskIdPatch', 'updateRisk', updateRisk)
            const localVarPath = `/api/risks/{risk_id}`
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRisk, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the assignee of the risk to the user with the given user_id.  Args:     assignee (schemas.OrganizationUser): The user to assign the risk to.     db (Session, optional): The database session.     risk_id (uuid.UUID, optional): The id of the risk to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The updated risk.
         * @summary Update Risk Assignee
         * @param {string} riskId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRiskAssigneeApiRisksRiskIdAssigneePatch: async (riskId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('updateRiskAssigneeApiRisksRiskIdAssigneePatch', 'riskId', riskId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateRiskAssigneeApiRisksRiskIdAssigneePatch', 'userId', userId)
            const localVarPath = `/api/risks/{risk_id}/assignee`
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the treatment of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to update the treatment for.     risk_treatment (schemas.RiskTreatment): The updated risk treatment.  Returns:     schemas.RiskTreatment: The risk treatment.
         * @summary Update Risk Treatment
         * @param {string} riskId 
         * @param {RiskTreatmentProps} riskTreatmentProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRiskTreatmentApiRisksRiskIdTreatmentPatch: async (riskId: string, riskTreatmentProps: RiskTreatmentProps, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('updateRiskTreatmentApiRisksRiskIdTreatmentPatch', 'riskId', riskId)
            // verify required parameter 'riskTreatmentProps' is not null or undefined
            assertParamExists('updateRiskTreatmentApiRisksRiskIdTreatmentPatch', 'riskTreatmentProps', riskTreatmentProps)
            const localVarPath = `/api/risks/{risk_id}/treatment`
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(riskTreatmentProps, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create the treatment strategy of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to create the treatment strategy for.     new_strategy (schemas.RiskTreatmentStrategy): The new risk treatment strategy.  Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategy.
         * @summary Update Risk Treatment Strategy
         * @param {string} strategyId 
         * @param {string} riskId 
         * @param {RiskTreatmentStrategyProps} riskTreatmentStrategyProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch: async (strategyId: string, riskId: string, riskTreatmentStrategyProps: RiskTreatmentStrategyProps, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'strategyId' is not null or undefined
            assertParamExists('updateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch', 'strategyId', strategyId)
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('updateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch', 'riskId', riskId)
            // verify required parameter 'riskTreatmentStrategyProps' is not null or undefined
            assertParamExists('updateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch', 'riskTreatmentStrategyProps', riskTreatmentStrategyProps)
            const localVarPath = `/api/risks/{risk_id}/treatment/strategies/{strategy_id}`
                .replace(`{${"strategy_id"}}`, encodeURIComponent(String(strategyId)))
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(riskTreatmentStrategyProps, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RisksApi - functional programming interface
 * @export
 */
export const RisksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RisksApiAxiosParamCreator(configuration)
    return {
        /**
         * Create the treatment of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to create the treatment for.     new_risk_treatment (schemas.RiskTreatment): The new risk treatment.  Returns:     schemas.RiskTreatmentProps: The risk treatment.
         * @summary Create Risk Treatment
         * @param {string} riskId 
         * @param {RiskTreatmentProps} riskTreatmentProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRiskTreatmentApiRisksRiskIdTreatmentPost(riskId: string, riskTreatmentProps: RiskTreatmentProps, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskTreatment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRiskTreatmentApiRisksRiskIdTreatmentPost(riskId, riskTreatmentProps, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RisksApi.createRiskTreatmentApiRisksRiskIdTreatmentPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create the treatment strategy of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to create the treatment strategy for.     new_strategy (schemas.RiskTreatmentStrategyProps): The new risk treatment strategy. # noqa: E501  Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategy.
         * @summary Create Risk Treatment Strategy
         * @param {string} riskId 
         * @param {RiskTreatmentStrategyProps} riskTreatmentStrategyProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost(riskId: string, riskTreatmentStrategyProps: RiskTreatmentStrategyProps, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskTreatmentStrategy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost(riskId, riskTreatmentStrategyProps, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RisksApi.createRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete the treatment strategy of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to delete the treatment strategy for.     strategy_id (uuid.UUID Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategy.
         * @summary Delete Risk Treatment Strategy
         * @param {string} strategyId 
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete(strategyId: string, riskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete(strategyId, riskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RisksApi.deleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the controls of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to get the controls for.  Returns:     list[schemas.Control]: The controls.
         * @summary Get Controls By Risk
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlsByRiskApiRisksRiskIdControlsGet(riskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Control>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlsByRiskApiRisksRiskIdControlsGet(riskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RisksApi.getControlsByRiskApiRisksRiskIdControlsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return a single risk.  Args:     risk (schemas.Risk, optional): The risk to return.         Defaults to Depends(dependencies.get_risk_by_id).  Returns:     schemas.Risk: The risk to return.
         * @summary Get Risk
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRiskApiRisksRiskIdGet(riskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskApiRisksRiskIdGet(riskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RisksApi.getRiskApiRisksRiskIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the treatment of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to get the treatment for.  Returns:     schemas.RiskTreatment: The risk treatment.
         * @summary Get Risk Treatment
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRiskTreatmentApiRisksRiskIdTreatmentGet(riskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskTreatment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskTreatmentApiRisksRiskIdTreatmentGet(riskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RisksApi.getRiskTreatmentApiRisksRiskIdTreatmentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the treatment strategies of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to get the treatment strategies for.  Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategies.
         * @summary Get Risk Treatment Strategy
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGet(riskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RiskTreatmentStrategy>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGet(riskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RisksApi.getRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Remove a risk from a control.  Args:     control (schemas.Control): The control to remove the risk from.     risk (schemas.Risk): The risk to remove from the control.  Returns:     schemas.Risk: The risks for the control.
         * @summary Remove Control From Risk
         * @param {string} controlId 
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeControlFromRiskApiRisksRiskIdControlsControlIdDelete(controlId: string, riskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeControlFromRiskApiRisksRiskIdControlsControlIdDelete(controlId, riskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RisksApi.removeControlFromRiskApiRisksRiskIdControlsControlIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the risk with the given id.  Args:     update_risk (schemas.UpdateRisk): The updated risk.     risk (schemas.Risk, optional): The risk to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The updated risk.
         * @summary Update Risk
         * @param {string} riskId 
         * @param {UpdateRisk} updateRisk 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRiskApiRisksRiskIdPatch(riskId: string, updateRisk: UpdateRisk, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRiskApiRisksRiskIdPatch(riskId, updateRisk, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RisksApi.updateRiskApiRisksRiskIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the assignee of the risk to the user with the given user_id.  Args:     assignee (schemas.OrganizationUser): The user to assign the risk to.     db (Session, optional): The database session.     risk_id (uuid.UUID, optional): The id of the risk to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The updated risk.
         * @summary Update Risk Assignee
         * @param {string} riskId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRiskAssigneeApiRisksRiskIdAssigneePatch(riskId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskAggregator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRiskAssigneeApiRisksRiskIdAssigneePatch(riskId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RisksApi.updateRiskAssigneeApiRisksRiskIdAssigneePatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update the treatment of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to update the treatment for.     risk_treatment (schemas.RiskTreatment): The updated risk treatment.  Returns:     schemas.RiskTreatment: The risk treatment.
         * @summary Update Risk Treatment
         * @param {string} riskId 
         * @param {RiskTreatmentProps} riskTreatmentProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRiskTreatmentApiRisksRiskIdTreatmentPatch(riskId: string, riskTreatmentProps: RiskTreatmentProps, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskTreatment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRiskTreatmentApiRisksRiskIdTreatmentPatch(riskId, riskTreatmentProps, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RisksApi.updateRiskTreatmentApiRisksRiskIdTreatmentPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create the treatment strategy of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to create the treatment strategy for.     new_strategy (schemas.RiskTreatmentStrategy): The new risk treatment strategy.  Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategy.
         * @summary Update Risk Treatment Strategy
         * @param {string} strategyId 
         * @param {string} riskId 
         * @param {RiskTreatmentStrategyProps} riskTreatmentStrategyProps 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch(strategyId: string, riskId: string, riskTreatmentStrategyProps: RiskTreatmentStrategyProps, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskTreatmentStrategy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch(strategyId, riskId, riskTreatmentStrategyProps, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RisksApi.updateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RisksApi - factory interface
 * @export
 */
export const RisksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RisksApiFp(configuration)
    return {
        /**
         * Create the treatment of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to create the treatment for.     new_risk_treatment (schemas.RiskTreatment): The new risk treatment.  Returns:     schemas.RiskTreatmentProps: The risk treatment.
         * @summary Create Risk Treatment
         * @param {RisksApiCreateRiskTreatmentApiRisksRiskIdTreatmentPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRiskTreatmentApiRisksRiskIdTreatmentPost(requestParameters: RisksApiCreateRiskTreatmentApiRisksRiskIdTreatmentPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<RiskTreatment> {
            return localVarFp.createRiskTreatmentApiRisksRiskIdTreatmentPost(requestParameters.riskId, requestParameters.riskTreatmentProps, options).then((request) => request(axios, basePath));
        },
        /**
         * Create the treatment strategy of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to create the treatment strategy for.     new_strategy (schemas.RiskTreatmentStrategyProps): The new risk treatment strategy. # noqa: E501  Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategy.
         * @summary Create Risk Treatment Strategy
         * @param {RisksApiCreateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost(requestParameters: RisksApiCreateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<RiskTreatmentStrategy> {
            return localVarFp.createRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost(requestParameters.riskId, requestParameters.riskTreatmentStrategyProps, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the treatment strategy of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to delete the treatment strategy for.     strategy_id (uuid.UUID Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategy.
         * @summary Delete Risk Treatment Strategy
         * @param {RisksApiDeleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete(requestParameters: RisksApiDeleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete(requestParameters.strategyId, requestParameters.riskId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the controls of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to get the controls for.  Returns:     list[schemas.Control]: The controls.
         * @summary Get Controls By Risk
         * @param {RisksApiGetControlsByRiskApiRisksRiskIdControlsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlsByRiskApiRisksRiskIdControlsGet(requestParameters: RisksApiGetControlsByRiskApiRisksRiskIdControlsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Control>> {
            return localVarFp.getControlsByRiskApiRisksRiskIdControlsGet(requestParameters.riskId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return a single risk.  Args:     risk (schemas.Risk, optional): The risk to return.         Defaults to Depends(dependencies.get_risk_by_id).  Returns:     schemas.Risk: The risk to return.
         * @summary Get Risk
         * @param {RisksApiGetRiskApiRisksRiskIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskApiRisksRiskIdGet(requestParameters: RisksApiGetRiskApiRisksRiskIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RiskAggregator> {
            return localVarFp.getRiskApiRisksRiskIdGet(requestParameters.riskId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the treatment of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to get the treatment for.  Returns:     schemas.RiskTreatment: The risk treatment.
         * @summary Get Risk Treatment
         * @param {RisksApiGetRiskTreatmentApiRisksRiskIdTreatmentGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskTreatmentApiRisksRiskIdTreatmentGet(requestParameters: RisksApiGetRiskTreatmentApiRisksRiskIdTreatmentGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<RiskTreatment> {
            return localVarFp.getRiskTreatmentApiRisksRiskIdTreatmentGet(requestParameters.riskId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the treatment strategies of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to get the treatment strategies for.  Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategies.
         * @summary Get Risk Treatment Strategy
         * @param {RisksApiGetRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGet(requestParameters: RisksApiGetRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<RiskTreatmentStrategy>> {
            return localVarFp.getRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGet(requestParameters.riskId, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove a risk from a control.  Args:     control (schemas.Control): The control to remove the risk from.     risk (schemas.Risk): The risk to remove from the control.  Returns:     schemas.Risk: The risks for the control.
         * @summary Remove Control From Risk
         * @param {RisksApiRemoveControlFromRiskApiRisksRiskIdControlsControlIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeControlFromRiskApiRisksRiskIdControlsControlIdDelete(requestParameters: RisksApiRemoveControlFromRiskApiRisksRiskIdControlsControlIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeControlFromRiskApiRisksRiskIdControlsControlIdDelete(requestParameters.controlId, requestParameters.riskId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the risk with the given id.  Args:     update_risk (schemas.UpdateRisk): The updated risk.     risk (schemas.Risk, optional): The risk to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The updated risk.
         * @summary Update Risk
         * @param {RisksApiUpdateRiskApiRisksRiskIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRiskApiRisksRiskIdPatch(requestParameters: RisksApiUpdateRiskApiRisksRiskIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<RiskAggregator> {
            return localVarFp.updateRiskApiRisksRiskIdPatch(requestParameters.riskId, requestParameters.updateRisk, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the assignee of the risk to the user with the given user_id.  Args:     assignee (schemas.OrganizationUser): The user to assign the risk to.     db (Session, optional): The database session.     risk_id (uuid.UUID, optional): The id of the risk to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The updated risk.
         * @summary Update Risk Assignee
         * @param {RisksApiUpdateRiskAssigneeApiRisksRiskIdAssigneePatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRiskAssigneeApiRisksRiskIdAssigneePatch(requestParameters: RisksApiUpdateRiskAssigneeApiRisksRiskIdAssigneePatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<RiskAggregator> {
            return localVarFp.updateRiskAssigneeApiRisksRiskIdAssigneePatch(requestParameters.riskId, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the treatment of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to update the treatment for.     risk_treatment (schemas.RiskTreatment): The updated risk treatment.  Returns:     schemas.RiskTreatment: The risk treatment.
         * @summary Update Risk Treatment
         * @param {RisksApiUpdateRiskTreatmentApiRisksRiskIdTreatmentPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRiskTreatmentApiRisksRiskIdTreatmentPatch(requestParameters: RisksApiUpdateRiskTreatmentApiRisksRiskIdTreatmentPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<RiskTreatment> {
            return localVarFp.updateRiskTreatmentApiRisksRiskIdTreatmentPatch(requestParameters.riskId, requestParameters.riskTreatmentProps, options).then((request) => request(axios, basePath));
        },
        /**
         * Create the treatment strategy of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to create the treatment strategy for.     new_strategy (schemas.RiskTreatmentStrategy): The new risk treatment strategy.  Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategy.
         * @summary Update Risk Treatment Strategy
         * @param {RisksApiUpdateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch(requestParameters: RisksApiUpdateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<RiskTreatmentStrategy> {
            return localVarFp.updateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch(requestParameters.strategyId, requestParameters.riskId, requestParameters.riskTreatmentStrategyProps, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRiskTreatmentApiRisksRiskIdTreatmentPost operation in RisksApi.
 * @export
 * @interface RisksApiCreateRiskTreatmentApiRisksRiskIdTreatmentPostRequest
 */
export interface RisksApiCreateRiskTreatmentApiRisksRiskIdTreatmentPostRequest {
    /**
     * 
     * @type {string}
     * @memberof RisksApiCreateRiskTreatmentApiRisksRiskIdTreatmentPost
     */
    readonly riskId: string

    /**
     * 
     * @type {RiskTreatmentProps}
     * @memberof RisksApiCreateRiskTreatmentApiRisksRiskIdTreatmentPost
     */
    readonly riskTreatmentProps: RiskTreatmentProps
}

/**
 * Request parameters for createRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost operation in RisksApi.
 * @export
 * @interface RisksApiCreateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPostRequest
 */
export interface RisksApiCreateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof RisksApiCreateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost
     */
    readonly riskId: string

    /**
     * 
     * @type {RiskTreatmentStrategyProps}
     * @memberof RisksApiCreateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost
     */
    readonly riskTreatmentStrategyProps: RiskTreatmentStrategyProps
}

/**
 * Request parameters for deleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete operation in RisksApi.
 * @export
 * @interface RisksApiDeleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDeleteRequest
 */
export interface RisksApiDeleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof RisksApiDeleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete
     */
    readonly strategyId: string

    /**
     * 
     * @type {string}
     * @memberof RisksApiDeleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete
     */
    readonly riskId: string
}

/**
 * Request parameters for getControlsByRiskApiRisksRiskIdControlsGet operation in RisksApi.
 * @export
 * @interface RisksApiGetControlsByRiskApiRisksRiskIdControlsGetRequest
 */
export interface RisksApiGetControlsByRiskApiRisksRiskIdControlsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof RisksApiGetControlsByRiskApiRisksRiskIdControlsGet
     */
    readonly riskId: string
}

/**
 * Request parameters for getRiskApiRisksRiskIdGet operation in RisksApi.
 * @export
 * @interface RisksApiGetRiskApiRisksRiskIdGetRequest
 */
export interface RisksApiGetRiskApiRisksRiskIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof RisksApiGetRiskApiRisksRiskIdGet
     */
    readonly riskId: string
}

/**
 * Request parameters for getRiskTreatmentApiRisksRiskIdTreatmentGet operation in RisksApi.
 * @export
 * @interface RisksApiGetRiskTreatmentApiRisksRiskIdTreatmentGetRequest
 */
export interface RisksApiGetRiskTreatmentApiRisksRiskIdTreatmentGetRequest {
    /**
     * 
     * @type {string}
     * @memberof RisksApiGetRiskTreatmentApiRisksRiskIdTreatmentGet
     */
    readonly riskId: string
}

/**
 * Request parameters for getRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGet operation in RisksApi.
 * @export
 * @interface RisksApiGetRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGetRequest
 */
export interface RisksApiGetRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof RisksApiGetRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGet
     */
    readonly riskId: string
}

/**
 * Request parameters for removeControlFromRiskApiRisksRiskIdControlsControlIdDelete operation in RisksApi.
 * @export
 * @interface RisksApiRemoveControlFromRiskApiRisksRiskIdControlsControlIdDeleteRequest
 */
export interface RisksApiRemoveControlFromRiskApiRisksRiskIdControlsControlIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof RisksApiRemoveControlFromRiskApiRisksRiskIdControlsControlIdDelete
     */
    readonly controlId: string

    /**
     * 
     * @type {string}
     * @memberof RisksApiRemoveControlFromRiskApiRisksRiskIdControlsControlIdDelete
     */
    readonly riskId: string
}

/**
 * Request parameters for updateRiskApiRisksRiskIdPatch operation in RisksApi.
 * @export
 * @interface RisksApiUpdateRiskApiRisksRiskIdPatchRequest
 */
export interface RisksApiUpdateRiskApiRisksRiskIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof RisksApiUpdateRiskApiRisksRiskIdPatch
     */
    readonly riskId: string

    /**
     * 
     * @type {UpdateRisk}
     * @memberof RisksApiUpdateRiskApiRisksRiskIdPatch
     */
    readonly updateRisk: UpdateRisk
}

/**
 * Request parameters for updateRiskAssigneeApiRisksRiskIdAssigneePatch operation in RisksApi.
 * @export
 * @interface RisksApiUpdateRiskAssigneeApiRisksRiskIdAssigneePatchRequest
 */
export interface RisksApiUpdateRiskAssigneeApiRisksRiskIdAssigneePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof RisksApiUpdateRiskAssigneeApiRisksRiskIdAssigneePatch
     */
    readonly riskId: string

    /**
     * 
     * @type {string}
     * @memberof RisksApiUpdateRiskAssigneeApiRisksRiskIdAssigneePatch
     */
    readonly userId: string
}

/**
 * Request parameters for updateRiskTreatmentApiRisksRiskIdTreatmentPatch operation in RisksApi.
 * @export
 * @interface RisksApiUpdateRiskTreatmentApiRisksRiskIdTreatmentPatchRequest
 */
export interface RisksApiUpdateRiskTreatmentApiRisksRiskIdTreatmentPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof RisksApiUpdateRiskTreatmentApiRisksRiskIdTreatmentPatch
     */
    readonly riskId: string

    /**
     * 
     * @type {RiskTreatmentProps}
     * @memberof RisksApiUpdateRiskTreatmentApiRisksRiskIdTreatmentPatch
     */
    readonly riskTreatmentProps: RiskTreatmentProps
}

/**
 * Request parameters for updateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch operation in RisksApi.
 * @export
 * @interface RisksApiUpdateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatchRequest
 */
export interface RisksApiUpdateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof RisksApiUpdateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch
     */
    readonly strategyId: string

    /**
     * 
     * @type {string}
     * @memberof RisksApiUpdateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch
     */
    readonly riskId: string

    /**
     * 
     * @type {RiskTreatmentStrategyProps}
     * @memberof RisksApiUpdateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch
     */
    readonly riskTreatmentStrategyProps: RiskTreatmentStrategyProps
}

/**
 * RisksApi - object-oriented interface
 * @export
 * @class RisksApi
 * @extends {BaseAPI}
 */
export class RisksApi extends BaseAPI {
    /**
     * Create the treatment of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to create the treatment for.     new_risk_treatment (schemas.RiskTreatment): The new risk treatment.  Returns:     schemas.RiskTreatmentProps: The risk treatment.
     * @summary Create Risk Treatment
     * @param {RisksApiCreateRiskTreatmentApiRisksRiskIdTreatmentPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public createRiskTreatmentApiRisksRiskIdTreatmentPost(requestParameters: RisksApiCreateRiskTreatmentApiRisksRiskIdTreatmentPostRequest, options?: RawAxiosRequestConfig) {
        return RisksApiFp(this.configuration).createRiskTreatmentApiRisksRiskIdTreatmentPost(requestParameters.riskId, requestParameters.riskTreatmentProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create the treatment strategy of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to create the treatment strategy for.     new_strategy (schemas.RiskTreatmentStrategyProps): The new risk treatment strategy. # noqa: E501  Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategy.
     * @summary Create Risk Treatment Strategy
     * @param {RisksApiCreateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public createRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost(requestParameters: RisksApiCreateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPostRequest, options?: RawAxiosRequestConfig) {
        return RisksApiFp(this.configuration).createRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesPost(requestParameters.riskId, requestParameters.riskTreatmentStrategyProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the treatment strategy of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to delete the treatment strategy for.     strategy_id (uuid.UUID Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategy.
     * @summary Delete Risk Treatment Strategy
     * @param {RisksApiDeleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public deleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete(requestParameters: RisksApiDeleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return RisksApiFp(this.configuration).deleteRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdDelete(requestParameters.strategyId, requestParameters.riskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the controls of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to get the controls for.  Returns:     list[schemas.Control]: The controls.
     * @summary Get Controls By Risk
     * @param {RisksApiGetControlsByRiskApiRisksRiskIdControlsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public getControlsByRiskApiRisksRiskIdControlsGet(requestParameters: RisksApiGetControlsByRiskApiRisksRiskIdControlsGetRequest, options?: RawAxiosRequestConfig) {
        return RisksApiFp(this.configuration).getControlsByRiskApiRisksRiskIdControlsGet(requestParameters.riskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return a single risk.  Args:     risk (schemas.Risk, optional): The risk to return.         Defaults to Depends(dependencies.get_risk_by_id).  Returns:     schemas.Risk: The risk to return.
     * @summary Get Risk
     * @param {RisksApiGetRiskApiRisksRiskIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public getRiskApiRisksRiskIdGet(requestParameters: RisksApiGetRiskApiRisksRiskIdGetRequest, options?: RawAxiosRequestConfig) {
        return RisksApiFp(this.configuration).getRiskApiRisksRiskIdGet(requestParameters.riskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the treatment of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to get the treatment for.  Returns:     schemas.RiskTreatment: The risk treatment.
     * @summary Get Risk Treatment
     * @param {RisksApiGetRiskTreatmentApiRisksRiskIdTreatmentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public getRiskTreatmentApiRisksRiskIdTreatmentGet(requestParameters: RisksApiGetRiskTreatmentApiRisksRiskIdTreatmentGetRequest, options?: RawAxiosRequestConfig) {
        return RisksApiFp(this.configuration).getRiskTreatmentApiRisksRiskIdTreatmentGet(requestParameters.riskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the treatment strategies of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to get the treatment strategies for.  Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategies.
     * @summary Get Risk Treatment Strategy
     * @param {RisksApiGetRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public getRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGet(requestParameters: RisksApiGetRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGetRequest, options?: RawAxiosRequestConfig) {
        return RisksApiFp(this.configuration).getRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesGet(requestParameters.riskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove a risk from a control.  Args:     control (schemas.Control): The control to remove the risk from.     risk (schemas.Risk): The risk to remove from the control.  Returns:     schemas.Risk: The risks for the control.
     * @summary Remove Control From Risk
     * @param {RisksApiRemoveControlFromRiskApiRisksRiskIdControlsControlIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public removeControlFromRiskApiRisksRiskIdControlsControlIdDelete(requestParameters: RisksApiRemoveControlFromRiskApiRisksRiskIdControlsControlIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return RisksApiFp(this.configuration).removeControlFromRiskApiRisksRiskIdControlsControlIdDelete(requestParameters.controlId, requestParameters.riskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the risk with the given id.  Args:     update_risk (schemas.UpdateRisk): The updated risk.     risk (schemas.Risk, optional): The risk to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The updated risk.
     * @summary Update Risk
     * @param {RisksApiUpdateRiskApiRisksRiskIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public updateRiskApiRisksRiskIdPatch(requestParameters: RisksApiUpdateRiskApiRisksRiskIdPatchRequest, options?: RawAxiosRequestConfig) {
        return RisksApiFp(this.configuration).updateRiskApiRisksRiskIdPatch(requestParameters.riskId, requestParameters.updateRisk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the assignee of the risk to the user with the given user_id.  Args:     assignee (schemas.OrganizationUser): The user to assign the risk to.     db (Session, optional): The database session.     risk_id (uuid.UUID, optional): The id of the risk to update.     user (schemas.OrganizationUser, optional): The current user.  Returns:     schemas.Risk: The updated risk.
     * @summary Update Risk Assignee
     * @param {RisksApiUpdateRiskAssigneeApiRisksRiskIdAssigneePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public updateRiskAssigneeApiRisksRiskIdAssigneePatch(requestParameters: RisksApiUpdateRiskAssigneeApiRisksRiskIdAssigneePatchRequest, options?: RawAxiosRequestConfig) {
        return RisksApiFp(this.configuration).updateRiskAssigneeApiRisksRiskIdAssigneePatch(requestParameters.riskId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the treatment of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to update the treatment for.     risk_treatment (schemas.RiskTreatment): The updated risk treatment.  Returns:     schemas.RiskTreatment: The risk treatment.
     * @summary Update Risk Treatment
     * @param {RisksApiUpdateRiskTreatmentApiRisksRiskIdTreatmentPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public updateRiskTreatmentApiRisksRiskIdTreatmentPatch(requestParameters: RisksApiUpdateRiskTreatmentApiRisksRiskIdTreatmentPatchRequest, options?: RawAxiosRequestConfig) {
        return RisksApiFp(this.configuration).updateRiskTreatmentApiRisksRiskIdTreatmentPatch(requestParameters.riskId, requestParameters.riskTreatmentProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create the treatment strategy of the risk.  Args:     risk_id (uuid.UUID): The id of the risk to create the treatment strategy for.     new_strategy (schemas.RiskTreatmentStrategy): The new risk treatment strategy.  Returns:     schemas.RiskTreatmentStrategy: The risk treatment strategy.
     * @summary Update Risk Treatment Strategy
     * @param {RisksApiUpdateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RisksApi
     */
    public updateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch(requestParameters: RisksApiUpdateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatchRequest, options?: RawAxiosRequestConfig) {
        return RisksApiFp(this.configuration).updateRiskTreatmentStrategyApiRisksRiskIdTreatmentStrategiesStrategyIdPatch(requestParameters.strategyId, requestParameters.riskId, requestParameters.riskTreatmentStrategyProps, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestingLogsApi - axios parameter creator
 * @export
 */
export const TestingLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a monitoring datasource.  Args:     testing_log (TestingLogCreate): Monitoring datasource data.     project (Project): Project.     db (Session): Database session.  Returns:     TestingLog: Monitoring datasource.  Raises:     HTTPException: Project not found.
         * @summary Create Testing Log
         * @param {string} projectId 
         * @param {TestingLogCreate} testingLogCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingLogApiV1ProjectsProjectIdTestingLogsPost: async (projectId: string, testingLogCreate: TestingLogCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createTestingLogApiV1ProjectsProjectIdTestingLogsPost', 'projectId', projectId)
            // verify required parameter 'testingLogCreate' is not null or undefined
            assertParamExists('createTestingLogApiV1ProjectsProjectIdTestingLogsPost', 'testingLogCreate', testingLogCreate)
            const localVarPath = `/api/v1/projects/{project_id}/testing/logs`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testingLogCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestingLogsApi - functional programming interface
 * @export
 */
export const TestingLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestingLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a monitoring datasource.  Args:     testing_log (TestingLogCreate): Monitoring datasource data.     project (Project): Project.     db (Session): Database session.  Returns:     TestingLog: Monitoring datasource.  Raises:     HTTPException: Project not found.
         * @summary Create Testing Log
         * @param {string} projectId 
         * @param {TestingLogCreate} testingLogCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestingLogApiV1ProjectsProjectIdTestingLogsPost(projectId: string, testingLogCreate: TestingLogCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestingLogApiV1ProjectsProjectIdTestingLogsPost(projectId, testingLogCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingLogsApi.createTestingLogApiV1ProjectsProjectIdTestingLogsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TestingLogsApi - factory interface
 * @export
 */
export const TestingLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestingLogsApiFp(configuration)
    return {
        /**
         * Create a monitoring datasource.  Args:     testing_log (TestingLogCreate): Monitoring datasource data.     project (Project): Project.     db (Session): Database session.  Returns:     TestingLog: Monitoring datasource.  Raises:     HTTPException: Project not found.
         * @summary Create Testing Log
         * @param {TestingLogsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingLogApiV1ProjectsProjectIdTestingLogsPost(requestParameters: TestingLogsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingLog> {
            return localVarFp.createTestingLogApiV1ProjectsProjectIdTestingLogsPost(requestParameters.projectId, requestParameters.testingLogCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTestingLogApiV1ProjectsProjectIdTestingLogsPost operation in TestingLogsApi.
 * @export
 * @interface TestingLogsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPostRequest
 */
export interface TestingLogsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingLogsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPost
     */
    readonly projectId: string

    /**
     * 
     * @type {TestingLogCreate}
     * @memberof TestingLogsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPost
     */
    readonly testingLogCreate: TestingLogCreate
}

/**
 * TestingLogsApi - object-oriented interface
 * @export
 * @class TestingLogsApi
 * @extends {BaseAPI}
 */
export class TestingLogsApi extends BaseAPI {
    /**
     * Create a monitoring datasource.  Args:     testing_log (TestingLogCreate): Monitoring datasource data.     project (Project): Project.     db (Session): Database session.  Returns:     TestingLog: Monitoring datasource.  Raises:     HTTPException: Project not found.
     * @summary Create Testing Log
     * @param {TestingLogsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingLogsApi
     */
    public createTestingLogApiV1ProjectsProjectIdTestingLogsPost(requestParameters: TestingLogsApiCreateTestingLogApiV1ProjectsProjectIdTestingLogsPostRequest, options?: RawAxiosRequestConfig) {
        return TestingLogsApiFp(this.configuration).createTestingLogApiV1ProjectsProjectIdTestingLogsPost(requestParameters.projectId, requestParameters.testingLogCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestingSourcesApi - axios parameter creator
 * @export
 */
export const TestingSourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Testing Metric
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {TestingMetricCreate} testingMetricCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost: async (testingSourceId: string, projectId: string, testingMetricCreate: TestingMetricCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingSourceId' is not null or undefined
            assertParamExists('createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost', 'testingSourceId', testingSourceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost', 'projectId', projectId)
            // verify required parameter 'testingMetricCreate' is not null or undefined
            assertParamExists('createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost', 'testingMetricCreate', testingMetricCreate)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources/{testing_source_id}/metrics`
                .replace(`{${"testing_source_id"}}`, encodeURIComponent(String(testingSourceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testingMetricCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Metric
         * @param {string} testingSourceId 
         * @param {string} testingMetricId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet: async (testingSourceId: string, testingMetricId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingSourceId' is not null or undefined
            assertParamExists('getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet', 'testingSourceId', testingSourceId)
            // verify required parameter 'testingMetricId' is not null or undefined
            assertParamExists('getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet', 'testingMetricId', testingMetricId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources/{testing_source_id}/metrics/{testing_metric_id}`
                .replace(`{${"testing_source_id"}}`, encodeURIComponent(String(testingSourceId)))
                .replace(`{${"testing_metric_id"}}`, encodeURIComponent(String(testingMetricId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Source
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet: async (testingSourceId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingSourceId' is not null or undefined
            assertParamExists('getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet', 'testingSourceId', testingSourceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources/{testing_source_id}`
                .replace(`{${"testing_source_id"}}`, encodeURIComponent(String(testingSourceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Source Metrics
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet: async (testingSourceId: string, projectId: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingSourceId' is not null or undefined
            assertParamExists('getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet', 'testingSourceId', testingSourceId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources/{testing_source_id}/metrics`
                .replace(`{${"testing_source_id"}}`, encodeURIComponent(String(testingSourceId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Sources
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet: async (projectId: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/sources`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestingSourcesApi - functional programming interface
 * @export
 */
export const TestingSourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestingSourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Testing Metric
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {TestingMetricCreate} testingMetricCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(testingSourceId: string, projectId: string, testingMetricCreate: TestingMetricCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingMetric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(testingSourceId, projectId, testingMetricCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingSourcesApi.createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Metric
         * @param {string} testingSourceId 
         * @param {string} testingMetricId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(testingSourceId: string, testingMetricId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingMetric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(testingSourceId, testingMetricId, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingSourcesApi.getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Source
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(testingSourceId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(testingSourceId, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingSourcesApi.getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Source Metrics
         * @param {string} testingSourceId 
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(testingSourceId: string, projectId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTestingMetric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(testingSourceId, projectId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingSourcesApi.getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Sources
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(projectId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTestingSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(projectId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingSourcesApi.getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TestingSourcesApi - factory interface
 * @export
 */
export const TestingSourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestingSourcesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Testing Metric
         * @param {TestingSourcesApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(requestParameters: TestingSourcesApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingMetric> {
            return localVarFp.createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(requestParameters.testingSourceId, requestParameters.projectId, requestParameters.testingMetricCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Metric
         * @param {TestingSourcesApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(requestParameters: TestingSourcesApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingMetric> {
            return localVarFp.getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(requestParameters.testingSourceId, requestParameters.testingMetricId, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Source
         * @param {TestingSourcesApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(requestParameters: TestingSourcesApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingSource> {
            return localVarFp.getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(requestParameters.testingSourceId, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Source Metrics
         * @param {TestingSourcesApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(requestParameters: TestingSourcesApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageTestingMetric> {
            return localVarFp.getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(requestParameters.testingSourceId, requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Sources
         * @param {TestingSourcesApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(requestParameters: TestingSourcesApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageTestingSource> {
            return localVarFp.getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost operation in TestingSourcesApi.
 * @export
 * @interface TestingSourcesApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPostRequest
 */
export interface TestingSourcesApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingSourcesApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost
     */
    readonly testingSourceId: string

    /**
     * 
     * @type {string}
     * @memberof TestingSourcesApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost
     */
    readonly projectId: string

    /**
     * 
     * @type {TestingMetricCreate}
     * @memberof TestingSourcesApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost
     */
    readonly testingMetricCreate: TestingMetricCreate
}

/**
 * Request parameters for getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet operation in TestingSourcesApi.
 * @export
 * @interface TestingSourcesApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest
 */
export interface TestingSourcesApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingSourcesApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet
     */
    readonly testingSourceId: string

    /**
     * 
     * @type {string}
     * @memberof TestingSourcesApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet
     */
    readonly testingMetricId: string

    /**
     * 
     * @type {string}
     * @memberof TestingSourcesApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet operation in TestingSourcesApi.
 * @export
 * @interface TestingSourcesApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGetRequest
 */
export interface TestingSourcesApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingSourcesApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet
     */
    readonly testingSourceId: string

    /**
     * 
     * @type {string}
     * @memberof TestingSourcesApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet
     */
    readonly projectId: string
}

/**
 * Request parameters for getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet operation in TestingSourcesApi.
 * @export
 * @interface TestingSourcesApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest
 */
export interface TestingSourcesApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingSourcesApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet
     */
    readonly testingSourceId: string

    /**
     * 
     * @type {string}
     * @memberof TestingSourcesApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet
     */
    readonly projectId: string

    /**
     * Page number
     * @type {number}
     * @memberof TestingSourcesApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof TestingSourcesApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet operation in TestingSourcesApi.
 * @export
 * @interface TestingSourcesApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest
 */
export interface TestingSourcesApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingSourcesApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet
     */
    readonly projectId: string

    /**
     * Page number
     * @type {number}
     * @memberof TestingSourcesApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof TestingSourcesApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet
     */
    readonly size?: number
}

/**
 * TestingSourcesApi - object-oriented interface
 * @export
 * @class TestingSourcesApi
 * @extends {BaseAPI}
 */
export class TestingSourcesApi extends BaseAPI {
    /**
     * 
     * @summary Create Testing Metric
     * @param {TestingSourcesApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingSourcesApi
     */
    public createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(requestParameters: TestingSourcesApiCreateTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPostRequest, options?: RawAxiosRequestConfig) {
        return TestingSourcesApiFp(this.configuration).createTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsPost(requestParameters.testingSourceId, requestParameters.projectId, requestParameters.testingMetricCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Metric
     * @param {TestingSourcesApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingSourcesApi
     */
    public getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(requestParameters: TestingSourcesApiGetTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGetRequest, options?: RawAxiosRequestConfig) {
        return TestingSourcesApiFp(this.configuration).getTestingMetricApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsTestingMetricIdGet(requestParameters.testingSourceId, requestParameters.testingMetricId, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Source
     * @param {TestingSourcesApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingSourcesApi
     */
    public getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(requestParameters: TestingSourcesApiGetTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGetRequest, options?: RawAxiosRequestConfig) {
        return TestingSourcesApiFp(this.configuration).getTestingSourceApiV1ProjectsProjectIdTestingSourcesTestingSourceIdGet(requestParameters.testingSourceId, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Source Metrics
     * @param {TestingSourcesApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingSourcesApi
     */
    public getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(requestParameters: TestingSourcesApiGetTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGetRequest, options?: RawAxiosRequestConfig) {
        return TestingSourcesApiFp(this.configuration).getTestingSourceMetricsApiV1ProjectsProjectIdTestingSourcesTestingSourceIdMetricsGet(requestParameters.testingSourceId, requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Sources
     * @param {TestingSourcesApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingSourcesApi
     */
    public getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(requestParameters: TestingSourcesApiGetTestingSourcesApiV1ProjectsProjectIdTestingSourcesGetRequest, options?: RawAxiosRequestConfig) {
        return TestingSourcesApiFp(this.configuration).getTestingSourcesApiV1ProjectsProjectIdTestingSourcesGet(requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestingTestsApi - axios parameter creator
 * @export
 */
export const TestingTestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Testing Testing To Control
         * @param {string} projectId 
         * @param {string} controlId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut: async (projectId: string, controlId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut', 'projectId', projectId)
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut', 'controlId', controlId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/controls/{control_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Testing Test
         * @param {string} projectId 
         * @param {CreateTestingTest} createTestingTest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingTestApiV1ProjectsProjectIdTestingTestsPost: async (projectId: string, createTestingTest: CreateTestingTest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createTestingTestApiV1ProjectsProjectIdTestingTestsPost', 'projectId', projectId)
            // verify required parameter 'createTestingTest' is not null or undefined
            assertParamExists('createTestingTestApiV1ProjectsProjectIdTestingTestsPost', 'createTestingTest', createTestingTest)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTestingTest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Controls By Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet: async (projectId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/controls`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Test And Latest Result
         * @param {string} projectId 
         * @param {string | null} [sortBy] Column name to sort by
         * @param {boolean} [descending] Sort in descending order
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string | null} [name] Filter by name
         * @param {TestingTestStatus | null} [resultStatus] Filter by result status
         * @param {boolean | null} [status] Filter by test status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet: async (projectId: string, sortBy?: string | null, descending?: boolean, page?: number, size?: number, name?: string | null, resultStatus?: TestingTestStatus | null, status?: boolean | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests-results`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (resultStatus !== undefined) {
                localVarQueryParameter['result_status'] = resultStatus;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet: async (projectId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Test Result
         * @param {string} testingTestResultId 
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet: async (testingTestResultId: string, projectId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testingTestResultId' is not null or undefined
            assertParamExists('getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet', 'testingTestResultId', testingTestResultId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/results/{testing_test_result_id}`
                .replace(`{${"testing_test_result_id"}}`, encodeURIComponent(String(testingTestResultId)))
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Test Results
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {TestingTestStatus | null} [resultStatus] Filter by result status
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string | null} [sortBy] Column name to sort by
         * @param {boolean} [descending] Sort in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet: async (projectId: string, testingTestId: string, resultStatus?: TestingTestStatus | null, page?: number, size?: number, sortBy?: string | null, descending?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/results`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (resultStatus !== undefined) {
                localVarQueryParameter['result_status'] = resultStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Testing Tests
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestsApiV1ProjectsProjectIdTestingTestsGet: async (projectId: string, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getTestingTestsApiV1ProjectsProjectIdTestingTestsGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Control From Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete: async (projectId: string, testingTestId: string, controlId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete', 'testingTestId', testingTestId)
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete', 'controlId', controlId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/controls/{control_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)))
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost: async (projectId: string, testingTestId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost', 'testingTestId', testingTestId)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}/results`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {UpdateTestingTest} updateTestingTest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch: async (projectId: string, testingTestId: string, updateTestingTest: UpdateTestingTest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch', 'projectId', projectId)
            // verify required parameter 'testingTestId' is not null or undefined
            assertParamExists('updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch', 'testingTestId', testingTestId)
            // verify required parameter 'updateTestingTest' is not null or undefined
            assertParamExists('updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch', 'updateTestingTest', updateTestingTest)
            const localVarPath = `/api/v1/projects/{project_id}/testing/tests/{testing_test_id}`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"testing_test_id"}}`, encodeURIComponent(String(testingTestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTestingTest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestingTestsApi - functional programming interface
 * @export
 */
export const TestingTestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestingTestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Testing Testing To Control
         * @param {string} projectId 
         * @param {string} controlId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(projectId: string, controlId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(projectId, controlId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingTestsApi.addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Testing Test
         * @param {string} projectId 
         * @param {CreateTestingTest} createTestingTest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTestingTestApiV1ProjectsProjectIdTestingTestsPost(projectId: string, createTestingTest: CreateTestingTest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTestingTestApiV1ProjectsProjectIdTestingTestsPost(projectId, createTestingTest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingTestsApi.createTestingTestApiV1ProjectsProjectIdTestingTestsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Controls By Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(projectId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Control>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(projectId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingTestsApi.getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Test And Latest Result
         * @param {string} projectId 
         * @param {string | null} [sortBy] Column name to sort by
         * @param {boolean} [descending] Sort in descending order
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string | null} [name] Filter by name
         * @param {TestingTestStatus | null} [resultStatus] Filter by result status
         * @param {boolean | null} [status] Filter by test status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(projectId: string, sortBy?: string | null, descending?: boolean, page?: number, size?: number, name?: string | null, resultStatus?: TestingTestStatus | null, status?: boolean | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTestAndResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(projectId, sortBy, descending, page, size, name, resultStatus, status, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingTestsApi.getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(projectId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(projectId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingTestsApi.getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Test Result
         * @param {string} testingTestResultId 
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(testingTestResultId: string, projectId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(testingTestResultId, projectId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingTestsApi.getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Test Results
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {TestingTestStatus | null} [resultStatus] Filter by result status
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {string | null} [sortBy] Column name to sort by
         * @param {boolean} [descending] Sort in descending order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(projectId: string, testingTestId: string, resultStatus?: TestingTestStatus | null, page?: number, size?: number, sortBy?: string | null, descending?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTestResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(projectId, testingTestId, resultStatus, page, size, sortBy, descending, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingTestsApi.getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Testing Tests
         * @param {string} projectId 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(projectId: string, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(projectId, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingTestsApi.getTestingTestsApiV1ProjectsProjectIdTestingTestsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove Control From Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(projectId: string, testingTestId: string, controlId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(projectId, testingTestId, controlId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingTestsApi.removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Run Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(projectId: string, testingTestId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTestResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(projectId, testingTestId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingTestsApi.runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Testing Test
         * @param {string} projectId 
         * @param {string} testingTestId 
         * @param {UpdateTestingTest} updateTestingTest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(projectId: string, testingTestId: string, updateTestingTest: UpdateTestingTest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestingTest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(projectId, testingTestId, updateTestingTest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestingTestsApi.updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TestingTestsApi - factory interface
 * @export
 */
export const TestingTestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestingTestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Testing Testing To Control
         * @param {TestingTestsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(requestParameters: TestingTestsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTest> {
            return localVarFp.addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(requestParameters.projectId, requestParameters.controlId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Testing Test
         * @param {TestingTestsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTestingTestApiV1ProjectsProjectIdTestingTestsPost(requestParameters: TestingTestsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTest> {
            return localVarFp.createTestingTestApiV1ProjectsProjectIdTestingTestsPost(requestParameters.projectId, requestParameters.createTestingTest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Controls By Testing Test
         * @param {TestingTestsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(requestParameters: TestingTestsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<Control>> {
            return localVarFp.getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Test And Latest Result
         * @param {TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(requestParameters: TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTestAndResultPage> {
            return localVarFp.getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(requestParameters.projectId, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, requestParameters.name, requestParameters.resultStatus, requestParameters.status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Test
         * @param {TestingTestsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(requestParameters: TestingTestsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTest> {
            return localVarFp.getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Test Result
         * @param {TestingTestsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(requestParameters: TestingTestsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTestResult> {
            return localVarFp.getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(requestParameters.testingTestResultId, requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Test Results
         * @param {TestingTestsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(requestParameters: TestingTestsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTestResultPage> {
            return localVarFp.getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(requestParameters.projectId, requestParameters.testingTestId, requestParameters.resultStatus, requestParameters.page, requestParameters.size, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Testing Tests
         * @param {TestingTestsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(requestParameters: TestingTestsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageTestingTest> {
            return localVarFp.getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Control From Testing Test
         * @param {TestingTestsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(requestParameters: TestingTestsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(requestParameters.projectId, requestParameters.testingTestId, requestParameters.controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run Testing Test
         * @param {TestingTestsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(requestParameters: TestingTestsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTestResult> {
            return localVarFp.runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Testing Test
         * @param {TestingTestsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(requestParameters: TestingTestsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<TestingTest> {
            return localVarFp.updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(requestParameters.projectId, requestParameters.testingTestId, requestParameters.updateTestingTest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut operation in TestingTestsApi.
 * @export
 * @interface TestingTestsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest
 */
export interface TestingTestsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut
     */
    readonly controlId: string

    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut
     */
    readonly testingTestId: string
}

/**
 * Request parameters for createTestingTestApiV1ProjectsProjectIdTestingTestsPost operation in TestingTestsApi.
 * @export
 * @interface TestingTestsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest
 */
export interface TestingTestsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPost
     */
    readonly projectId: string

    /**
     * 
     * @type {CreateTestingTest}
     * @memberof TestingTestsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPost
     */
    readonly createTestingTest: CreateTestingTest
}

/**
 * Request parameters for getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet operation in TestingTestsApi.
 * @export
 * @interface TestingTestsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest
 */
export interface TestingTestsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet
     */
    readonly testingTestId: string
}

/**
 * Request parameters for getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet operation in TestingTestsApi.
 * @export
 * @interface TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest
 */
export interface TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly projectId: string

    /**
     * Column name to sort by
     * @type {string}
     * @memberof TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort in descending order
     * @type {boolean}
     * @memberof TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly size?: number

    /**
     * Filter by name
     * @type {string}
     * @memberof TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly name?: string | null

    /**
     * Filter by result status
     * @type {TestingTestStatus}
     * @memberof TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly resultStatus?: TestingTestStatus | null

    /**
     * Filter by test status
     * @type {boolean}
     * @memberof TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet
     */
    readonly status?: boolean | null
}

/**
 * Request parameters for getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet operation in TestingTestsApi.
 * @export
 * @interface TestingTestsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest
 */
export interface TestingTestsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet
     */
    readonly testingTestId: string
}

/**
 * Request parameters for getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet operation in TestingTestsApi.
 * @export
 * @interface TestingTestsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest
 */
export interface TestingTestsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet
     */
    readonly testingTestResultId: string

    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet
     */
    readonly testingTestId: string
}

/**
 * Request parameters for getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet operation in TestingTestsApi.
 * @export
 * @interface TestingTestsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest
 */
export interface TestingTestsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly testingTestId: string

    /**
     * Filter by result status
     * @type {TestingTestStatus}
     * @memberof TestingTestsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly resultStatus?: TestingTestStatus | null

    /**
     * Page number
     * @type {number}
     * @memberof TestingTestsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof TestingTestsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly size?: number

    /**
     * Column name to sort by
     * @type {string}
     * @memberof TestingTestsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort in descending order
     * @type {boolean}
     * @memberof TestingTestsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet
     */
    readonly descending?: boolean
}

/**
 * Request parameters for getTestingTestsApiV1ProjectsProjectIdTestingTestsGet operation in TestingTestsApi.
 * @export
 * @interface TestingTestsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGetRequest
 */
export interface TestingTestsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet
     */
    readonly projectId: string

    /**
     * Page number
     * @type {number}
     * @memberof TestingTestsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof TestingTestsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGet
     */
    readonly size?: number
}

/**
 * Request parameters for removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete operation in TestingTestsApi.
 * @export
 * @interface TestingTestsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest
 */
export interface TestingTestsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete
     */
    readonly testingTestId: string

    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete
     */
    readonly controlId: string
}

/**
 * Request parameters for runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost operation in TestingTestsApi.
 * @export
 * @interface TestingTestsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest
 */
export interface TestingTestsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost
     */
    readonly testingTestId: string
}

/**
 * Request parameters for updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch operation in TestingTestsApi.
 * @export
 * @interface TestingTestsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest
 */
export interface TestingTestsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof TestingTestsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch
     */
    readonly testingTestId: string

    /**
     * 
     * @type {UpdateTestingTest}
     * @memberof TestingTestsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch
     */
    readonly updateTestingTest: UpdateTestingTest
}

/**
 * TestingTestsApi - object-oriented interface
 * @export
 * @class TestingTestsApi
 * @extends {BaseAPI}
 */
export class TestingTestsApi extends BaseAPI {
    /**
     * 
     * @summary Add Testing Testing To Control
     * @param {TestingTestsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingTestsApi
     */
    public addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(requestParameters: TestingTestsApiAddTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPutRequest, options?: RawAxiosRequestConfig) {
        return TestingTestsApiFp(this.configuration).addTestingTestingToControlApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdPut(requestParameters.projectId, requestParameters.controlId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Testing Test
     * @param {TestingTestsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingTestsApi
     */
    public createTestingTestApiV1ProjectsProjectIdTestingTestsPost(requestParameters: TestingTestsApiCreateTestingTestApiV1ProjectsProjectIdTestingTestsPostRequest, options?: RawAxiosRequestConfig) {
        return TestingTestsApiFp(this.configuration).createTestingTestApiV1ProjectsProjectIdTestingTestsPost(requestParameters.projectId, requestParameters.createTestingTest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Controls By Testing Test
     * @param {TestingTestsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingTestsApi
     */
    public getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(requestParameters: TestingTestsApiGetControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGetRequest, options?: RawAxiosRequestConfig) {
        return TestingTestsApiFp(this.configuration).getControlsByTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsGet(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Test And Latest Result
     * @param {TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingTestsApi
     */
    public getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(requestParameters: TestingTestsApiGetTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGetRequest, options?: RawAxiosRequestConfig) {
        return TestingTestsApiFp(this.configuration).getTestingTestAndLatestResultApiV1ProjectsProjectIdTestingTestsResultsGet(requestParameters.projectId, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, requestParameters.name, requestParameters.resultStatus, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Test
     * @param {TestingTestsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingTestsApi
     */
    public getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(requestParameters: TestingTestsApiGetTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGetRequest, options?: RawAxiosRequestConfig) {
        return TestingTestsApiFp(this.configuration).getTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdGet(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Test Result
     * @param {TestingTestsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingTestsApi
     */
    public getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(requestParameters: TestingTestsApiGetTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGetRequest, options?: RawAxiosRequestConfig) {
        return TestingTestsApiFp(this.configuration).getTestingTestResultApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsTestingTestResultIdGet(requestParameters.testingTestResultId, requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Test Results
     * @param {TestingTestsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingTestsApi
     */
    public getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(requestParameters: TestingTestsApiGetTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGetRequest, options?: RawAxiosRequestConfig) {
        return TestingTestsApiFp(this.configuration).getTestingTestResultsApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsGet(requestParameters.projectId, requestParameters.testingTestId, requestParameters.resultStatus, requestParameters.page, requestParameters.size, requestParameters.sortBy, requestParameters.descending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Testing Tests
     * @param {TestingTestsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingTestsApi
     */
    public getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(requestParameters: TestingTestsApiGetTestingTestsApiV1ProjectsProjectIdTestingTestsGetRequest, options?: RawAxiosRequestConfig) {
        return TestingTestsApiFp(this.configuration).getTestingTestsApiV1ProjectsProjectIdTestingTestsGet(requestParameters.projectId, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Control From Testing Test
     * @param {TestingTestsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingTestsApi
     */
    public removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(requestParameters: TestingTestsApiRemoveControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return TestingTestsApiFp(this.configuration).removeControlFromTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdControlsControlIdDelete(requestParameters.projectId, requestParameters.testingTestId, requestParameters.controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run Testing Test
     * @param {TestingTestsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingTestsApi
     */
    public runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(requestParameters: TestingTestsApiRunTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPostRequest, options?: RawAxiosRequestConfig) {
        return TestingTestsApiFp(this.configuration).runTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdResultsPost(requestParameters.projectId, requestParameters.testingTestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Testing Test
     * @param {TestingTestsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestingTestsApi
     */
    public updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(requestParameters: TestingTestsApiUpdateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatchRequest, options?: RawAxiosRequestConfig) {
        return TestingTestsApiFp(this.configuration).updateTestingTestApiV1ProjectsProjectIdTestingTestsTestingTestIdPatch(requestParameters.projectId, requestParameters.testingTestId, requestParameters.updateTestingTest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel Pending User Invites
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost', 'userId', userId)
            const localVarPath = `/api/users/{user_id}/cancel-invites`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User Roles
         * @param {string} userId 
         * @param {Roles} role 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRolesApiUsersUserIdRolesDelete: async (userId: string, role: Roles, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserRolesApiUsersUserIdRolesDelete', 'userId', userId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('deleteUserRolesApiUsersUserIdRolesDelete', 'role', role)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteUserRolesApiUsersUserIdRolesDelete', 'projectId', projectId)
            const localVarPath = `/api/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the user with the given id.  Args:     user_id (uuid.UUID): The user id.  Returns:     schemas.User: The user with the given id.
         * @summary Get User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiUsersUserIdGet: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserApiUsersUserIdGet', 'userId', userId)
            const localVarPath = `/api/users/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the current user.  Returns:     schemas.User: The current user.
         * @summary Get Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersMeApiUsersMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch User Roles
         * @param {string} userId 
         * @param {string} projectId 
         * @param {Array<Roles>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserRolesApiUsersUserIdRolesPatch: async (userId: string, projectId: string, roles: Array<Roles>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchUserRolesApiUsersUserIdRolesPatch', 'userId', userId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('patchUserRolesApiUsersUserIdRolesPatch', 'projectId', projectId)
            // verify required parameter 'roles' is not null or undefined
            assertParamExists('patchUserRolesApiUsersUserIdRolesPatch', 'roles', roles)
            const localVarPath = `/api/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roles, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows users to update their information.  Args:     user (schemas.UpdateUser): The new user information.     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
         * @summary Patch Users Me
         * @param {UpdateUser} updateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUsersMeApiUsersMePatch: async (updateUser: UpdateUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUser' is not null or undefined
            assertParamExists('patchUsersMeApiUsersMePatch', 'updateUser', updateUser)
            const localVarPath = `/api/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the last access of the user.  Args:     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
         * @summary Patch Users Me Last Access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUsersMeLastAccessApiUsersMeLastAccessPatch: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/me/last_access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post User Roles
         * @param {string} userId 
         * @param {Roles} role 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserRolesApiUsersUserIdRolesPost: async (userId: string, role: Roles, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postUserRolesApiUsersUserIdRolesPost', 'userId', userId)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('postUserRolesApiUsersUserIdRolesPost', 'role', role)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('postUserRolesApiUsersUserIdRolesPost', 'projectId', projectId)
            const localVarPath = `/api/users/{user_id}/roles`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['project_id'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend User Invite
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserInviteApiUsersUserIdResendInvitePost: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resendUserInviteApiUsersUserIdResendInvitePost', 'userId', userId)
            const localVarPath = `/api/users/{user_id}/resend-invite`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel Pending User Invites
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete User Roles
         * @param {string} userId 
         * @param {Roles} role 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserRolesApiUsersUserIdRolesDelete(userId: string, role: Roles, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserRolesApiUsersUserIdRolesDelete(userId, role, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.deleteUserRolesApiUsersUserIdRolesDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the user with the given id.  Args:     user_id (uuid.UUID): The user id.  Returns:     schemas.User: The user with the given id.
         * @summary Get User
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserApiUsersUserIdGet(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseGetUserApiUsersUserIdGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserApiUsersUserIdGet(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUserApiUsersUserIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the current user.  Returns:     schemas.User: The current user.
         * @summary Get Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersMeApiUsersMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseGetUsersMeApiUsersMeGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersMeApiUsersMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getUsersMeApiUsersMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Patch User Roles
         * @param {string} userId 
         * @param {string} projectId 
         * @param {Array<Roles>} roles 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUserRolesApiUsersUserIdRolesPatch(userId: string, projectId: string, roles: Array<Roles>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUserRolesApiUsersUserIdRolesPatch(userId, projectId, roles, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.patchUserRolesApiUsersUserIdRolesPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Allows users to update their information.  Args:     user (schemas.UpdateUser): The new user information.     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
         * @summary Patch Users Me
         * @param {UpdateUser} updateUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUsersMeApiUsersMePatch(updateUser: UpdateUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUsersMeApiUsersMePatch(updateUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.patchUsersMeApiUsersMePatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the last access of the user.  Args:     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
         * @summary Patch Users Me Last Access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUsersMeLastAccessApiUsersMeLastAccessPatch(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUsersMeLastAccessApiUsersMeLastAccessPatch(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.patchUsersMeLastAccessApiUsersMeLastAccessPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Post User Roles
         * @param {string} userId 
         * @param {Roles} role 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserRolesApiUsersUserIdRolesPost(userId: string, role: Roles, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserRolesApiUsersUserIdRolesPost(userId, role, projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.postUserRolesApiUsersUserIdRolesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Resend User Invite
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendUserInviteApiUsersUserIdResendInvitePost(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendUserInviteApiUsersUserIdResendInvitePost(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.resendUserInviteApiUsersUserIdResendInvitePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel Pending User Invites
         * @param {UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost(requestParameters: UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete User Roles
         * @param {UsersApiDeleteUserRolesApiUsersUserIdRolesDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRolesApiUsersUserIdRolesDelete(requestParameters: UsersApiDeleteUserRolesApiUsersUserIdRolesDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUserRolesApiUsersUserIdRolesDelete(requestParameters.userId, requestParameters.role, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the user with the given id.  Args:     user_id (uuid.UUID): The user id.  Returns:     schemas.User: The user with the given id.
         * @summary Get User
         * @param {UsersApiGetUserApiUsersUserIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiUsersUserIdGet(requestParameters: UsersApiGetUserApiUsersUserIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<ResponseGetUserApiUsersUserIdGet> {
            return localVarFp.getUserApiUsersUserIdGet(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the current user.  Returns:     schemas.User: The current user.
         * @summary Get Users Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersMeApiUsersMeGet(options?: RawAxiosRequestConfig): AxiosPromise<ResponseGetUsersMeApiUsersMeGet> {
            return localVarFp.getUsersMeApiUsersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch User Roles
         * @param {UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserRolesApiUsersUserIdRolesPatch(requestParameters: UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchUserRolesApiUsersUserIdRolesPatch(requestParameters.userId, requestParameters.projectId, requestParameters.roles, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows users to update their information.  Args:     user (schemas.UpdateUser): The new user information.     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
         * @summary Patch Users Me
         * @param {UsersApiPatchUsersMeApiUsersMePatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUsersMeApiUsersMePatch(requestParameters: UsersApiPatchUsersMeApiUsersMePatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchUsersMeApiUsersMePatch(requestParameters.updateUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the last access of the user.  Args:     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
         * @summary Patch Users Me Last Access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUsersMeLastAccessApiUsersMeLastAccessPatch(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.patchUsersMeLastAccessApiUsersMeLastAccessPatch(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post User Roles
         * @param {UsersApiPostUserRolesApiUsersUserIdRolesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserRolesApiUsersUserIdRolesPost(requestParameters: UsersApiPostUserRolesApiUsersUserIdRolesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.postUserRolesApiUsersUserIdRolesPost(requestParameters.userId, requestParameters.role, requestParameters.projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend User Invite
         * @param {UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserInviteApiUsersUserIdResendInvitePost(requestParameters: UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resendUserInviteApiUsersUserIdResendInvitePost(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost operation in UsersApi.
 * @export
 * @interface UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest
 */
export interface UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPost
     */
    readonly userId: string
}

/**
 * Request parameters for deleteUserRolesApiUsersUserIdRolesDelete operation in UsersApi.
 * @export
 * @interface UsersApiDeleteUserRolesApiUsersUserIdRolesDeleteRequest
 */
export interface UsersApiDeleteUserRolesApiUsersUserIdRolesDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteUserRolesApiUsersUserIdRolesDelete
     */
    readonly userId: string

    /**
     * 
     * @type {Roles}
     * @memberof UsersApiDeleteUserRolesApiUsersUserIdRolesDelete
     */
    readonly role: Roles

    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteUserRolesApiUsersUserIdRolesDelete
     */
    readonly projectId: string
}

/**
 * Request parameters for getUserApiUsersUserIdGet operation in UsersApi.
 * @export
 * @interface UsersApiGetUserApiUsersUserIdGetRequest
 */
export interface UsersApiGetUserApiUsersUserIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUserApiUsersUserIdGet
     */
    readonly userId: string
}

/**
 * Request parameters for patchUserRolesApiUsersUserIdRolesPatch operation in UsersApi.
 * @export
 * @interface UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest
 */
export interface UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiPatchUserRolesApiUsersUserIdRolesPatch
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiPatchUserRolesApiUsersUserIdRolesPatch
     */
    readonly projectId: string

    /**
     * 
     * @type {Array<Roles>}
     * @memberof UsersApiPatchUserRolesApiUsersUserIdRolesPatch
     */
    readonly roles: Array<Roles>
}

/**
 * Request parameters for patchUsersMeApiUsersMePatch operation in UsersApi.
 * @export
 * @interface UsersApiPatchUsersMeApiUsersMePatchRequest
 */
export interface UsersApiPatchUsersMeApiUsersMePatchRequest {
    /**
     * 
     * @type {UpdateUser}
     * @memberof UsersApiPatchUsersMeApiUsersMePatch
     */
    readonly updateUser: UpdateUser
}

/**
 * Request parameters for postUserRolesApiUsersUserIdRolesPost operation in UsersApi.
 * @export
 * @interface UsersApiPostUserRolesApiUsersUserIdRolesPostRequest
 */
export interface UsersApiPostUserRolesApiUsersUserIdRolesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiPostUserRolesApiUsersUserIdRolesPost
     */
    readonly userId: string

    /**
     * 
     * @type {Roles}
     * @memberof UsersApiPostUserRolesApiUsersUserIdRolesPost
     */
    readonly role: Roles

    /**
     * 
     * @type {string}
     * @memberof UsersApiPostUserRolesApiUsersUserIdRolesPost
     */
    readonly projectId: string
}

/**
 * Request parameters for resendUserInviteApiUsersUserIdResendInvitePost operation in UsersApi.
 * @export
 * @interface UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest
 */
export interface UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiResendUserInviteApiUsersUserIdResendInvitePost
     */
    readonly userId: string
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Cancel Pending User Invites
     * @param {UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost(requestParameters: UsersApiCancelPendingUserInvitesApiUsersUserIdCancelInvitesPostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).cancelPendingUserInvitesApiUsersUserIdCancelInvitesPost(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete User Roles
     * @param {UsersApiDeleteUserRolesApiUsersUserIdRolesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserRolesApiUsersUserIdRolesDelete(requestParameters: UsersApiDeleteUserRolesApiUsersUserIdRolesDeleteRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUserRolesApiUsersUserIdRolesDelete(requestParameters.userId, requestParameters.role, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the user with the given id.  Args:     user_id (uuid.UUID): The user id.  Returns:     schemas.User: The user with the given id.
     * @summary Get User
     * @param {UsersApiGetUserApiUsersUserIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserApiUsersUserIdGet(requestParameters: UsersApiGetUserApiUsersUserIdGetRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserApiUsersUserIdGet(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the current user.  Returns:     schemas.User: The current user.
     * @summary Get Users Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsersMeApiUsersMeGet(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsersMeApiUsersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch User Roles
     * @param {UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchUserRolesApiUsersUserIdRolesPatch(requestParameters: UsersApiPatchUserRolesApiUsersUserIdRolesPatchRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).patchUserRolesApiUsersUserIdRolesPatch(requestParameters.userId, requestParameters.projectId, requestParameters.roles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows users to update their information.  Args:     user (schemas.UpdateUser): The new user information.     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
     * @summary Patch Users Me
     * @param {UsersApiPatchUsersMeApiUsersMePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchUsersMeApiUsersMePatch(requestParameters: UsersApiPatchUsersMeApiUsersMePatchRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).patchUsersMeApiUsersMePatch(requestParameters.updateUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the last access of the user.  Args:     current_user (schemas.User, optional): The current user. Defaults         to Depends(dependencies.get_current_active_user).     db (Session, optional): Database session. Defaults to Depends(get_db).
     * @summary Patch Users Me Last Access
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchUsersMeLastAccessApiUsersMeLastAccessPatch(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).patchUsersMeLastAccessApiUsersMeLastAccessPatch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post User Roles
     * @param {UsersApiPostUserRolesApiUsersUserIdRolesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postUserRolesApiUsersUserIdRolesPost(requestParameters: UsersApiPostUserRolesApiUsersUserIdRolesPostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).postUserRolesApiUsersUserIdRolesPost(requestParameters.userId, requestParameters.role, requestParameters.projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend User Invite
     * @param {UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resendUserInviteApiUsersUserIdResendInvitePost(requestParameters: UsersApiResendUserInviteApiUsersUserIdResendInvitePostRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).resendUserInviteApiUsersUserIdResendInvitePost(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * V1Api - axios parameter creator
 * @export
 */
export const V1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Evidence
         * @param {string} projectId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvidenceApiV1ProjectsProjectIdEvidencesPost: async (projectId: string, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createEvidenceApiV1ProjectsProjectIdEvidencesPost', 'projectId', projectId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('createEvidenceApiV1ProjectsProjectIdEvidencesPost', 'file', file)
            const localVarPath = `/api/v1/projects/{project_id}/evidences`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Evidence Suggestions
         * @param {string} projectId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost: async (projectId: string, evidenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost', 'projectId', projectId)
            // verify required parameter 'evidenceId' is not null or undefined
            assertParamExists('createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost', 'evidenceId', evidenceId)
            const localVarPath = `/api/v1/projects/{project_id}/evidences{evidence_id}/suggestions`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"evidence_id"}}`, encodeURIComponent(String(evidenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Vendor
         * @param {VendorData} vendorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVendorApiV1VendorsPost: async (vendorData: VendorData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorData' is not null or undefined
            assertParamExists('createVendorApiV1VendorsPost', 'vendorData', vendorData)
            const localVarPath = `/api/v1/vendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vendorData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Document
         * @param {string} vendorId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete: async (vendorId: string, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete', 'vendorId', vendorId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete', 'documentId', documentId)
            const localVarPath = `/api/v1/vendors/{vendor_id}/documents/{document_id}`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Vendor
         * @param {string} vendorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVendorApiV1VendorsVendorIdDelete: async (vendorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('deleteVendorApiV1VendorsVendorIdDelete', 'vendorId', vendorId)
            const localVarPath = `/api/v1/vendors/{vendor_id}`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Vendor Document
         * @param {string} vendorId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet: async (vendorId: string, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet', 'vendorId', vendorId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet', 'documentId', documentId)
            const localVarPath = `/api/v1/vendors/{vendor_id}/documents/{document_id}/download`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlApiV1ControlsControlIdGet: async (controlId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'controlId' is not null or undefined
            assertParamExists('getControlApiV1ControlsControlIdGet', 'controlId', controlId)
            const localVarPath = `/api/v1/controls/{control_id}`
                .replace(`{${"control_id"}}`, encodeURIComponent(String(controlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get File Url For Download
         * @param {string} vendorId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet: async (vendorId: string, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet', 'vendorId', vendorId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet', 'documentId', documentId)
            const localVarPath = `/api/v1/vendors/{vendor_id}/documents/{document_id}/url`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Framework In Organization
         * @param {string} frameworkCode 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet: async (frameworkCode: string, organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frameworkCode' is not null or undefined
            assertParamExists('getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet', 'frameworkCode', frameworkCode)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}/frameworks/{framework_code}`
                .replace(`{${"framework_code"}}`, encodeURIComponent(String(frameworkCode)))
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Frameworks In Organization
         * @param {string} organizationId 
         * @param {ProjectType} [projectType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet: async (organizationId: string, projectType?: ProjectType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet', 'organizationId', organizationId)
            const localVarPath = `/api/v1/organizations/{organization_id}/frameworks`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (projectType !== undefined) {
                localVarQueryParameter['project_type'] = projectType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Project Controls
         * @param {string} projectId 
         * @param {Status | null} [status] Filter by control status
         * @param {string | null} [name] 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsApiV1ProjectsProjectIdControlsGet: async (projectId: string, status?: Status | null, name?: string | null, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectControlsApiV1ProjectsProjectIdControlsGet', 'projectId', projectId)
            const localVarPath = `/api/v1/projects/{project_id}/controls`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Requirement
         * @param {string} requirementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequirementApiV1RequirementsRequirementIdGet: async (requirementId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requirementId' is not null or undefined
            assertParamExists('getRequirementApiV1RequirementsRequirementIdGet', 'requirementId', requirementId)
            const localVarPath = `/api/v1/requirements/{requirement_id}`
                .replace(`{${"requirement_id"}}`, encodeURIComponent(String(requirementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return a single risk.  Args:     risk (schemas.Risk, optional): The risk to return.         Defaults to Depends(dependencies.get_risk_by_id).  Returns:     schemas.Risk: The risk to return.
         * @summary Get Risk
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskApiV1RisksRiskIdGet: async (riskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'riskId' is not null or undefined
            assertParamExists('getRiskApiV1RisksRiskIdGet', 'riskId', riskId)
            const localVarPath = `/api/v1/risks/{risk_id}`
                .replace(`{${"risk_id"}}`, encodeURIComponent(String(riskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Vendor
         * @param {string} vendorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVendorApiV1VendorsVendorIdGet: async (vendorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('getVendorApiV1VendorsVendorIdGet', 'vendorId', vendorId)
            const localVarPath = `/api/v1/vendors/{vendor_id}`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Documents For Vendor
         * @param {string} vendorId 
         * @param {string | null} [name] Filter by name
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet: async (vendorId: string, name?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet', 'vendorId', vendorId)
            const localVarPath = `/api/v1/vendors/{vendor_id}/documents`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Vendors
         * @param {string | null} [name] Filter by name
         * @param {string | null} [vendorType] Filter by vendor type
         * @param {string | null} [vendorStatus] Filter by vendor status
         * @param {string | null} [vendorRiskLevel] Filter by vendor risk level
         * @param {string | null} [reviewDate] Filter by review date
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVendorsApiV1VendorsGet: async (name?: string | null, vendorType?: string | null, vendorStatus?: string | null, vendorRiskLevel?: string | null, reviewDate?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/vendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (vendorType !== undefined) {
                localVarQueryParameter['vendor_type'] = vendorType;
            }

            if (vendorStatus !== undefined) {
                localVarQueryParameter['vendor_status'] = vendorStatus;
            }

            if (vendorRiskLevel !== undefined) {
                localVarQueryParameter['vendor_risk_level'] = vendorRiskLevel;
            }

            if (reviewDate !== undefined) {
                localVarQueryParameter['review_date'] = reviewDate;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Vendor
         * @param {string} vendorId 
         * @param {VendorData} vendorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendorApiV1VendorsVendorIdPut: async (vendorId: string, vendorData: VendorData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('updateVendorApiV1VendorsVendorIdPut', 'vendorId', vendorId)
            // verify required parameter 'vendorData' is not null or undefined
            assertParamExists('updateVendorApiV1VendorsVendorIdPut', 'vendorData', vendorData)
            const localVarPath = `/api/v1/vendors/{vendor_id}`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vendorData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Document For Vendor
         * @param {string} vendorId 
         * @param {File} file 
         * @param {string} [fileName] The file name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost: async (vendorId: string, file: File, fileName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost', 'vendorId', vendorId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost', 'file', file)
            const localVarPath = `/api/v1/vendors/{vendor_id}/documents`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['file_name'] = fileName;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * V1Api - functional programming interface
 * @export
 */
export const V1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = V1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Evidence
         * @param {string} projectId 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEvidenceApiV1ProjectsProjectIdEvidencesPost(projectId: string, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evidence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEvidenceApiV1ProjectsProjectIdEvidencesPost(projectId, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.createEvidenceApiV1ProjectsProjectIdEvidencesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Evidence Suggestions
         * @param {string} projectId 
         * @param {string} evidenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost(projectId: string, evidenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EvidenceSuggestions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost(projectId, evidenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create Vendor
         * @param {VendorData} vendorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVendorApiV1VendorsPost(vendorData: VendorData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVendorApiV1VendorsPost(vendorData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.createVendorApiV1VendorsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Document
         * @param {string} vendorId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete(vendorId: string, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete(vendorId, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Vendor
         * @param {string} vendorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVendorApiV1VendorsVendorIdDelete(vendorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVendorApiV1VendorsVendorIdDelete(vendorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.deleteVendorApiV1VendorsVendorIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Download Vendor Document
         * @param {string} vendorId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet(vendorId: string, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet(vendorId, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Control
         * @param {string} controlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getControlApiV1ControlsControlIdGet(controlId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Control>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getControlApiV1ControlsControlIdGet(controlId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.getControlApiV1ControlsControlIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get File Url For Download
         * @param {string} vendorId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet(vendorId: string, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet(vendorId, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Framework In Organization
         * @param {string} frameworkCode 
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(frameworkCode: string, organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrameworkDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(frameworkCode, organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Frameworks In Organization
         * @param {string} organizationId 
         * @param {ProjectType} [projectType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(organizationId: string, projectType?: ProjectType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FrameworkOverview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(organizationId, projectType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Project Controls
         * @param {string} projectId 
         * @param {Status | null} [status] Filter by control status
         * @param {string | null} [name] 
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectControlsApiV1ProjectsProjectIdControlsGet(projectId: string, status?: Status | null, name?: string | null, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageControl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectControlsApiV1ProjectsProjectIdControlsGet(projectId, status, name, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.getProjectControlsApiV1ProjectsProjectIdControlsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Requirement
         * @param {string} requirementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequirementApiV1RequirementsRequirementIdGet(requirementId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Requirement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRequirementApiV1RequirementsRequirementIdGet(requirementId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.getRequirementApiV1RequirementsRequirementIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Return a single risk.  Args:     risk (schemas.Risk, optional): The risk to return.         Defaults to Depends(dependencies.get_risk_by_id).  Returns:     schemas.Risk: The risk to return.
         * @summary Get Risk
         * @param {string} riskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRiskApiV1RisksRiskIdGet(riskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Risk>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRiskApiV1RisksRiskIdGet(riskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.getRiskApiV1RisksRiskIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Vendor
         * @param {string} vendorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVendorApiV1VendorsVendorIdGet(vendorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVendorApiV1VendorsVendorIdGet(vendorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.getVendorApiV1VendorsVendorIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Documents For Vendor
         * @param {string} vendorId 
         * @param {string | null} [name] Filter by name
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet(vendorId: string, name?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDocumentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet(vendorId, name, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Vendors
         * @param {string | null} [name] Filter by name
         * @param {string | null} [vendorType] Filter by vendor type
         * @param {string | null} [vendorStatus] Filter by vendor status
         * @param {string | null} [vendorRiskLevel] Filter by vendor risk level
         * @param {string | null} [reviewDate] Filter by review date
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVendorsApiV1VendorsGet(name?: string | null, vendorType?: string | null, vendorStatus?: string | null, vendorRiskLevel?: string | null, reviewDate?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageVendorDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVendorsApiV1VendorsGet(name, vendorType, vendorStatus, vendorRiskLevel, reviewDate, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.listVendorsApiV1VendorsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Vendor
         * @param {string} vendorId 
         * @param {VendorData} vendorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVendorApiV1VendorsVendorIdPut(vendorId: string, vendorData: VendorData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVendorApiV1VendorsVendorIdPut(vendorId, vendorData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.updateVendorApiV1VendorsVendorIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload Document For Vendor
         * @param {string} vendorId 
         * @param {File} file 
         * @param {string} [fileName] The file name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost(vendorId: string, file: File, fileName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost(vendorId, file, fileName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['V1Api.uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * V1Api - factory interface
 * @export
 */
export const V1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = V1ApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Evidence
         * @param {V1ApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvidenceApiV1ProjectsProjectIdEvidencesPost(requestParameters: V1ApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<Evidence> {
            return localVarFp.createEvidenceApiV1ProjectsProjectIdEvidencesPost(requestParameters.projectId, requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Evidence Suggestions
         * @param {V1ApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost(requestParameters: V1ApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<EvidenceSuggestions> {
            return localVarFp.createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost(requestParameters.projectId, requestParameters.evidenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Vendor
         * @param {V1ApiCreateVendorApiV1VendorsPost0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVendorApiV1VendorsPost(requestParameters: V1ApiCreateVendorApiV1VendorsPost0Request, options?: RawAxiosRequestConfig): AxiosPromise<VendorDetails> {
            return localVarFp.createVendorApiV1VendorsPost(requestParameters.vendorData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Document
         * @param {V1ApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete(requestParameters: V1ApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete0Request, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete(requestParameters.vendorId, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Vendor
         * @param {V1ApiDeleteVendorApiV1VendorsVendorIdDelete0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVendorApiV1VendorsVendorIdDelete(requestParameters: V1ApiDeleteVendorApiV1VendorsVendorIdDelete0Request, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteVendorApiV1VendorsVendorIdDelete(requestParameters.vendorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Vendor Document
         * @param {V1ApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet(requestParameters: V1ApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet(requestParameters.vendorId, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Control
         * @param {V1ApiGetControlApiV1ControlsControlIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getControlApiV1ControlsControlIdGet(requestParameters: V1ApiGetControlApiV1ControlsControlIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Control> {
            return localVarFp.getControlApiV1ControlsControlIdGet(requestParameters.controlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get File Url For Download
         * @param {V1ApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet(requestParameters: V1ApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<DocumentURL> {
            return localVarFp.getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet(requestParameters.vendorId, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Framework In Organization
         * @param {V1ApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(requestParameters: V1ApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<FrameworkDetailed> {
            return localVarFp.getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(requestParameters.frameworkCode, requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Frameworks In Organization
         * @param {V1ApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(requestParameters: V1ApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<Array<FrameworkOverview>> {
            return localVarFp.getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(requestParameters.organizationId, requestParameters.projectType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Project Controls
         * @param {V1ApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectControlsApiV1ProjectsProjectIdControlsGet(requestParameters: V1ApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageControl> {
            return localVarFp.getProjectControlsApiV1ProjectsProjectIdControlsGet(requestParameters.projectId, requestParameters.status, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Requirement
         * @param {V1ApiGetRequirementApiV1RequirementsRequirementIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequirementApiV1RequirementsRequirementIdGet(requestParameters: V1ApiGetRequirementApiV1RequirementsRequirementIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Requirement> {
            return localVarFp.getRequirementApiV1RequirementsRequirementIdGet(requestParameters.requirementId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return a single risk.  Args:     risk (schemas.Risk, optional): The risk to return.         Defaults to Depends(dependencies.get_risk_by_id).  Returns:     schemas.Risk: The risk to return.
         * @summary Get Risk
         * @param {V1ApiGetRiskApiV1RisksRiskIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskApiV1RisksRiskIdGet(requestParameters: V1ApiGetRiskApiV1RisksRiskIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<Risk> {
            return localVarFp.getRiskApiV1RisksRiskIdGet(requestParameters.riskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Vendor
         * @param {V1ApiGetVendorApiV1VendorsVendorIdGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVendorApiV1VendorsVendorIdGet(requestParameters: V1ApiGetVendorApiV1VendorsVendorIdGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<VendorDetails> {
            return localVarFp.getVendorApiV1VendorsVendorIdGet(requestParameters.vendorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Documents For Vendor
         * @param {V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet(requestParameters: V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet0Request, options?: RawAxiosRequestConfig): AxiosPromise<PageDocumentOverview> {
            return localVarFp.listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet(requestParameters.vendorId, requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Vendors
         * @param {V1ApiListVendorsApiV1VendorsGet0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVendorsApiV1VendorsGet(requestParameters: V1ApiListVendorsApiV1VendorsGet0Request = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageVendorDetails> {
            return localVarFp.listVendorsApiV1VendorsGet(requestParameters.name, requestParameters.vendorType, requestParameters.vendorStatus, requestParameters.vendorRiskLevel, requestParameters.reviewDate, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Vendor
         * @param {V1ApiUpdateVendorApiV1VendorsVendorIdPut0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendorApiV1VendorsVendorIdPut(requestParameters: V1ApiUpdateVendorApiV1VendorsVendorIdPut0Request, options?: RawAxiosRequestConfig): AxiosPromise<VendorDetails> {
            return localVarFp.updateVendorApiV1VendorsVendorIdPut(requestParameters.vendorId, requestParameters.vendorData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Document For Vendor
         * @param {V1ApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost0Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost(requestParameters: V1ApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost0Request, options?: RawAxiosRequestConfig): AxiosPromise<DocumentOverview> {
            return localVarFp.uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost(requestParameters.vendorId, requestParameters.file, requestParameters.fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createEvidenceApiV1ProjectsProjectIdEvidencesPost operation in V1Api.
 * @export
 * @interface V1ApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPostRequest
 */
export interface V1ApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof V1ApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost
     */
    readonly projectId: string

    /**
     * 
     * @type {File}
     * @memberof V1ApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPost
     */
    readonly file: File
}

/**
 * Request parameters for createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost operation in V1Api.
 * @export
 * @interface V1ApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPostRequest
 */
export interface V1ApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof V1ApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost
     */
    readonly projectId: string

    /**
     * 
     * @type {string}
     * @memberof V1ApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost
     */
    readonly evidenceId: string
}

/**
 * Request parameters for createVendorApiV1VendorsPost operation in V1Api.
 * @export
 * @interface V1ApiCreateVendorApiV1VendorsPost0Request
 */
export interface V1ApiCreateVendorApiV1VendorsPost0Request {
    /**
     * 
     * @type {VendorData}
     * @memberof V1ApiCreateVendorApiV1VendorsPost0
     */
    readonly vendorData: VendorData
}

/**
 * Request parameters for deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete operation in V1Api.
 * @export
 * @interface V1ApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete0Request
 */
export interface V1ApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete0Request {
    /**
     * 
     * @type {string}
     * @memberof V1ApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete0
     */
    readonly vendorId: string

    /**
     * 
     * @type {string}
     * @memberof V1ApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete0
     */
    readonly documentId: string
}

/**
 * Request parameters for deleteVendorApiV1VendorsVendorIdDelete operation in V1Api.
 * @export
 * @interface V1ApiDeleteVendorApiV1VendorsVendorIdDelete0Request
 */
export interface V1ApiDeleteVendorApiV1VendorsVendorIdDelete0Request {
    /**
     * 
     * @type {string}
     * @memberof V1ApiDeleteVendorApiV1VendorsVendorIdDelete0
     */
    readonly vendorId: string
}

/**
 * Request parameters for downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet operation in V1Api.
 * @export
 * @interface V1ApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet0Request
 */
export interface V1ApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet0Request {
    /**
     * 
     * @type {string}
     * @memberof V1ApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet0
     */
    readonly vendorId: string

    /**
     * 
     * @type {string}
     * @memberof V1ApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet0
     */
    readonly documentId: string
}

/**
 * Request parameters for getControlApiV1ControlsControlIdGet operation in V1Api.
 * @export
 * @interface V1ApiGetControlApiV1ControlsControlIdGetRequest
 */
export interface V1ApiGetControlApiV1ControlsControlIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof V1ApiGetControlApiV1ControlsControlIdGet
     */
    readonly controlId: string
}

/**
 * Request parameters for getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet operation in V1Api.
 * @export
 * @interface V1ApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet0Request
 */
export interface V1ApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet0Request {
    /**
     * 
     * @type {string}
     * @memberof V1ApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet0
     */
    readonly vendorId: string

    /**
     * 
     * @type {string}
     * @memberof V1ApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet0
     */
    readonly documentId: string
}

/**
 * Request parameters for getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet operation in V1Api.
 * @export
 * @interface V1ApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet0Request
 */
export interface V1ApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet0Request {
    /**
     * 
     * @type {string}
     * @memberof V1ApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet0
     */
    readonly frameworkCode: string

    /**
     * 
     * @type {string}
     * @memberof V1ApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet0
     */
    readonly organizationId: string
}

/**
 * Request parameters for getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet operation in V1Api.
 * @export
 * @interface V1ApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet0Request
 */
export interface V1ApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet0Request {
    /**
     * 
     * @type {string}
     * @memberof V1ApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet0
     */
    readonly organizationId: string

    /**
     * 
     * @type {ProjectType}
     * @memberof V1ApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet0
     */
    readonly projectType?: ProjectType
}

/**
 * Request parameters for getProjectControlsApiV1ProjectsProjectIdControlsGet operation in V1Api.
 * @export
 * @interface V1ApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest
 */
export interface V1ApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof V1ApiGetProjectControlsApiV1ProjectsProjectIdControlsGet
     */
    readonly projectId: string

    /**
     * Filter by control status
     * @type {Status}
     * @memberof V1ApiGetProjectControlsApiV1ProjectsProjectIdControlsGet
     */
    readonly status?: Status | null

    /**
     * 
     * @type {string}
     * @memberof V1ApiGetProjectControlsApiV1ProjectsProjectIdControlsGet
     */
    readonly name?: string | null

    /**
     * Page number
     * @type {number}
     * @memberof V1ApiGetProjectControlsApiV1ProjectsProjectIdControlsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof V1ApiGetProjectControlsApiV1ProjectsProjectIdControlsGet
     */
    readonly size?: number
}

/**
 * Request parameters for getRequirementApiV1RequirementsRequirementIdGet operation in V1Api.
 * @export
 * @interface V1ApiGetRequirementApiV1RequirementsRequirementIdGetRequest
 */
export interface V1ApiGetRequirementApiV1RequirementsRequirementIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof V1ApiGetRequirementApiV1RequirementsRequirementIdGet
     */
    readonly requirementId: string
}

/**
 * Request parameters for getRiskApiV1RisksRiskIdGet operation in V1Api.
 * @export
 * @interface V1ApiGetRiskApiV1RisksRiskIdGetRequest
 */
export interface V1ApiGetRiskApiV1RisksRiskIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof V1ApiGetRiskApiV1RisksRiskIdGet
     */
    readonly riskId: string
}

/**
 * Request parameters for getVendorApiV1VendorsVendorIdGet operation in V1Api.
 * @export
 * @interface V1ApiGetVendorApiV1VendorsVendorIdGet0Request
 */
export interface V1ApiGetVendorApiV1VendorsVendorIdGet0Request {
    /**
     * 
     * @type {string}
     * @memberof V1ApiGetVendorApiV1VendorsVendorIdGet0
     */
    readonly vendorId: string
}

/**
 * Request parameters for listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet operation in V1Api.
 * @export
 * @interface V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet0Request
 */
export interface V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet0Request {
    /**
     * 
     * @type {string}
     * @memberof V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet0
     */
    readonly vendorId: string

    /**
     * Filter by name
     * @type {string}
     * @memberof V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet0
     */
    readonly name?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet0
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet0
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet0
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet0
     */
    readonly size?: number
}

/**
 * Request parameters for listVendorsApiV1VendorsGet operation in V1Api.
 * @export
 * @interface V1ApiListVendorsApiV1VendorsGet0Request
 */
export interface V1ApiListVendorsApiV1VendorsGet0Request {
    /**
     * Filter by name
     * @type {string}
     * @memberof V1ApiListVendorsApiV1VendorsGet0
     */
    readonly name?: string | null

    /**
     * Filter by vendor type
     * @type {string}
     * @memberof V1ApiListVendorsApiV1VendorsGet0
     */
    readonly vendorType?: string | null

    /**
     * Filter by vendor status
     * @type {string}
     * @memberof V1ApiListVendorsApiV1VendorsGet0
     */
    readonly vendorStatus?: string | null

    /**
     * Filter by vendor risk level
     * @type {string}
     * @memberof V1ApiListVendorsApiV1VendorsGet0
     */
    readonly vendorRiskLevel?: string | null

    /**
     * Filter by review date
     * @type {string}
     * @memberof V1ApiListVendorsApiV1VendorsGet0
     */
    readonly reviewDate?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof V1ApiListVendorsApiV1VendorsGet0
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof V1ApiListVendorsApiV1VendorsGet0
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof V1ApiListVendorsApiV1VendorsGet0
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof V1ApiListVendorsApiV1VendorsGet0
     */
    readonly size?: number
}

/**
 * Request parameters for updateVendorApiV1VendorsVendorIdPut operation in V1Api.
 * @export
 * @interface V1ApiUpdateVendorApiV1VendorsVendorIdPut0Request
 */
export interface V1ApiUpdateVendorApiV1VendorsVendorIdPut0Request {
    /**
     * 
     * @type {string}
     * @memberof V1ApiUpdateVendorApiV1VendorsVendorIdPut0
     */
    readonly vendorId: string

    /**
     * 
     * @type {VendorData}
     * @memberof V1ApiUpdateVendorApiV1VendorsVendorIdPut0
     */
    readonly vendorData: VendorData
}

/**
 * Request parameters for uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost operation in V1Api.
 * @export
 * @interface V1ApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost0Request
 */
export interface V1ApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost0Request {
    /**
     * 
     * @type {string}
     * @memberof V1ApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost0
     */
    readonly vendorId: string

    /**
     * 
     * @type {File}
     * @memberof V1ApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost0
     */
    readonly file: File

    /**
     * The file name.
     * @type {string}
     * @memberof V1ApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost0
     */
    readonly fileName?: string
}

/**
 * V1Api - object-oriented interface
 * @export
 * @class V1Api
 * @extends {BaseAPI}
 */
export class V1Api extends BaseAPI {
    /**
     * 
     * @summary Create Evidence
     * @param {V1ApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public createEvidenceApiV1ProjectsProjectIdEvidencesPost(requestParameters: V1ApiCreateEvidenceApiV1ProjectsProjectIdEvidencesPostRequest, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).createEvidenceApiV1ProjectsProjectIdEvidencesPost(requestParameters.projectId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Evidence Suggestions
     * @param {V1ApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost(requestParameters: V1ApiCreateEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPostRequest, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).createEvidenceSuggestionsApiV1ProjectsProjectIdEvidencesEvidenceIdSuggestionsPost(requestParameters.projectId, requestParameters.evidenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Vendor
     * @param {V1ApiCreateVendorApiV1VendorsPost0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public createVendorApiV1VendorsPost(requestParameters: V1ApiCreateVendorApiV1VendorsPost0Request, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).createVendorApiV1VendorsPost(requestParameters.vendorData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Document
     * @param {V1ApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete(requestParameters: V1ApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete0Request, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete(requestParameters.vendorId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Vendor
     * @param {V1ApiDeleteVendorApiV1VendorsVendorIdDelete0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public deleteVendorApiV1VendorsVendorIdDelete(requestParameters: V1ApiDeleteVendorApiV1VendorsVendorIdDelete0Request, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).deleteVendorApiV1VendorsVendorIdDelete(requestParameters.vendorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Vendor Document
     * @param {V1ApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet(requestParameters: V1ApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet0Request, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet(requestParameters.vendorId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Control
     * @param {V1ApiGetControlApiV1ControlsControlIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getControlApiV1ControlsControlIdGet(requestParameters: V1ApiGetControlApiV1ControlsControlIdGetRequest, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).getControlApiV1ControlsControlIdGet(requestParameters.controlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get File Url For Download
     * @param {V1ApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet(requestParameters: V1ApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet0Request, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet(requestParameters.vendorId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Framework In Organization
     * @param {V1ApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(requestParameters: V1ApiGetFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet0Request, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).getFrameworkInOrganizationApiV1OrganizationsOrganizationIdFrameworksFrameworkCodeGet(requestParameters.frameworkCode, requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Frameworks In Organization
     * @param {V1ApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(requestParameters: V1ApiGetFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet0Request, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).getFrameworksInOrganizationApiV1OrganizationsOrganizationIdFrameworksGet(requestParameters.organizationId, requestParameters.projectType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Project Controls
     * @param {V1ApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getProjectControlsApiV1ProjectsProjectIdControlsGet(requestParameters: V1ApiGetProjectControlsApiV1ProjectsProjectIdControlsGetRequest, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).getProjectControlsApiV1ProjectsProjectIdControlsGet(requestParameters.projectId, requestParameters.status, requestParameters.name, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Requirement
     * @param {V1ApiGetRequirementApiV1RequirementsRequirementIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getRequirementApiV1RequirementsRequirementIdGet(requestParameters: V1ApiGetRequirementApiV1RequirementsRequirementIdGetRequest, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).getRequirementApiV1RequirementsRequirementIdGet(requestParameters.requirementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return a single risk.  Args:     risk (schemas.Risk, optional): The risk to return.         Defaults to Depends(dependencies.get_risk_by_id).  Returns:     schemas.Risk: The risk to return.
     * @summary Get Risk
     * @param {V1ApiGetRiskApiV1RisksRiskIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getRiskApiV1RisksRiskIdGet(requestParameters: V1ApiGetRiskApiV1RisksRiskIdGetRequest, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).getRiskApiV1RisksRiskIdGet(requestParameters.riskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Vendor
     * @param {V1ApiGetVendorApiV1VendorsVendorIdGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public getVendorApiV1VendorsVendorIdGet(requestParameters: V1ApiGetVendorApiV1VendorsVendorIdGet0Request, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).getVendorApiV1VendorsVendorIdGet(requestParameters.vendorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Documents For Vendor
     * @param {V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet(requestParameters: V1ApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet0Request, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet(requestParameters.vendorId, requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Vendors
     * @param {V1ApiListVendorsApiV1VendorsGet0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public listVendorsApiV1VendorsGet(requestParameters: V1ApiListVendorsApiV1VendorsGet0Request = {}, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).listVendorsApiV1VendorsGet(requestParameters.name, requestParameters.vendorType, requestParameters.vendorStatus, requestParameters.vendorRiskLevel, requestParameters.reviewDate, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Vendor
     * @param {V1ApiUpdateVendorApiV1VendorsVendorIdPut0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public updateVendorApiV1VendorsVendorIdPut(requestParameters: V1ApiUpdateVendorApiV1VendorsVendorIdPut0Request, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).updateVendorApiV1VendorsVendorIdPut(requestParameters.vendorId, requestParameters.vendorData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Document For Vendor
     * @param {V1ApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost0Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof V1Api
     */
    public uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost(requestParameters: V1ApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost0Request, options?: RawAxiosRequestConfig) {
        return V1ApiFp(this.configuration).uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost(requestParameters.vendorId, requestParameters.file, requestParameters.fileName, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VendorsApi - axios parameter creator
 * @export
 */
export const VendorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Vendor
         * @param {VendorData} vendorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVendorApiV1VendorsPost: async (vendorData: VendorData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorData' is not null or undefined
            assertParamExists('createVendorApiV1VendorsPost', 'vendorData', vendorData)
            const localVarPath = `/api/v1/vendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vendorData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Document
         * @param {string} vendorId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete: async (vendorId: string, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete', 'vendorId', vendorId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete', 'documentId', documentId)
            const localVarPath = `/api/v1/vendors/{vendor_id}/documents/{document_id}`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Vendor
         * @param {string} vendorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVendorApiV1VendorsVendorIdDelete: async (vendorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('deleteVendorApiV1VendorsVendorIdDelete', 'vendorId', vendorId)
            const localVarPath = `/api/v1/vendors/{vendor_id}`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Vendor Document
         * @param {string} vendorId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet: async (vendorId: string, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet', 'vendorId', vendorId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet', 'documentId', documentId)
            const localVarPath = `/api/v1/vendors/{vendor_id}/documents/{document_id}/download`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get File Url For Download
         * @param {string} vendorId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet: async (vendorId: string, documentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet', 'vendorId', vendorId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet', 'documentId', documentId)
            const localVarPath = `/api/v1/vendors/{vendor_id}/documents/{document_id}/url`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)))
                .replace(`{${"document_id"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Vendor
         * @param {string} vendorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVendorApiV1VendorsVendorIdGet: async (vendorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('getVendorApiV1VendorsVendorIdGet', 'vendorId', vendorId)
            const localVarPath = `/api/v1/vendors/{vendor_id}`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Documents For Vendor
         * @param {string} vendorId 
         * @param {string | null} [name] Filter by name
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet: async (vendorId: string, name?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet', 'vendorId', vendorId)
            const localVarPath = `/api/v1/vendors/{vendor_id}/documents`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List Vendors
         * @param {string | null} [name] Filter by name
         * @param {string | null} [vendorType] Filter by vendor type
         * @param {string | null} [vendorStatus] Filter by vendor status
         * @param {string | null} [vendorRiskLevel] Filter by vendor risk level
         * @param {string | null} [reviewDate] Filter by review date
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVendorsApiV1VendorsGet: async (name?: string | null, vendorType?: string | null, vendorStatus?: string | null, vendorRiskLevel?: string | null, reviewDate?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/vendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (vendorType !== undefined) {
                localVarQueryParameter['vendor_type'] = vendorType;
            }

            if (vendorStatus !== undefined) {
                localVarQueryParameter['vendor_status'] = vendorStatus;
            }

            if (vendorRiskLevel !== undefined) {
                localVarQueryParameter['vendor_risk_level'] = vendorRiskLevel;
            }

            if (reviewDate !== undefined) {
                localVarQueryParameter['review_date'] = reviewDate;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (descending !== undefined) {
                localVarQueryParameter['descending'] = descending;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Vendor
         * @param {string} vendorId 
         * @param {VendorData} vendorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendorApiV1VendorsVendorIdPut: async (vendorId: string, vendorData: VendorData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('updateVendorApiV1VendorsVendorIdPut', 'vendorId', vendorId)
            // verify required parameter 'vendorData' is not null or undefined
            assertParamExists('updateVendorApiV1VendorsVendorIdPut', 'vendorData', vendorData)
            const localVarPath = `/api/v1/vendors/{vendor_id}`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vendorData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Document For Vendor
         * @param {string} vendorId 
         * @param {File} file 
         * @param {string} [fileName] The file name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost: async (vendorId: string, file: File, fileName?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost', 'vendorId', vendorId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost', 'file', file)
            const localVarPath = `/api/v1/vendors/{vendor_id}/documents`
                .replace(`{${"vendor_id"}}`, encodeURIComponent(String(vendorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['file_name'] = fileName;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VendorsApi - functional programming interface
 * @export
 */
export const VendorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VendorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Vendor
         * @param {VendorData} vendorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVendorApiV1VendorsPost(vendorData: VendorData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVendorApiV1VendorsPost(vendorData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VendorsApi.createVendorApiV1VendorsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Document
         * @param {string} vendorId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete(vendorId: string, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete(vendorId, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VendorsApi.deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Vendor
         * @param {string} vendorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVendorApiV1VendorsVendorIdDelete(vendorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVendorApiV1VendorsVendorIdDelete(vendorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VendorsApi.deleteVendorApiV1VendorsVendorIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Download Vendor Document
         * @param {string} vendorId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet(vendorId: string, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet(vendorId, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VendorsApi.downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get File Url For Download
         * @param {string} vendorId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet(vendorId: string, documentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet(vendorId, documentId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VendorsApi.getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Vendor
         * @param {string} vendorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVendorApiV1VendorsVendorIdGet(vendorId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVendorApiV1VendorsVendorIdGet(vendorId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VendorsApi.getVendorApiV1VendorsVendorIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Documents For Vendor
         * @param {string} vendorId 
         * @param {string | null} [name] Filter by name
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet(vendorId: string, name?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDocumentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet(vendorId, name, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VendorsApi.listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List Vendors
         * @param {string | null} [name] Filter by name
         * @param {string | null} [vendorType] Filter by vendor type
         * @param {string | null} [vendorStatus] Filter by vendor status
         * @param {string | null} [vendorRiskLevel] Filter by vendor risk level
         * @param {string | null} [reviewDate] Filter by review date
         * @param {string | null} [sortBy] Sort by a field.
         * @param {boolean} [descending] Sort order. If true, descending, otherwise ascending.
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVendorsApiV1VendorsGet(name?: string | null, vendorType?: string | null, vendorStatus?: string | null, vendorRiskLevel?: string | null, reviewDate?: string | null, sortBy?: string | null, descending?: boolean, page?: number, size?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageVendorDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVendorsApiV1VendorsGet(name, vendorType, vendorStatus, vendorRiskLevel, reviewDate, sortBy, descending, page, size, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VendorsApi.listVendorsApiV1VendorsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Vendor
         * @param {string} vendorId 
         * @param {VendorData} vendorData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVendorApiV1VendorsVendorIdPut(vendorId: string, vendorData: VendorData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVendorApiV1VendorsVendorIdPut(vendorId, vendorData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VendorsApi.updateVendorApiV1VendorsVendorIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload Document For Vendor
         * @param {string} vendorId 
         * @param {File} file 
         * @param {string} [fileName] The file name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost(vendorId: string, file: File, fileName?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentOverview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost(vendorId, file, fileName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VendorsApi.uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VendorsApi - factory interface
 * @export
 */
export const VendorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VendorsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Vendor
         * @param {VendorsApiCreateVendorApiV1VendorsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVendorApiV1VendorsPost(requestParameters: VendorsApiCreateVendorApiV1VendorsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<VendorDetails> {
            return localVarFp.createVendorApiV1VendorsPost(requestParameters.vendorData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Document
         * @param {VendorsApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete(requestParameters: VendorsApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete(requestParameters.vendorId, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Vendor
         * @param {VendorsApiDeleteVendorApiV1VendorsVendorIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVendorApiV1VendorsVendorIdDelete(requestParameters: VendorsApiDeleteVendorApiV1VendorsVendorIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteVendorApiV1VendorsVendorIdDelete(requestParameters.vendorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Vendor Document
         * @param {VendorsApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet(requestParameters: VendorsApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet(requestParameters.vendorId, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get File Url For Download
         * @param {VendorsApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet(requestParameters: VendorsApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DocumentURL> {
            return localVarFp.getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet(requestParameters.vendorId, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Vendor
         * @param {VendorsApiGetVendorApiV1VendorsVendorIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVendorApiV1VendorsVendorIdGet(requestParameters: VendorsApiGetVendorApiV1VendorsVendorIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<VendorDetails> {
            return localVarFp.getVendorApiV1VendorsVendorIdGet(requestParameters.vendorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Documents For Vendor
         * @param {VendorsApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet(requestParameters: VendorsApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageDocumentOverview> {
            return localVarFp.listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet(requestParameters.vendorId, requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List Vendors
         * @param {VendorsApiListVendorsApiV1VendorsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVendorsApiV1VendorsGet(requestParameters: VendorsApiListVendorsApiV1VendorsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageVendorDetails> {
            return localVarFp.listVendorsApiV1VendorsGet(requestParameters.name, requestParameters.vendorType, requestParameters.vendorStatus, requestParameters.vendorRiskLevel, requestParameters.reviewDate, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Vendor
         * @param {VendorsApiUpdateVendorApiV1VendorsVendorIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendorApiV1VendorsVendorIdPut(requestParameters: VendorsApiUpdateVendorApiV1VendorsVendorIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<VendorDetails> {
            return localVarFp.updateVendorApiV1VendorsVendorIdPut(requestParameters.vendorId, requestParameters.vendorData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Document For Vendor
         * @param {VendorsApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost(requestParameters: VendorsApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<DocumentOverview> {
            return localVarFp.uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost(requestParameters.vendorId, requestParameters.file, requestParameters.fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createVendorApiV1VendorsPost operation in VendorsApi.
 * @export
 * @interface VendorsApiCreateVendorApiV1VendorsPostRequest
 */
export interface VendorsApiCreateVendorApiV1VendorsPostRequest {
    /**
     * 
     * @type {VendorData}
     * @memberof VendorsApiCreateVendorApiV1VendorsPost
     */
    readonly vendorData: VendorData
}

/**
 * Request parameters for deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete operation in VendorsApi.
 * @export
 * @interface VendorsApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDeleteRequest
 */
export interface VendorsApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof VendorsApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete
     */
    readonly vendorId: string

    /**
     * 
     * @type {string}
     * @memberof VendorsApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete
     */
    readonly documentId: string
}

/**
 * Request parameters for deleteVendorApiV1VendorsVendorIdDelete operation in VendorsApi.
 * @export
 * @interface VendorsApiDeleteVendorApiV1VendorsVendorIdDeleteRequest
 */
export interface VendorsApiDeleteVendorApiV1VendorsVendorIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof VendorsApiDeleteVendorApiV1VendorsVendorIdDelete
     */
    readonly vendorId: string
}

/**
 * Request parameters for downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet operation in VendorsApi.
 * @export
 * @interface VendorsApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGetRequest
 */
export interface VendorsApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VendorsApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet
     */
    readonly vendorId: string

    /**
     * 
     * @type {string}
     * @memberof VendorsApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet
     */
    readonly documentId: string
}

/**
 * Request parameters for getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet operation in VendorsApi.
 * @export
 * @interface VendorsApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGetRequest
 */
export interface VendorsApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VendorsApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet
     */
    readonly vendorId: string

    /**
     * 
     * @type {string}
     * @memberof VendorsApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet
     */
    readonly documentId: string
}

/**
 * Request parameters for getVendorApiV1VendorsVendorIdGet operation in VendorsApi.
 * @export
 * @interface VendorsApiGetVendorApiV1VendorsVendorIdGetRequest
 */
export interface VendorsApiGetVendorApiV1VendorsVendorIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VendorsApiGetVendorApiV1VendorsVendorIdGet
     */
    readonly vendorId: string
}

/**
 * Request parameters for listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet operation in VendorsApi.
 * @export
 * @interface VendorsApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGetRequest
 */
export interface VendorsApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof VendorsApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet
     */
    readonly vendorId: string

    /**
     * Filter by name
     * @type {string}
     * @memberof VendorsApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet
     */
    readonly name?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof VendorsApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof VendorsApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof VendorsApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof VendorsApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGet
     */
    readonly size?: number
}

/**
 * Request parameters for listVendorsApiV1VendorsGet operation in VendorsApi.
 * @export
 * @interface VendorsApiListVendorsApiV1VendorsGetRequest
 */
export interface VendorsApiListVendorsApiV1VendorsGetRequest {
    /**
     * Filter by name
     * @type {string}
     * @memberof VendorsApiListVendorsApiV1VendorsGet
     */
    readonly name?: string | null

    /**
     * Filter by vendor type
     * @type {string}
     * @memberof VendorsApiListVendorsApiV1VendorsGet
     */
    readonly vendorType?: string | null

    /**
     * Filter by vendor status
     * @type {string}
     * @memberof VendorsApiListVendorsApiV1VendorsGet
     */
    readonly vendorStatus?: string | null

    /**
     * Filter by vendor risk level
     * @type {string}
     * @memberof VendorsApiListVendorsApiV1VendorsGet
     */
    readonly vendorRiskLevel?: string | null

    /**
     * Filter by review date
     * @type {string}
     * @memberof VendorsApiListVendorsApiV1VendorsGet
     */
    readonly reviewDate?: string | null

    /**
     * Sort by a field.
     * @type {string}
     * @memberof VendorsApiListVendorsApiV1VendorsGet
     */
    readonly sortBy?: string | null

    /**
     * Sort order. If true, descending, otherwise ascending.
     * @type {boolean}
     * @memberof VendorsApiListVendorsApiV1VendorsGet
     */
    readonly descending?: boolean

    /**
     * Page number
     * @type {number}
     * @memberof VendorsApiListVendorsApiV1VendorsGet
     */
    readonly page?: number

    /**
     * Page size
     * @type {number}
     * @memberof VendorsApiListVendorsApiV1VendorsGet
     */
    readonly size?: number
}

/**
 * Request parameters for updateVendorApiV1VendorsVendorIdPut operation in VendorsApi.
 * @export
 * @interface VendorsApiUpdateVendorApiV1VendorsVendorIdPutRequest
 */
export interface VendorsApiUpdateVendorApiV1VendorsVendorIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof VendorsApiUpdateVendorApiV1VendorsVendorIdPut
     */
    readonly vendorId: string

    /**
     * 
     * @type {VendorData}
     * @memberof VendorsApiUpdateVendorApiV1VendorsVendorIdPut
     */
    readonly vendorData: VendorData
}

/**
 * Request parameters for uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost operation in VendorsApi.
 * @export
 * @interface VendorsApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPostRequest
 */
export interface VendorsApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof VendorsApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost
     */
    readonly vendorId: string

    /**
     * 
     * @type {File}
     * @memberof VendorsApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost
     */
    readonly file: File

    /**
     * The file name.
     * @type {string}
     * @memberof VendorsApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost
     */
    readonly fileName?: string
}

/**
 * VendorsApi - object-oriented interface
 * @export
 * @class VendorsApi
 * @extends {BaseAPI}
 */
export class VendorsApi extends BaseAPI {
    /**
     * 
     * @summary Create Vendor
     * @param {VendorsApiCreateVendorApiV1VendorsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorsApi
     */
    public createVendorApiV1VendorsPost(requestParameters: VendorsApiCreateVendorApiV1VendorsPostRequest, options?: RawAxiosRequestConfig) {
        return VendorsApiFp(this.configuration).createVendorApiV1VendorsPost(requestParameters.vendorData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Document
     * @param {VendorsApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorsApi
     */
    public deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete(requestParameters: VendorsApiDeleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return VendorsApiFp(this.configuration).deleteDocumentApiV1VendorsVendorIdDocumentsDocumentIdDelete(requestParameters.vendorId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Vendor
     * @param {VendorsApiDeleteVendorApiV1VendorsVendorIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorsApi
     */
    public deleteVendorApiV1VendorsVendorIdDelete(requestParameters: VendorsApiDeleteVendorApiV1VendorsVendorIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return VendorsApiFp(this.configuration).deleteVendorApiV1VendorsVendorIdDelete(requestParameters.vendorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Vendor Document
     * @param {VendorsApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorsApi
     */
    public downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet(requestParameters: VendorsApiDownloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGetRequest, options?: RawAxiosRequestConfig) {
        return VendorsApiFp(this.configuration).downloadVendorDocumentApiV1VendorsVendorIdDocumentsDocumentIdDownloadGet(requestParameters.vendorId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get File Url For Download
     * @param {VendorsApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorsApi
     */
    public getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet(requestParameters: VendorsApiGetFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGetRequest, options?: RawAxiosRequestConfig) {
        return VendorsApiFp(this.configuration).getFileUrlForDownloadApiV1VendorsVendorIdDocumentsDocumentIdUrlGet(requestParameters.vendorId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Vendor
     * @param {VendorsApiGetVendorApiV1VendorsVendorIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorsApi
     */
    public getVendorApiV1VendorsVendorIdGet(requestParameters: VendorsApiGetVendorApiV1VendorsVendorIdGetRequest, options?: RawAxiosRequestConfig) {
        return VendorsApiFp(this.configuration).getVendorApiV1VendorsVendorIdGet(requestParameters.vendorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Documents For Vendor
     * @param {VendorsApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorsApi
     */
    public listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet(requestParameters: VendorsApiListDocumentsForVendorApiV1VendorsVendorIdDocumentsGetRequest, options?: RawAxiosRequestConfig) {
        return VendorsApiFp(this.configuration).listDocumentsForVendorApiV1VendorsVendorIdDocumentsGet(requestParameters.vendorId, requestParameters.name, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List Vendors
     * @param {VendorsApiListVendorsApiV1VendorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorsApi
     */
    public listVendorsApiV1VendorsGet(requestParameters: VendorsApiListVendorsApiV1VendorsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return VendorsApiFp(this.configuration).listVendorsApiV1VendorsGet(requestParameters.name, requestParameters.vendorType, requestParameters.vendorStatus, requestParameters.vendorRiskLevel, requestParameters.reviewDate, requestParameters.sortBy, requestParameters.descending, requestParameters.page, requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Vendor
     * @param {VendorsApiUpdateVendorApiV1VendorsVendorIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorsApi
     */
    public updateVendorApiV1VendorsVendorIdPut(requestParameters: VendorsApiUpdateVendorApiV1VendorsVendorIdPutRequest, options?: RawAxiosRequestConfig) {
        return VendorsApiFp(this.configuration).updateVendorApiV1VendorsVendorIdPut(requestParameters.vendorId, requestParameters.vendorData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Document For Vendor
     * @param {VendorsApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorsApi
     */
    public uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost(requestParameters: VendorsApiUploadDocumentForVendorApiV1VendorsVendorIdDocumentsPostRequest, options?: RawAxiosRequestConfig) {
        return VendorsApiFp(this.configuration).uploadDocumentForVendorApiV1VendorsVendorIdDocumentsPost(requestParameters.vendorId, requestParameters.file, requestParameters.fileName, options).then((request) => request(this.axios, this.basePath));
    }
}



