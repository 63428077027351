<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useQuasar } from 'quasar';

import { captureException } from '@/composables/Sentry.ts';
import { startStream } from '@/composables/EventStream.ts';
import { ForbiddenError } from '@/composables/ApiError';
import { errorMessage } from '@/composables/Notify';

import { useAuthStore } from '@/stores/AuthStore.ts';
import { useConfigStore } from '@/stores/ConfigStore.ts';

import Drawer from '@/components/DefaultLayout/DefaultDrawer.vue';
import DrawerRight from '@/components/DefaultLayout/DefaultDrawerRight.vue';
import Footer from '@/components/DefaultLayout/DefaultFooter.vue';

const $q = useQuasar();
const store = useAuthStore();
const configStore = useConfigStore();
const ctrl = new AbortController();

const drawerOpen = ref(false);

function closeDrawer() {
  drawerOpen.value = false;
}

onMounted(async () => {
  try {
    await store.getMe();
    await configStore.getOrganizationConfig();
    const user = localStorage.getItem('user');

    if (!user) return;

    store.user = JSON.parse(user);
    await store.userLastAccess();
  } catch (error) {
    if (error instanceof ForbiddenError) {
      errorMessage(error.message);

      return;
    }

    captureException(error, { message: 'Component: DefaultLayout, Hook: onMounted' });
  }

  try {
    startStream();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response && e.response.headers.get('Content-Type') === 'application/json') {
      return;
    }

    captureException(e, { message: 'Component: DefaultLayout, Function: startStream' });
  }

  const darkMode = localStorage.getItem('darkMode');

  if (!darkMode) return;

  $q.dark.set(JSON.parse(darkMode));
});

onBeforeUnmount(() => {
  ctrl.abort();
});
</script>

<template>
  <q-layout view="lHr lpR lfr">
    <Drawer />
    <q-page-container>
      <router-view />
    </q-page-container>
    <DrawerRight :drawer-open="drawerOpen" @close-drawer="closeDrawer" />
    <Footer />
  </q-layout>
</template>

<style lang="scss">
// Do not make scoped (styles bug).

.q-layout {
  background: $secondary-50;
  :deep(.q-page-container) {
    background: transparent !important;
    padding: 0 !important;
  }
}
</style>
