<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { defineAsyncComponent, computed } from 'vue';
import type { Component } from 'vue';

const props = defineProps({
  iconName: {
    type: String,
    required: true,
  },
  iconFolder: {
    type: String,
    required: true,
  },
  iconSize: {
    type: String,
    default: '',
  },
});

const icons = import.meta.glob('/src/assets/icons/**/*.svg');
const AsyncComp = computed(() => {
  const iconPath = `/src/assets/icons/svg/${props.iconFolder}/${props.iconName}.svg`;
  const loadComponent = icons[iconPath];
  if (!loadComponent) {
    throw new Error(`Icon not found: ${iconPath}`);
  }
  return defineAsyncComponent(() =>
    loadComponent().then((module) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const component: Component = module ? (module as any).default : null;
      return component;
    }),
  );
});
</script>

<template>
  <div class="icon-wrapp row items-center">
    <component :is="AsyncComp" :style="{ '--icon-size': iconSize }" />
    <slot name="tooltip" />
  </div>
</template>

<style scoped>
.icon-wrapp {
  padding: 0 !important;
}
svg {
  display: inline-block;
  width: var(--icon-size); /* Set a default icon size */
  height: auto;
}
</style>
