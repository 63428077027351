<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { vOnClickOutside } from '@vueuse/components';

import OTabPanels from '@/components/organisms/OTabPanels.vue';

import { profileModalIds } from '@/utils/DataTestIds.ts';

import { keyOutlined, settingsOutlined } from '@/assets/icons/materialSymbols/MaterialSymbols.ts';

defineProps({
  left: {
    type: String,
    default: '0',
  },
});

const emit = defineEmits(['hide']);
const activeTab = ref('settings');
const router = useRouter();

function hide() {
  emit('hide');
}

function changeTab(value: string) {
  activeTab.value = value;
  localStorage.setItem('scope-active-tab', value);
}

// const profileItems = [
//   {
//     id: 0,
//     icon: 'profile-edit',
//     text: 'Edit Profile',
//   },
//   {
//     id: 1,
//     icon: 'profile-user',
//     text: 'View Profile',
//     to: null,
//   },
// ];

const settingsItems = [
  // {
  //   id: 1,
  //   icon: 'profile-user',
  //   text: 'Account Settings',
  // },
  {
    id: 2,
    component: keyOutlined,
    text: 'API Tokens',
    to: 'tokens',
  },
];

function toPage(to: string) {
  router.push({
    name: to,
  });
}
</script>

<template>
  <q-card v-on-click-outside="hide" class="dialog__container" :style="{ left: left }">
    <q-card-section>
      <slot name="top" />
    </q-card-section>
    <OTabPanels
      :active-tab="activeTab"
      :tab-size="{ md: 12, lg: 12 }"
      default-tab="settings"
      @change-tab="changeTab"
    >
      <template #tabs>
        <!-- <q-tab content-class="my-tab" name="profile" label="Profile">
          <Icon icon-name="dialog-user" icon-folder="header" />
        </q-tab> -->
        <q-tab content-class="my-tab" name="settings" label="Settings">
          <q-icon
            :name="settingsOutlined"
            size="20px"
            color="grey"
            :data-test-id="profileModalIds.profileModalSettingsIcon"
          />
        </q-tab>
      </template>
      <template #panels>
        <!-- <q-tab-panel name="profile">
          <div
            v-for="item in profileItems"
            :key="item.id"
            class="row items-center"
            :class="[!item.to ? 'disabled' : '']"
          >
            <Icon :icon-name="item.icon" icon-folder="profile" class="q-mr-md" />
            <span>{{ item.text }}</span>
          </div>
        </q-tab-panel> -->
        <q-tab-panel name="settings">
          <div
            v-for="item in settingsItems"
            :key="item.id"
            class="row items-center"
            :class="[!item.to ? 'disabled' : '']"
            @click="item.to ? toPage(item.to) : null"
          >
            <q-icon
              :name="item.component"
              class="q-mr-md"
              size="20px"
              color="grey"
              :data-test-id="profileModalIds.profileModalTokenIcon"
            />
            <span>{{ item.text }}</span>
          </div>
        </q-tab-panel>
      </template>
    </OTabPanels>
  </q-card>
</template>

<style lang="scss" scoped>
.dialog__container {
  position: fixed;
  min-width: 290px;
  // min-height: 230px;
  bottom: 20px;
  z-index: 9999;
  :deep(.user) {
    span {
      @include paragraph-01(400, $secondary-800);
      margin-left: 1px;
    }
    .q-avatar {
      background: $secondary-100;
      span {
        @include paragraph-02(400, $secondary-800);
      }
    }
  }
  :deep(.user-info) {
    .user-name {
      @include paragraph-01(400, $common-4);
    }
    .user-email {
      @include caption(400, rgba(0, 0, 0, 0.45));
    }
  }
  :deep(.q-tab) {
    padding: 0;
    .q-tab__content {
      justify-content: flex-end;
      flex-direction: row-reverse !important;
      .q-tab__label {
        margin-left: 35px;
      }
    }
    svg {
      position: absolute;
      left: 20px;
      line-height: 0;
    }
  }
  :deep(.q-tab-panels) {
    margin: 0;
    .q-tab-panel {
      padding: 0;
      div {
        padding: 15px 20px;
        span {
          @include paragraph-01(400, $common-4);
        }
      }
      div:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
      }
      div:hover,
      i:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
  .disabled {
    opacity: 0.7;
  }
}
</style>
