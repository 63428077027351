import { useAuthStore } from '@/stores/AuthStore.ts';
import { Roles } from '@/client/api.ts';
import { captureException } from '@/composables/Sentry.ts';

export const UpperCaseRoles = {
  Owner: Roles.Owner.charAt(0).toUpperCase() + Roles.Owner.slice(1),
  Editor: Roles.Editor.charAt(0).toUpperCase() + Roles.Editor.slice(1),
  Reviewer: Roles.Reviewer.charAt(0).toUpperCase() + Roles.Reviewer.slice(1),
  Auditor: Roles.Auditor.charAt(0).toUpperCase() + Roles.Auditor.slice(1),
} as const;

export const userProjectRoles = async (projectId: string) => {
  const authStore = useAuthStore();

  if (!authStore.user || !('roles' in authStore.user)) {
    return [];
  }

  const relatedProjects = authStore.user.roles?.filter((role) => role.project_id === projectId);

  const rolesArray = relatedProjects?.map((role) => role.role);

  return rolesArray;
};

export const isOwner = (conceptId: string) => {
  const authStore = useAuthStore();

  return authStore.user?.id === conceptId;
};

export const isAdmin = () => {
  const authStore = useAuthStore();

  if (!authStore.user) return false;

  return authStore.user.is_org_admin || authStore.user.is_super_admin;
};

export const hasRights = (projectId: string, rightsRule: string) => {
  const authStore = useAuthStore();

  if (!authStore.user) return false;

  const relatedProject = authStore.user.rights[projectId];

  if (!relatedProject) return false;

  return Object.values(relatedProject).includes(rightsRule);
};

export const roleDescription = (role: string) => {
  switch (role) {
    case UpperCaseRoles.Owner:
      return 'The person responsible for the project execution and delivery (e.g. project manager, lead data scientist)';
    case UpperCaseRoles.Editor:
      return 'The person contributing to the project execution (e.g. data scientist, ML engineer, data engineer, data analyst)';
    case UpperCaseRoles.Reviewer:
      return 'The person reviewing the project delivery (e.g. external stakeholder, project member)';
    case UpperCaseRoles.Auditor:
      return 'The person accountable for the fullfillment of Controls (e.g model risk, AI governance)F';
  }
};

export const removeTokens = async () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};

export function useAuth() {
  const logout = () => {
    removeTokens();
    localStorage.removeItem('user');
    window.location.href = `${import.meta.env.VITE_API_URL}/api/auth/logout`;
  };

  const toProblemPage = () => {
    removeTokens();
    localStorage.removeItem('user');
    window.location.href = `${import.meta.env.VITE_API_URL}/problem`;
  };

  const refreshToken = async () => {
    try {
      const authStore = useAuthStore();
      await authStore.getAccessTokenFromRefreshToken();
      const access_token = localStorage.getItem('access_token');
      if (!access_token) {
        logout();
      }
    } catch (error) {
      captureException(error, {
        message: 'Composable: Auth.ts',
      });
    }
  };

  return { logout, refreshToken, toProblemPage };
}
